import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from '../Link'
import PartnerImageArticle from './components/PartnerImageArticle'
import TypeImageArticle from './components/TypeImageArticle'
import LocalSymbol from '../../images/MapSouthtyrol'
import PlayCircle from '../../images/VideoPlayCircle'
import ImageWidescreen from '../ImageWidescreen'
import ResizeDetector from '../ResizeDetector'
import { useMediaQuery } from '../../modules/media-query'

const ImageLink = styled(Link)`
  display: block;
  position: relative;
  color: transparent;
  text-decoration: none;
`
const LocalImage = styled(LocalSymbol)`
  stroke-width: 5.6px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  height: ${(props) => (props.view === 'big' ? '50px' : '35px')};

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: 30px;
  }
`

const CommunityImage = styled.img`
  stroke-width: 5.6px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  height: ${(props) => (props.view === 'big' ? '60px' : '40px')};

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: 40px;
  }
`

const WrapperLocalSymbol = styled.div`
  text-align: right;
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 8px;
  height: max-content;
  width: max-content;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    top: 5px;
    right: 5px;
  }
`

const LeftCornerImage = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 8px;
  left: 8px;
  width: 70%;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    top: 5px;
    left: 5px;
  }
`
const StyledPartnerItemArticle = styled(PartnerImageArticle)`
  height: ${(props) => (props.iconSize && props.iconSize <= 40 ? props.iconSize + 'px' : '40px')};
  margin-right: 5px;
`

const StyledPlayCircle = styled(PlayCircle)`
  height: ${(props) => (props.iconSize && props.iconSize <= 40 ? props.iconSize + 'px' : '40px')};
  margin-right: 5px;
`

const Wrapper = styled.div`
  align-self: center;
  position: relative;
  cursor: pointer;
`

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageArticle = ({
  image,
  video,
  hasVideo,
  url,
  local,
  type,
  view = 'small',
  partner,
  headline,
  title,
  onClick,
  text,
  department,
  date,
  adsTargeting,
  content,
  location,
  eventLocation,
  breadcrumb,
  OverlayComponent,
  communityNews,
  to,
  parentClass,
  noLazyLoading,
  communityLogo,
  ...props
}) => {
  const [iconSize, setIconSize] = useState(28)
  const mobileS = useMediaQuery('(max-width:450px)')
  const tabletS = useMediaQuery('(max-width:767px)')

  return (
    <Wrapper {...props} video={video}>
      <ResizeDetector
        onResize={(boundingClientRect) => {
          if (boundingClientRect.width <= 210) {
            setIconSize(Math.round(boundingClientRect.width / 6.6))
          } else {
            if (mobileS) {
              setIconSize(Math.round(boundingClientRect.width / 13.5))
              if (
                parentClass &&
                (parentClass.includes('WidgetVideo') || parentClass.includes('DescriptionInVideoItemArticle'))
              ) {
                setIconSize(Math.round(boundingClientRect.width / 12))
              }
            } else {
              if (tabletS) {
                setIconSize(Math.round(boundingClientRect.width / 13.5))
              } else {
                setIconSize(Math.round(boundingClientRect.width / 11))
              }
            }
          }
        }}
      >
        <ImageLink to={to} onClick={onClick}>
          <Overlay>
            <OverlayComponent />
          </Overlay>
          {(partner || type || hasVideo) && (
            <LeftCornerImage>
              {hasVideo && <StyledPlayCircle iconSize={iconSize} />}
              {partner && <StyledPartnerItemArticle iconSize={iconSize} partner={partner} />}
              {type && <TypeImageArticle type={type && type} />}
            </LeftCornerImage>
          )}
          {local && (
            <WrapperLocalSymbol iconSize={iconSize}>
              {!communityLogo && <LocalImage iconSize={iconSize} view={view} />}
              {communityLogo && (
                <CommunityImage
                  type={type}
                  department={department}
                  parentClass={parentClass}
                  src={communityLogo}
                  view={view}
                />
              )}
            </WrapperLocalSymbol>
          )}
          <ImageWidescreen image={image} title={title} noLazyLoading={noLazyLoading} />{' '}
        </ImageLink>
      </ResizeDetector>
    </Wrapper>
  )
}

ImageArticle.defaultProps = {
  type: 'null', //TODO before Golive change to null if required by API from WeMo
  OverlayComponent: () => false,
}

ImageArticle.propTypes = {
  url: PropTypes.string,
  image: PropTypes.object,
  view: PropTypes.oneOf(['small', 'big']),
  type: TypeImageArticle.propTypes.type,
  partner: PartnerImageArticle.propTypes.partner,
}

export default ImageArticle
