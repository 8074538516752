import React from 'react'
import Components from '../../topics/components'
import routes from '../../topics/routes'
import layouts from '../../topics/layouts'
import DlabLoader from '../DlabLoader'
import modules from '../../topics/modules'
import blocks from '../../topics/blocks'
//import components from '../../topics/components'

const Routes = (props) => (
  <DlabLoader routes={routes} components={Components} blocks={blocks} layouts={layouts} modules={modules} {...props} />
)

export default Routes
