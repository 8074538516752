import layoutBackground from './layouts/background'
import defaultThemeDesktop from '../../../../../../config/themes'

const getArticleLayout = (PassedComponent, { articleLayout, props }) => {
  if (articleLayout === 'pr') {
    //TODO no hardcoded route names - replace the logic for simplyguat
    return layoutBackground(PassedComponent, { color: defaultThemeDesktop.default.color.pr })
  } else {
    return PassedComponent
  }
}

export default getArticleLayout
