import React, { Component } from 'react'
import styled from 'styled-components'
import AdUnit from '../../../../components/AdUnit'
import ItemArticle from '../../../../topics/Article/components/ItemArticle'
import { Description0NakedItemArticle } from '../../../../topics/Article/components/ItemArticle/views/NakedItemArticle'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const AdsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledItemArticle = styled(ItemArticle)`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  ${Description0NakedItemArticle} {
    padding-top: 0;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-top: 0;
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

class BlockBigVideoItemArticle extends Component {
  render() {
    const { data, adIds, ...props } = this.props
    return (
      <Wrapper {...props}>
        <StyledItemArticle
          view="big_video"
          article={{
            image: data.image,
            breadcrumb: data.breadcrumb,
            title: data.title,
            text: data.text,
            local: data.local,
            partner: data.partner,
            type: data.type,
            url: data.url,
          }}
          video={data.video}
        />
        <AdsWrapper>
          <AdUnit adId={adIds[0]} />
          <AdUnit adId={adIds[1]} />
        </AdsWrapper>
      </Wrapper>
    )
  }
}

export default BlockBigVideoItemArticle
