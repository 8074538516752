import LayoutBuilder from '../../../../components/LayoutBuilder'
import CategoryArticlesPanel from '../../../../components/CategoryArticlesPanel'
import hasItems from '../../../../modules/has-items'

const BlockCategoryArticlesPanel = ({ layout, blocks, data: { articles, locale } = {}, data, loading, ...props }) => (
  <CategoryArticlesPanel articles={articles} disable={!locale && !loading} loading={loading}>
    {(categoryArticles) => (
      <LayoutBuilder
        layout={layout}
        data={data && hasItems(categoryArticles) && { ...data, articles: categoryArticles }}
        loading={loading || !hasItems(categoryArticles)}
        blocks={blocks}
        {...props}
      />
    )}
  </CategoryArticlesPanel>
)

export default BlockCategoryArticlesPanel
