/**
 * creates a unique string - is being used to prevent caching problems and other stuff.
 * @returns {string}
 */
const createUuid = () => {
  let dt = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx-'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

/**
 * created just for fun
 * @param s
 * @param uuid
 * @returns {*}
 */
const getUS = (s, uuid) => {
  return s.split(uuid + 'pm97')[1]
}

export { createUuid, getUS }
