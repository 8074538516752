import React, { useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import ErrorBoundary from '../../topics/Error/widgets/ErrorBoundary'
import translate from '../../modules/translate'
import consoleLogger from '../../modules/console-logger'
import getCustomApi from './modules/get-custom-api'
import getModifiedResponse from './modules/get-modified-response'
import PreComponent from './components/PreComponent'
import errorLog from '../../modules/error-log'
import loadable from '@loadable/component'
const LayoutDefault = loadable(() =>
  import(/* webpackChunkName: "LayoutDefault" , webpackPrefetch: true */ '../../layouts/Default'),
)

const ScreenLoader = ({
  match,
  location,
  history,
  view,
  initialData,
  route,
  showLayout = true,
  Screen,
  layout,
  blocks,
  modules,
  components,
  ...props
}) => {
  const [consoleLog] = consoleLogger('ScreenLoader')
  consoleLog('route location: ', location)
  consoleLog('route match: ', match)
  consoleLog('layout config: ', layout)
  consoleLog('initial data:  ', initialData)
  consoleLog('route:  ', route)
  consoleLog('modules:  ', modules)
  consoleLog('components:  ', components)
  consoleLog('blocks:  ', blocks)
  consoleLog('showLayout?:  ', showLayout)
  const routeOptions = route.getOptions()

  if (!Screen) {
    return false
  }

  const customApi = getCustomApi({ route, modules, match })
  const deactivatedByCustomApi = customApi === false

  const queryKey = route.getQueryKey({ location, match, customApi })
  const queryOptions = routeOptions.query && {
    ...routeOptions.query.options,
  }

  const uniqueLocationKey = location && location.key
  const queryClient = useQueryClient()
  useEffect(() => {
    queryClient.resetQueries(queryKey)
  }, [uniqueLocationKey, history])

  if (initialData) {
    queryOptions.initialData = initialData
  }

  let queryResult = {}
  if (queryKey && !deactivatedByCustomApi) {
    queryResult = useQuery(queryKey, queryOptions)
  }

  const { isLoading, isFetching, data, error: fetchError } = queryResult

  consoleLog('queryKey:  ', queryKey)
  consoleLog('queryOptions:  ', queryOptions)
  consoleLog('isLoading:  ', isLoading)
  consoleLog('isFetching:  ', isFetching)
  consoleLog('data:  ', data)
  consoleLog('error:  ', fetchError)
  let error = fetchError
  const preparedData = !isLoading && getModifiedResponse({ route, data, modules })
  if (preparedData && preparedData.status && preparedData.status === 404) {
    error = { status: 404 }
  }

  const errorOptions = {
    component: 'der Seite',
    logError: translate('dev_errorlog_screen', { view }),
  }

  const loading = (isLoading && !preparedData) || isFetching
  consoleLog('preparedData:  ', preparedData)
  return (
    <LayoutDefault
      meta={preparedData && preparedData.meta}
      waitAds={routeOptions.ads && routeOptions.ads.wait}
      pianoTargets={preparedData && preparedData.sPlusTargets}
      disable={!showLayout || !routeOptions.showLayout}
      adIds={layout && layout.general && layout.general.adIds}
      updateAds={uniqueLocationKey}
      {...props}
    >
      <ErrorBoundary error={error} {...errorOptions}>
        <PreComponent
          ready={!isLoading}
          response={preparedData}
          route={route}
          components={components}
          loading={loading}
        />
        <Screen
          response={preparedData}
          loading={loading}
          view={view}
          error={error}
          ready={!isLoading}
          match={match}
          location={location}
          history={history}
          fetching={isFetching}
          page={routeOptions.screen}
          layout={layout}
          blocks={blocks}
        />
      </ErrorBoundary>
    </LayoutDefault>
  )
}

ScreenLoader.getInitialData = async ({ queryClient, route, req, match, modules }) => {
  const location = {
    pathname: req.url,
  }

  const customApi = getCustomApi({ route, modules, match })
  const deactivatedByCustomApi = customApi === false

  if (deactivatedByCustomApi) {
    return false
  }

  const queryKey = route.getQueryKey({ location, match })

  if (!queryKey) {
    return false
  }

  try {
    await queryClient.fetchQuery({ queryKey, staleTime: 0 })
  } catch (error) {
    const errorStatus = error && error.response && error.response.status
    if (errorStatus === 408 || (error && error.message && error.message.includes('timeout'))) {
      error.response = {
        ...error.response,
        status: 408,
      }
      errorLog({ error, info: error.message, level: 'error' })
    }
    if (errorStatus !== 404 && error) {
      errorLog({ error, info: error && error.message, level: 'error' })
    }
    if (error) {
      throw error
    }
    throw {
      queryKey,
      location,
      customApi,
    }
  }
}

export default ScreenLoader
