import React, { useEffect, useState } from 'react'
import domToReact from 'html-react-parser/lib/dom-to-react'
import useScript from 'react-script-hook'
import LockedPinpoll from './components/LockedPinpoll'
import getPinpollData from './modules/get-pinpoll-data'

const ExternalPinpoll = (attribs, embedBoxChildren) => {
  const [loading, error] = useScript({
    src: 'https://tools.pinpoll.com/global.js',
    checkForExisting: true,
  })
  const isPinpollReady = !loading && !error
  const pinpollData = getPinpollData({ element: embedBoxChildren })
  const isPoll = pinpollData.mode === 'poll'
  useEffect(() => {
    if (!loading && !error && window && window.Pinpoll && !!window.Pinpoll.embed) {
      window.Pinpoll.embed.start()
    }
  }, [loading, error])

  return (
    <>
      {/*isPoll && <LockedPinpoll ready={isPinpollReady} pinpollId={pinpollData.id} />*/}
      <div id={'pinpollParentNode'}>{domToReact(embedBoxChildren)}</div>
    </>
  )
}

export default ExternalPinpoll
