import React from 'react'
import styled, { withTheme } from 'styled-components'
import NakedHeaderOnTour from '../../../../../../../OnTour/components/HeaderOnTour/views/NakedOnTourHeader'
import Button from '../../../../../../../../components/Button'
import translate from '../../../../../../../../modules/translate'
import GalleryPhotoSwipe from '../../../../../../../../components/GalleryPhotoSwipe'

const Wrapper = styled.div`
  position: relative;
  margin-left: -220px;
  margin-right: -220px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-left: -40px;
    margin-right: -40px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: -20px;
    margin-right: -20px;
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  right: 0;
  color: #fff;
  border-color: #fff;
  margin-top: -20px;
`

const StyledNakedHeaderOnTour = styled(NakedHeaderOnTour)`
    position: relative
    padding-top: ${(props) => props.theme.sizes.gapVertical};
    padding-bottom: calc(2*${(props) => props.theme.sizes.gapVertical});
    @media(max-width: 600px){
        padding-bottom: calc(3*${(props) => props.theme.sizes.gapVertical});
        .stol_logo_ontour {
            margin: 0 auto;
        }
        > div {
            padding-bottom: calc(3*${(props) => props.theme.mobile.sizes.gapVertical});
            margin: 0;
        }
        ${StyledButton}{
            width: calc(100% - 2*(${(props) => props.theme.mobile.sizes.gapEdge}) - 10px);
            bottom: 60px;
        }
    }
`

const StyledGalleryPhotoSwipe = styled(GalleryPhotoSwipe)`
  width: 100%;
  height: 120px;
  position: absolute;
  .ScrollbarsCustom-Wrapper {
    left: 0;
  }
`

const WrapperGallery = styled.div`
  overflow: hidden;
  white-space: nowrap;
  height: 170px;
  position: relative;
  background-color: white;
  margin-top: -90px;
  background-color: #fff;
  margin-left: ${(props) => props.theme.sizes.gapEdge};
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.sizes.breakPoint}) {
    margin-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.sizesbreakPoint}) {
    margin-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
  @media (max-width: 600px) {
    margin-top: -50px;
  }
`

const OnTourDetailArticle = ({ images, id, type, url, ...props }) => {
  return (
    <Wrapper {...props}>
      <StyledNakedHeaderOnTour>
        <StyledButton to={url}>{translate('ontour_view_gallery_button')}</StyledButton>
      </StyledNakedHeaderOnTour>
      <WrapperGallery>
        <StyledGalleryPhotoSwipe
          inverted={false}
          images={images}
          type={type}
          style={{ position: 'unset' }}
          wrapperProps={{ style: { left: '0px' } }}
          id={id}
        />
      </WrapperGallery>
    </Wrapper>
  )
}

export default withTheme(OnTourDetailArticle)
