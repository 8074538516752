import React from 'react'
import styled from 'styled-components'
import ContainerFull from '../../../../components/ContainerFull/index'
import HeaderVideo from '../../components/HeaderVideo/index'
import MoreVideo, { MoreVideo0StyledTitle, StyledItemArticle0MoreVideo } from './components/MoreVideo/index'
import { Title0TitleItemArticle } from '../../../Article/components/ItemArticle/components/DescriptionItemArticle/components/TitleItemArticle/index'
//import PropTypes from 'prop-types'
import ItemArticle from '../../../Article/components/ItemArticle/index'
import { Description0NakedItemArticle } from '../../../Article/components/ItemArticle/views/NakedItemArticle/index'

const StyledMoreVideo = styled(MoreVideo)`
  flex-basis: ${(props) => props.theme.sizes.col4};
  position: relative;
  padding-right: calc(${(props) => props.theme.sizes.gapEdge} - ${(props) => props.theme.sizes.gapColHalf});
  background-color: ${(props) => props.theme.color.multimedia.backgroundTertiary};
`
const Leader = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: ${(props) => props.theme.sizes.col8.slice(0, -1) + ' - ' + props.theme.sizes.gapEdge + ')'};
  margin-right: ${(props) => props.theme.sizes.gapColHalf};
`
const BigItemArticle = styled(ItemArticle)`
  order: 2;
  ${Title0TitleItemArticle} {
    font-size: ${(props) => props.theme.fontSize.title1};
  }
  ${Description0NakedItemArticle} {
    padding-right: 0;
  }
  @media (max-width: 450px) {
    ${Title0TitleItemArticle} {
      font-size: ${(props) => props.theme.fontSize.title3};
    }
  }
`

const StyledHeaderVideo = styled(HeaderVideo)``

const Wrapper = styled(ContainerFull)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
  ${MoreVideo0StyledTitle} {
    margin: 0;
    padding-top: 20px;
    height: 70px;
    margin-bottom: 40px;
  }

  @media (max-width: 840px) {
    ${StyledHeaderVideo} {
      justify-content: flex-start;
      padding: 0;
      margin-top: ${(props) => props.theme.sizes.gapVertical};
      margin-bottom: ${(props) => props.theme.sizes.gapVertical};
      &:before {
        display: none;
      }
    }
    ${Leader} {
      flex-basis: 100%;
      margin-right: ${(props) => props.theme.sizes.gapEdge};
    }
    ${StyledMoreVideo} {
      flex-basis: 100%;
      align-items: center;
      margin-left: -${(props) => props.theme.sizes.gapEdge};
      padding-left: ${(props) => props.theme.sizes.gapEdge};
    }
    ${StyledItemArticle0MoreVideo} {
      margin-bottom: ${(props) => props.theme.sizes.gapVertical};
    }
    ${MoreVideo0StyledTitle} {
      height: auto;
      margin-top: ${(props) => props.theme.sizes.gapVertical};
      margin-bottom: ${(props) => props.theme.sizes.gapVertical};
      font-size: ${(props) => props.theme.fontSize.title1};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${StyledItemArticle0MoreVideo} {
      margin-bottom: 0;
    }
  }
`

const WidgetVideo = ({ videos, ...props }) => (
  <Wrapper noGapEdge as="section" cinemaMode="multimedia" {...props}>
    <Leader>
      <StyledHeaderVideo />
      <BigItemArticle view="video" article={{ ...videos[0], description: undefined }} />
    </Leader>
    <StyledMoreVideo videos={videos.slice(1)} />
  </Wrapper>
)

WidgetVideo.propTypes = {
  cinemaMode: ContainerFull.propTypes.cinemaMode,
}

export default WidgetVideo
