import React from 'react'

const optionsYouTube = ({ domNode, youtubeAutoplay }) => {
  const { attribs } = domNode
  //youtube autoplay
  if (attribs && attribs.src && attribs.src.includes('youtube') && attribs.src.includes('?') && youtubeAutoplay) {
    return <iframe title="youtube" {...attribs} src={attribs.src + '&autoplay=1'}></iframe>
  }
  if (attribs && attribs.src && attribs.src.includes('youtube') && !attribs.src.includes('?') && youtubeAutoplay) {
    return <iframe title="youtube" {...attribs} src={attribs.src + '?autoplay=1'}></iframe>
  }
}

export default optionsYouTube
