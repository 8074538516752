import React from 'react'
import styled from 'styled-components'
import MapWeather from '../../components/MapWeather'
import PreviewSymbols from '../../components/PreviewSymbols'
import Title from '../../../../components/Title'
import Link from '../../../../components/Link'
import styledContainerQuery from 'styled-container-query'
import translate from '../../../../modules/translate'
import ContainerFull from '../../../../components/ContainerFull'
import moment from 'moment'
import firstLetterToUpperCase from '../../../../modules/first-letter-to-upper-case'

const Today = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: ${(props) => props.theme.sizes.col4};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.tablet.sizes.col4};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.mobile.sizes.col4};
  }
`
const StyledMapWeather = styled(MapWeather)`
  margin: 0;
`
const StyledTitleToday = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.titleBigger};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.titleBigger};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.titleBigger};
  }
  color: ${(props) => props.theme.color.textSecondary};
  line-height: 50px;
  margin: 0 0 0.5em;
`
const Preview = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: ${(props) => props.theme.sizes.col8};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.tablet.sizes.col8};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.mobile.sizes.col8};
  }
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    background-color: ${(props) => props.theme.color.textTertiary};
    top: 0;
    bottom: 0;
    width: 1px;
    height: auto;
    right: auto;
    left: -20px;
  }
`
const StyledTitlePreview = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: ${(props) => props.theme.color.textSecondary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 50px;
  margin: 0;
`
const StyledPreviewSymbols = styled(PreviewSymbols)``
const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  color: ${(props) => props.theme.color.stol};
  align-self: flex-end;
  margin-right: 2em;
  margin-top: 1em;
`

const Wrapper = styledContainerQuery(ContainerFull)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: ${(props) => props.theme.sizes.gapVerticalHalf};
  padding-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  position: relative;
  &:before {
    background-color: ${(props) => props.theme.color.background};
    border: ${(props) => props.theme.color.backgroundBorder} 1px solid;
    border-right-width: 0;
    border-left-width: 0;
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &:container(max-width: 979px) {
    ${Today}, ${Preview} {
      flex-basis: ${(props) => props.theme.sizes.col6};
    }

    ${StyledPreviewSymbols} {
      & > div {
        flex-basis: 50%;
      }

      &:nth-child(2n) {
        padding-right: 0;
      }
      &:nth-child(2n + 1) {
        padding-left: 0;
      }
    }
  }

  &:container(max-width: 739px) {
    ${Today}, ${Preview} {
      flex-basis: 100%;
    }

    ${Today} {
      margin-bottom: 20px;

      ${StyledLink} {
        margin-top: 0;
      }
    }

    ${Preview} {
      &:before {
        content: " ";
        display: block;
        position: absolute;
        background-color: ${(props) => props.theme.color.textTertiary};
        left: 0;
        right: 0;
        height: 1px;
        width: auto;
        top: -10px;
      }
    }
  }
`

const SkeletonWidgetWeather = ({ weatherDay, ...props }) => {
  const matchDays = (data) =>
    data.map((dataItem) => ({
      date: dataItem.date ? dataItem.date : moment().format(),
      icon: {
        name: dataItem.icon.name,
        description: dataItem.description,
      },
      minTemp: dataItem.minTemp,
      maxTemp: dataItem.maxTemp,
    }))

  const samplePreviewSymbolsData = [
    {
      icon: {
        name: 'a',
        description: 'sonnig',
      },
      minTemp: '',
      maxTemp: '',
    },
    {
      icon: {
        name: 'a',
        description: 'sonnig',
      },
      minTemp: '',
      maxTemp: '',
    },
    {
      icon: {
        name: 'a',
        description: 'sonnig',
      },
      minTemp: '',
      maxTemp: '',
    },
    {
      icon: {
        name: 'a',
        description: 'sonnig',
      },
      minTemp: '',
      maxTemp: '',
    },
  ]
  const days = matchDays(samplePreviewSymbolsData)
  return (
    <Wrapper as="section" {...props}>
      <Today>
        <StyledTitleToday tag="h2">{translate('weather_today', { weatherday: weatherDay })}</StyledTitleToday>
        <StyledMapWeather />
        <StyledLink to="/wetter">{translate('more')}</StyledLink>
      </Today>
      <Preview>
        <StyledTitlePreview tag="div">{translate('weather_preview')}</StyledTitlePreview>
        <StyledPreviewSymbols days={days} />
        <StyledLink to="/wetter">{translate('more')}</StyledLink>
      </Preview>
    </Wrapper>
  )
}

SkeletonWidgetWeather.propTypes = {}

SkeletonWidgetWeather.defaultProps = {
  margin: '0',
  weatherDay: firstLetterToUpperCase(translate('today')),
}

export default SkeletonWidgetWeather
