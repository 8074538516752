import React, { useEffect, useReducer, useState } from 'react'
import styled from 'styled-components'
import MessageTextNotVerified from './components/MessageTextNotVerified'
import DialogNotVerified from './components/DialogNotVerified'
import DialogMissingFields from './components/DialogMissingFields'
import { useUserContext } from '../../../../components/ContextUser'
import MessageTextMissingFields from './components/MessageTextMissingFields'
import consoleLogger from '../../../../modules/console-logger'
import usePostMessage from '../../../../modules/use-post-message'
import uniqueId from 'lodash/uniqueId'
import addUserSession from '../../../../layouts/Default/components/AutomaticLogin/modules/add-user-session'
import userSession from '../../modules/user-session'
import setSmsVerified from './modules/set-sms-verified'

const NotVerifiedWrapper = styled.div``

const reducerMethod = (state, action) => {
  switch (action) {
    case 'hide':
      return {
        ...state,
        all: false,
        modal: false,
      }
    case 'showModal':
      return {
        ...state,
        all: true,
        modal: true,
      }
    case 'closeModal':
      return {
        ...state,
        modal: false,
      }
    case 'differentPhone':
      return {
        ...state,
        all: true,
        modal: true,
      }
  }
}

const stateProps = {
  modal: false,
  all: false,
}

const NotVerified = () => {
  const [consoleLog] = consoleLogger('NotVerified')
  const [state, dispatch] = useReducer(reducerMethod, stateProps)
  const { profile } = useUserContext()
  const isProfileSave = usePostMessage({ nameEvent: 'piano-save-profil' })
  const missingFields = profile && profile.completeProfile === false
  const completeFields = profile && profile.completeProfile === true
  const smsNotVerified = profile && profile.smsVerified === false
  const smsToVerify = completeFields && smsNotVerified

  consoleLog('profile', profile)
  consoleLog('fields complete', !missingFields)
  consoleLog('sms verified?', !smsNotVerified)
  consoleLog('fields complete, but not yet verified?', smsToVerify)
  const lastUpdateProfile = profile && profile.updateTime
  consoleLog('profile update?', lastUpdateProfile)

  /*   useEffect(() => {
    if (smsToVerify && !state.modal) {
      dispatch('showModal')
    }
  }, [smsToVerify]) */

  useEffect(() => {
    consoleLog('is modal shown', state.modal)
    if (!state.modal) {
      const cmsPhoneNumber = profile && profile.telephone
      const pianoPhoneNumber = profile && profile.user_phone

      const differentPhoneNumber = cmsPhoneNumber && pianoPhoneNumber && cmsPhoneNumber !== pianoPhoneNumber
      consoleLog('phone number from cms?', cmsPhoneNumber)
      consoleLog('phone number direct from piano?', pianoPhoneNumber)
      consoleLog('cms and piano phone number different?', differentPhoneNumber)

      /*       if (smsToVerify || differentPhoneNumber) {
        setSmsVerified(false)
        dispatch('differentPhone')
      } */
    }
  }, [lastUpdateProfile])

  useEffect(() => {
    if (isProfileSave.length > 0) {
      consoleLog('profile save', isProfileSave)
      window.tp?.experience?.execute()
      addUserSession({ accessToken: userSession.getAccessToken(), forceUpdate: true })
    }
  }, [isProfileSave.length])

  return (
    <NotVerifiedWrapper>
      {missingFields && (
        <>
          <DialogMissingFields id={uniqueId()} open={missingFields} />
          <MessageTextMissingFields />
        </>
      )}
      {smsNotVerified && (
        <>
          <DialogNotVerified
            id={uniqueId()}
            open={state.modal}
            onClose={({ isSuccess }) => {
              if (isSuccess) {
                setSmsVerified(true)
                dispatch('hide')
              }
              if (!isSuccess) {
                dispatch('closeModal')
              }
            }}
          />
          <MessageTextNotVerified onClick={() => dispatch('showModal')} />
        </>
      )}
    </NotVerifiedWrapper>
  )
}

export default NotVerified
