import React from 'react'
import styledContainerQuery from 'styled-container-query'
import PropTypes from 'prop-types'
import ItemArticle from '../../components/ItemArticle'
import ListWidgetNakedLineArticle, {
  Date0ListWidgetNakedLineArticle,
  StyledIcon0ListWidgetNakedLineArticle,
  TimeLine0ListWidgetNakedLineArticle,
} from './components/ListWidgetNakedLineArticle'
import TitleWidgetNakedLineArticle from './components/TitleWidgetNakedLineArticle'
import WrapperWidgetNakeLineArticle from './components/WrapperWidgetNakedLineArticle'

const Wrapper = styledContainerQuery(WrapperWidgetNakeLineArticle)`
  &:container(max-width: 355px){
    ${TimeLine0ListWidgetNakedLineArticle}{
      &:after{
        width: 40%;
        margin-top: 20px;
        margin-bottom: 5px;
      }
    }
  }
  &:container(max-width: 280px){
    ${Date0ListWidgetNakedLineArticle}{
      font-size: 10px;
    }
  }
`

const WidgetNakedLineArticle = ({
  articles,
  numbering,
  timeLine,
  headlinePrefix,
  icon,
  title,
  containerArticle,
  children,
  onClick,
  textTag,
  forceTarget,
  ...props
}) => {
  return (
    <Wrapper containerArticle={containerArticle} {...props}>
      {title && <TitleWidgetNakedLineArticle tag={'span'}>{title}</TitleWidgetNakedLineArticle>}
      {icon && <StyledIcon0ListWidgetNakedLineArticle disabledHover icon={icon} />}

      <ListWidgetNakedLineArticle
        timeLine={timeLine}
        articles={articles}
        numbering={numbering}
        textTag={textTag}
        headlinePrefix={headlinePrefix}
        onClick={onClick}
        forceTarget={forceTarget}
      />
      {children}
    </Wrapper>
  )
}

WidgetNakedLineArticle.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      ...ItemArticle.propTypes,
    }),
  ),
  numbering: PropTypes.bool,
  articleTabs: PropTypes.bool,
  title: PropTypes.string,
  containerArticle: PropTypes.bool,
}

WidgetNakedLineArticle.defaultProps = {
  articles: [],
  numbering: false,
  articleTabs: false,
  containerArticle: false,
}

export default WidgetNakedLineArticle
export {
  Wrapper as Wrapper0WidgetLineArticle,
  ListWidgetNakedLineArticle as List0WidgetNakedLineArticle,
  TitleWidgetNakedLineArticle as StyledTitle0WidgetLineArticle,
}
