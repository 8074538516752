import React from 'react'
import styled from 'styled-components'
import GalleryPhotoSwipe from '../../../../../../../../components/GalleryPhotoSwipe'

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.multimedia.backgroundPrimary};
  overflow: hidden;
  white-space: nowrap;
  margin-left: -213px;
  width: 1078px;
  height: 171px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-left: -${(props) => props.theme.tablet.sizes.gapCol};
    width: calc(100% + (2 * ${(props) => props.theme.tablet.sizes.gapCol}));
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: -${(props) => props.theme.mobile.sizes.gapCol};
    width: calc(100% + (2 * ${(props) => props.theme.mobile.sizes.gapCol}));
  }
`

const GalleryDetailArticle = ({ images, id, type, fontSize, ...props }) => (
  <Wrapper {...props}>
    <GalleryPhotoSwipe images={images} id={id} type={type} fontSize={fontSize} />
  </Wrapper>
)

GalleryDetailArticle.propTypes = {
  /** Images of the gallery */
  type: GalleryPhotoSwipe.propTypes.type,
  images: GalleryPhotoSwipe.propTypes.images,
}

export default GalleryDetailArticle
