import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Title from '../../../../../../components/Title'

const StyledTitle = styled(Title)`
  font-family: ${(props) => props.theme.font.secondary};
  font-size: ${(props) => props.theme.fontSize.title3};
  height: ${(props) =>
    props.truncateOptions && props.truncateOptions.numberOfLines
      ? props.truncateOptions.numberOfLines *
          props.theme.lineHeight.title *
          parseInt(props.theme.fontSize.title3.slice(0, 2)) +
        'px'
      : '48px'};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title3};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title3};
    height: auto;
  }
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.title};
  transition: color 0.5s ease;
  color: inherit;
  margin-top: 0;
`

const truncateOptions = {
  numberOfLines: 2,
}

const OnTourTitle = ({ title, ...props }) => (
  <StyledTitle truncateOptions={truncateOptions} {...props}>
    {title}
  </StyledTitle>
)

OnTourTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default OnTourTitle
