import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ItemArticle from '../../../../topics/Article/components/ItemArticle'
import isServerSide from '../../../../modules/is-server-side'
import LoadMore from '../../../../components/LoadMore'

const BlockArticles = ({ data: { articles }, layout: { loadMore } = { loadMore: false } }) => {
  const [totalArticles, setTotalArticles] = useState(articles)

  useEffect(() => {
    setTotalArticles(articles)
  }, [articles])

  return (
    <>
      {totalArticles.map((article, index) => (
        <ItemArticle key={'ItemArticle' + index} view="small_landscape" article={article} />
      ))}
      {!isServerSide() && loadMore && (
        <LoadMore
          articles={totalArticles}
          afterLoadMore={(newArticles) => {
            setTotalArticles(newArticles)
          }}
        />
      )}
    </>
  )
}

BlockArticles.propTypes = {
  articles: PropTypes.arrayOf(ItemArticle.propTypes.article),
}

export default BlockArticles
