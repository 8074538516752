import React from 'react'
import styled from 'styled-components'
import WidgetOnTourItems from '../../../../topics/OnTour/widgets/WidgetOnTourItems'
import ContainerAd from '../../../../components/ContainerAd'

const StyledWidgetOnTourItems = styled(WidgetOnTourItems)`
  @media (max-width: 790px) {
    overflow-x: hidden;
  }
  justify-content: flex-start;
`

const Wrapper = styled.div`
  padding-top: 0;
`

const BlockOnTourItems = ({ data: { onTourItems }, numberItems, ...props }) => (
  <Wrapper {...props}>
    <StyledWidgetOnTourItems onTourItems={onTourItems} numberItems={numberItems} />
    <ContainerAd />
  </Wrapper>
)

BlockOnTourItems.propTypes = {
  numberItems: WidgetOnTourItems.propTypes.numberItems,
  onTourItems: WidgetOnTourItems.propTypes.onTourItems,
}

export default BlockOnTourItems
