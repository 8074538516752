import React from 'react'
import styled from 'styled-components'
import { Description0ImageDetailArticle, Wrapper0ImageDetailArticle } from '.'
import TextSkeleton from '../../../../../../components/Text/views/TextSkeleton/index.js'
import skeletonFade from '../../../../../../modules/mixins/skeleton-fade'

const Wrapper = styled(Wrapper0ImageDetailArticle)``
const SkeletonImage = styled.div`
  background-color: ${(props) => props.theme.color.skeletonScreen};
  ${skeletonFade};
  padding-top: 56.25%;
`
const SkeletonImageDetailArticle = ({ ...props }) => (
  <Wrapper {...props}>
    <SkeletonImage />
    <Description0ImageDetailArticle>
      <TextSkeleton lastWidth="150px" />
    </Description0ImageDetailArticle>
  </Wrapper>
)

export default SkeletonImageDetailArticle
