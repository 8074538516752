import ReactGA from 'react-ga'

const screenModifyResponseTag = ({
  response: {
    data: { identifier, articles },
  },
  response,
}) => {
  if (response.data.articles.length <= 0) {
    response.status = 404
  }
  return response
}

export default screenModifyResponseTag
