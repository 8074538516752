import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Scrollbar from 'react-scrollbars-custom'

const Wrapper = styled(Scrollbar)``

const invertedThumb = {
  background: 'rgba(255, 255, 255, .2)',
}

const invertedTrack = {
  background: 'rgba(255, 255, 255, .1)',
}

const Scrollbars = ({ children, inverted, ...props }) => {
  return (
    <Wrapper
      thumbYProps={inverted && { style: invertedThumb }}
      thumbXProps={inverted && { style: invertedThumb }}
      trackXProps={inverted && { style: invertedTrack }}
      trackYProps={inverted && { style: invertedTrack }}
      {...props}
    >
      {children}
    </Wrapper>
  )
}

Scrollbars.propTypes = {
  children: PropTypes.node,
  inverted: PropTypes.number,
}

Scrollbars.defaultProps = {
  inverted: false,
}

export default Scrollbars
