import ExperiencePiano from '../../../../vendor/Piano/components/ExperiencePiano'
import React from 'react'
import Sourcepoint from '../../../../vendor/Sourcepoint'
import Meta from '../../../../components/Meta'

const PreComponentMVPEvent = ({ route }) => {
  const routeOptions = route.getOptions()
  const meta = routeOptions.meta
  return (
    <>
      <Meta meta={meta} />
      <Sourcepoint />
      <ExperiencePiano adTargeting={meta.adsTargeting} section={meta.title} />
    </>
  )
}

export default PreComponentMVPEvent
