import { useCallback, useEffect, useState } from 'react'

const usePostMessage = ({ nameEvent }) => {
  const [events, setEvents] = useState([])

  const onProfileSave = useCallback(
    (event) => {
      if (event && typeof event.data === 'string' && event.data.includes(nameEvent)) {
        setEvents((previousEvents) => [...previousEvents, event])
      }
    },
    [events],
  )

  useEffect(() => {
    if (nameEvent) {
      window.addEventListener('message', onProfileSave)
      return () => {
        window.removeEventListener('message', onProfileSave)
      }
    }
  }, [])

  return events
}

export default usePostMessage
