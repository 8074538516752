import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import MapDistrictsSouthTyrol from '../MapDistrictsSouthTyrol'
import NavLink from '../NavLink'
import ContainerArticle from '../ContainerArticle'
import menuDistricts from '../../modules/menu-districts'
import options from '../../config/options'
import isServerSide from '../../modules/is-server-side'
import ContextMenu from '../ContextMenu'

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.sizes.gapVertical} 0;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: ${(props) => props.theme.tablet.sizes.gapVertical} 0;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: ${(props) => props.theme.mobile.sizes.gapVertical} 0;
  }
`
const StyledMapDistrictsSouthTyrol = styled(MapDistrictsSouthTyrol)``
const Names = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledNavLink = styled(NavLink)`
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
  padding-left: 0;
  &:before {
    left: -1em;
  }
`
const Wrapper = styled(({ open, ref, onMouseLeaveCallBack, ...props }) => (
  <ContainerArticle onMouseLeave={onMouseLeaveCallBack} ref={ref} {...props} />
))`
  ${(props) =>
    props.backgroundColor
      ? css`
          background-color: ${(props) => props.backgroundColor};
        `
      : css`
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          background-color: ${(props) => props.theme.color.background};
        `}
  ${(props) =>
    !props.open &&
    css`
      display: none;
    `};

  @media (max-width: 650px) {
    ${InnerWrapper} {
      flex-wrap: wrap;
      justify-content: center;
      ${Names} {
        height: 150px;
        width: 100%;
        flex-wrap: wrap;
        order: 2;
      }
      ${StyledMapDistrictsSouthTyrol} {
        order: 1;
        margin-bottom: ${(props) => props.theme.sizes.gapVertical};
      }
    }
  }

  @media (max-width: 400px) {
    ${InnerWrapper} {
      ${Names} {
        height: auto;
      }
      ${StyledMapDistrictsSouthTyrol} {
        height: 150px;
      }
    }
  }
`

const DistrictsNav = ({ selected, onMouseLeaveCallBack, backgroundColor, customDistricts, ...props }) => {
  const menuData = useContext(ContextMenu)

  let districts = menuData && menuDistricts(menuData)
  if (customDistricts) {
    districts = customDistricts && customDistricts
  }

  const selectedIdentifier = []

  const [chosenSelected, setChosenSelected] = useState(null)

  const findIdentifier = (selected, identifier) => selected.find((e) => Object.values(e).includes(identifier))
  const handlerMouseMove = ({ pageX, clientY }) => {
    if (!isServerSide()) {
      const deepestChild = document.elementFromPoint(pageX, clientY)
      const district = deepestChild && deepestChild.getAttribute('data-district')
      setChosenSelected(district)
    }
  }

  if (props.className) {
    props.className += ' '
  } else {
    props.className = ''
  }
  props.className += 'district_nav'

  return (
    <Wrapper onMouseLeave={onMouseLeaveCallBack} backgroundColor={backgroundColor} {...props}>
      <InnerWrapper onMouseMove={handlerMouseMove}>
        <Names>
          {districts.map(({ name, url, identifier }) => {
            const isSelectedMap =
              selected &&
              (findIdentifier(selected, options.Layout.districtsNav.nationwideIdentifier) ||
                findIdentifier(selected, identifier))
            const isSelectedNav = selected && findIdentifier(selected, identifier)
            if (isSelectedMap) selectedIdentifier.push(identifier)
            return (
              <StyledNavLink
                key={identifier}
                bold={isSelectedNav}
                selected={chosenSelected === identifier}
                data-district={identifier}
                to={url}
              >
                {name}
              </StyledNavLink>
            )
          })}
        </Names>
        <StyledMapDistrictsSouthTyrol district={chosenSelected} districts={selectedIdentifier} fill="grey" />
      </InnerWrapper>
    </Wrapper>
  )
}

DistrictsNav.propTypes = {
  open: PropTypes.bool,
  onMouseLeaveCallBack: PropTypes.func,
}

DistrictsNav.defaultProps = {
  open: false,
}

export default DistrictsNav
