//this are custom options for parsing html in stol_react to include a gall

import React, { Fragment } from 'react'
import Link from '../../components/Link'
import domToReact from 'html-react-parser/lib/dom-to-react'
import PropTypes from 'prop-types'
import SportnewsBannerDetailArticle from '../../topics/Article/components/DetailArticle/components/SportnewsBannerDetailArticle'
import VideoDetailArticle from '../../topics/Article/components/DetailArticle/components/VideoDetailArticle'
import ArticleSmartAd, { SMARTAD } from './modules/article-smart-ad'
import simplifyImage from './modules/simplify-image'
import optionsYouTube from './modules/options-youtube'
import ezHeadlinePaper from './modules/ez-headline-paper'
import embedExternal from './modules/embed-external'
import embedInternal from './modules/embed-internal'
import { scramble } from 'scrambled-text'
import ErrorBoundary from '../../topics/Error/widgets/ErrorBoundary'
import translate from '../translate'

const parserOptions = (parserProps) => {
  const embeds = parserProps && parserProps.embeds
  const adComponents = parserProps && parserProps.adComponents
  const allowedHtml = parserProps && parserProps.allowedHtml
  const dropImageAttribs = parserProps && parserProps.dropImageAttribs
  const youtubeAutoplay = parserProps && parserProps.youtubeAutoplay
  const articleLayout = parserProps && parserProps.articleLayout
  const scrambleText = parserProps && parserProps.scrambleText

  const isSportnews = articleLayout === 'sportnews'

  const smartAd = new ArticleSmartAd(adComponents)

  const concatenateChildren = (children) => {
    let result = ''
    children.forEach((child) => {
      if (child.data) {
        result += child.data
      }
    })
    return result
  }

  return {
    replace: (domNode) => {
      const { attribs, name, children, data, type } = domNode
      if (name === 'h3') {
        domNode.children.forEach((c) => {
          if (c.name === 'a') {
            c.name = null
            c.attribs = null
            c.type = 'text'
            c.data = c.children && c.children.length > 0 ? c.children[0].data : ''
            c.children = null
          }
        })
      }
      if (allowedHtml && !allowedHtml.includes(name)) return <Fragment>{data}</Fragment>
      if (scrambleText && type === 'text') {
        domNode.data = scramble(data, { preserveCasing: true })
      }
      const DesktopAd = smartAd.showDesktopAd({ domNode })
      if (DesktopAd) {
        return (
          <ErrorBoundary logError={translate('dev_errorlog_htmlparser_desktopad')}>
            <DesktopAd />
          </ErrorBoundary>
        )
      }

      const IframeYoutube = optionsYouTube({ domNode, youtubeAutoplay })
      if (IframeYoutube) return IframeYoutube

      switch (name) {
        case 'img':
          const ImageSimple = simplifyImage({ domNode, dropImageAttribs })
          if (ImageSimple) return ImageSimple
          break
        case 'br':
          if (!isSportnews) {
            const MobileAd = smartAd.showMobileAd({ domNode })

            if (MobileAd)
              return (
                <ErrorBoundary logError={translate('dev_errorlog_htmlparser_mobilead')}>
                  <br />
                  <MobileAd />
                </ErrorBoundary>
              )
          }
          break
        case 'h3':
          if (!isSportnews) {
            const MobileAd = smartAd.showMobileAd({ domNode })

            if (MobileAd)
              return (
                <ErrorBoundary logError={translate('dev_errorlog_htmlparser_mobilead')}>
                  <br />
                  <MobileAd />
                  <h3>{domNode.children && domNode.children.length > 0 && concatenateChildren(domNode.children)}</h3>
                </ErrorBoundary>
              )
          }
          break

        case 'a':
          if (ezHeadlinePaper({ domNode })) return false
          const reactElement = domToReact(children)
          return (
            <Link autoExternalLinkIcon to={attribs.href} title={attribs.title} id={attribs.id}>
              {typeof reactElement === 'string' ? reactElement.trim() : reactElement}
            </Link>
          )
        case 'div':
          const EmbedExternal = embedExternal({ domNode })
          if (EmbedExternal) {
            return EmbedExternal
          }
          break
        case 'embed':
          const EmbedInternal = embedInternal({ domNode, embeds })
          if (EmbedInternal) return EmbedInternal
          break
        case 'sportnews':
          const MobileAd = smartAd.showMobileAd({ domNode, show: true })
          return (
            <ErrorBoundary logError={translate('dev_errorlog_htmlparser_mobilead_spn')}>
              <Fragment>
                <SportnewsBannerDetailArticle url={attribs.url} />
                <br />
                <br />
                {MobileAd && <MobileAd />}
              </Fragment>
            </ErrorBoundary>
          )
        case 'video-jw':
          return (
            <VideoDetailArticle
              video={{ type: 'jw', jwId: attribs['video-id'] }}
              showAds={adComponents.showAds}
              adTargeting={adComponents.targeting}
              showDesc={false}
            />
          )
        default:
          return
      }
    },
  }
}

parserOptions.propTypes = {
  embeds: PropTypes.object,
  adComponents: PropTypes.object,
  scrambleText: PropTypes.bool,
}

parserOptions.defaultProps = {
  scrambleText: false,
}

export default parserOptions
export { SMARTAD }
