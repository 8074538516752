const jobsSLCSlotIDs = {
  desktop:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/13005116-12fe-4baf-aa23-404b68bcd87f?p=r'
      : 'https://delivery.connect.suedtirol.live/json/b8b5a220-ba31-4a3b-8856-7ec5ac222dd2?p=r',
  tablet:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/13005116-12fe-4baf-aa23-404b68bcd87f?p=r'
      : 'https://delivery.connect.suedtirol.live/json/e34bdfed-296f-4725-ae97-c1bd584a900c?p=r',
  mobile:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/13005116-12fe-4baf-aa23-404b68bcd87f?p=r'
      : 'https://delivery.connect.suedtirol.live/json/b7c0f4c3-74ba-4b7f-8bf9-ca731da40d26?p=r',
  tabletApp:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/13005116-12fe-4baf-aa23-404b68bcd87f?p=r'
      : 'https://delivery.connect.suedtirol.live/json/e7d39be4-3701-4e21-b6f9-14f69f82423e?p=r',
  mobileApp:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/13005116-12fe-4baf-aa23-404b68bcd87f?p=r'
      : 'https://delivery.connect.suedtirol.live/json/51c933c6-3050-480b-a561-79fcde7650e9?p=r',
}

export { jobsSLCSlotIDs }
