import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Link from '../Link'
import CircularProgress from '../CircularProgress'

const Wrapper = styled(Link)`
  display: inline-block;
  min-width: 100px;
  text-transform: uppercase;
  padding: 0.4em 1.5em;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 400;
  text-align: center;
  transition: background-color 0.2s ease-in, box-shadow 0.1s ease-in-out, border-color 0.2s ease-in-out,
    color 0.15s ease-in-out;
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.color.stol};
  color: ${(props) => props.theme.color.stol};
  align-self: center;
  line-height: ${(props) => props.theme.lineHeight.title};
  user-select: none;
  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;
      &:focus,
      &:hover {
        outline: 0;
        background-color: ${(props) => props.theme.color.stol};
        color: ${(props) => props.theme.color.textInverted};
      }
    `}
  &:active {
    box-shadow: inset 0 8px 5px -2px rgba(0, 0, 0, 0.1);
  }

  ${(props) =>
    props.view === 'grey' &&
    css`
      border-color: ${(props) => props.theme.color.textSecondary};
      color: ${(props) => props.theme.color.textSecondary};

      ${(props) =>
        !props.disabled &&
        css`
          &:focus,
          &:hover {
            background-color: ${(props) => props.theme.color.textSecondary};
          }
        `}
    `}

  ${(props) =>
    props.view === 'inverted' &&
    css`
      border-color: ${(props) => props.theme.color.textInverted};
      color: ${(props) => props.theme.color.textInverted};
      &:focus,
      &:hover {
        background-color: ${(props) => props.theme.color.textInverted};
        color: ${(props) => props.theme.color.textPrimary};
      }
    `}

  ${(props) =>
    props.view === 'block' &&
    css`
      background: ${(props) => props.theme.color.background};
      border-width: 1px;
      border-color: ${(props) => props.theme.color.textTertiary};
      border-radius: 0;
      padding: 0.5em;
      color: ${(props) => props.theme.color.textPrimary};
      min-width: auto;
      &:focus,
      &:hover {
        background-color: #ddd;
        color: ${(props) => props.theme.color.textPrimary};
      }
    `}
`

const Button = ({ onClick, view, children, disabled, loading, ...props }) => (
  <Wrapper
    {...props}
    view={disabled ? 'grey' : view}
    onClick={!disabled && onClick}
    className={props.className && props.className + ' Button'}
    role="button"
    disabled={disabled}
  >
    {!loading && children}
    {loading && <CircularProgress align="center" size={20} />}
  </Wrapper>
)

Button.propTypes = {
  onClick: Link.propTypes.to,
  view: PropTypes.oneOf(['default', 'grey', 'inverted', 'block']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  view: 'default',
}

export default Button

export { Wrapper as Wrapper0Button }
