import React from 'react'
import styled, { withTheme } from 'styled-components'
import ImageArticle from '../../../../../../components/ImageArticle'
import translate from '../../../../../../modules/translate'
import LightBox from '../../../../../../components/LightBox'
import { ImageFormat } from '../../../../../../modules/parse'
import { translateFontSizeToTheme } from '../../../../../../modules/font'
import isStolApp from '../../../../../../modules/is-stolapp'
import isServerSide from '../../../../../../modules/is-server-side'

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.backgroundBorder};
  .image {
    cursor: zoom-in;
  }
`
const Description = styled.p`
  margin: 0;
  padding: 10px;
  min-height: 35px;
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.color.textSecondary};
  font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.default.fontSize.small)};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.tablet.fontSize.small)};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.mobile.fontSize.small)};
  }
`

class ImageDetailArticle extends React.Component {
  constructor(props) {
    super(props)
    this.state = { lightBoxIsOpen: false }
    this.openLightBox = this.openLightBox.bind(this)
    this.closeLightBox = this.closeLightBox.bind(this)
  }
  openLightBox(image) {
    if (!isServerSide()) {
      let nativeUrl = `image-single://show?url=${image.url}${
        image.copyright !== null ? '&copyright=' + image.copyright : ''
      }${image.description !== null ? '&description=' + image.description : ''}`
      if (isStolApp()) {
        try {
          window.location.href = nativeUrl
        } catch (error) {}
      } else {
        this.setState({
          lightBoxIsOpen: true,
        })
      }
    }
  }
  closeLightBox() {
    this.setState({
      lightBoxIsOpen: false,
    })
  }

  render() {
    const {
      image,
      type,
      partner,
      local,
      fontSize,
      allowOtherFormat,
      duplicateDescription,
      view,
      communityLogo,
      noLazyLoading,
      department,
      ...props
    } = this.props
    const thumbnail = new ImageFormat(image.url)
    const description = image.description && `${image.description} ${image.copyright !== null ? '-' : ''} `
    const copyright = image.copyright ? translate('image_copyright', { copyright: image.copyright }) : ''
    image.allowOtherFormat = allowOtherFormat
    return (
      <Wrapper {...props}>
        <ImageArticle
          id={'InlineImageLink'}
          image={image}
          type={type}
          partner={partner}
          communityLogo={communityLogo}
          view={view}
          local={local}
          onClick={() => this.openLightBox(image)}
          to={image.link}
          autoExternalLinkIcon={false}
          noLazyLoading={noLazyLoading}
          department={department}
        />
        {!image.link && (
          <LightBox
            images={[
              {
                src: thumbnail.format('max_1280x850'),
                thumbnail: thumbnail.format('detail_521x293'),
                w: 850 * image.ratio,
                h: 850,
                title: `${description ? description : ''} ${copyright ? copyright : ''}`,
              },
            ]}
            isOpen={this.state.lightBoxIsOpen}
            onClose={this.closeLightBox}
          />
        )}
        {(description || copyright) && !(partner === 'sportnews' && duplicateDescription) && (
          <Description fontSize={fontSize}>
            {description} {copyright}
          </Description>
        )}
      </Wrapper>
    )
  }
}

export default withTheme(ImageDetailArticle)

export {
  Wrapper as Wrapper0ImageDetailArticle,
  Description as Description0ImageDetailArticle,
  ImageArticle as ImageArticle0ImageArticle,
}
