import React from 'react'
import styled from 'styled-components'
import skeletonFade from '../../../../../../modules/mixins/skeleton-fade'
import TextSkeleton, { Line0TextSkeleton } from '../../../../../../components/Text/views/TextSkeleton'

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.skeletonScreen};
  ${skeletonFade};
  width: 100%;
  position: relative;
  display: block;
  flex-direction: column;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
`

const StyledSecondTextSkeleton = styled(TextSkeleton)`
  width: 20%;
  position: absolute;
  left: 0;
  bottom: 35px;
  ${Line0TextSkeleton} {
    border-width: 20px;
  }
`

const StyledFirstTextSkeleton = styled(TextSkeleton)`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  ${Line0TextSkeleton} {
    border-width: 30px;
  }
`
const ImageSkeleton = styled.div`
  width: 100%;
  padding-top: ${(props) => (props.second ? '55.78%' : '56.25%')};
  position: relative;
  @media (max-width: ${(props) => props.theme.default.breakPoint + 'px'}) {
    padding-top: 56.25%;
  }
  @media (max-width: 767px) {
    padding-top: calc((100vw - 20px - 17px) * 56.25 / 100);
  }
`
const SkeletonLeaderItemArticle = ({ ...props }) => (
  <Wrapper {...props}>
    <ImageSkeleton second={props['second_leader_item']}>
      <StyledSecondTextSkeleton />
      <StyledFirstTextSkeleton />
    </ImageSkeleton>
  </Wrapper>
)

export default SkeletonLeaderItemArticle
export { Wrapper as Wrapper0SkeletonSmallLandscapeItemArticle }
