import React from 'react'
import PropTypes from 'prop-types'
import AccountPiano from './screens/AccountPiano'
import ShowIdPiano from './screens/ShowIdPiano'
import LogoutPiano from './screens/LogoutPiano'
import { SCREEN_PIANO } from '../../../../vendor/Piano/modules/show-modal-id'

const IdPiano = ({ view, ...props }) => {
  let screen
  switch (view) {
    case 'account':
      return <AccountPiano {...props} />
    case 'profile.register':
      screen = SCREEN_PIANO.register
      break
    case 'profile.restore':
      screen = SCREEN_PIANO.restore
      break
    case 'profile.new_password':
      screen = SCREEN_PIANO.newPassword
      break
    case 'profile.logout':
      return <LogoutPiano {...props} />
    default:
      screen = SCREEN_PIANO.login
  }
  return <ShowIdPiano screen={screen} {...props} />
}

IdPiano.propTypes = {
  view: PropTypes.oneOf(['account', 'profile.register', 'profile.restore', 'profile.new_password', 'profile.logout']),
}

IdPiano.defaultProps = {
  view: 'piano.login',
}

export default IdPiano
