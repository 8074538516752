import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NakedItemOnTour from '../NakedItemOnTour'

const StyledNakedItemOnTour = styled(NakedItemOnTour)`
  background-color: #ffff;
  flex: 1;
`

const WidgetItemOnTourWithEventTipps = ({ headline, title, start, image, url, ...props }) => (
  <StyledNakedItemOnTour headline={headline} title={title} image={image} url={url} start={start} {...props} />
)

WidgetItemOnTourWithEventTipps.propTypes = {
  headline: NakedItemOnTour.propTypes.headline,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default WidgetItemOnTourWithEventTipps
