import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import WidgetWeather from '../../../../topics/Weather/widgets/WidgetWeather'
import AdUnit from '../../../../components/AdUnit'
import ErrorBoundary from '../../../../topics/Error/widgets/ErrorBoundary'
import hasItems from '../../../../modules/has-items'
import translate from '../../../../modules/translate'

const Wrapper = styled.div``

class BlockWeather extends PureComponent {
  render() {
    const { data, adIds, ...props } = this.props
    return (
      <Wrapper {...props}>
        {hasItems(data) && (
          <ErrorBoundary component={translate('weather1')}>
            <WidgetWeather data={data} adIds={adIds} />
          </ErrorBoundary>
        )}
      </Wrapper>
    )
  }
}
//TODO extract Ad from Widget and place it into this component

BlockWeather.propTypes = {
  adIds: PropTypes.arrayOf(AdUnit.propTypes.adId),
}

export default BlockWeather
