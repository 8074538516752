import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import showModalId, { SCREEN_PIANO } from '../../../../../../vendor/Piano/modules/show-modal-id'

const Wrapper = styled.div``

const ShowIdPiano = ({ screen, ...props }) => {
  useEffect(() => {
    showModalId({ screen: screen, elementSelector: '#splus-login' })
  }, [])

  return <Wrapper id="splus-login" {...props} />
}

ShowIdPiano.propTypes = {
  screen: PropTypes.oneOf(Object.values(SCREEN_PIANO)),
  modal: PropTypes.bool,
}

ShowIdPiano.defaultProps = {
  screen: 'login',
}

export default ShowIdPiano
