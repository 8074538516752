import styled from 'styled-components'

const layoutBackground = (PassedComponent, { color }) => {
  return styled(PassedComponent)`
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -${props => props.theme.sizes.gapVerticalHalf};
      left: 0;
      bottom: 0;
      right: 0;
      background-color: ${color};
    }

    @media (max-width: ${props => props.theme.mobile.breakPoint + 'px'}) {
      &:before {
        top: -${props => props.theme.mobile.sizes.gapVerticalHalf};
      }
    }
  `
}

export default layoutBackground
