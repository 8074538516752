import React from 'react'
import styled from 'styled-components'
import translate from '../../../../../../modules/translate/index'
import Title from '../../../../../../components/Title/index'
import Button from '../../../../../../components/Button/index'
import ItemArticle from '../../../../../Article/components/ItemArticle/index'

const StyledTitle = styled(Title)`
  color: ${(props) => props.theme.color.textInvertedSecondary};
  font-size: ${(props) => props.theme.fontSize.title2};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title2};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title2};
  }
  font-weight: ${(props) => props.theme.fontWeight.regular};
  align-items: flex-end;
  display: flex;
  color: #fff;
`
const StyledItemArticle = styled(ItemArticle)`
  max-width: calc(100% - ${(props) => props.theme.sizes.gapColHalf});
  margin-bottom: calc(${(props) => props.theme.sizes.gapVerticalHalf} + 5px);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: calc(${(props) => props.theme.tablet.sizes.gapVerticalHalf} + 10px);
    max-width: calc(100% - ${(props) => props.theme.tablet.sizes.gapColHalf});
    margin-bottom: calc(${(props) => props.theme.tablet.sizes.gapVerticalHalf} + 5px);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    max-width: unset;
    width: 300px;
    padding-right: ${(props) => props.theme.sizes.gapColHalf};
    margin-bottom: 0px;
  }
`
const StyledButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
`
const Wrapper = styled.div`
  padding-left: ${(props) => props.theme.sizes.gapColHalf};
  padding-right: ${(props) => props.theme.sizes.gapColHalf};
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapColHalf};
    padding-right: ${(props) => props.theme.tablet.sizes.gapColHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.mobile.sizes.gapColHalf};
    padding-right: ${(props) => props.theme.mobile.sizes.gapColHalf};
  }
  @media (max-width: 450px) {
    padding: 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.sizes.gapVerticalHalf};
  margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
`

const ItemArticleWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    height: 210px;
  }
`

const ItemArticleWrapperHelper = styled.div`
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: 190px;
    overflow: hidden;
  }
`

const MoreVideo = ({ videos, ...props }) => (
  <Wrapper {...props}>
    <StyledTitle tag="h2">{translate('videos_newest')}</StyledTitle>
    <ItemArticleWrapperHelper>
      <ItemArticleWrapper>
        <StyledItemArticle view="description_in_video" article={{ ...videos[0], description: undefined }} />
        <StyledItemArticle article={{ ...videos[1], description: undefined }} view="description_in_video" />
      </ItemArticleWrapper>
    </ItemArticleWrapperHelper>
    <ButtonWrapper>
      <StyledButton to={'videos'} view="inverted">
        {translate('videos_all')}
      </StyledButton>
    </ButtonWrapper>
  </Wrapper>
)

export default MoreVideo
export { StyledTitle as MoreVideo0StyledTitle, StyledItemArticle as StyledItemArticle0MoreVideo }
