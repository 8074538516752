import BlockBigVideoItemArticle from '../layouts/Blocks/views_video/BlockBigVideoItemArticle'
import BlockArticles from '../layouts/Blocks/views_article/BlockArticles'
import BlockTwoLandscapeArticle from '../layouts/Blocks/views_article/BlockTwoLandscapeArticle'
import BlockThreeLandscapeArticle from '../layouts/Blocks/views_article/BlockThreeLandscapeArticle'
import BlockBigOneArticle from '../layouts/Blocks/views_article/BlockBigOneArticle'
import BlockEventsAndOnTourItems from '../layouts/Blocks/views_ontour/BlockEventsAndOnTourItems'
import BlockOnTourItems from '../layouts/Blocks/views_ontour/BlockOnTourItems'
import BlockAdTwo from '../layouts/Blocks/views_ads/BlockAdTwo'
import BlockBigArticle from '../layouts/Blocks/views_article/BlockBigArticle'
import BlockEventsAndAds from '../layouts/Blocks/views_article/BlockEventsAndAds'
import BlockFacebookAndArticle from '../layouts/Blocks/views_article/BlockFacebookAndArticle'
import BlockOnTour from '../layouts/Blocks/views_ontour/BlockOnTour'
import BlockThreeArticle from '../layouts/Blocks/views_article/BlockThreeArticle'
//import BlockThreeHotelsPretty from '../layouts/Blocks/views_article/BlockThreeHotelsPretty'
//import BlockThreeHotelsCommercial from '../layouts/Blocks/views_article/BlockThreeHotelsCommercial'
import BlockVideoArticle from '../layouts/Blocks/views_article/BlockVideoArticle'
import BlockWeather from '../layouts/Blocks/views_weather/BlockWeather'
import WidgetInstagram from './Social/widgets/WidgetInstagram'
import WidgetLeader from './Article/widgets/WidgetLeader'
import BlockAdSingle from '../layouts/Blocks/views_ads/BlockAdSingle'
import Page from './Search/components/SearchResult/components/AllCategories/components/Page'
import BlockSingleLandscapeArticle from '../layouts/Blocks/views_article/BlockSingleLandscapeArticle'
import BlockTitleTopic from '../layouts/Blocks/views/BlockTitleTopic'
import BlockDistrictTitleTopic from '../layouts/Blocks/views/BlockDistrictTitleTopic'
import WidgetLeaderSkeleton from './Article/widgets/WidgetLeader/skeleton'
import SkeletonBlockBigArticle from '../layouts/Blocks/views_article/BlockBigArticle/skeleton'
import SkeletonBlockThreeArticle from '../layouts/Blocks/views_article/BlockThreeArticle/skeleton'
import SkeletonWidgetRackpaper from './Paper/widgets/WidgetRackPaper/skeleton'
import SkeletonBlockMostReadArticle from '../layouts/Blocks/views_article/BlockMostReadArticle/skeleton'
import SkeletonLandscapeItemArticle from './Article/components/ItemArticle/views_default/SmallLandscapeItemArticle/skeleton'
import SkeletonWidgetInstagram from './Social/widgets/WidgetInstagram/skeleton'
import SkeletonBlockFacebookAndArticle from '../layouts/Blocks/views_article/BlockFacebookAndArticle/skeleton'
import SkeletonWidgetWeather from './Weather/widgets/WidgetWeather/skeleton'
import BlockCategoryArticlesPanel from '../layouts/Blocks/views/BlockCategoryArticlesPanel'
import SkeletonBlockBigOneArticle from '../layouts/Blocks/views_article/BlockBigOneArticle/skeleton'
import SkeletonBlockThreeLandscapeArticle from '../layouts/Blocks/views_article/BlockThreeLandscapeArticle/skeleton'
import loadable from '@loadable/component'

const BlockTabs = loadable(() =>
  import(/* webpackChunkName: "BlockTabs", webpackPrefetch: true */ '../layouts/Blocks/views/BlockTabs'),
)
const BlockMostReadArticle = loadable(() =>
  import(
    /* webpackChunkName: "BlockMostReadArticle", webpackPrefetch: true */ '../layouts/Blocks/views_article/BlockMostReadArticle'
  ),
)
const WidgetDistrictNav = loadable(() =>
  import(/* webpackChunkName: "WidgetDistrictNav", webpackPrefetch: true */ './Home/widgets/WidgetDistrictNav'),
)
const BlockJobs = loadable(() =>
  import(/* webpackChunkName: "BlockJobs", webpackPrefetch: true */ '../layouts/Blocks/views_article/BlockJobs'),
)
const WidgetRackPaper = loadable(() =>
  import(/* webpackChunkName: "WidgetRackPaper", webpackPrefetch: true */ './Paper/widgets/WidgetRackPaper'),
)

const blockKeys = {
  title: (props) => ({
    component: BlockTitleTopic,
    skeleton: BlockTitleTopic,
  }),
  title_district: (props) => ({
    component: BlockDistrictTitleTopic,
    skeleton: BlockDistrictTitleTopic,
  }),
  category_articles_panel: (props) => ({
    component: BlockCategoryArticlesPanel,
    skeleton: BlockCategoryArticlesPanel,
  }),
  big_video: (props) => ({
    component: BlockBigVideoItemArticle,
  }),
  articles: (props) => ({
    component: BlockArticles,
    skeleton: (props) => <SkeletonBlockThreeLandscapeArticle numberItems={5} {...props} />,
  }),
  two_landscape_article: (props) => ({
    component: BlockTwoLandscapeArticle,
  }),
  three_landscape_article: (props) => ({
    component: BlockThreeLandscapeArticle,
    skeleton: SkeletonBlockThreeLandscapeArticle,
  }),
  big_one_article: (props) => ({
    component: BlockBigOneArticle,
    skeleton: SkeletonBlockBigOneArticle,
  }),
  events_and_ontour_items: (props) => ({
    component: BlockEventsAndOnTourItems,
  }),
  ontour_items: (props) => ({
    component: BlockOnTourItems,
  }),
  ad_two: (props) => ({
    component: BlockAdTwo,
  }),
  big_article: (props) => ({
    component: BlockBigArticle,
    skeleton: SkeletonBlockBigArticle,
  }),
  events: (props) => ({
    component: BlockEventsAndAds,
  }),
  facebook_article: (props) => ({
    component: BlockFacebookAndArticle,
    skeleton: SkeletonBlockFacebookAndArticle,
  }),
  mostread_article: (props) => ({
    component: BlockMostReadArticle,
    skeleton: SkeletonBlockMostReadArticle,
  }),
  ontour: (props) => ({
    component: BlockOnTour,
  }),
  rackpaper: (props) => ({
    component: WidgetRackPaper,
    skeleton: SkeletonWidgetRackpaper,
  }),
  portrait_article: (props) => ({
    component: BlockThreeArticle,
    skeleton: SkeletonBlockThreeArticle,
  }),
  three_article: (props) => ({
    component: BlockThreeArticle,
    margin: props.theme.sizes.gapVertical + ' 0px 0px 0px',
  }),
  special_box: (props) => ({
    component: BlockThreeArticle,
    autoHide: true,
    skeleton: SkeletonBlockThreeArticle,
  }),
  /*
  special_box_hotels_pretty: (props) => ({
    component: BlockThreeHotelsPretty,
    skeleton: SkeletonBlockThreeArticle,
  }),
  special_box_hotels_commercial: (props) => ({
    component: BlockThreeHotelsCommercial,
    skeleton: SkeletonBlockThreeArticle,
  }),

   */
  video: (props) => ({
    component: BlockVideoArticle,
  }),
  weather: (props) => ({
    component: BlockWeather,
    skeleton: SkeletonWidgetWeather,
  }),
  instagram: (props) => ({
    component: WidgetInstagram,
    skeleton: SkeletonWidgetInstagram,
  }),
  leader_headline: (props) => ({
    component: WidgetLeader,
    skeleton: WidgetLeaderSkeleton,
  }),
  pr: (props) => ({
    component: BlockThreeArticle,
    pr: true,
    skeleton: SkeletonBlockThreeArticle,
  }),
  ad: (props) => ({
    component: BlockAdSingle,
  }),
  landscape_article: (props) => ({
    component: BlockSingleLandscapeArticle,
    skeleton: SkeletonLandscapeItemArticle,
  }),
  page_article: (props) => ({
    component: Page,
    margin: props.theme.sizes.gapVerticalHalf + ' 0px -' + props.theme.sizes.gapVerticalHalf + ' 0px',
  }),
  map: (props) => ({
    component: WidgetDistrictNav,
    skeleton: WidgetDistrictNav,
  }),
  jobs: (props) => ({
    component: BlockJobs,
    skeleton: BlockJobs,
  }),
  tabs: () => ({
    component: BlockTabs,
    skeleton: BlockTabs,
  }),
}

export default blockKeys
