import options from '../../../../config/options'

const articleSPlusTargets = ({ response }) => {
  const sPlusTargets = {}
  if (!response || !response.data) {
    return response
  }

  let tags = []
  if (response.data.local !== 'undefined') {
    tags.push(response.data.local ? 'Lokal' : 'Weltweit')
  }
  if (response.data.plus) {
    tags.push(options.Plus.tag)
  }
  if (response.data.tags) {
    tags = tags.concat(response.data.tags)
  }
  if (response.data.districts && response.data.districts.length > 0) {
    tags = tags.concat(response.data.districts.map((district) => district.name))
  }
  if (response.data.editor) {
    sPlusTargets.author = response.data.editor
  }
  if (response.data.type) {
    sPlusTargets.native = response.data.type === 'PR'
  }
  if (response.data.type) {
    sPlusTargets.contentType = response.data.type
  }
  if (response.data.hasVideo) {
    sPlusTargets.hasVideo = response.data.hasVideo
  }
  if (response.data.date) {
    sPlusTargets.contentDate = response.data.date
  }
  sPlusTargets.tags = tags

  response.sPlusTargets = sPlusTargets

  return response
}

export default articleSPlusTargets
