import React from 'react'
import styled, { css } from 'styled-components'
import Icon from '../../../../components/Icon'
import ContainerFull from '../../../../components/ContainerFull'
import Link from '../../../../components/Link'
import skeletonFade from '../../../../modules/mixins/skeleton-fade'

const Wrapper = styled(ContainerFull)`
  width: 100%;
  z-index: 0;
  position: relative;
`
const InstagramImagesWrapper = styled.div`
  display: flex;
  background: ${(props) => props.theme.color.multimedia.backgroundSecondary};
`
const InstagramImageContainer = styled.div`
  width: calc(100% / 3);
  padding-top: calc(100% / 3);
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
    padding-top: 100%;
  }
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${skeletonFade};
  background-color: ${(props) => props.theme.color.skeletonScreen};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  &:nth-child(n + 2) {
    display: flex;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    &:nth-child(n + 2) {
      display: none;
    }
  }
`
const LabelWrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  background: ${(props) => props.theme.color.multimedia.backgroundSecondary};
  &:nth-child(1n) {
    padding-left: 45px;
  }
  &:nth-child(2n) {
    padding-right: 45px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    &:nth-child(1n) {
      padding-left: 20px;
    }
    &:nth-child(2n) {
      padding-right: 20px;
    }
  }
`
const IconAndDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.color.textInverted};
  font-size: 2em;
  opacity: 0.7;
  margin-right: 5px;
`
const Label = styled.p`
  color: ${(props) => props.theme.color.textInverted};
  font-family: ${(props) => props.theme.font.primary};
  font-size: 1.125em;
  opacity: 0.7;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 1em;
  }
`

const SkeletonWidgetInstagram = ({ ...props }) => {
  return (
    <Wrapper as="section" cinemaMode="multimedia" noGapEdge {...props}>
      <Link to="https://www.instagram.com/stol.it/">
        <InstagramImagesWrapper>
          <InstagramImageContainer />
          <InstagramImageContainer />
          <InstagramImageContainer />
        </InstagramImagesWrapper>
        <LabelWrapper>
          <IconAndDescription>
            <StyledIcon icon="instagram" />
            <Label>/stol.it</Label>
          </IconAndDescription>
          <Label>#stollemomente</Label>
        </LabelWrapper>
      </Link>
    </Wrapper>
  )
}

SkeletonWidgetInstagram.defaultProps = {
  margin: '0',
}

export default SkeletonWidgetInstagram
