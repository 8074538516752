import isServerSide from '../is-server-side'

const scrollToView = (elementId) => {
  if (!isServerSide()) {
    const elementToScroll = document.getElementById(elementId)
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

export default scrollToView
