import isServerSide from '../../../is-server-side'
import OnLoadedDomElement from '../../../on-loaded-dom-element'
import React from 'react'

const addScripts = ({ scriptText }) => {
  if (!isServerSide()) {
    scriptText.forEach((text) => {
      let fbScript = document.createElement('script')
      fbScript.text = text
      document.head.appendChild(fbScript)
    })
  }
}

const ExternalScript = (scripts) => {
  if (scripts) {
    return <OnLoadedDomElement callback={addScripts} scriptText={scripts} />
  }
}

export default ExternalScript
