import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LinkBreadcrumb from './components/LinkBreadcrumb'
import ContainerArticle from '../ContainerArticle'
import Delimiter from '../Delimiter'
import Icon from '../Icon'
import BookmarkSymbol from '../BookmarkSymbol'
import isStolApp from "../../modules/is-stolapp";
import createValidRelativeUrl from "../../modules/create-valid-relative-url";

const Wrapper = styled.div``

const StyledLinkBreadcrumb = styled(LinkBreadcrumb)`
  line-height: 20px;
  margin-top: 3px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: 2px;
  }
`

const BreadcrumbList = styled(ContainerArticle)`
  padding-top: 1em;
  padding-bottom: 1em;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    left: ${(props) => props.theme.sizes.gapEdge};
    right: ${(props) => props.theme.sizes.gapEdge};
    bottom: 0;
    border-bottom-color: ${(props) =>
      props.inverted ? props.theme.color.textInverted : props.theme.color.textTertiary};
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    &:after {
      left: ${(props) => props.theme.tablet.sizes.gapEdge};
      right: ${(props) => props.theme.tablet.sizes.gapEdge};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    &:after {
      left: ${(props) => props.theme.mobile.sizes.gapEdge};
      right: ${(props) => props.theme.mobile.sizes.gapEdge};
    }
  }
`

const HomeIcon = styled(Icon)`
  font-size: 1.2em;
  vertical-align: sub;
`
const FlexDiv = styled.div`
  display: flex;
`
const StyledDelimiter = styled(Delimiter)`
  margin-top: 2px;
  color: ${(props) => (props.inverted === 1 ? props.theme.color.textInverted : props.theme.color.textSecondary)};
`

const Breadcrumb = ({ crumbs, home, inverted, bookmark, ...props }) => {
  let reactItems = []

  crumbs.map(({ route, name }, i) => {
      let relRoute=  createValidRelativeUrl(route) ? createValidRelativeUrl(route) :null

    reactItems.push(
      <StyledLinkBreadcrumb key={i + route + name} to={!isStolApp() && relRoute?relRoute:route}>
        {name}
      </StyledLinkBreadcrumb>,
    )
    if (crumbs.length - 1 !== i) {
      reactItems.push(<StyledDelimiter inverted={inverted} key={i + 0.5} />)
    }
    return null
  })

  return (
    <Wrapper {...props}>
      <BreadcrumbList inverted={inverted}>
        <FlexDiv>
          {home && (
            <Fragment>
              <LinkBreadcrumb to="/">
                <HomeIcon icon="home" />
              </LinkBreadcrumb>
              <StyledDelimiter inverted={inverted} />
            </Fragment>
          )}
          {reactItems}
          {bookmark.show && <BookmarkSymbol state={bookmark.state} />}
        </FlexDiv>
      </BreadcrumbList>
    </Wrapper>
  )
}

Breadcrumb.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      route: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  home: PropTypes.bool,
}

Breadcrumb.defaultProps = {
  home: true,
  bookmark: {
    show: false,
  },
}

export default Breadcrumb
export {
  BreadcrumbList as BreadcrumbList0Breadcrumb,
  HomeIcon as HomeIcon0Breadcrumb,
  StyledDelimiter as StyledDelimiter0Breadcrumb,
}
