import styled from 'styled-components'
import ModifyAndShareDetailArticle, { StyledIcon0ModifyAndShareDetailArticle }  from './index'

const SkeletonModifyAndShareDetailArticle = styled(ModifyAndShareDetailArticle)`
  ${StyledIcon0ModifyAndShareDetailArticle} {
    cursor: auto;
  }
`

export default SkeletonModifyAndShareDetailArticle
