import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from '../Image'
import { ImageFormat } from '../../modules/parse'
import errorLog from '../../modules/error-log'

const ImageBg = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  position: absolute;
  filter: blur(15px);
  opacity: 0.8;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  width: 100%;
  display: block;
  position: relative;
  z-index: 0;

  &:after {
    display: block;
    padding-top: 56.25%;
    content: ' ';
  }
`

const Main = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
`

const CustomVideoImage = ({ video, ...props }) => {
  return (
    <Image
      src={video.replace('{dimensionWidth}', '720')}
      srcSet={`${video.replace('{dimensionWidth}', '320')} 550w, ${video.replace('{dimensionWidth}', '480')} 750w`}
      sizes="(max-width: 780px) 500px, 700px"
      blur={video.replace('{dimensionWidth}', '320')}
    />
  )
}

const CustomImage = ({ image, noLazyLoading, ...props }) => {
  const thumbnail = image.url && new ImageFormat(image.url)

  if (image.allowOtherFormat) {
    const landscape = image.ratio > 1.777
    const portrait = image.ratio <= 1.777
    return (
      <React.Fragment>
        <ImageBg image={thumbnail.format('detail_158x158')} />
        <Image
          className="image"
          width={landscape && 980}
          height={portrait && 540}
          src={thumbnail.format('max_x540')}
          srcSet={`${thumbnail.format('max_x270')} 521w, ${thumbnail.format('max_x540')} 759w`}
          sizes="(max-width: 780px) 500px, 700px"
          blur={thumbnail.format('detail_158x158')}
          noLazyLoading={noLazyLoading}
        />
      </React.Fragment>
    )
  } else {
    return (
      <Image
        className="image"
        src={thumbnail.format('detail_759x427')}
        srcSet={`${thumbnail.format('detail_759x427')} 759w`}
        sizes="(max-width: 780px) 500px, 700px"
        blur={thumbnail.format('detail_158x158')}
        noLazyLoading={noLazyLoading}
      />
    )
  }
}

const CustomError = () => {
  return <Image />
}

const ImageWidescreen = ({ image, video, title, noLazyLoading, ...props }) => {
  return (
    <Wrapper {...props}>
      <Main>
        {image && !video ? (
          <CustomImage image={image} noLazyLoading={noLazyLoading} />
        ) : video ? (
          <CustomVideoImage video={video} />
        ) : (
          <CustomError title={title} />
        )}
      </Main>
    </Wrapper>
  )
}

ImageWidescreen.defaultProps = {
  title: '',
}

ImageWidescreen.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.number,
    copyright: PropTypes.string,
    ratio: PropTypes.number,
    url: PropTypes.string,
  }),
}

export default ImageWidescreen
