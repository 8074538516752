import isServerSide from '../../../is-server-side'
import { Helmet } from 'react-helmet'
import domToReact from 'html-react-parser/lib/dom-to-react'
import React from 'react'

const ExternalGenially = (scriptText, embedBoxChildren) => (
  <div>
    {!isServerSide() && !document.getElementById('genially-script') && (
      <Helmet>
        <script id={'genially-script'}>{`${scriptText}`}</script>
      </Helmet>
    )}
    {domToReact(embedBoxChildren)}
  </div>
)

export default ExternalGenially
