import { setStoredItem } from '../../../../modules/stored-item'

const screenModifyResponseHome = ({ response: { data }, response }) => {
  if (data.lastArticleId) {
    setStoredItem('lastArticleId', data.lastArticleId.toString())
  }
  return response
}

export default screenModifyResponseHome
