import hasItems from '../../../../modules/has-items'
import translate from '../../../../modules/translate'

const getIdsArticles = ({ articleIds, articles }) => {
  return articleIds.map((id) => {
    if (hasItems(articles[id])) {
      articles[id].position = id
      return articles[id]
    }
  })
}

export default getIdsArticles
