import styled from 'styled-components'
import Text from '../Text'
import truncate from '../../modules/truncate'

const TextArticle = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  margin-bottom: 1em;
`

export default truncate(TextArticle)

export { TextArticle as NoTruncateTextArticle }
