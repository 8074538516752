import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Link from '../Link'
import Delimiter from '../Delimiter'
import TruncateText from '../../components/TruncateText'
import isStolApp from '../../modules/is-stolapp'
import createValidRelativeUrl from '../../modules/create-valid-relative-url'

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: ${(props) => props.theme.lineHeight.topLine};
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }

  ${(props) =>
    props.inverted
      ? css`
          background-color: ${(props) => props.theme.color.multimedia[props.inverted_background_color]};
        `
      : css`
          background-color: transparent;
        `};
`

const LinkDepartment = styled(Link)`
  color: currentColor;
  font-family: ${(props) => props.theme.font.primary};
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  ${(props) =>
    props.inverted === 1
      ? css`
          color: ${(props) => props.theme.color.textInverted};
          text-decoration: underline;
        `
      : css`
          color: ${(props) => props.theme.color.stol};
        `};
`

const LinkHeadLine = styled(Link)`
  display: block;
  color: currentColor;
  font-family: ${(props) => props.theme.font.primary};
  font-size: inherit;
  line-height: inherit;
  overflow: hidden;
  text-decoration: none;
  ${(props) =>
    props.inverted === 1
      ? css`
          color: ${(props) => props.theme.color.textInverted};
        `
      : css`
          color: ${(props) => props.theme.color.textSecondary};
        `};
  text-overflow: ellipsis;
  white-space: nowrap;
`
const HeadlinePrefix = styled.span`
  margin-right: 3px;
`
const StyledDelimiter = styled(Delimiter)`
  font-size: inherit;
  line-height: inherit;
  ${(props) =>
    props.inverted === 1
      ? css`
          color: ${(props) => props.theme.color.textInverted};
        `
      : css`
          color: ${(props) => props.theme.color.stol};
        `};
`
const TopLine = ({ department, headline, headlinePrefix, inverted, inverted_background_color, ...props }) => (
  <Wrapper inverted={inverted} {...props}>
    {department && (
      <Fragment>
        <LinkDepartment inverted={inverted} to={createValidRelativeUrl(department?.route)} inherit={1}>
          {/*React will log an error if inherit is boolean */}
          {department.name}
        </LinkDepartment>
        <StyledDelimiter inverted={inverted} />
      </Fragment>
    )}
    <LinkHeadLine inverted={inverted} to={createValidRelativeUrl(headline?.route)} inherit={1}>
      {' '}
      {/*inherit must be a string, otherwise it will not be written to the DOM and React will log it as error*/}
      {headlinePrefix && <HeadlinePrefix>{headlinePrefix}</HeadlinePrefix>}
      {headline && headline.name}
    </LinkHeadLine>
  </Wrapper>
)

TopLine.defaultProps = {
  inverted_background_color: 'backgroundTertiary',
}

TopLine.propTypes = {
  department: PropTypes.shape({
    name: PropTypes.string,
    route: PropTypes.string,
  }),
  headline: PropTypes.shape({
    name: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
  }),
  headlinePrefix: PropTypes.string,
}

export default TopLine

export {
  LinkDepartment as LinkDepartment0TopLine,
  StyledDelimiter as StyledDelimiter0TopLine,
  LinkHeadLine as LinkHeadLine0TopLine,
  Wrapper as Wrapper0TopLine,
}
