import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ItemOnTour from '../../components/ItemOnTour'
import Breadcrumb from '../../../../components/Breadcrumb'
import {useMediaQuery} from "../../../../modules/media-query";

const Wrapper = styled.div`
  margin: 0 auto;
  min-width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: hidden;
  background-color: #fff;
  @media (max-width: 790px) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
`

const WidgetOnTourItems = ({ onTourItems = [], view, numberItems, ...props }) => {
  let scroll = useMediaQuery('(max-width:790px)')
  return (
    <Wrapper {...props}>
      {onTourItems.map((currentItem, index) => {
        if (!numberItems || index < numberItems || scroll) {
          const { image, title, url, date, ...props } = currentItem
          return (
            <ItemOnTour
              key={'itemOnTour ' + index}
              image={image}
              headline={props.breadcrumb.headline}
              title={title}
              url={url}
              view={view}
              start={date}
              breadcrumb={props.breadcrumb}
              {...props}
            />
          )
        } else {
          return false
        }
      })}
    </Wrapper>
  )
}

WidgetOnTourItems.propTypes = {
  onTourItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        copyright: PropTypes.string,
        id: PropTypes.number,
        ratio: PropTypes.number,
        url: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
      breadcrumb: Breadcrumb.propTypes,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  numberItems: PropTypes.number,
}

export default WidgetOnTourItems
export { Wrapper as Wrapper0WidgetOnTourItems }
