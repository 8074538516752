const mvpSleedingDays = ({ translations }) => ({
  feed: [
    {
      name: 'mvp_header',
      noGapEdge: true,
      dataStatic: translations.header,
    },
    {
      name: 'mvp_listbox',
      noGapEdge: true,
      dataStatic: translations.listbox,
    },
    {
      name: 'mvp_offer',
      noGapEdge: true,
      dataStatic: translations.offer,
    },
    {
      name: 'mvp_paragraph',
      noGapEdge: true,
      dataStatic: translations.paragraph,
    },
    {
      name: 'mvp_footer',
      noGapEdge: true,
      dataStatic: translations.footer,
    },
  ],
})

export default mvpSleedingDays
