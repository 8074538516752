// TODO create powered by
import React from 'react'
import styled from 'styled-components'
import AdUnit from '../../../../../../components/AdUnit'

const Wrapper = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.color.multimedia.backgroundSecondary};
`
const PoweredBy = ({ flexDirection, alignItems, ...props }) => {
  return (
    <Wrapper {...props} flexDirection={flexDirection} alignItems={alignItems}>
      <AdUnit adId={'sponsoredBy'} />
    </Wrapper>
  )
}

PoweredBy.defaultProps = {
  alignItems: 'center',
}

export default PoweredBy
export { Wrapper as Wrapper0PoweredBy }
