import React from 'react'
import styled, { css } from 'styled-components'
import styledContainerQuery from 'styled-container-query'
import Text from '../../../../components/Text'
import ContainerArticle from '../../../../components/ContainerArticle'
import Icon from '../../../../components/Icon'
import Link from '../../../../components/Link'
import TextSkeleton, { Line0TextSkeleton } from '../../../../components/Text/views/TextSkeleton'
import SkeletonLineItemArticle, {
  Wrapper0SkeletonLineItemArticle,
} from '../../../Article/components/ItemArticle/views_line/LineItemArticle/skeleton'

const StyledTextSkeleton = styled(TextSkeleton)`
  ${Line0TextSkeleton} {
    border-width: ${(props) => props.theme.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    ${Line0TextSkeleton} {
      border-width: ${(props) => props.theme.tablet.sizes.gapVertical};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${Line0TextSkeleton} {
      border-width: ${(props) => props.theme.mobile.sizes.gapVertical};
    }
  }
`

const WrapperLineItems = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapVerticalHalf};
  width: 70%;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`

const ContainerSkeletonLineItemArticle = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`

const Number = styled(Text)`
  font-size: 48px;
  color: ${(props) => props.theme.color.textSecondary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 1;
  width: 30px;
  text-align: center;
  margin-right: 25px;
`

const TimeLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  /*Line for the first Item in the TimeLine*/
  ${(props) =>
    props.index === 0 &&
    css`
      &:before {
        content: '';
        width: 15%;
        border-bottom: solid 1px rgba(0, 0, 0, 0.2);
        transform: rotate(90deg);
        margin-left: -1px;
        margin-bottom: 10px;
      }
    `} &:after {
    content: '';
    width: 25%;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    transform: rotate(90deg);
    margin-left: -1px;
    margin-top: 15px;
  }
`

const Date = styled(Text)`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: rgba(0, 0, 0, 0.5);
`

const StyledIcon = styled(Icon)`
  position: absolute;
  right: ${(props) => props.theme.sizes.gapCol};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    right: ${(props) => props.theme.tablet.sizes.gapCol};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    right: ${(props) => props.theme.mobile.sizes.gapCol};
  }
  top: 0;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.5s ease;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.color.stol};
  }
`

const StyledSection = styled.section``

const StyledContainerArticle = styled(ContainerArticle)`
  width: 100%;
`

const Wrapper = styledContainerQuery(({ children, containerArticle, ...props }) => (
  <StyledSection {...props}>
    {(containerArticle && <StyledContainerArticle>{children}</StyledContainerArticle>) || children}
  </StyledSection>
))`
  background-color: ${(props) => props.theme.color.background};
  border: ${(props) => props.theme.color.backgroundBorder} 1px solid;
  padding-top: 1em;
  padding-bottom: 1em;
  position: relative;
  display: flex;
  width: 100%;
  &:container(max-width: 355px){
    ${TimeLine}{
      &:after{
        width: 40%;
        margin-top: 20px;
        margin-bottom: 5px;
      }
    }
  }
  &:container(max-width: 280px){
    ${Date}{
      font-size: 10px;
    }
  }
`

const StyledSkeletonLineItemArticle = styled(SkeletonLineItemArticle)`
  width: 100%;
  ${Wrapper0SkeletonLineItemArticle} {
    width: 100%;
  }
`

const TimeContainer = styled.div`
  max-width: 90px;
  width: 90px;
  padding-right: ${(props) => props.theme.sizes.gapVerticalHalf};
  text-align: center;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
`

const TimeTextSkeleton = styled(TextSkeleton)`
  ${Line0TextSkeleton} {
    margin: 0 auto;
  }
`

const SkeletonWidgetNakedLineArticle = ({
  numbering,
  timeLine,
  headlinePrefix,
  withMailIcon,
  containerArticle,
  children,
  title,
  items,
  views,
  ...props
}) => {
  let SkeletonLineItemArticles = []
  for (let i = 0; i < items; i++) {
    SkeletonLineItemArticles.push(
      <ContainerSkeletonLineItemArticle key={i}>
        {timeLine && (
          <TimeContainer>
            <TimeTextSkeleton lastWidth="50%" />
            <TextSkeleton />
          </TimeContainer>
        )}
        {numbering && <Number>{i + 1}</Number>}
        <StyledSkeletonLineItemArticle views={views} />
      </ContainerSkeletonLineItemArticle>,
    )
  }
  return (
    <Wrapper containerArticle={containerArticle} {...props}>
      {title && <StyledTextSkeleton lastWidth="50%" />}
      {withMailIcon && (
        <Link to="mailto:support@firstavenue.it">
          <StyledIcon icon="email" />
        </Link>
      )}
      <WrapperLineItems>{SkeletonLineItemArticles}</WrapperLineItems>
      {children}
    </Wrapper>
  )
}

SkeletonWidgetNakedLineArticle.propTypes = {}

SkeletonWidgetNakedLineArticle.defaultProps = {
  containerArticle: false,
  widthMailIcon: false,
  title: false,
  items: 3,
}

export default SkeletonWidgetNakedLineArticle
export {
  Wrapper as Wrapper0WidgetLineArticle,
  ContainerSkeletonLineItemArticle as ContainerSkeletonLineItemArticle0SkeletonWidgetNakedLineArticle,
  WrapperLineItems as WrapperLineItems0SkeletonWidgetNakedLineArticle,
}
