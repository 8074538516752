import React, { useEffect, useState } from 'react'
import styled, { css, withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import translate from '../../../../../../modules/translate'
import Icon from '../../../../../../components/Icon'
import DescriptionItemArticle, { Text0DescriptionItemArticle } from '../../components/DescriptionItemArticle'
import { Link0TitleItemArticle } from '../../components/DescriptionItemArticle/components/TitleItemArticle'
import { LinkHeadLine0TopLine } from '../../../../../../components/TopLine'
import { useScreenSize } from '../../../../../../modules/media-query'

const Wrapper = styled('article')``
const StyledDescriptionItemArticle = styled(DescriptionItemArticle)`
  margin-top: 0;
  content: 'Freddy, Pascal, lil Phil';

  ${LinkHeadLine0TopLine} {
    ${(props) =>
      props.color === 'red' &&
      css`
        color: ${(props) => props.theme.color.stol};
      `}
  }

  ${Text0DescriptionItemArticle} {
    color: ${(props) => props.theme.color.textSecondary};
    fill: ${(props) => props.theme.color.textSecondary};
    padding-top: 0.1em;
    margin-bottom: 0;
    text-shadow: none;
  }

  ${Link0TitleItemArticle} {
    margin-top: 0;
  }
`
const LineItemArticle = ({ url, breadcrumb, headlinePrefix, title, views, color, onClick, ...props }) => {
  const [truncateTitle, setTruncateTitle] = useState(2)
  const mode = useScreenSize()

  useEffect(() => {
    switch (mode) {
      case 'mobileS':
      case 'mobile':
        if (props.isEvent) {
          setTruncateTitle(2)
        } else {
          setTruncateTitle(3)
        }
        break
      case 'tablet':
      case 'desktop':
      default:
        if (props.isEvent) {
          setTruncateTitle(1)
        } else {
          setTruncateTitle(2)
        }
    }
  }, [mode])

  return (
    <Wrapper {...props}>
      <StyledDescriptionItemArticle
        {...props}
        url={url}
        department={breadcrumb && breadcrumb.department && breadcrumb.department}
        headline={breadcrumb && breadcrumb.headline}
        headlinePrefix={headlinePrefix}
        title={title}
        truncateTitle={{ numberOfLines: truncateTitle }}
        moreLink={false}
        onClick={onClick}
        color={color}
        {...(views && {
          description: translate('article_views', {
            amount: views,
            icon: <Icon key={'styledIconEye' + url} icon="eye" />,
          }),
        })}
      />
    </Wrapper>
  )
}

LineItemArticle.propTypes = {
  url: DescriptionItemArticle.propTypes.url,
  department: DescriptionItemArticle.propTypes.department,
  headline: DescriptionItemArticle.propTypes.headline,
  headlinePrefix: DescriptionItemArticle.propTypes.headlinePrefix,
  title: DescriptionItemArticle.propTypes.title,
  views: PropTypes.string,
  color: PropTypes.oneOf(['red', 'inherit']),
}

export default withTheme(LineItemArticle)

export { Text0DescriptionItemArticle as LineItemArticle0Description }
