import React from 'react'
import styled, { withTheme } from 'styled-components'
import { PhotoSwipeGallery } from '@dlab/react-photoswipe'
import Scrollbars from '../Scrollbars'
import Image from '../Image'
import PhotoSwipeStyle from '../../vendor/photoswipe'
import { ImageFormat } from '../../modules/parse'
import optionsPhotoswipe from '../../modules/options-photoswipe'
import { translateFontSizeToTheme } from '../../modules/font'
import isStolApp from '../../modules/is-stolapp'
import { withRouter } from 'react-router'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import isServerSide from '../../modules/is-server-side'

const StyledImage = styled(Image)`
  margin: 5px 5px 0 5px;
`

const StyledPhotoSwipeGallery = styled(PhotoSwipeGallery)`
  width: 100%;
  .pswp-thumbnail {
    display: inline-block;
    cursor: pointer;
  }
  .pswp__caption__center {
    white-space: normal;
    text-align: center;
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.default.fontSize.base)};
    @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
      font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.tablet.fontSize.base)};
    }
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.mobile.fontSize.base)};
    }
  }
`

const StyledScrollbars = styled(Scrollbars)``

const setScroll = () => {
  if (!isServerSide()) {
    document.getElementsByClassName('ScrollbarsCustom-Scroller')[0].style.overflowY = 'hidden'
    document.getElementsByClassName('ScrollbarsCustom-Scroller')[0].style.overflowX = 'scroll'
  }
}

const GalleryPhotoSwipe = ({
  id,
  type,
  noScrollY,
  inverted,
  images,
  customOptions,
  style,
  wrapperProps,
  fontSize,
  location,
  ...props
}) => {
  const getThumbnailContent = (item) => (
    <StyledImage
      src={item.thumbnail}
      blur={item.blur}
      height={150}
      width={150 * item.ratio}
      onClick={(event) => {
        document.getElementsByClassName('ScrollbarsCustom-Scroller')[0].style.overflow = 'visible'
        if (isStolApp()) {
          setScroll()
          let nativeUrl = `image-gallery://${type}/${id}/${item.id}`
          if (!isServerSide()) {
            window.location.href = nativeUrl
          }
          event.stopPropagation()
        }
      }}
    />
  )
  const options = {
    galleryUID: id,
    ...optionsPhotoswipe,
    ...customOptions,
  }
  const items = images.map((item, index) => {
    const itemImage = new ImageFormat(item.url)
    const itemDescription = item.description ? item.description : ''
    const itemCopyright = item.copyright ? ' copyright: ' + item.copyright : ''
    const itemWidth = 850 * item.ratio
    const itemHeight = 850

    return {
      id: index,
      src: itemImage.format('max_1280x850'),
      thumbnail: itemImage.format('max_x270'),
      blur: itemImage.format('detail_158x158'),
      ratio: item.ratio,
      w: itemWidth,
      h: itemHeight,
      title: itemDescription + itemCopyright,
    }
  })
  const galleryQuery = queryString.parse(location.hash, { parseNumbers: true })
  return (
    <StyledScrollbars noScrollY={noScrollY} inverted={inverted} style={style} wrapperProps={wrapperProps}>
      <PhotoSwipeStyle />
      <StyledPhotoSwipeGallery
        items={items}
        options={options}
        thumbnailContent={getThumbnailContent}
        fontSize={fontSize}
        isOpen={galleryQuery.gid === id && true}
        onClose={() => setScroll()}
        {...props}
      />
    </StyledScrollbars>
  )
}

GalleryPhotoSwipe.defaultProps = {
  id: 1,
  noScrollY: true,
  inverted: true,
  wrapperProps: {},
}

GalleryPhotoSwipe.propTypes = {
  /** A unique ID for the gallery */
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['gallery', 'ontour']).isRequired,
}

export default withRouter(withTheme(GalleryPhotoSwipe))
export { StyledPhotoSwipeGallery as StyledPhotoSwipeGallery0GalleryPhotoSwipe }
