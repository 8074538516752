import React from 'react'
import styled from 'styled-components'
import SkeletonBreadcrumb from '../../../../components/Breadcrumb/skeleton'
import { StyledContainerArticle0DetailArticle, StyledImageDetailArticle0DetailArticle } from './index'
import SkeletonHeaderDetailArticle from './components/HeaderDetailArticle/skeleton'
import SkeletonModifyAndShareDetailArticle from './components/ModifyAndShareDetailArticle/skeleton'
import SkeletonImageDetailArticle from './components/ImageDetailArticle/skeleton'
import TextSkeleton from '../../../../components/Text/views/TextSkeleton'
import isStolApp from '../../../../modules/is-stolapp'
import HeaderDetailArticle from './components/HeaderDetailArticle'
import Block from '../../../../layouts/Blocks'

const Wrapper = styled.div``

const SkeletonDetailArticle = ({ routerState, ...props }) => (
  <Wrapper {...props}>
    {!isStolApp() && (
      <SkeletonBreadcrumb
        department={
          (routerState && routerState.department) ||
          (routerState && routerState.breadcrumb && routerState.breadcrumb.department)
        }
      />
    )}
    <StyledContainerArticle0DetailArticle>
      {routerState ? (
        <HeaderDetailArticle
          title={routerState && routerState.title}
          description={(routerState && routerState.text) || (routerState && routerState.description)}
          date={routerState && routerState.date}
          headline={
            (routerState && routerState.headline) ||
            (routerState && routerState.breadcrumb && routerState.breadcrumb.headline)
          }
          partner={routerState && routerState.partner}
        />
      ) : (
        <SkeletonHeaderDetailArticle />
      )}
      <SkeletonModifyAndShareDetailArticle />
      <StyledImageDetailArticle0DetailArticle as={SkeletonImageDetailArticle} />
      <TextSkeleton lines={5} fontSize="16px" lastWidth="45px" justify={false} />
      <TextSkeleton lines={3} fontSize="16px" lastWidth="65px" justify={false} />
      <SkeletonModifyAndShareDetailArticle position="bottom" />
    </StyledContainerArticle0DetailArticle>
    {
      <Block
        view="jobs"
        loading={true}
        numberItems={{
          desktop: 2,
          tablet: 2,
          mobile: 2,
        }}
        slotIds={{
          desktop: 'https://delivery.connect.suedtirol.live/json/b8b5a220-ba31-4a3b-8856-7ec5ac222dd2?p=r',
          tablet: 'https://delivery.connect.suedtirol.live/json/e34bdfed-296f-4725-ae97-c1bd584a900c?p=r',
          mobile: 'https://delivery.connect.suedtirol.live/json/b7c0f4c3-74ba-4b7f-8bf9-ca731da40d26?p=r',
          tabletApp: 'https://delivery.connect.suedtirol.live/json/e7d39be4-3701-4e21-b6f9-14f69f82423e?p=r',
          mobileApp: 'https://delivery.connect.suedtirol.live/json/51c933c6-3050-480b-a561-79fcde7650e9?p=r',
        }}
        noGapEdge={true}
      />
    }
  </Wrapper>
)

export default SkeletonDetailArticle
