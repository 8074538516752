import options from '../options'

const jobsSLCSlotIDs = {
  desktop:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/35357643-649a-4620-9fcf-8e62a001467e?p=r'
      : 'https://delivery.connect.suedtirol.live/json/d07c980d-f8fe-405f-b638-aeb06413e93a?p=r',
  tablet:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/35357643-649a-4620-9fcf-8e62a001467e?p=r'
      : 'https://delivery.connect.suedtirol.live/json/973b5803-8d37-487d-bfe4-5c35d8e6e0ad?p=r',
  mobile:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/07cf37dd-47ba-43b0-a726-6080988dcd4b?p=r'
      : 'https://delivery.connect.suedtirol.live/json/59a49673-67ff-48c7-84ec-161dad52b119?p=r',
  tabletApp:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/35357643-649a-4620-9fcf-8e62a001467e?p=r'
      : 'https://delivery.connect.suedtirol.live/json/102076c7-319c-4934-bb4c-82d7c039c1f5?p=r',
  mobileApp:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/07cf37dd-47ba-43b0-a726-6080988dcd4b?p=r'
      : 'https://delivery.connect.suedtirol.live/json/f0771162-7272-4e84-b71b-93ed9c8a63ac?p=r',
}

const departmentLayout = {
  general: {
    adIds: ['S1', 'S2'],
  },
  loadMore: true,
  feed: [
    {
      name: 'tabs',
      dataStatic: {
        locations: options.Plus.tabLinks.map(({ value }) => {
          return {
            pathName: value,
            items: options.Plus.tabLinks,
          }
        }),
      },
      noGapEdge: true,
    },
    {
      name: 'title',
      dataProps: ['title'],
      noGapEdge: true,
    },
    {
      name: 'category_articles_panel',
      dataProps: ['articles', 'locale'],
      noGapEdge: true,
      feed: [
        {
          name: 'big_one_article',
          articleIds: [0],
          noLazyLoading: true,
        },
        {
          name: 'three_landscape_article',
          articleIds: [1, 2, 3],
          adIds: ['S5', 'S6'],
          noLazyLoading: true,
        },
        {
          name: 'jobs',
          numberItems: {
            desktop: 6,
            tablet: 6,
            mobile: 3,
          },
          slotIds: jobsSLCSlotIDs,
          noGapEdge: true,
          margin: {
            all: {
              top: '0',
              bottom: 'half',
            },
          },
        },
        {
          name: 'three_landscape_article',
          articleIds: [4, 5, 6],
          adIds: ['S6'],
        },
        {
          name: 'articles',
          articlesFrom: 7,
        },
      ],
    },
  ],
}

export default departmentLayout

export { jobsSLCSlotIDs }
