  import React from 'react'
  import NakedItemArticle, { Image0NakedItemArticle, Description0NakedItemArticle } from '../../views/NakedItemArticle'
  import styledContainerQuery from 'styled-container-query'

  const Wrapper = styledContainerQuery(NakedItemArticle)`
    ${Image0NakedItemArticle}{
      min-width: ${props => props.theme.sizes.col8};
    }
    ${Description0NakedItemArticle}{
      padding-top: ${props => props.theme.sizes.gapColHalf};
      padding-left:  ${props => props.theme.sizes.gapColHalf};
    }
    &:container(max-width: 955px){
      ${Description0NakedItemArticle}, ${Image0NakedItemArticle}{
        flex-basis: 100%;
        padding: 0;
      }
    }
  `
  
  const BigVideoItemArticle = ({ video, headline, title, image, local, partner, type, url,...props}) => (
    <Wrapper
        video={video}
        url={url}
        local={local}
        partner={partner}
        type={type}
        image={image}
        inverted_background_color='backgroundSecondary'
        headline={headline} 
        title={title}
        inverted={1}
        {...props} 
    >
    </Wrapper>
  ) 
  
  BigVideoItemArticle.propTypes = {
    ...NakedItemArticle.propTypes,
  }
  
  BigVideoItemArticle.defaultProps = {
    truncateText: {
      numberOfLines: 'auto',
    },
  }
  
  export default BigVideoItemArticle
 