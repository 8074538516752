import React from 'react'

const ezHeadlinePaper = ({ domNode }) => {
  const { parent } = domNode

  if (
    parent &&
    parent.attribs &&
    parent.attribs.style ===
      'position: relative; margin-bottom: 45px; margin-top:50px; background-color: #EAEAEA; padding: 8px 80px 8px 8px;'
  ) {
    return true // Escape for ez dolomiten/zett headline
  }
}

export default ezHeadlinePaper
