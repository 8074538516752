import React, { useEffect, useState } from 'react'
import domToReact from 'html-react-parser/lib/dom-to-react'
import { Helmet } from 'react-helmet'
import isServerSide from '../../../is-server-side'

const ExternalFacebook = (attribs, embedBoxChildren) => {
  const [prepared, setPrepared] = useState(false)
  const startFBWidget = () => {
    window.FB.XFBML.parse()
  }
  useEffect(() => {
    if (!isServerSide() && window.FB) {
      if (prepared) {
        startFBWidget()
        return true
      }
      if (!prepared) {
        setPrepared(true)
        return domToReact(embedBoxChildren)
      }
    }
  }, [prepared])

  return (
    <>
      <Helmet>
        <script id="facebookEmbed" src={attribs.src} onload={startFBWidget} />
      </Helmet>
      {domToReact(embedBoxChildren)}
    </>
  )
}

export default ExternalFacebook
