import React from 'react'
import RackPaper from '.'

const data = [
  {
    image: 'https://abo.athesiamedien.com/cover_images/dolomiten.jpg',
    url: 'https://abo.athesiamedien.com/epaper/kiosks/10-dolomiten/aktuelle-ausgabe',
    title: 'Dolomiten',
  },
  {
    image: 'https://abo.athesiamedien.com/cover_images/markt.jpg',
    url: 'https://abo.athesiamedien.com/epaper/kiosks/5-markt/aktuelle-ausgabe',
    title: 'Markt',
  },
  {
    image: 'https://abo.athesiamedien.com/cover_images/zett.jpg',
    url: 'https://abo.athesiamedien.com/epaper/kiosks/9-zett/aktuelle-ausgabe',
    title: 'Zett',
  },
  {
    image: 'https://abo.athesiamedien.com/cover_images/magazin.jpg',
    url: 'https://abo.athesiamedien.com/epaper/kiosks/4-magazin/aktuelle-ausgabe',
    title: 'Magazin',
  },
  {
    image: 'https://abo.athesiamedien.com/cover_images/wiku.jpg',
    url: 'https://abo.athesiamedien.com/epaper/kiosks/3-wiku/aktuelle-ausgabe',
    title: 'Wiku',
  },
]

const SkeletonRackpaper = ({...props}) => <RackPaper data={data} {...props}/>

export default SkeletonRackpaper
