import mvpSleedingDays from './mvpSleedingDays'

const translations = {
  header: {
    lng: 'DE',
    label: {
      title: '4. März 2023',
      subtitle: 'Talstation Pemmern',
    },
    title: '<span>Der STOL & SportNews</span> <br />Gratis Familien Rodeltag <br /><span>am Rittner Horn</span>',
    subject: 'Der STOL & SportNews Gratis Familien Rodeltag am Rittner Horn',
    description: ['Familien sparen 139€*', '+++ Gratis Tageskarten', '+++ Gratis parken', '+++ Gratis Rodelverleih'],
    button: 'Sei dabei!',
    bottomDescription01: 'Limitierte Teilnehmeranzahl!',
    bottomDescription02: 'Nur noch ',
    bottomDescription03: ' Plätze verfügbar.',
  },
  listbox: {
    lng: 'DE',
    title: 'Verbringe einen traumhaften Rodeltag mit deiner Familie',
    content: {
      title: 'Brieftasche bleibt zu Hause!!',
      description: `Am <span>4. März</span> ist es soweit. Rodelspaß ohnegleichen am Rittner Sonnenplateau mit
          einem Traumtag für die ganze Familie. Ein unvergessliches Erlebnis mit deinen Liebsten in
          zauberhafter Umgebung.`,
      list: {
        first: {
          title: 'Gratis Tageskarte',
          description: 'für die gesamte Familie',
        },
        second: {
          title: 'Gratis parken',
          description: 'direkt an der Talstation',
        },
        third: {
          title: 'Gratis Rodelverleih',
          description: '2 Rodeln pro Familie beim Skicenter Rittnerhorn, solange der Vorrat reicht',
        },
        fourth: {
          title: 'Profitipps',
          description: 'mit Martin Psenner und dem neuen Bachmann Rodel-Bremssystem',
        },
      },
    },
  },
  offer: {
    offerID: 'sleedingOfferDE',
    title: "Aufsitzen, festhalten und los geht's!",
    subTitle: 'Du hast noch kein Abo? Melde dich jetzt an und sichere dir deinen Wintertraumtag mit deiner Familie!',
    content: {
      bottomList: ['Schon ABONNENT? Wir haben dir den Link zur Anmeldung gemailt.'],
    },
  },
  paragraph: {
    title: 'Genuss pur am Rittner Sonnenplateau!',
    subTitle: 'Ein unvergesslicher Familientag mit einer einmaligen Aussicht',
    content: [
      'Was die Traumlandschaft am Rittner Horn Rodlern verspricht, hält sie auch. Auf der 2,5 km langen Strecke erlebt man Abwechslung pur - sanfte Hänge sowie abenteuerliche Waldstücke wechseln sich von der Mittelstation bis runter ins Tal ab. Dort heißt es einsteigen in die Bergbahn, die Bergfahrt genießen und erneut ab auf die Rodelstrecke. Ein Winterspaß, bei dem Familien und Freunde so richtig in Fahrt kommen und auch nach Stunden kaum zu bremsen sind.',
      'Eine Rodelschulung für sicheres Rodeln mit dem Rodelexperten Martin Psenner und Michael Graf von "Bachmann Rodel", die in München mit dem ISPO-Award ausgezeichnet wurde ist Teil deines Familien-Wunschprogramms.',
      'Herzlichen Dank an unsere Partner von der Rittner Horn Bergbahnen AG und vom Snowspace-Skicenter Rittner Horn für die tolle Unterstützung der Aktion!',
      '*2 Erwachsene, 2 Kinder, 2 geliehene Rodeln',
    ],
  },
  footer: {
    lng: 'DE',
  },
}

const mvpSleedingDaysDE = mvpSleedingDays({ translations })

export default mvpSleedingDaysDE
