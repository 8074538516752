const PreComponent = ({ route, components, ...props }) => {
  const routeOptions = route.getOptions()
  const routeScreen = routeOptions && routeOptions.screen
  if (routeScreen && typeof routeScreen.preComponent === 'string') {
    let Component = components[routeScreen.preComponent]
    return <Component route={route} {...props} />
  }
  return false
}

export default PreComponent
