import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Text from '../../../../components/Text'
import IconWeather from '../IconWeather'

const Wrapper = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Name = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.title};
  white-space: nowrap;
`

const Temperature = styled(Text)`
  color: ${(props) => props.theme.color.textSecondary};
  line-height: ${(props) => props.theme.lineHeight.title};
  white-space: nowrap;
`

const StyledIconWeather = styled(IconWeather)`
  width: ${(props) => props.iconWidth};
  margin-bottom: ${(props) => (!props.square ? '.5em' : '0')};
`

const SymbolWeather = ({ name, icon, minTemp, maxTemp, square, iconWidth, ...props }) => (
  <Wrapper {...props}>
    <StyledIconWeather name={icon.name} description={icon.description} square={square} iconWidth={iconWidth} />
    <Name>{name}</Name>
    <Temperature>
      {minTemp}/{maxTemp}
    </Temperature>
  </Wrapper>
)

SymbolWeather.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  minTemp: PropTypes.string.isRequired,
  maxTemp: PropTypes.string.isRequired,
  square: PropTypes.bool,
  iconWidth: PropTypes.string,
}

SymbolWeather.defaultProps = {
  square: true,
  iconWidth: '80px',
}

export default SymbolWeather
export {
  Name as SymbolWeather0Name,
  Temperature as SymbolWeather0Temperature,
  StyledIconWeather as SymbolWeather0IconWeather,
}
