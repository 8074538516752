import consoleLogger from '../../../../../../modules/console-logger'
import { useState, useEffect } from 'react'
import isServerSide from '../../../../../../modules/is-server-side'

function useTargetsFromPopads(targets) {
  const [stateTargetingData, setStateTargetingData] = useState()
  const [consoleLog] = consoleLogger('usePopadsTargets')

  useEffect(() => {
    let timeout = null

    if (!isServerSide()) {
      if ((targets == null || targets.length == 0) && (stateTargetingData == null || stateTargetingData.length == 0)) {
        consoleLog('Loading Targets from Popads')
        let tempTargeting = {}
        window.googletag = window.googletag || { cmd: [] }
        window.googletag.cmd.push(function () {
          timeout = setInterval(function () {
            const targetingKeys = window.googletag.pubads().getTargetingKeys()
            if (targetingKeys.length > 0) {
              consoleLog('Found Keys - Saving')
              targetingKeys.forEach((key) => {
                tempTargeting[key] = window.googletag.pubads().getTargeting(key)
              })
              setStateTargetingData(tempTargeting)
              clearInterval(timeout)
            }
          }, 250)
        })
      }
    }
  }, [])
  return stateTargetingData
}

export default useTargetsFromPopads
