import React, { Component } from 'react'
import NakedItemArticle, { Description0NakedItemArticle, Image0NakedItemArticle } from '../../views/NakedItemArticle'
import styled from 'styled-components'
import { Wrapper0TextItemArticle } from '../../components/DescriptionItemArticle/components/TextItemArticle'
import { TopLine0DescriptionItemArticle } from '../../components/DescriptionItemArticle'

const Wrapper = styled.div``

const StyledNakedItemArticle = styled(NakedItemArticle)`
  min-width: 100%;
  ${Image0NakedItemArticle} {
    width: 100%;
  }
  ${Description0NakedItemArticle} {
    min-width: 100%;
    padding-right: ${(props) => props.theme.sizes.gapColHalf};
    margin-top: 0;
    padding-top: calc(${(props) => props.theme.sizes.gapColHalf} / 2);
    padding-bottom: 20px;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    ${Description0NakedItemArticle} {
      padding-right: ${(props) => props.theme.tablet.sizes.gapColHalf};
      padding-top: calc(${(props) => props.theme.tablet.sizes.gapColHalf} / 2);
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${Description0NakedItemArticle} {
      padding-right: ${(props) => props.theme.mobile.sizes.gapColHalf};
      padding-top: calc(${(props) => props.theme.mobile.sizes.gapColHalf} / 2);
    }
  }
  ${Wrapper0TextItemArticle} {
    display: none;
  }
  ${TopLine0DescriptionItemArticle} {
    display: none;
  }
`

class VideoItemArticle extends Component {
  render() {
    const { video, headline, title, image, local, partner, type, url, ...props } = this.props
    return (
      <Wrapper {...props}>
        <StyledNakedItemArticle
          {...props}
          video={video}
          url={url}
          local={local}
          partner={partner}
          type={type}
          image={image}
          inverted_background_color="backgroundSecondary"
          headline={headline}
          title={title}
          inverted={1}
          department={undefined}
          moreLink={false}
        />
      </Wrapper>
    )
  }
}

VideoItemArticle.propTypes = {
  ...NakedItemArticle.propTypes,
}

VideoItemArticle.defaultProps = {
  truncateText: {
    numberOfLines: 'auto',
  },
  truncateTitle: {
    numberOfLines: 3,
  },
}

export default VideoItemArticle
export { Wrapper as Wrapper0VideoItemArticle }
