import React, { Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import skeletonFade from '../../../../../../modules/mixins/skeleton-fade'
import TextSkeleton, { Line0TextSkeleton } from '../../../../../../components/Text/views/TextSkeleton'
import { useScreenSize } from '../../../../../../modules/media-query'

const StyledTextSkeleton = styled(TextSkeleton)`
  ${Line0TextSkeleton} {
    border-width: 16px;
    margin-top: 6px;
  }
`

const StyledTitleSkeleton = styled(TextSkeleton)`
  ${Line0TextSkeleton} {
    border-width: 20px;
  }
  margin-bottom: 15px;
  margin-top: 33px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: 0;
    margin-top: 21px;
    ${Line0TextSkeleton} {
      border-width: 16px;
      margin-top: 3.2px;
    }
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    ${Line0TextSkeleton} {
      border-width: 15px;
      margin-top: 3px;
    }
  }
`

const TextWrapper = styled.div`
  flex: 1;
  margin-left: ${(props) => props.theme.sizes.gapCol};
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: 14px;
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    margin-right: 10px;
  }
`

const ImageWrapper = styled.div`
  background-color: ${(props) => props.theme.color.skeletonScreen};
  ${skeletonFade};
  font-size: ${(props) => props.theme.fontSize.base};
  width: 300px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
    width: ${(props) => props.theme.tablet.sizes.col6};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
    width: ${(props) => props.theme.mobile.sizes.col6};
  }
`

const ImageSkeleton = styled.div`
  padding-top: 56.25%;
`

const Wrapper = styled.div`
  display: flex;
  @media (max-width: 450px) {
    margin-right: 0;
    margin-left: 0;
  }
`

const SkeletonSmallLandscapeItemArticle = () => {
  const screen = useScreenSize()
  let text, title
  switch (screen) {
    case 'mobileS':
    case 'mobile':
      text = 0
      title = 4
      break
    case 'tablet':
      text = 3
      title = 2
      break
    case 'desktop':
    default:
      text = 3
      title = 1
  }
  return (
    <Wrapper>
      <Fragment>
        <ImageWrapper>
          <ImageSkeleton />
        </ImageWrapper>
        <TextWrapper>
          <StyledTitleSkeleton lines={title} lastWidth="80%" />
          <StyledTextSkeleton lines={text} lastWidth="50%" />
        </TextWrapper>
      </Fragment>
    </Wrapper>
  )
}

SkeletonSmallLandscapeItemArticle.defaultProps = {
  margin: '0',
}

export default withTheme(SkeletonSmallLandscapeItemArticle)
export { Wrapper as Wrapper0SkeletonSmallLandscapeItemArticle }
