import styled from 'styled-components'
import MuiDialogActions from '@mui/material/DialogActions'

const DialogActions = styled(MuiDialogActions)`
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-wrap: wrap;
  }
`

export default DialogActions
