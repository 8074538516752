import React, { Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import skeletonFade from '../../../../../../modules/mixins/skeleton-fade'
import TextSkeleton, { Line0TextSkeleton } from '../../../../../../components/Text/views/TextSkeleton'
import { useScreenSize } from '../../../../../../modules/media-query'

const StyledTextSkeleton = styled(TextSkeleton)`
  ${Line0TextSkeleton} {
    border-width: 15px;
    margin-bottom: 7.4px;
    margin-top: 0;
  }
`

const StyledTitleSkeleton = styled(TextSkeleton)`
  ${Line0TextSkeleton} {
    border-width: 20px;
    margin-bottom: 4px;
    margin-top: 0;
  }
  margin-bottom: 15px;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${Line0TextSkeleton} {
      border-width: 16px;
      margin-bottom: 3.2px;
    }
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    ${Line0TextSkeleton} {
      border-width: 15px;
      margin-bottom: 3px;
    }
  }
`

const TextWrapper = styled.div`
  flex: 1;
  margin-top: 35px;
  margin-right: 10px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-left: 40px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: 20px;
    margin-left: 14px;
  }
`

const ImageSkeleton = styled.div`
  background-color: ${(props) => props.theme.color.skeletonScreen};
  ${skeletonFade};
  font-size: ${(props) => props.theme.fontSize.base};
  width: 100%;
  > div {
    padding-top: 56.25%;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
    width: ${(props) => props.theme.tablet.sizes.col6};
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
    width: ${(props) => props.theme.mobile.sizes.col6};
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-direction: row;
  }
`

const SkeletonPortraitItemArticle = ({ ...props }) => {
  const screen = useScreenSize()
  let title, text
  switch (screen) {
    case 'mobileS':
    case 'mobile':
      title = 4
      text = 0
      break
    case 'tablet':
      title = 2
      text = 3
      break
    case 'desktop':
    default:
      title = 2
      text = 4
  }
  return (
    <Wrapper {...props}>
      <Fragment>
        <ImageSkeleton>
          <div />
        </ImageSkeleton>
        <TextWrapper>
          <StyledTitleSkeleton lines={title} lastWidth="50%" />
          <StyledTextSkeleton lines={text} lastWidth="70%" />
        </TextWrapper>
      </Fragment>
    </Wrapper>
  )
}

export default withTheme(SkeletonPortraitItemArticle)
export { Wrapper as Wrapper0SkeletonPortraitItemArticle }
