import React from 'react'
import styled from 'styled-components'
import NakedTitleTopic, { StyledTitle0NakedTitleTopic, WrapperTitle0NakedTitleTopic } from '../NakedTitleTopic'
import JobPoweredByBlock from '../../../../topics/JobWidgetPage/components/JobPoweredByBlock'

const StyledTitleTopic = styled(NakedTitleTopic)`
  justify-content: space-between;
  min-height: 108px;
  height: fit-content;
  margin-bottom: 0;
  padding: 0px 20px;
  font-weight: normal;

  ${WrapperTitle0NakedTitleTopic} {
    margin-right: 300px;
  }

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    ${WrapperTitle0NakedTitleTopic} {
      margin-right: 65px;
    }
    padding: 0px 20px 0px 40px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    justify-content: center;
    flex-direction: column;
    ${StyledTitle0NakedTitleTopic} {
      font-size: 30px;
    }
    ${WrapperTitle0NakedTitleTopic} {
      margin-right: 0;
    }
    padding: 0px 0px;
  }
`

const JobsTitleTopic = ({ ...props }) => {
  return (
    <StyledTitleTopic {...props}>
      <JobPoweredByBlock partner={props.partner ? props.partner : undefined} />
    </StyledTitleTopic>
  )
}

export default JobsTitleTopic
