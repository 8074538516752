import { useEffect, useState } from 'react'
import isServerSide from '../is-server-side'

const useTimeout = ({ active = true, timeout = 10000 }) => {
  const [showMessage, setShowMessage] = useState(true)

  useEffect(() => {
    let id
    if (active && !isServerSide()) {
      id = window.setTimeout(() => {
        if (showMessage) {
          setShowMessage(false)
        }
      }, timeout)
    }

    return () => {
      while (id--) {
        window.clearTimeout(id) // will do nothing if no timeout with id is present
      }
    }
  }, [active])

  return showMessage
}

export default useTimeout
