import { getStoredItem } from '../stored-item'
import isStolApp from '../is-stolapp'
import isServerSide from '../is-server-side'

/**
 * gets the fontSize for the current location or from the UserAgent in our App.
 */
const getFont = () => {
  if (!isServerSide()) {
    return getStoredItem('fontSize ' + window.location.pathname, true)
      ? parseInt(getStoredItem('fontSize ' + window.location.pathname, true))
      : isStolApp()
      ? parseFloat(
          window.navigator.userAgent.match(/fontSize\(.*\)/g) &&
            window.navigator.userAgent
              .match(/fontSize\(.*\)/g)
              .toString()
              .match(/[+-]?\d+(?:\.\d+)?/g),
        )
      : 0
  } else {
    return 0
  }
}

/**
 * central defined function for returning the right fontSize from -3 to 3
 * @param currentFontSize
 * @param operation
 * @param callBackAfterSettingFont
 * @returns {number|*}
 */
const setFont = (currentFontSize, operation, callBackAfterSettingFont) => {
  if (operation === '-' && currentFontSize > -3) {
    let newFont = currentFontSize - 1
    callBackAfterSettingFont(newFont)
    return newFont
  }
  if (operation === '+' && currentFontSize < 3) {
    let newFont = currentFontSize + 1
    callBackAfterSettingFont(newFont)
    return newFont
  } else {
    return currentFontSize
  }
}

/**
 * returns font in px in relation to a baseFont
 * @param fontSize
 * @param defaultThemeFont
 * @returns {string}
 */
const translateFontSizeToTheme = (fontSize, defaultThemeFont) => {
  let modifier = 0
  if (!isServerSide()) {
    modifier = fontSize
      ? fontSize
      : isStolApp()
      ? parseFloat(
          window.navigator.userAgent.match(/fontSize\(.*\)/g) &&
            window.navigator.userAgent
              .match(/fontSize\(.*\)/g)
              .toString()
              .match(/[+-]?\d+(?:\.\d+)?/g),
        )
      : 0
  }
  let defaultThemeFontInt = parseInt(defaultThemeFont.replace('px', ''))
  let translatedFontSize = defaultThemeFontInt + modifier * 2
  return translatedFontSize + 'px'
}

export { getFont, setFont, translateFontSizeToTheme }
