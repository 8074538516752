import React from 'react'
import { PhotoSwipe } from '@dlab/react-photoswipe'
import styled from 'styled-components'
import optionsPhotoswipe from '../../modules/options-photoswipe'
import PropTypes from 'prop-types'
import PhotoSwipeStyle from '../../vendor/photoswipe'

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
`

const LightBox = ({ galleryId, currentImage, images, isOpen, onClose, ...props }) => {
  if (galleryId) optionsPhotoswipe.galleryUID = galleryId
  optionsPhotoswipe.index = currentImage
  return (
    <Wrapper {...props}>
      <PhotoSwipeStyle />
      <PhotoSwipe items={images} options={optionsPhotoswipe} isOpen={isOpen} onClose={onClose} />
    </Wrapper>
  )
}

LightBox.propTypes = {
  galleryId: PropTypes.string,
  currentImage: PropTypes.string,
  images: PropTypes.shape({
    src: PropTypes.string,
    w: PropTypes.number,
    h: PropTypes.number,
  }),
  isOpen: PropTypes.bool,
}

export default LightBox
