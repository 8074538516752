import React from 'react'

const IconRight = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.029" height="25.082" viewBox="0 0 16.029 25.082" {...props}>
    <path
      id="Pfad_738"
      data-name="Pfad 738"
      d="M8.59,26.887l9.033-9.053L8.59,8.781,11.371,6,23.2,17.834,11.371,29.668Z"
      transform="translate(-7.883 -5.293)"
      fill="#fff"
      stroke="#707070"
      strokeWidth="1"
      opacity="0.519"
    />
  </svg>
)

export default IconRight
