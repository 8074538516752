import consoleLogger from '../../../../../console-logger'

const pinPollData = {
  id: undefined,
  mode: undefined,
}
const getPinpollData = ({ element }) => {
  const [consoleLog] = consoleLogger('GetPinpollId')
  consoleLog('element', element, 'Pinpoll')
  if (!Array.isArray(element) && element.length <= 0) {
    consoleLog('element: ', 'not valid', 'Pinpoll')
    return
  }

  const parentElement = element[0]

  consoleLog('parentElement', parentElement, 'Pinpoll')
  const parentElementPinpollId = parentElement && parentElement.attribs['data-pinpoll-id']
  const parentElementPinpollMode = parentElement && parentElement.attribs['data-mode']
  consoleLog('parent element pinpoll id', parentElementPinpollId, 'Pinpoll')
  consoleLog('parent element pinpoll mode', parentElementPinpollMode, 'Pinpoll')

  pinPollData.id = parentElementPinpollId
  pinPollData.mode = parentElementPinpollMode

  return pinPollData
}

export default getPinpollData
