import React, { PureComponent } from 'react'
import styled from 'styled-components'
// import PropTypes from 'prop-types'
import ContainerAd from '../../../../components/ContainerAd'

const Wrapper = styled.div``

const StyledContainerAd = styled(ContainerAd)`
  flex-basis: calc(100% + 2 * ${(props) => props.theme.sizes.gapEdge});
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  justify-content: space-between;
  margin: 0;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: calc(100% + 2 * ${(props) => props.theme.tablet.sizes.gapEdge});
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: calc(200px + ${(props) => props.theme.tablet.sizes.gapVertical});
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: calc(100% + 2 * ${(props) => props.theme.mobile.sizes.gapEdge});
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: calc(200px + ${(props) => props.theme.mobile.sizes.gapVertical});
  }
`

class BlockAdTwo extends PureComponent {
  render() {
    const { adIds, ...props } = this.props
    return (
      <Wrapper {...props}>
        <StyledContainerAd adIds={adIds.slice(0, 2)} noGapEdge />
      </Wrapper>
    )
  }
}

export default BlockAdTwo

//TODO check for two ads if passed and return this block
