import React from 'react'
import styled, { css } from 'styled-components'
import styledContainerQuery from 'styled-container-query'
import PropTypes from 'prop-types'
import southtyrolMap from './images/southtyrol.svg'
import SymbolWeather, {
  SymbolWeather0Name,
  SymbolWeather0IconWeather,
  SymbolWeather0Temperature,
} from '../SymbolWeather'

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapCol};
  margin-bottom: ${(props) => props.theme.sizes.gapCol};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapCol};
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapCol};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapCol};
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapCol};
  }
`
const Map = styledContainerQuery.div`
  position: relative;
  display: inline-block;

  &:container(max-width: 450px) {
    ${SymbolWeather0Name} {
      display: none;
    }
  }

  &:container(max-width: 410px) {
    ${SymbolWeather0IconWeather} {
      width: 60px;
    }
  }

  &:container(max-width: 360px) {
    ${SymbolWeather0Temperature} {
      font-size: ${(props) => props.theme.fontSize.small};
    }

    ${SymbolWeather0IconWeather} {
      width: 50px;
      margin-bottom: 0;
    }
  }
`
const ImageMap = styled.img`
  width: 100%;
  max-width: 640px;
  -webkit-filter: drop-shadow(0 1.5px 2px rgba(0, 0, 0, 0.02)) drop-shadow(0 3px 10px rgba(0, 0, 0, 0.07));
  filter: drop-shadow(0 1.5px 2px rgba(0, 0, 0, 0.02)) drop-shadow(0 3px 10px rgba(0, 0, 0, 0.07));
  display: block;
`
const MapItems = styledContainerQuery.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const StyledSymbolWeather = styled(SymbolWeather)`
  height: 100px;
  transform: translate(-40px, -50px);
  position: absolute;

  ${(props) =>
    props.location === 'schlanders' &&
    css`
      top: 51%;
      left: 15%;
    `}

  ${(props) =>
    props.location === 'meran' &&
    css`
      top: 47%;
      left: 36%;
    `}

  ${(props) =>
    props.location === 'bozen' &&
    css`
      top: 72%;
      left: 50%;
    `}

  ${(props) =>
    props.location === 'sterzing' &&
    css`
      top: 23%;
      left: 48%;
    `}

  ${(props) =>
    props.location === 'brixen' &&
    css`
      top: 42%;
      left: 63%;
    `}

  ${(props) =>
    props.location === 'bruneck' &&
    css`
      top: 33%;
      left: 81%;
    `}
`
const MapWeather = ({ data, ...props }) => (
  <Wrapper {...props}>
    <Map>
      <ImageMap src={southtyrolMap} />
      <MapItems>
        {data.map(({ name, location, icon, minTemp, maxTemp }, index) => (
          <StyledSymbolWeather
            name={name}
            location={location}
            icon={icon}
            minTemp={minTemp}
            maxTemp={maxTemp}
            key={index}
            square={false}
          />
        ))}
      </MapItems>
    </Map>
  </Wrapper>
)

MapWeather.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
      }).isRequired,
      maxTemp: PropTypes.string.isRequired,
      minTemp: PropTypes.string.isRequired,
    }),
  ),
}

MapWeather.defaultProps = {
  data: [],
}

export default MapWeather
