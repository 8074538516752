import React from 'react'

const NoVideo = (props) => (
  <svg width="72px" height="60px" viewBox="0 0 72 60" {...props}>
    <g>
      <path
        fill="#fff"
        d="M70.793,29.571l-18.883,-9.6l-2.651,-1.348l-35.714,35.716l0,4.5c0,0.549 0.451,1 1,1c0.157,0 0.313,-0.037 0.453,-0.108l55.795,-28.372c0.335,-0.17 0.547,-0.516 0.547,-0.892c0,-0.375 -0.212,-0.721 -0.547,-0.891l0,-0.005Z"
      />
      <path
        fill="#fff"
        d="M58.272,2.42l-1.758,-1.758c-0.423,-0.423 -0.998,-0.662 -1.596,-0.662c-0.598,0 -1.173,0.239 -1.596,0.662l-13.279,13.279l-25.045,-12.735c-0.14,-0.071 -0.296,-0.108 -0.453,-0.108c-0.548,0 -1,0.451 -1,0.999l0,38.343l-12.883,12.883c-0.423,0.423 -0.662,0.998 -0.662,1.596c0,0.598 0.239,1.173 0.662,1.596l1.757,1.758c0.876,0.875 2.316,0.875 3.192,0l52.661,-52.661c0.875,-0.876 0.875,-2.316 0,-3.192Z"
      />
    </g>
  </svg>
)

export default NoVideo
