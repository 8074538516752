import ReactJWPlayer from 'react-jw-player'
import optionsJwPlayer from '../../../../modules/options-jwplayer'
import { htmlParser } from '../../../../modules/parse'
import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import useTargetsFromPopads from './modules/use-targets-from-popads'
import consoleLogger from '../../../../modules/console-logger'
import { actionMETHOD } from '../../../../modules/fetch-data'
import { useWidgetContext } from '../../../../bundles/components/WidgetContext'
import { useAdsContext } from '../../../ContextAds'
import hasItems from '../../../../modules/has-items'
import { useUserContext } from '../../../ContextUser'

const VideoPlayer = ({
  video,
  adsTargeting,
  showAds,
  imageUrl,
  autoplay,
  defaultOptions,
  jwAutoStartHandler,
  isMuted,
  ...props
}) => {
  const isWidget = useWidgetContext()
  const { targets, excludedLabels, videoTargetUri } = useAdsContext()
  const {
    access: { isPlus },
    profile,
  } = useUserContext()
  const [consoleLog] = consoleLogger('usePopadsTargets')
  let targetings = useTargetsFromPopads(adsTargeting)
  const [stateVideo, setStateVideo] = useState({
    id: video && video.jwId,
  })

  consoleLog('VideoSuccessCalled videoTargetUri', videoTargetUri)

  function onAutoStart(event) {
    if (event.item.mediaid !== stateVideo.id && !isWidget) {
      setStateVideo({ id: event.item.mediaid })

      actionMETHOD([], {
        location: '/video/jw/' + event.item.mediaid,
      }).then(function (data) {
        jwAutoStartHandler(data)
      })
    }
  }

  if (targetings) {
    consoleLog('setting AdsTarget to FuncTargets')
    adsTargeting = targetings
  }
  consoleLog('working with ', adsTargeting)

  //JW
  if (video && video.jwId && (hasItems(targets) || isWidget || !showAds)) {
    const videoTargets = {
      ...adsTargeting,
      ...targets,
    }
    return (
      <ReactJWPlayer
        {...optionsJwPlayer({
          jwId: video.jwId,
          advertising: showAds,
          bidding: !(isPlus && profile),
          targeting: videoTargets,
          defaultOptions: defaultOptions,
          excludedLabels,
          videoTargetUri: videoTargetUri,
          isMuted: isMuted,
        })}
        onVideoLoad={onAutoStart}
        image={imageUrl}
        isAutoPlay={autoplay}
        isMuted={isMuted}
        {...props}
      />
    )
  }

  //YOUTUBE
  if (video && video.type.localeCompare('youtube') == 0) {
    return <>{video.type === 'youtube' && video.embed && htmlParser(video.embed, { youtubeAutoplay: autoplay })}</>
  }

  //EMBED
  if (video && video.type.localeCompare('embed') == 0) {
    return <>{video.type === 'embed' && video.embed && htmlParser(video.embed)}</>
  }

  //ELSE
  return false
}

VideoPlayer.propTypes = {
  video: PropTypes.shape({
    type: PropTypes.oneOf(['youtube', 'embed']),
    jwId: PropTypes.string,
    url: PropTypes.string,
  }),
  imageUrl: PropTypes.string,
  showAds: PropTypes.bool,
  adsTargeting: PropTypes.object,
  autoplay: PropTypes.bool,
}

VideoPlayer.defaultProps = {
  showAds: true,
}

const areEqual = (prevProps, nextProps) => {
  const equalTargeting = isEqual(prevProps.adsTargeting, nextProps.adsTargeting)
  const equalShowAds = prevProps.showAds === nextProps.showAds
  const equalImageUrl = prevProps.imageUrl === nextProps.imageUrl
  const equalAutoplay = prevProps.autoplay === nextProps.autoplay
  const equalVideoData = isEqual(prevProps.video, nextProps.video)
  return equalAutoplay && equalImageUrl && equalShowAds && equalTargeting && equalVideoData
}

export default memo(VideoPlayer, areEqual)
