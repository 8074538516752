import React from 'react'
import styled from 'styled-components'

const Image = styled.img`
  max-width: 100%;
`

const simplifyImage = ({ domNode, dropImageAttribs }) => {
  //dropping fix image width and height for imported articles from ez
  const { attribs } = domNode
  if (dropImageAttribs === true) {
    attribs.width && delete attribs.width
    attribs.height && delete attribs.height
    return <Image src={attribs.src} alt={attribs.src} />
  }
}

export default simplifyImage
