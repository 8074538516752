import React from 'react'
import styled from 'styled-components'
import SkeletonLeaderItemArticle from '../../components/ItemArticle/views_leader/LeaderItemArticle/skeleton'
import SkeletonBigLeaderItemArticle from '../../components/ItemArticle/views_leader/BigLeaderItemArticle/skeleton'
import {
  BigLeader0WidgetLeader,
  SecondLeader0WidgetLeader,
  StyledContainerAd0WidgetLeader,
  StyledItemArticle0WidgetLeader,
  ThirdLeader0WidgetLeader,
  Wrapper0WidgetLeader,
  WrapperArticles0WidgetLeader,
} from '.'
import SkeletonHeadlinePaper from '../../../Paper/widgets/WidgetHeadlinePaper/skeleton'
import { skeletonFade } from '../../../../modules/mixins'
import moment from 'moment'

const StyledWrapperArticles0WidgetLeader = styled(WrapperArticles0WidgetLeader)`
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) and (min-width: 768px) {
    ${StyledContainerAd0WidgetLeader} {
      flex-basis: calc((100% - 30px) / 6 * 3 + 12px);
      flex-wrap: wrap;
      align-items: center;
      align-content: space-evenly;
      margin: 0;
      padding: 0;
    }
    ${BigLeader0WidgetLeader} {
      display: flex;
      flex-basis: 100%;
      padding-bottom: 6px;
    }
    ${SecondLeader0WidgetLeader}, ${ThirdLeader0WidgetLeader} {
      display: flex;
      flex-basis: 100%;
      flex-direction: row;
      justify-content: space-between;

      ${StyledItemArticle0WidgetLeader} {
        margin-bottom: 0;
        overflow: unset;
        flex-basis: calc((100% - 30px) / 6 * 3 + 12px);
      }
      ${StyledContainerAd0WidgetLeader} {
        justify-content: space-evenly;
        align-content: center;
        flex-direction: column;
      }
    }
  }
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;

    ${BigLeader0WidgetLeader} {
      padding-bottom: 0;
    }
    ${BigLeader0WidgetLeader}, ${SecondLeader0WidgetLeader}, ${ThirdLeader0WidgetLeader} {
      ${StyledItemArticle0WidgetLeader} {
        margin: 10px 10px 0 10px;
        width: auto;
      }
    }
    ${StyledContainerAd0WidgetLeader} {
      flex-basis: 100%;
      margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};

      > div {
        margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
      }
      > div:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const StyledAdContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${(props) => props.theme.default.sizes.gapCol} 0;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-direction: column;
    margin: 0;
    place-content: center space-evenly;
    width: 50%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

const StyledFullsizeAd = styled.div`
  background-color: ${(props) => props.theme.default.color.skeletonScreen};
  ${skeletonFade};
  width: 468px;
  height: 60px;
  margin-top: ${(props) => props.theme.default.sizes.gapVertical};
  display: ${(props) => (props.tablet ? 'none' : 'block')};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: 5px;
    width: 300px;
    height: 100px;
    display: ${(props) => (props.tablet ? 'block' : 'none')};
  }
  @media (max-width: 767px) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
    display: ${(props) => (props.tablet ? 'none' : 'block')};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const SkeletonWidgetLeader = ({ ...props }) => (
  <Wrapper0WidgetLeader {...props}>
    <StyledWrapperArticles0WidgetLeader>
      <BigLeader0WidgetLeader>
        <StyledItemArticle0WidgetLeader as={SkeletonBigLeaderItemArticle} />
      </BigLeader0WidgetLeader>
      <SecondLeader0WidgetLeader>
        <StyledItemArticle0WidgetLeader second_leader_item as={SkeletonLeaderItemArticle} />
        <StyledItemArticle0WidgetLeader second_leader_item as={SkeletonLeaderItemArticle} />
      </SecondLeader0WidgetLeader>
      <ThirdLeader0WidgetLeader>
        <StyledItemArticle0WidgetLeader as={SkeletonLeaderItemArticle} />
        <StyledItemArticle0WidgetLeader as={SkeletonLeaderItemArticle} />
        <StyledItemArticle0WidgetLeader as={SkeletonLeaderItemArticle} />
        <StyledAdContainer>
          <StyledFullsizeAd tablet={true} />
          <StyledFullsizeAd tablet={true} />
        </StyledAdContainer>
      </ThirdLeader0WidgetLeader>
    </StyledWrapperArticles0WidgetLeader>
    {parseInt(moment().format('HH')) < 12 && <SkeletonHeadlinePaper />}
    <StyledAdContainer>
      <StyledFullsizeAd />
      <StyledFullsizeAd />
    </StyledAdContainer>
  </Wrapper0WidgetLeader>
)

export default SkeletonWidgetLeader
