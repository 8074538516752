import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'
import paper from './images/index'
import { htmlParser } from '../../../../../../../../modules/parse'
import { translateFontSizeToTheme } from '../../../../../../../../modules/font'

const Wrapper = styled.div`
  position: relative;
  margin-top: 50px;
  margin-bottom: 45px;
  background-color: ${(props) => props.theme.color.background};
  padding: 8px 80px 8px 8px;

  ${(props) =>
    props.type === 'zett' &&
    css`
      padding-right: 8px;
    `}
`
const Content = styled.span`
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.color.textPrimary};
  font-size: ${(props) => props.fontSize};
  > a {
    font-size: ${(props) => props.fontSize};
  }
`
const PaperImage = styled.img`
  position: absolute;
  right: -10px;
  bottom: -35px;
  width: 200px;
  ${(props) =>
    props.type === 'zett' &&
    css`
      position: relative;
      right: auto;
      bottom: auto;
      float: right;
      margin: -40px -20px 5px 5px;
      width: 160px;
    `}
`

const HeadlinePaperDetailArticle = ({ fontSize, type, content, ...props }) => {
  const parsedContent = htmlParser(content)
  let linkContent = null
  parsedContent.forEach((element) => {
    if (element.props && element.props.to) linkContent = element
  })
  const nobr = React.createFactory('nobr')
  parsedContent[1] = nobr([], parsedContent[1])

  const HeadlinePaperEmbed = (
    <Wrapper type={type} {...props}>
      <PaperImage type={type} src={paper[type]} />
      <Content fontSize={translateFontSizeToTheme(fontSize, props.theme.fontSize.base)}>{parsedContent}</Content>
    </Wrapper>
  )
  if (linkContent) return React.cloneElement(linkContent, { autoExternalLinkIcon: false, children: HeadlinePaperEmbed })
  return HeadlinePaperEmbed
}

HeadlinePaperDetailArticle.propTypes = {
  content: PropTypes.string,
  type: PropTypes.oneOf(['dolomiten', 'zett']),
}

export default withTheme(HeadlinePaperDetailArticle)
