import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NakedItemOnTour from '../NakedItemOnTour'

const StyledNakedItemOnTour = styled(NakedItemOnTour)`
  background-color: #ffff;
  flex-basis: calc((100% / 3) - 20px);

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: calc((100% / 2) - 20px);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: 100%;
  }
`

const WidgetItemOnTour = ({ headline, title, start, image, url, ...props }) => (
  <StyledNakedItemOnTour headline={headline} title={title} image={image} start={start} url={url} {...props} />
)

WidgetItemOnTour.propTypes = {
  headline: NakedItemOnTour.propTypes.headline,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default WidgetItemOnTour
