const generateStylingString = (marginValues, theme) => {
  // ToDo: mobile-s
  let cssString = ''
  for (const [viewport, margins] of Object.entries(marginValues)) {
    let tempCssString = ''
    const relevantThemeForViewport = ['tablet', 'mobile'].includes(viewport) ? theme[viewport] : theme

    for (const [position, value] of Object.entries(margins)) {
      let interpretedValue = ''
      interpretedValue = value

      if (['top', 'bottom'].includes(position)) {
        interpretedValue = interpretedValue
          .replace('full', relevantThemeForViewport.sizes.gapVertical)
          .replace('half', relevantThemeForViewport.sizes.gapVerticalHalf)
      } else if (['right', 'left'].includes(position)) {
        interpretedValue = interpretedValue.replace('full', relevantThemeForViewport.sizes.gapEdge)
      }

      if (!interpretedValue.trim().endsWith('px') && !interpretedValue.trim().endsWith(')')) {
        interpretedValue = interpretedValue + 'px'
      }

      tempCssString += `
            margin-${position}: ${interpretedValue};`
    }
    if (!['all', 'desktop'].includes(viewport)) {
      tempCssString = `
            @media (max-width: ${relevantThemeForViewport.breakPoint}px) {${tempCssString}
            }
          `
    }
    cssString += tempCssString
  }

  return cssString
}

export default generateStylingString
