import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BreadcrumbList0Breadcrumb, HomeIcon0Breadcrumb, StyledDelimiter0Breadcrumb } from './index'
import LinkBreadcrumb from './components/LinkBreadcrumb'
import TextSkeleton from '../Text/views/TextSkeleton'

const Wrapper = styled.div``
const StyledBreadcrumbList0Breadcrumb = styled(BreadcrumbList0Breadcrumb)`
  div {
    display: flex;
  }
`
const StyledTextSkeleton = styled(TextSkeleton)`
  display: inline-block;
`

const SkeletonBreadcrumb = ({ home, department, ...props }) => (
  <Wrapper {...props}>
    <StyledBreadcrumbList0Breadcrumb>
      {home && (
        <Fragment>
          <LinkBreadcrumb to="/">
            <HomeIcon0Breadcrumb icon="home" />
          </LinkBreadcrumb>
          <StyledDelimiter0Breadcrumb />
          {department ? (
            <LinkBreadcrumb to={department.route}>{department.name}</LinkBreadcrumb>
          ) : (
            <StyledTextSkeleton lastWidth="150px" />
          )}
        </Fragment>
      )}
    </StyledBreadcrumbList0Breadcrumb>
  </Wrapper>
)

SkeletonBreadcrumb.propTypes = {
  home: PropTypes.bool,
}

SkeletonBreadcrumb.defaultProps = {
  home: true,
}

export default SkeletonBreadcrumb
