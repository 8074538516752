import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Title from '../../../../../../../../components/Title'
import Text from '../../../../../../../../components/Text'
import Link from '../../../../../../../../components/Link'

const Wrapper = styled.div`
  margin-bottom: 15px;
  padding-bottom: 15px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: 7px;
    padding-bottom: 7px;
  }
`
const SizeDiv = styled.div`
  height: 185px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: 120px;
  }

  border-bottom-style: solid;
  border-bottom-width: 0.7px;
  border-bottom-color: rgba(0, 0, 0, 0.2);
`
const TextDiv = styled.div`
  padding-top: 5px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin: 7px;
    padding: unset;
  }
`

const Page = ({ data, ...props }) => (
  <Wrapper {...props}>
    <Link to={data.url}>
      <SizeDiv>
        <TextDiv>
          <Title>{data.title}</Title>
          <Text>{data.description}</Text>
        </TextDiv>
      </SizeDiv>
    </Link>
  </Wrapper>
)
Page.propTypes = {}
Page.defaultProps = {}
export default Page
