import React from 'react'
import { withTheme } from 'styled-components'
import TextSkeleton from '../../../../../../components/Text/views/TextSkeleton'
import { withRouter } from 'react-router-dom'
import {
  Header0DetailArticle,
  StyledDate0DetailArticle,
  StyledText0DetailArticle,
  StyledTitle0DetailArticle,
  Wrapper0HeaderDetailArticle,
} from '.'

const SkeletonHeaderDetailArticle = ({ ...props }) => (
  <Wrapper0HeaderDetailArticle {...props}>
    <StyledTitle0DetailArticle as={TextSkeleton} fontSize="28px" lastWidth="320px" />
    <Header0DetailArticle>
      <TextSkeleton fontSize="18px" lastWidth="100px" />
      <StyledDate0DetailArticle as={TextSkeleton} fontSize="18px" lastWidth="120px" />
    </Header0DetailArticle>
    <StyledText0DetailArticle as={TextSkeleton} lines={3} lastWidth="30px" justify={false} />
  </Wrapper0HeaderDetailArticle>
)

export default withRouter(withTheme(SkeletonHeaderDetailArticle))
