import styled from 'styled-components'
import NakedTitleTopic, { StyledTitle0NakedTitleTopic } from '../NakedTitleTopic'

const ScreenTitleTopic = styled(NakedTitleTopic)`
  margin-bottom: 0;
  @media (max-width: 600px) {
    height: 85px;
    ${StyledTitle0NakedTitleTopic} {
      font-size: 30px;
    }
  }
`

export default ScreenTitleTopic
