import styled from 'styled-components'
import Title from '../../../../../../components/Title'

const TitleWidgetNakedLineArticle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.titleBigger};
  color: ${(props) => props.theme.color.textSecondary};

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.titleBigger};
  }
`

export default TitleWidgetNakedLineArticle
