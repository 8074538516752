import SkeletonBigItemArticle from '../../../../topics/Article/components/ItemArticle/views_default/BigItemArticle/skeleton'
import React from 'react'
import styled from 'styled-components'

const WrapperSkeletonBigItemArticle = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const SkeletonBlockBigOneArticle = () => (
  <WrapperSkeletonBigItemArticle>
    <SkeletonBigItemArticle />
  </WrapperSkeletonBigItemArticle>
)

export default SkeletonBlockBigOneArticle
