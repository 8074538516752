import React from 'react'
import PropTypes from 'prop-types'
import SymbolWeather from '../SymbolWeather'
import styled from 'styled-components'
import styledContainerQuery from 'styled-container-query'
import moment from 'moment'

const StyledSymbolWeather = styled(SymbolWeather)`
  width: ${props => props.theme.sizes.col3};
  @media(max-width: ${props => props.theme.tablet.breakPoint + 'px'}){
    width: ${props => props.theme.tablet.sizes.col3};
  }
  @media(max-width: ${props => props.theme.mobile.breakPoint + 'px'}){
    width: ${props => props.theme.mobile.sizes.col3};
  }
  height: auto;
  padding-bottom: 0.5em;
`

const Wrapper = styledContainerQuery.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: uppercase;
  flex-wrap: wrap;
  
  &:container(max-width: 450px){
    ${StyledSymbolWeather} {
      width: ${props => props.theme.mobile.sizes.col6};
    }
  }    
`

const DATE_FORMAT = 'dddd'

const PreviewSymbols = ({ days, ...props }) => {
  return (
    <Wrapper {...props}>
      {days.map((day, index) => {
        const name = moment(day.date).locale("de").format(DATE_FORMAT)
        return (
          <StyledSymbolWeather key={'weather-symbol-' + index} name={name} icon={day.icon} minTemp={day.minTemp} maxTemp={day.maxTemp} />
        )
      })}
    </Wrapper>
  )
}

PreviewSymbols.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
      }).isRequired,
      minTemp: PropTypes.string.isRequired,
      maxTemp: PropTypes.string.isRequired,
    }),
  ),
}

PreviewSymbols.defaultProps = {
  days: [],
}

export default PreviewSymbols
