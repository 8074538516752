const getCustomApi = ({ route, modules, match }) => {
  const routeOptions = route.getOptions()
  const routeQueryOptions = routeOptions && routeOptions.query
  if (!routeQueryOptions) {
    return false
  }
  if (typeof routeQueryOptions.customApiPath === 'string') {
    const customApiPathModule = modules[routeQueryOptions.customApiPath]
    if (customApiPathModule && typeof customApiPathModule === 'function') {
      return customApiPathModule({ match })
    }
    return false
  }
}

export default getCustomApi
