const jobsSLCSlotIDs = {
  desktop:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/35357643-649a-4620-9fcf-8e62a001467e?p=r' // ids from department
      : 'https://delivery.connect.suedtirol.live/json/0cbc8a33-5745-4a0a-953e-7e39b303402b?p=r',
  tablet:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/35357643-649a-4620-9fcf-8e62a001467e?p=r' // ids from department
      : 'https://delivery.connect.suedtirol.live/json/0f216bf8-4ec9-4f6c-90fc-6af3738281e6?p=r',
  mobile:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/07cf37dd-47ba-43b0-a726-6080988dcd4b?p=r' // ids from department
      : 'https://delivery.connect.suedtirol.live/json/616a88f6-b083-4472-b191-143f2ec7dbb8?p=r',
  tabletApp:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/35357643-649a-4620-9fcf-8e62a001467e?p=r' // ids from department
      : 'https://delivery.connect.suedtirol.live/json/b4bfcfdf-c16d-487e-9bac-3174e53671f9?p=r',
  mobileApp:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/07cf37dd-47ba-43b0-a726-6080988dcd4b?p=r' // ids from department
      : 'https://delivery.connect.suedtirol.live/json/95830563-c997-4e77-8048-50caa0a4d8a1?p=r',
}

const districtLayout = {
  general: {
    adIds: ['S1', 'S2'],
  },
  loadMore: true,
  feed: [
    {
      name: 'title_district',
      dataProps: ['title', 'identifier'],
      noGapEdge: true,
    },
    {
      name: 'category_articles_panel',
      dataProps: ['articles', 'locale'],
      noGapEdge: true,
      feed: [
        {
          name: 'big_one_article',
          articleIds: [0],
          noLazyLoading: true,
          adIds: ['S3', 'S4'],
        },
        {
          name: 'three_landscape_article',
          articleIds: [1, 2, 3],
          adIds: ['S5', 'S6'],
          noLazyLoading: true,
        },
        {
          name: 'jobs',
          numberItems: {
            desktop: 6,
            tablet: 6,
            mobile: 3,
          },
          slotIds: jobsSLCSlotIDs,
          noGapEdge: true,
          margin: {
            all: {
              top: '0',
              bottom: 'half',
            },
          },
        },
        {
          name: 'three_landscape_article',
          articleIds: [4, 5, 6],
          adIds: ['S6'],
        },
        {
          name: 'articles',
          articlesFrom: 7,
        },
      ],
    },
  ],
}

export default districtLayout

export { jobsSLCSlotIDs }
