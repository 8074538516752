import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import OnTourTitle from '../../components/OnTourTitle'
import LocationTitle from '../../components/LocationTitle'
import Link from '../../../../../../components/Link'
import Date from '../../../../../../components/Date'
import ImageWidescreen from '../../../../../../components/ImageWidescreen'

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  flex-direction: column;
  padding: 10px;
  display: flex;
  transition: background-color 0.5s ease;
  &:hover {
    background-color: ${(props) => props.theme.color.background};
    color: #000;
  }
`

const StyledLink = styled(Link)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

const Wrapper = styled.div`
  pointer-events: none;
`

const StyledLocationTitle = styled(LocationTitle)`
  pointer-events: all;
  z-index: 1;
`

const StyledImage = styled(ImageWidescreen)`
  width: 100%;
  flex: 1;
`

const Caption = styled.div`
  flex: 1;
`

const Label = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
`

const StyledDate = styled(Date)`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) => props.theme.color.textSecondary};
  transition: color 0.5s ease;
  margin-left: 5px;
`
class NakedItemOnTour extends Component {
  render() {
    const { title, url, start, headline, image, breadcrumb, ...props } = this.props
    let state = { date: start, breadcrumb: breadcrumb, image: image, title: title, ...props }
    let to = { pathname: url, state: state }
    // unused props
    delete props.adsTargeting
    delete props.locationEvent
    delete props.hasVideo
    delete props.local
    delete props.articleLayout
    return (
      <StyledContainer {...props}>
        <StyledLink to={to} />
        <Wrapper>
          <StyledImage
            url={url}
            headline={headline}
            image={image}
            date={start}
            title={title}
            location={props.location}
            eventLocation={props.locationEvent}
            breadcrumb={breadcrumb}
            content={props.description}
          />
          <Caption>
            <Label>
              <StyledLocationTitle headline={headline} />
              {start ? <StyledDate format="DD.MM.YYYY">{start}</StyledDate> : null}
            </Label>
            <OnTourTitle title={title} />
          </Caption>
        </Wrapper>
      </StyledContainer>
    )
  }
}

NakedItemOnTour.propTypes = {
  headline: LocationTitle.propTypes.headline,
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
}

export default NakedItemOnTour
export {
  LocationTitle as LocationTitle0NakedItemOnTour,
  StyledContainer as StyledContainer0NakedItemOnTour,
  Wrapper as Wrapper0NakedItemOnTour,
  Caption as Caption0NakedItemOnTour,
  Label as Label0NakedItemOnTour,
  StyledDate as StyledDate0NakedItemOnTour,
  OnTourTitle as OnTourTitle0NakedItemOnTour,
}
