import React from 'react'
import styled from 'styled-components'
import translate from '../../../../../../modules/translate'
import PropTypes from 'prop-types'
import LogoSportnews from './images/sportnews-banner.svg'
import Link from '../../../../../../components/Link'
import isStolApp from "../../../../../../modules/is-stolapp";

const StyledLogoSportnews = styled.img`
  height: 43px;
  width: auto;
  vertical-align: bottom;
  margin-top: 15px;
`

const LinkToSportNews = styled.span`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.text};
`

const SportnewsBannerDetailArticle = ({ url, ...props }) => {

  if(isStolApp()){
    url=url+"?utm_source=appstol.it";
  }

  return(
  <React.Fragment {...props}>
    <LinkToSportNews>
      {translate('article_sportnews') + ' '}
      <Link inherit={1} autoExternalLinkIcon to={url}>
        {translate('sportnews_link_label')}
      </Link>
    </LinkToSportNews>
    <br />
    <Link to={url}>
      <StyledLogoSportnews src={LogoSportnews} />
    </Link>
  </React.Fragment>
)}

SportnewsBannerDetailArticle.propTypes = {
  url: PropTypes.string,
}

export default SportnewsBannerDetailArticle
