import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SkeletonSmallPortraitItemArticle from '../../../../topics/Article/components/ItemArticle/views_default/SmallPortraitItemArticle/skeleton'

const Wrapper = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-direction: column;
  }
  @media (max-width: 450px) {
    margin-right: 0;
    margin-left: 0;
  }
`

const WrapperSkeletonSmallPortraitItemArticle = styled.div`
  flex: 1;
  margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  margin-right: ${(props) => props.theme.sizes.gapColHalf};
  padding-right: ${(props) => props.theme.sizes.gapColHalf};
  border-right: 1px solid #e5e5e5;
  border-bottom: none;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
    margin-right: 0;
    padding-right: 0;
    border-right: none;
    border-bottom: 1px solid #e5e5e5;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
    margin-right: 0;
  }
`

const WrapperSkeletonSmallPortraitItemArticleLast = styled.div`
  flex: 1;
`

const StyledSkeletonSmallPortraitItemArticle = styled(SkeletonSmallPortraitItemArticle)`
  margin-bottom: 12px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
`

const SkeletonBlockThreeArticle = ({ ...props }) => {
  if (props.dataIds && props.dataIds[0].includes('specialbox') && !props.dataIds[0].includes('specialbox1')) {
    return false
  }
  return (
    <Wrapper {...props}>
      <WrapperSkeletonSmallPortraitItemArticle>
        <StyledSkeletonSmallPortraitItemArticle />
      </WrapperSkeletonSmallPortraitItemArticle>
      <WrapperSkeletonSmallPortraitItemArticle>
        <StyledSkeletonSmallPortraitItemArticle />
      </WrapperSkeletonSmallPortraitItemArticle>
      <WrapperSkeletonSmallPortraitItemArticleLast>
        <StyledSkeletonSmallPortraitItemArticle />
      </WrapperSkeletonSmallPortraitItemArticleLast>
    </Wrapper>
  )
}

SkeletonBlockThreeArticle.propTypes = {
  margin: PropTypes.string,
}

SkeletonBlockThreeArticle.defaultProps = {
  margin: '0',
}

export default SkeletonBlockThreeArticle
