import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from '../../../../components/Image'
import ContainerArticle from '../../../../components/ContainerArticle'
import Title from '../../../../components/Title'
import Text from '../../../../components/Text'
import ContainerAd from '../../../../components/ContainerAd'
import Link from '../../../../components/Link'
import translate from '../../../../modules/translate'
import WidgetRackPaper from '../../widgets/WidgetRackPaper'

const StyledImage = styled(Image)`
  top: 2.5em;
  margin-bottom: 5em;
  max-width: 467px;
  max-height: 677px;
  height: 677px;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    top: 1.8em;
    margin: 0 auto;
    margin-bottom: 5em;
  }
  @media (max-width: 540px) {
    max-width: 350px;
    max-height: 507px;
  }
  @media (max-width: 390px) {
    max-width: 300px;
    max-height: 435px;
  }
  @media (max-width: 340px) {
    max-width: 270px;
    max-height: 391px;
  }
  z-index: 1 !important;
`

const WrapperPaper = styled(ContainerArticle)``

const StyledTitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.title3};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.fontSize.title1};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.fontSize.title1};
  }
  color: ${(props) => props.theme.color.stol};
`

const StyledText = styled(Text)`
  line-height: 1.6;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.fontSize.title3};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.fontSize.title3};
  }
`

const SellingBox = styled.div`
  padding: 2.1875em 4.375em 2.1875em 1.5625em;
  background-color: ${(props) => props.theme.color.backgroundBorder};
  position: absolute;
  right: 0;
  top: 25%;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    position: relative;
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: -${(props) => props.theme.tablet.sizes.gapEdge};
    margin-left: -${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    position: relative;
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: -${(props) => props.theme.mobile.sizes.gapEdge};
    margin-left: -${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const StyledA = styled.a`
  text-decoration: none;
`

const StyledUpperContainerAd = styled(ContainerAd)`
  margin-bottom: 2em;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    > div > div {
      display: flex;
      min-height: 120px;
      align-items: center;
    }

    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
`

const StyledLowwerContainerAd = styled(StyledUpperContainerAd)`
  margin-top: 1em;
  margin-bottom: 1em;
`

const Wrapper = styled.div``

const PagePaper = ({
  data: { imagePaper = 'https://abo.athesiamedien.com/cover_images/dolomiten.jpg?size=preview', rackpaper = [] } = {},
  ...props
}) => (
  <Wrapper {...props}>
    <WrapperPaper>
      <Link to="https://abo.athesiamedien.com/epaper/kiosks/10-dolomiten/aktuelle-ausgabe">
        <StyledImage src={imagePaper} />
      </Link>
      <SellingBox>
        <StyledA href="https://abo.athesiamedien.com/epaper/product_groups" target="_blank">
          <StyledTitle tag="h2">{translate('buy_abo')}</StyledTitle>
        </StyledA>
        <StyledA href="https://www.kiosk.at/services/kioskrestservice/1.0/landingpages/DOLOMITEN" target="_blank">
          <StyledTitle tag="h2">{translate('buy_single')}</StyledTitle>
        </StyledA>
        <StyledA href="https://trauer.bz/" target="_blank">
          <StyledTitle tag="h2">{translate('breadcrumb_obituaries')}</StyledTitle>
        </StyledA>
        <StyledA href="https://dolomiten.it/de/leserbrief-schreiben/" target="_blank">
          <StyledText>{translate('paper_dolomiten_write_readers_letter')}</StyledText>
        </StyledA>
        <StyledA href="https://dolomiten.it/de/" target="_blank">
          <StyledText>{translate('dolomiten_website')}</StyledText>
        </StyledA>
      </SellingBox>
    </WrapperPaper>
    <StyledUpperContainerAd adIds={['D1', 'D2', 'D3']} />
    <WidgetRackPaper data={rackpaper} />
    <StyledLowwerContainerAd adIds={['D4', 'D5', 'D6']} />
  </Wrapper>
)

PagePaper.propTypes = {
  data: PropTypes.shape({
    imagePaper: PropTypes.string,
    rackpaper: WidgetRackPaper.propTypes,
  }),
}

export default PagePaper
