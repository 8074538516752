import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import translate from '../../../../../../modules/translate'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import React, { useState } from 'react'
import DialogContentSendSMS from '../DialogContentSendSMS'
import DialogContentInsertCode from '../DialogContentInsertCode'

const DialogNotVerified = ({ open = false, onClose = () => {} }) => {
  const [showInsertCode, setShowInsertCode] = useState(false)
  const onCloseDialogInsertCode = (props) => {
    setShowInsertCode(false)
    onClose(props)
  }
  return (
    <Dialog open={open} aria-labelledby="verification-dialog-title" aria-describedby="verification-dialog-description">
      <DialogTitle id="verification-dialog-title">{translate('verification_dialog_title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="verification-dialog-description">
          {translate('verification_dialog_description')}
        </DialogContentText>
      </DialogContent>
      {!showInsertCode && <DialogContentSendSMS onClose={onClose} onSuccess={() => setShowInsertCode(true)} />}
      {showInsertCode && <DialogContentInsertCode onClose={onCloseDialogInsertCode} />}
    </Dialog>
  )
}

export default DialogNotVerified
