import React, { Fragment, PureComponent } from 'react'
import styled, { css } from 'styled-components'
// import PropTypes from 'prop-types'
import ContainerAd from '../../../../components/ContainerAd'
import { ContainerQuery } from 'react-container-query'

const Wrapper = styled.div`
  ${(props) =>
    props.adsAreBlocked &&
    css`
      display: none;
    `}
`

const StyledContainerAd = styled(ContainerAd)`
  padding-top: 0;
  margin: 0;
`

const containerQuery = {
  mobile: {
    maxWidth: 680,
  },
}

class BlockAdSingle extends PureComponent {
  render() {
    const { adIds, mobileBlocked, ...props } = this.props
    /*The function blockAd() checks if a particular BlockAdSingle should be hidden in mobile mode (f.e. H8) with the prop mobileBlock and, if so, checks the current mode, blocking the ad if we're in mobile*/
    const blockAd = (themeMode) => {
      return mobileBlocked ? (this.props.theme.mode === 'mobile' || themeMode.mobile ? true : false) : false
    }

    return (
      <ContainerQuery query={containerQuery}>
        {(themeMode) => (
          <Fragment>
            {adIds.length > 0 && !blockAd(themeMode) && (
              <Wrapper adsAreBlocked={false} {...props}>
                <StyledContainerAd adIds={adIds.slice(0, 1)} noGapEdge />
              </Wrapper>
            )}
          </Fragment>
        )}
      </ContainerQuery>
    )
  }
}

export default BlockAdSingle
