import NotFoundError from '../topics/Error/screens/NotFoundError'
import ScreenVideoDetail from './Video/screens/ScreenVideo/screens/ScreenVideoDetail'
import ScreenPaper from '../topics/Paper/screens/ScreenPaper'
//import CouncilElection from '../topics/CouncilElection'
import Profile from './Profile'
import loadable from '@loadable/component'
import PreComponentHome from './Home/components/PreComponentHome'
import ScreenArticle from './Article/screens/ScreenArticle'
import PreComponentMVPEvent from './MvpEvent/components/PreComponentMVPEvent'
import ScreenWeather from './Weather/screens/ScreenWeather'

const Obituary = loadable(() => import(/* webpackChunkName: "Obituary" */ '../topics/Obituary'))
const Traffic = loadable(() => import(/* webpackChunkName: "Traffic" */ '../topics/Traffic'))
const Search = loadable(() => import(/* webpackChunkName: "Search" */ '../topics/Search'))
const Paper = loadable(() => import(/* webpackChunkName: "Paper" */ '../topics/Paper'))
const NearMe = loadable(() => import(/* webpackChunkName: "NearMe" */ '../topics/NearMe'))
const Topic = loadable(() => import(/* webpackChunkName: "Topic" */ '../topics/Topic'))
const NewsFeed = loadable(() => import(/* webpackChunkName: "NewsFeed" */ '../topics/NewsFeed'))
const OnTour = loadable(() => import(/* webpackChunkName: "OnTour" */ '../topics/OnTour'))
const Page = loadable(() => import(/* webpackChunkName: "Page" */ './Page'))
const Bookmark = loadable(() => import(/* webpackChunkName: "Bookmark" */ './Bookmark'))
const Faq = loadable(() => import(/* webpackChunkName: "Faq" */ './Faq'))
const Video = loadable(() => import(/* webpackChunkName: "Video" */ './Video'))
const DlabSettings = loadable(() => import(/* webpackChunkName: "DlabSettings" */ './DlabSettings'))

const UserGeneratedContent = loadable(() =>
  import(/* webpackChunkName: "UserGeneratedContent" */ '../topics/UserGeneratedContent'),
)
const JobWidgetPage = loadable(() => import(/* webpackChunkName: "JobWidgetPage" */ './JobWidgetPage'))

const Weather = loadable(() => import(/* webpackChunkName: "Weather" */ '../topics/Weather'))
const ScreenObituary = loadable(() =>
  import(/* webpackChunkName: "ScreenObituary" */ './Obituary/screens/ScreenObituary'),
)
const ScreenOnTour = loadable(() => import(/* webpackChunkName: "ScreenOnTour" */ './OnTour/screens/ScreenOnTour'))
const ScreenPage = loadable(() => import(/* webpackChunkName: "ScreenPage" */ './Page/screens/ScreenPage'))
const ScreenSearch = loadable(() => import(/* webpackChunkName: "ScreenSearch" */ './Search/screens/SearchScreen'))
const ScreenNewsFeed = loadable(() =>
  import(/* webpackChunkName: "ScreenNewsFeed" */ './NewsFeed/screens/ScreenNewsFeed'),
)
const ScreenTraffic = loadable(() => import(/* webpackChunkName: "ScreenTraffic" */ './Traffic/screens/ScreenTraffic'))

const Components = {
  ScreenArticle,
  Bookmark,
  //  CouncilElection,
  ScreenObituary,
  ScreenOnTour,
  ScreenPage,
  ScreenSearch,
  ScreenNewsFeed,
  ScreenTraffic,
  DlabSettings,
  Obituary,
  Traffic,
  Weather,
  Search,
  Video,
  Paper,
  NearMe,
  Profile,
  UserGeneratedContent,
  Topic,
  NewsFeed,
  NotFoundError,
  OnTour,
  Page,
  Faq,
  JobWidgetPage,
  ScreenWeather,
  PreComponentHome,
  PreComponentMVPEvent,
  ScreenVideoDetail,
  ScreenPaper,
}

export default Components
