import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import PropTypes from 'prop-types'
import { htmlParser } from '../../../../../../../../modules/parse'
import DefaultAvatar from '../../../../../../../../images/DefaultAvatar'

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  display: flex;
  flex-direction: column-reverse;
`
const CommentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
  color: ${(props) => props.theme.color.textSecondary};
`
const Info = styled.div`
  margin: 10px;
  flex: 1;
  align-self: flex-end;
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: ${(props) => props.theme.color.stol};
`
const PostTime = styled.div`
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  color: ${(props) => props.theme.color.textSecondary};
`
const StyledImageDiv = styled.div`
  height: 80px;
  min-width: 80px;
  max-width: 80px;
  max-height: 80px;
  background-color: ${(props) => props.theme.color.backgroundSecondary};
  margin: 10px;
`

const StyledDefaultAvatar = styled(DefaultAvatar)``

const Comment = styled.div`
  flex-basis: 100%;
  margin-left: 110px;

  &:after {
    width: 60%;
    content: ' ';
    display: block;
    margin-top: ${(props) => props.theme.sizes.gapVertical};
    border-bottom: 1px solid ${(props) => props.theme.color.textTertiary};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    &:after {
      margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
      border-bottom: 1px solid ${(props) => props.theme.tablet.color.textTertiary};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: 10px;
    &:after {
      margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
      border-bottom: 1px solid ${(props) => props.theme.mobile.color.textTertiary};
    }
  }
  overflow-wrap: anywhere;
`

class ListComments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comments: this.props.comments,
    }
  }
  shouldComponentUpdate(nextProps) {
    if (nextProps.comments.length !== this.props.comments.length) {
      this.setState({
        comments: nextProps.comments,
      })
      return true
    }
    return true
  }
  render() {
    const { ...props } = this.props
    return (
      <Wrapper {...props}>
        {this.state.comments &&
          this.state.comments.map(({ user, date, comment }) => {
            const momentDate = moment(date)
            let formattedDate = momentDate.format('LLL')
            if (momentDate.isAfter(moment().subtract(2, 'hour'))) {
              formattedDate = momentDate.startOf('minutes').fromNow()
            } else if (momentDate.isSameOrAfter(moment().subtract(2, 'day'))) {
              formattedDate = momentDate.calendar(null, {
                sameDay: '[Heute], LT',
                lastDay: '[Gestern], LT',
              })
            }

            return (
              <CommentWrapper
                key={'comment_' + date.toString('dd-MM-yyyy') + '_' + user.firstName + '_' + user.lastName}
              >
                <StyledImageDiv>
                  <StyledDefaultAvatar />
                </StyledImageDiv>
                <Info>
                  {user.firstName} {user.lastName}
                  <PostTime>{formattedDate}</PostTime>
                </Info>
                <Comment>{htmlParser(comment, { allowedHtml: ['br'] })}</Comment>
              </CommentWrapper>
            )
          })}
      </Wrapper>
    )
  }
}

ListComments.propTypes = {
  comments: PropTypes.shape({
    user: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
  }),
}

export default ListComments
