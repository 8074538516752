import React from 'react'
import styled, { css } from 'styled-components'
import Link from '../../../../../../../../components/Link'
import PropTypes from 'prop-types'
import Title from '../../../../../../../../components/Title'

const Wrapper = styled(Link)`
  display: block;
  color: ${(props) => props.theme.color.textPrimary};

  line-height: ${(props) => props.theme.lineHeight.title};
  text-decoration: none;
  margin: 0;
  max-width: 100%;
`
const StyledTitle = styled(Title)`
  margin: 0;
  height: ${(props) =>
    !props.nextToAnother
      ? 'auto'
      : props.truncateOptions && props.truncateOptions.numberOfLines
      ? props.truncateOptions.numberOfLines *
          props.theme.lineHeight.title *
          parseInt(props.theme.fontSize.title3.slice(0, 2)) +
        'px'
      : '48px'};
  font-size: ${(props) => props.theme.fontSize.title3};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    height: auto;
    font-size: ${(props) => props.theme.tablet.fontSize.title3};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 16px;
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobileS.fontSize.title3};
  }
  max-width: 100%;

  &:hover {
    opacity: 0.8;
  }

  ${(props) =>
    props.inverted === 1 &&
    css`
      color: ${(props) => props.theme.color.textInverted};
      opacity: 0.8;
      background-color: ${(props) => props.theme.color.multimedia[props.inverted_background_color]};
    `};
`
const BlockDiv = styled.div`
  display: block;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: unset;
  overflow: hidden;
`
const StyledSplus = styled.img`
  height: 1em;
  margin-bottom: -4px;
  margin-right: 5px;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: -2px;
  }
`
const TitleItemArticle = ({
  to,
  plus,
  children,
  inverted,
  truncateOptions,
  inverted_background_color,
  onClick,
  ...props
}) => (
  <Wrapper to={to} onClick={onClick} {...props}>
    <StyledTitle
      truncateOptions={truncateOptions}
      inverted_background_color={inverted_background_color}
      inverted={inverted}
      joinChildren={true}
      nextToAnother={props.nextToAnother}
    >
      <BlockDiv>
        {(plus !== undefined && plus && ' ') || ''}
        {children}{' '}
      </BlockDiv>
    </StyledTitle>
  </Wrapper>
)

TitleItemArticle.defaultProps = {
  inverted_background_color: 'backgroundTertiary',
}

TitleItemArticle.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node,
  inverted: PropTypes.number,
}

export default TitleItemArticle
export {
  Wrapper as Link0TitleItemArticle,
  StyledTitle as Title0TitleItemArticle,
  StyledSplus as Splus0TitleItemArticle,
}
