import React from 'react'
import PropTypes from 'prop-types'
import Title from '../../../Title'
import styled, { css } from 'styled-components'
import ContainerFull from '../../../ContainerFull'

const Wrapper = styled(ContainerFull)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 108px;
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  background-color: ${(props) => props.theme.color.background};
  background-image: -o-linear-gradient(
    55deg,
    ${(props) => props.theme.color.backgroundBorder} 30%,
    ${(props) => props.theme.color.background} 70%
  );
  background-image: -moz-linear-gradient(
    55deg,
    ${(props) => props.theme.color.backgroundBorder} 30%,
    ${(props) => props.theme.color.background} 70%
  );
  background-image: -webkit-linear-gradient(
    55deg,
    ${(props) => props.theme.color.backgroundBorder} 30%,
    ${(props) => props.theme.color.background} 70%
  );
  background-image: -ms-linear-gradient(
    55deg,
    ${(props) => props.theme.color.backgroundBorder} 30%,
    ${(props) => props.theme.color.background} 70%
  );
  background-image: linear-gradient(
    55deg,
    ${(props) => props.theme.color.backgroundBorder} 30%,
    ${(props) => props.theme.color.background} 70%
  );
  border: ${(props) => props.theme.color.backgroundBorder} 1px solid;
`

const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledTitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.titleBigger};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.titleBigger};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.titleBigger};
  }
  ${(props) =>
    props.subelement !== undefined &&
    css`
      margin-bottom: 0;
    `}
`

const NakedTitleTopic = ({ title, subelement, children, ...props }) => (
  <Wrapper {...props}>
    <WrapperTitle>
      <StyledTitle subelement={subelement}>{title}</StyledTitle>
      {subelement && subelement}
    </WrapperTitle>
    {children}
  </Wrapper>
)

NakedTitleTopic.defaultProps = {}

NakedTitleTopic.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  subelement: PropTypes.node,
}

export default NakedTitleTopic
export { StyledTitle as StyledTitle0NakedTitleTopic, WrapperTitle as WrapperTitle0NakedTitleTopic }
