import React from 'react'
import styled from 'styled-components'
import TextSkeleton, { Line0TextSkeleton } from '../../../../../../components/Text/views/TextSkeleton'

const Wrapper = styled.div`
  width: 100%;
`

const TitleTextSkeleton = styled(TextSkeleton)`
  ${Line0TextSkeleton} {
    border-width: 20px;
  }
`

const SkeletonLineItemArticle = ({ views, ...props }) => (
  <Wrapper {...props}>
    <TextSkeleton lastWidth="30%" />
    <TitleTextSkeleton />
    {views && <TextSkeleton lastWidth="10%" />}
  </Wrapper>
)

export default SkeletonLineItemArticle
export { Wrapper as Wrapper0SkeletonLineItemArticle }
