import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import translate from '../../../../modules/translate'

const Wrapper = styled.div`
  color: ${(props) => props.theme.color.stol};
  font-family: ${(props) => props.theme.font.primary};
  font-size: 0.8125em;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  text-transform: uppercase;
  background-color: ${(props) => props.theme.color.backgroundContent};
  padding: 1px 0.4em 0;
  line-height: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    line-height: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 0.625rem;
    padding: 1px 0.6em 0;
    line-height: ${(props) => props.theme.mobile.fontSize.text};
  }
  z-index: 2;
`
const types = ['live', 'poll', 'ugc', 'quiz']

const TypeImageArticle = ({ type, ...props }) => {
  if (types.indexOf(type) !== -1) {
    return <Wrapper {...props}>{translate(type)}</Wrapper>
  }
  return null
}

TypeImageArticle.propTypes = {
  type: PropTypes.oneOf(['live', 'poll', 'ugc', 'quiz', null, 'null']),
}

export default TypeImageArticle

export { types as TypeImageArticle0types }
