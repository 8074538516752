import React from 'react'

function SvgVideoPlayCircle(props) {
  return (
    <svg viewBox="0 0 43.2 43.2" {...props}>
      <defs>
        <style>{'.video-play-circle_svg__b{fill:#fff}'}</style>
      </defs>
      <path
        d="M33.8 9.4A16.7 16.7 0 0139 21.6a16.7 16.7 0 01-5 12.2A16.7 16.7 0 0121.5 39a16.7 16.7 0 01-12.2-5 16.7 16.7 0 01-5.1-12.3 16.7 16.7 0 015-12.2 16.7 16.7 0 0112.3-5.1 16.7 16.7 0 0112.2 5zm-16.6 22l13-9.8-13-9.7z"
        fill="#b71c1c"
        opacity={0.8}
      />
      <path
        className="video-play-circle_svg__b"
        d="M36.9 6.3a20.8 20.8 0 016.3 15.3 20.8 20.8 0 01-6.3 15.3 20.8 20.8 0 01-15.3 6.3 20.8 20.8 0 01-15.3-6.3A20.8 20.8 0 010 21.6 20.8 20.8 0 016.3 6.3 20.8 20.8 0 0121.6 0a20.8 20.8 0 0115.3 6.3zm2 15.3a16.7 16.7 0 00-5-12.2 16.7 16.7 0 00-12.3-5.1 16.7 16.7 0 00-12.2 5 16.7 16.7 0 00-5.1 12.3 16.7 16.7 0 005 12.2A16.7 16.7 0 0021.7 39a16.7 16.7 0 0012.2-5A16.7 16.7 0 0039 21.5z"
      />
      <path className="video-play-circle_svg__b" d="M30.2 21.6l-13 9.7V12z" />
    </svg>
  )
}

export default SvgVideoPlayCircle
