import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import WidgetEvents from '../../../../topics/Article/widgets_line/WidgetEvents'
import AdUnit from '../../../../components/AdUnit'

const StyledWidgetEvents = styled(WidgetEvents)`
  flex-basis: ${(props) => props.theme.sizes.col8.slice(0, -1) + ' - ' + props.theme.sizes.gapColHalf + ')'};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: ${(props) =>
      props.theme.tablet.sizes.col8.slice(0, -1) + ' - ' + props.theme.tablet.sizes.gapColHalf + ')'};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: ${(props) =>
      props.theme.mobile.sizes.col8.slice(0, -1) + ' - ' + props.theme.mobile.sizes.gapColHalf + ')'};
  }
  min-height: 300px;
`

const AdsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  flex-direction: column;
`

const StyledAdUnit = styled(AdUnit)``

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  ${(props) =>
    props.theme.mode !== 'desktop' &&
    css`
      flex-wrap: wrap;
    `}
  @media(max-width: 947px) {
    flex-direction: column;
    ${AdsWrapper} {
      flex-direction: row;
      flex-wrap: wrap;
    }
    ${StyledAdUnit} {
      margin-top: ${(props) => props.theme.sizes.gapVertical};
      &:last-child {
        display: none;
      }
    }
    ${StyledWidgetEvents} {
      min-height: auto;
    }
  }
`

class BlockEventsAndAds extends PureComponent {
  render() {
    const { adIds, data, ...props } = this.props
    return (
      <Wrapper {...props}>
        <StyledWidgetEvents articles={data} />
        <AdsWrapper>
          {adIds && adIds.map((adId, index) => <StyledAdUnit adId={adId} key={'BlockEventsAndAds' + index} />)}
        </AdsWrapper>
      </Wrapper>
    )
  }
}

BlockEventsAndAds.propTypes = {
  adIds: PropTypes.arrayOf(AdUnit.propTypes.adId),
}

export default BlockEventsAndAds
