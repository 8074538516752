import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ErrorBoundary from '../../../../topics/Error/widgets/ErrorBoundary'
import consoleLogger from '../../../../modules/console-logger'
import isServerSide from '../../../../modules/is-server-side'

const ExperienceScript = ({
  tags,
  author,
  section,
  native,
  contentID,
  hasVideo,
  contentType,
  contentDate,
  adTargeting,
}) => {
  const [consoleLog] = consoleLogger('ExperiencePiano')
  useEffect(() => {
    if (adTargeting.STOL7_rubrik) tags.push(adTargeting.STOL7_rubrik)
    if (adTargeting.STOL7_unterrubrik) tags.push(adTargeting.STOL7_unterrubrik)
    section = section || adTargeting.STOL7_rubrik
    contentID = contentID || adTargeting.STOL7_id

    if (!isServerSide()) {
      window.tp = window['tp'] || []
      if (tags) window.tp.push(['setTags', tags])
      if (author) window.tp.push(['setContentAuthor', author])
      if (section) window.tp.push(['setContentSection', section])
      if (native) window.tp.push(['setContentIsNative', native])
      if (contentID) window.tp.push(['setCustomVariable', 'articleID', contentID.toString(), 'content'])
      if (hasVideo) window.tp.push(['setCustomVariable', 'hasVideo', hasVideo.toString(), 'content'])
      if (contentType) window.tp.push(['setCustomVariable', 'articleType', contentType, 'content'])
      if (contentDate) window.tp.push(['setContentCreated', contentDate])

      window.tp.push([
        'init',
        function () {
          window.tp.experience.execute()
          // Tell Scheduler Piano is Ready
          window.callbackFunction('pianoLoaded')
          consoleLog('Init', '', 'ExperienceExecute')
        },
      ])
    }
  }, [])

  return true
}

const ExperiencePiano = (props) => (
  <ErrorBoundary logError="Piano Experience">
    <ExperienceScript {...props} />
  </ErrorBoundary>
)

ExperiencePiano.propTypes = {
  tags: PropTypes.array,
  author: PropTypes.string,
  native: PropTypes.bool,
  contentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contentType: PropTypes.string,
  contentDate: PropTypes.string,
  hasVideo: PropTypes.bool,
  adTargeting: PropTypes.object,
}

ExperiencePiano.defaultProps = {
  tags: [],
}

export default ExperiencePiano
