import React from 'react'
import domToReact from 'html-react-parser/lib/dom-to-react'
import isServerSide from '../../../is-server-side'
import { Helmet } from 'react-helmet-async'

const ExternalApa = (data, embedBoxChildren) => {
  const embedScript = []

  if (data) {
    embedScript.push(<script>{data}</script>)
  } else if (!isServerSide() && !document.getElementById('apa-script')) {
    embedScript.push(
      <script key="apa-script" id="apa-script" type="text/javascript">{`
          function apa_doc_ready(fn) {
            if (document.readyState != 'loading') {
              fn();
            } else {
              document.addEventListener('DOMContentLoaded', fn);
            }
          }

          function apa_set_consent(gdpr, gdpr_consent) {
            var elements = document.querySelectorAll('.apavideo');
            Array.prototype.forEach.call(elements, function(el, i) {
              var url = el.getAttribute('data-src');
              if (url) {
                if (url.indexOf("?") >= 0) {
                  url = url + "&";
                } else {
                  url = url + "?";
                }
                var newurl = url + "ad_gdpr=" + gdpr + "&ad_gdpr_consent=" + gdpr_consent;
                el.setAttribute('src', newurl);
              }
            });
          }
          apa_doc_ready(
            function() {
              const __tcfapi = window.__tcfapi

              if (!__tcfapi) {
                return false
              }

              __tcfapi('getTCData', 2, (tcData, success) => {
                if (success) {
                  var gdpr = "1";
                  var gdpr_consent = tcData.tcString;
                  apa_set_consent(gdpr, gdpr_consent);
                }
              })
            });
        `}</script>,
    )
  }
  return (
    <React.Fragment>
      <Helmet>{embedScript}</Helmet>
      {domToReact(embedBoxChildren)}
    </React.Fragment>
  )
}

export default ExternalApa
