import React from 'react'
import styled from 'styled-components'
import fetchData, { actionMETHOD } from '../../modules/fetch-data'
import userSession from '../../topics/Profile/modules/user-session'
import BookmarkStateChanger from './components/BookmarkStateChanger'
import Bookmarkgrey from './images/bookmarkgrey.svg'
import { Async } from 'react-async'
import consoleLogger from '../../modules/console-logger'
import isServerSide from '../../modules/is-server-side'
import { useUserContext } from '../ContextUser'

const Wrapper = styled.div`
  margin-left: auto;
`

const Symbol = styled.img`
  cursor: pointer;
`

const BookmarkSymbol = ({ ...props }) => {
  const {
    access: { isPlus },
    profile,
  } = useUserContext()
  const [consoleLog] = consoleLogger('BookmarkSymbol')
  consoleLog('plus account', isPlus, 'UseAccess')
  consoleLog('profile', profile, 'UseAccess')

  return (
    <Wrapper {...props}>
      {isPlus && profile && (
        <Async
          promiseFn={fetchData}
          deferFn={actionMETHOD}
          location={'/user/bookmarks'}
          access={userSession.getAccessToken()}
        >
          {({ data, error, isPending, isFulfilled, run }) => {
            function checkIfBookmarked(data) {
              let ret = false
              if (data && data.data && data.data.articles) {
                data.data.articles.map((entry) => {
                  if (!isServerSide() && entry.url.localeCompare(window.location.pathname) == 0) {
                    ret = true
                  }
                })
              }

              return ret
            }

            if (data && !isPending) {
              return (
                <BookmarkStateChanger
                  data={isFulfilled && data}
                  loading={isPending}
                  isBookmarked={isFulfilled && checkIfBookmarked(data)}
                />
              )
            }
          }}
        </Async>
      )}

      {!(isPlus && profile) && <Symbol id={'react_bookmark_symbol'} src={Bookmarkgrey} />}
    </Wrapper>
  )
}
BookmarkSymbol.propTypes = {}
BookmarkSymbol.defaultProps = {}
export default BookmarkSymbol
