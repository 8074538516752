import React from 'react'
import styled from 'styled-components'
import Play from './images/Play'

const StyledPlay = styled(Play)`
  opacity: 0.8;
  display: block;
  width: 50px;
`

const PlayButton = () => <StyledPlay />

export default PlayButton
