import isServerSide from '../is-server-side'

/**
 * Function for custom events in Tag Manager
 * Custom Varibles can also be passed. Exmaple:
 * pushEventToDataLayer("LocalArticle", ["DistrictName", "Bozen"], ["Department", "Ontour"])
 * @param eventName
 * @param parameters
 */
const pushEventToDataLayer = (eventName, ...parameters) => {
  if (!isServerSide() && window.dataLayer) {
    let data = {
      event: eventName,
    }
    parameters.map((param) => {
      if (param[0] && param[1]) {
        data[param[0]] = param[1]
      }
    })
    window.dataLayer.push(data)
  }
}

export default pushEventToDataLayer
