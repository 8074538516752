import React from 'react'
import styled from 'styled-components'
import Text from '../../../Text'
import Plus from '../../../../images/Plus'
import translate from '../../../../modules/translate'
import NoVideo from './images/NoVideo'

const Wrapper = styled.div`
  width: 250px;
  height: 60px;
  -webkit-text-stroke: 0.2px ${(props) => props.theme.color.multimedia.backgroundPrimary};
  text-shadow: 1px 1px ${(props) => props.theme.color.multimedia.backgroundTertiary};
  display: flex;
  align-items: center;
`

const StyledNoVideo = styled(NoVideo)`
  vertical-align: middle;
  margin-right: 17px;
  filter: drop-shadow(0 1px 1px ${(props) => props.theme.color.multimedia.backgroundTertiary});
`

const StyledPlus = styled(Plus)`
  width: 17px;
  line-height: 19px;
  vertical-align: -4px;
  margin: auto 4px;
`
const StyledText = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.smallX};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) => props.theme.color.textInverted};
  line-height: 19px;
`

const DisabledPlusVideo = () => (
  <Wrapper>
    <StyledNoVideo />
    <StyledText tag="div">{translate('video_plus_disabled', { plusIcon: <StyledPlus /> })}</StyledText>
  </Wrapper>
)

export default DisabledPlusVideo
