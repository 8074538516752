// this options are available in the lightbox

import translate from '../translate/index'

const optionsPhotoswipe = {
  showHideOpacity: true,
  bgOpacity: 0.9,
  shareButtons: [
    { id: 'facebook', label: translate('share_facebook'), url: 'https://www.facebook.com/sharer/sharer.php?u={{url}}' },
    {
      id: 'twitter',
      label: translate('share_twitter'),
      url: 'https://twitter.com/intent/tweet?text={{text}}&url={{url}}',
    },
    { id: 'download', label: translate('share_download_image'), url: '{{raw_image_url}}', download: true },
  ],
}

export default optionsPhotoswipe
