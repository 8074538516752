import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ItemArticle from '../../../../topics/Article/components/ItemArticle'
import AdUnit from '../../../../components/AdUnit'
import styled from 'styled-components'
import ContainerAd from '../../../../components/ContainerAd'
import { ContainerQuery } from 'react-container-query'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: calc(-${(props) => props.theme.default.sizes.gapVertical} / 2);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: calc(-${(props) => props.theme.tablet.sizes.gapVertical} / 2);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: calc(-${(props) => props.theme.mobile.sizes.gapVertical} / 2);
  }
`
const StyledContainerAd = styled(ContainerAd)`
  @media (min-width: ${(props) => props.theme.breakPoint + 'px'}) {
    padding-top: 0;
    > div > div {
      margin-left: ${(props) => props.theme.sizes.gapCol};
    }
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: 100%;
    justify-content: space-evenly;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: 100%;
    justify-content: space-evenly;
  }
`

const containerQuery = {
  mobile: {
    maxWidth: 680,
  },
}

class BlockSingleLandscapeArticle extends PureComponent {
  render() {
    const {
      data: { articles, hideDefaults },
      adIds,
      ...props
    } = this.props
    const responsiveAdIds = (themeMode) => {
      if (adIds && (this.props.theme.mode === 'mobile' || themeMode.mobile)) return [adIds[0]]
      return false
    }
    return (
      <ContainerQuery query={containerQuery}>
        {(themeMode) => (
          <Wrapper {...props}>
            {articles[0] && <ItemArticle view="small_landscape" article={articles[0]} hideDefaults={hideDefaults} />}
            {responsiveAdIds(themeMode) && (
              <StyledContainerAd themeMode={themeMode} adIds={responsiveAdIds(themeMode)} noGapEdge />
            )}
          </Wrapper>
        )}
      </ContainerQuery>
    )
  }
}

BlockSingleLandscapeArticle.propTypes = {
  articles: PropTypes.arrayOf(ItemArticle.propTypes.article),
  adIds: PropTypes.arrayOf(AdUnit.propTypes.adId),
}

export default BlockSingleLandscapeArticle
export { Wrapper as Wrapper0BlockSingleLandscapeArticle }
