import React, { useState, useEffect } from 'react'
import ErrorBoundary from '../../../../../Error/widgets/ErrorBoundary'
import translate from '../../../../../../modules/translate'
import MessageError from '../../../../../Error/screens/MessageError'
import styled, { withTheme } from 'styled-components'
import HeaderVideo from '../../../../components/HeaderVideo'
import ContainerFull from '../../../../../../components/ContainerFull'
import HeaderDetailArticle from '../../../../../Article/components/DetailArticle/components/HeaderDetailArticle'
import SkeletonHeaderDetailArticle from '../../../../../Article/components/DetailArticle/components/HeaderDetailArticle/skeleton'
import ModifyAndShareDetailArticle from '../../../../../Article/components/DetailArticle/components/ModifyAndShareDetailArticle'
import ContainerAd from '../../../../../../components/ContainerAd'
import ContainerArticle from '../../../../../../components/ContainerArticle'
import { withRouter } from 'react-router-dom'
import isStolApp from '../../../../../../modules/is-stolapp'
import PropTypes from 'prop-types'
import checkForValueInUserAgent from '../../../../../../modules/check-for-value-in-useragent'
import { getFont, setFont } from '../../../../../../modules/font'
import { setStoredItem } from '../../../../../../modules/stored-item'
import VideoArticle from '../../../../../../components/VideoArticle'
import CloudTag from '../../../../../Article/components/DetailArticle/components/CloudTag'
import hasItems from '../../../../../../modules/has-items'
import WidgetRelated from '../../../../../Article/widgets_line/WidgetRelated'
import isServerSide from '../../../../../../modules/is-server-side'
import pushEventToDataLayer from '../../../../../../modules/push-event-to-data-layer'
import loadable from '@loadable/component'
import Outbrain from '../../../../../../vendor/AdThirdParty/components/Outbrain'
import Schema from '../../../../../../vendor/Schema/index'

import ReactGA from 'react-ga'
import Block from '../../../../../../layouts/Blocks'
import { jobsSLCSlotIDs } from '../../../../../../config/layouts/video'
const Map = loadable(() => import(/* webpackChunkName: "Map" */ '../../../../../../components/Map'))

const ContentDetailArticle = loadable(() =>
  import(
    /* webpackChunkName: "ContentDetailArticle" */ '../../../../../Article/components/DetailArticle/components/ContentDetailArticle'
  ),
)

const WrapperHeaderVideo = styled(ContainerFull)``

const StyledHeaderVideo = styled(HeaderVideo)`
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  padding-bottom: 20px;
  background-color: ${(props) => props.theme.color.multimedia.backgroundTertiary};
  margin-bottom: 0;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    justify-content: center;
  }
`

const Wrapper = styled(ContainerFull)`
  padding-bottom: 0;
  @media (max-width: 450px) {
    padding-right: 0;
    padding-left: 0;
  }
`

const WrapperInner = styled(ContainerArticle)``

const StyledModifyAndShareDetailArticle = styled(ModifyAndShareDetailArticle)`
  color: ${(props) => props.theme.color.textInverted};
  border-color: ${(props) => props.theme.color.textInverted};
`

const StyledCloudTag = styled(CloudTag)`
  margin-top: 20px;
  margin-bottom: 0;
`

const WrapperVideo = styled.div`
  position: relative;
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  width: 120%;
  margin-left: -10%;
  @media (max-width: 1080px) {
    width: 100%;
    margin-left: 0;
  }
`

const NoGapEdgeWrapper = styled.div`
  margin-left: -${(props) => props.theme.sizes.gapEdge};
  margin-right: -${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-left: -${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: -${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: -${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: -${(props) => props.theme.mobile.sizes.gapEdge};
  }
  @media (max-width: 450px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const StyledContainerAd = styled(ContainerAd)`
  margin-bottom: 20px;
`

const StyledMap = styled(Map)`
  margin-bottom: 0;
`

const StyledVideoArticle = styled(VideoArticle)`
  background-color: rgb(91, 97, 100);
`

const StyledWidgetRelated = styled(WidgetRelated)`
  margin-bottom: 0;

  padding-left: ${(props) => props.theme.sizes.gapEdge};
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
  @media (max-width: 450px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const ScreenVideoDetail = ({ failed, loading, response, match, adTargeting, location, ...props }) => {
  const data = response && response.data
  const meta = response && response.meta
  const [fontSize, setFontSize] = useState(getFont())
  const [stateText, setStateText] = useState({
    id: undefined,
    title: undefined,
    description: undefined,
    headline: undefined,
    plus: undefined,
    sPlusTeaser: undefined,
    sPlusDescription: undefined,
    date: undefined,
    tags: undefined,
    related: undefined,
    geo: undefined,
  })
  useEffect(() => {
    setStateText({
      id: data && data.id,
      title: data && data.title && data.title,
      description: data && data.description && data.description,
      headline: data && data.breadcrumb && data.breadcrumb.headline,
      plus: data && data.plus && data.plus,
      sPlusTeaser: data && data.sPlusTeaser && data.sPlusTeaser,
      sPlusDescription: data && data.sPlusDescription && data.sPlusDescription,
      date: data && data.date && data.date,
      tags: data && data.tags && data.tags,
      related: data && data.related && data.related,
      geo: data && data.geo && data.geo,
    })
  }, [data])

  let routerState
  if (!isServerSide()) {
    routerState =
      (props && props.history && props.history.location && props.history.location.state) ||
      (window && window.athesia_react && window.athesia_react.state)
  }
  const videoData = data

  if (meta && meta.adsTargeting) videoData.adsTargeting = meta.adsTargeting
  /*if (data && data.breadcrumb) videoData.headline = data.breadcrumb.headline
  if (data && data.description) {
    videoData.text = data.description
  }*/

  if (data && data.districts && data.districts[0]) {
    pushEventToDataLayer('LocalArticle', ['DistrictName', data.districts[0].name])
  }

  const errorOptions = {
    component: translate('video_error'),
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'VideoDetail screen display',
  }

  const enlargeFont = () => {
    let newFont = setFont(fontSize, '+', () =>
      setStoredItem('fontSize ' + window.location.pathname, (fontSize + 1).toString(), true),
    )
    if (newFont !== fontSize) {
      setFontSize(newFont)
    }
  }

  const reduceFont = () => {
    let newFont = setFont(fontSize, '-', () =>
      setStoredItem('fontSize ' + window.location.pathname, (fontSize - 1).toString(), true),
    )
    if (newFont !== fontSize) {
      setFontSize(newFont)
    }
  }

  const onJwAutoStart = (autoStartData) => {
    setStateText({
      id: autoStartData.data.id,
      title: autoStartData.data.title,
      description: autoStartData.data.description,
      headline: autoStartData.data.breadcrumb.headline,
      plus: autoStartData.data.plus,
      sPlusTeaser: autoStartData.data.sPlusTeaser,
      sPlusDescription: autoStartData.data.sPlusDescription,
      date: autoStartData.data.date,
      tags: autoStartData.data.tags,
      related: autoStartData.data.related,
      geo: autoStartData.data.geo,
    })
    let stateUrl = new URL(autoStartData.meta.url)
    history.pushState(null, autoStartData.meta.title, stateUrl.pathname)
    ReactGA.set({ page: stateUrl.pathname })
    ReactGA.pageview(stateUrl.pathname, null, autoStartData.meta.title)
  }

  const newBlockJobId = () => {
    if (!stateText.id) {
      return
    }
    return 'jobsBlock' + stateText.id
  }

  return (
    <>
      <ErrorBoundary error={failed} {...errorOptions}>
        <WrapperHeaderVideo cinemaMode="multimedia" noGapEdge>
          <StyledHeaderVideo />
        </WrapperHeaderVideo>
        <Wrapper
          className="WrapperArticle"
          isStolApp={isStolApp()}
          isIphoneX={checkForValueInUserAgent('iphonex')}
          cinemaMode="multimedia"
        >
          <WrapperInner>
            <WrapperVideo>
              {failed && <MessageError error={failed} logInfo={translate('dev_errorlog_no_data')} {...errorOptions} />}
              <StyledVideoArticle
                video={videoData && videoData.video}
                image={videoData && videoData.image}
                showAds={videoData && videoData.showAds}
                adsTargeting={videoData && videoData.adsTargeting}
                plus={videoData && videoData.plus}
                jwAutoStartHandler={onJwAutoStart}
                autoplay={true}
                isMuted={true}
              />
            </WrapperVideo>

            {loading && !videoData && <SkeletonHeaderDetailArticle />}
            {!loading && videoData && (
              <HeaderDetailArticle
                title={stateText.title}
                headline={stateText.headline}
                plus={stateText.plus}
                description={
                  stateText.plus
                    ? (stateText.description || stateText.sPlusTeaser) && (
                        <ContentDetailArticle
                          htmlContent={stateText.description}
                          plusTeaser={stateText.sPlusTeaser}
                          plusContent={stateText.sPlusDescription}
                          plus={stateText.plus}
                          inverted
                        />
                      )
                    : stateText.description
                }
                date={stateText.date}
                inverted
                bookmark={{ show: true }}
                fontSize={fontSize}
              />
            )}
            {!loading && !isStolApp() && <Schema data={data} />}

            <StyledModifyAndShareDetailArticle
              enlargeFontCallBack={enlargeFont}
              reduceFontCallBack={reduceFont}
              inverted
              position="bottom"
            />
            {stateText && hasItems(stateText.tags) && stateText.tags.length > 1 && (
              <StyledCloudTag hasBackground={true} tags={stateText.tags} />
            )}
          </WrapperInner>
          {data && data.showAds && <StyledContainerAd adIds={['S3', 'S4']} />}
          <NoGapEdgeWrapper>
            <Block
              key={newBlockJobId()}
              view="jobs"
              numberItems={{
                desktop: 2,
                tablet: 2,
                mobile: 2,
              }}
              slotIds={jobsSLCSlotIDs}
              noGapEdge={true}
            />
          </NoGapEdgeWrapper>
          {stateText && stateText.geo && (
            <NoGapEdgeWrapper>
              <StyledMap lat={stateText.geo.lat} lng={stateText.geo.lng} height={300} />
            </NoGapEdgeWrapper>
          )}
          {stateText && hasItems(stateText.related) && (
            <NoGapEdgeWrapper>
              <StyledWidgetRelated articles={data.related} />
            </NoGapEdgeWrapper>
          )}
        </Wrapper>
        {data.showAds && <Outbrain location={location} />}
      </ErrorBoundary>
    </>
  )
}

ScreenVideoDetail.propTypes = {
  failed: PropTypes.bool,
  loading: PropTypes.bool,
  response: PropTypes.shape({
    data: PropTypes.object,
    meta: PropTypes.object,
  }),
  adTargeting: PropTypes.object,
  match: PropTypes.object,
}

export default withTheme(withRouter(ScreenVideoDetail))
