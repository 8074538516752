import React, { PureComponent } from 'react'
import styled from 'styled-components'
import {
  ArticleWrapper0BlockFacebookAndArticle,
  FbWrapper0BlockFacebookAndArticle,
  WidgetFacebook0BlockFacebookAndArticle,
  Wrapper0BlockfacebookAndArticle,
} from './index'
//import PropTypes from 'prop-types'
import SkeletonSmallLandscapeItemArticle from '../../../../topics/Article/components/ItemArticle/views_default/SmallLandscapeItemArticle/skeleton'

const WrapperSmallLandscapeItemArticle = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const StyledWrapper0BlockfacebookAndArticle = styled(Wrapper0BlockfacebookAndArticle)`
  @media (max-width: 450px) {
    margin-right: 0;
    margin-left: 0;
  }
`

class SkeletonBlockFacebookAndArticle extends PureComponent {
  render() {
    const { ...props } = this.props
    return (
      <StyledWrapper0BlockfacebookAndArticle {...props}>
        <FbWrapper0BlockFacebookAndArticle>
          <WidgetFacebook0BlockFacebookAndArticle />
        </FbWrapper0BlockFacebookAndArticle>
        <ArticleWrapper0BlockFacebookAndArticle>
          <SkeletonSmallLandscapeItemArticle />
          <WrapperSmallLandscapeItemArticle>
            <SkeletonSmallLandscapeItemArticle />
          </WrapperSmallLandscapeItemArticle>
        </ArticleWrapper0BlockFacebookAndArticle>
      </StyledWrapper0BlockfacebookAndArticle>
    )
  }
}

SkeletonBlockFacebookAndArticle.defaultProps = {
  margin: '0',
}

export default SkeletonBlockFacebookAndArticle
