import aIcon from './icon_a.svg'
import bIcon from './icon_b.svg'
import cIcon from './icon_c.svg'
import cIconAnimated from './icon_c_animated.svg'
import dIcon from './icon_d.svg'
import eIcon from './icon_e.svg'
import fIcon from './icon_f.svg'
import gIcon from './icon_g.svg'
import hIcon from './icon_h.svg'
import iIcon from './icon_i.svg'
import jIcon from './icon_j.svg'
import kIcon from './icon_k.svg'
import lIcon from './icon_l.svg'
import mIcon from './icon_m.svg'
import nIcon from './icon_n.svg'
import oIcon from './icon_o.svg'
import pIcon from './icon_p.svg'
import qIcon from './icon_q.svg'
import rIcon from './icon_r.svg'
import sIcon from './icon_s.svg'
import tIcon from './icon_t.svg'
import uIcon from './icon_u.svg'
import vIcon from './icon_v.svg'
import wIcon from './icon_w.svg'
import xIcon from './icon_x.svg'
import yIcon from './icon_y.svg'
import zIcon from './icon_z.svg'
import aaIcon from './icon_aa.svg'

export default {
  a: aIcon,
  b: bIcon,
  c: cIcon,
  cAnimated: cIconAnimated,
  d: dIcon,
  e: eIcon,
  f: fIcon,
  g: gIcon,
  h: hIcon,
  i: iIcon,
  j: jIcon,
  k: kIcon,
  l: lIcon,
  m: mIcon,
  n: nIcon,
  o: oIcon,
  p: pIcon,
  q: qIcon,
  r: rIcon,
  s: sIcon,
  t: tIcon,
  u: uIcon,
  v: vIcon,
  w: wIcon,
  x: xIcon,
  y: yIcon,
  z: zIcon,
  aa: aaIcon,
}

/* TODO here SHOULD be require.context */
