import styled, { css } from 'styled-components'
import React from 'react'
import Text from '../../../../../../components/Text'
import ItemArticle from '../../../../components/ItemArticle'
import moment from 'moment'
import Icon from '../../../../../../components/Icon'

const List = styled.ul`
  padding: 0;
  padding-left: ${(props) => (props.timeLine ? props.theme.sizes.gapColHalf : '0')};
  margin: 0.5em 0 0;
  display: flex;
  flex-direction: column;
  padding-right: ${(props) => (props.timeLine ? props.theme.sizes.gapCol : 0)};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => (props.timeLine ? props.theme.tablet.sizes.gapColHalf : '0')};
    padding-right: ${(props) => (props.timeLine ? props.theme.tablet.sizes.gapCol : 0)};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => (props.timeLine ? props.theme.mobile.sizes.gapColHalf : '0')};
    padding-right: ${(props) => (props.timeLine ? props.theme.mobile.sizes.gapEdge : 0)};
  }
`

const ItemList = styled.li`
  display: flex;
  align-items: flex-start;
  flex: 0.1 0 auto;
  margin: 0.5em 0;
`

const Number = styled(Text)`
  font-size: 46px;
  color: ${(props) => props.theme.color.textSecondary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 1;
  width: 30px;
  text-align: center;
  margin-right: 27px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: 46px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-right: 11px;
    font-size: 40px;
  }
`

const TimeLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  min-width: 120px;
  /*Line for the first Item in the TimeLine*/

  ${(props) =>
    props.index === 0 &&
    css`
      &:before {
        content: '';
        width: 15%;
        border-bottom: solid 1px rgba(0, 0, 0, 0.2);
        transform: rotate(90deg);
        margin-left: -1px;
        margin-bottom: 10px;
      }
    `} &:after {
    content: '';
    width: 25%;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    transform: rotate(90deg);
    margin-left: -1px;
    margin-top: 15px;
  }
`

const Time = styled(Text)`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: rgba(0, 0, 0, 0.3);
`

const Date = styled(Text)`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.text};
  color: rgba(0, 0, 0, 0.5);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
`

const StyledItemArticle = styled(ItemArticle)`
  flex: 1;
  ${(props) =>
    props.timeLine &&
    css`
      margin-top: ${props.index === 0 ? '7px' : '2px'};
    `};
`

const StyledIcon = styled(Icon)`
  position: absolute;
  right: ${(props) => props.theme.sizes.gapCol};
  top: calc(${(props) => props.theme.sizes.gapVerticalHalf} + 10px);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    right: ${(props) => props.theme.tablet.sizes.gapCol};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    right: ${(props) => props.theme.mobile.sizes.gapCol};
    top: 12px;
  }
  font-size: 32px;
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.5s ease;
  ${(props) =>
    props.disabledHover === false &&
    css`
      &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.color.stol};
      }
    `}
`

const ListWidgetNakedLineArticle = ({
  timeLine,
  articles,
  numbering,
  textTag,
  headlinePrefix,
  onClick,
  forceTarget,
  ...props
}) => {
  if (!articles) return false
  return (
    <List id="List0WidgetNakedLineArticle" timeLine={timeLine} {...props}>
      {articles.map((article, index) => {
        if (index + 1 <= 5) {
          moment.locale('de')
          return (
            <ItemList key={'WidgetNakedLineArticle' + article.id + index}>
              {numbering && <Number>{index + 1}</Number>}
              {timeLine && (
                <TimeLine index={index} numberOfArticles={articles.length}>
                  <Time>{moment(article.date).format('HH:mm')}</Time>
                  <Date>{moment(article.date).format('Do MMMM')}</Date>
                </TimeLine>
              )}
              <StyledItemArticle
                view="line"
                textTag={textTag}
                timeLine={timeLine}
                headlinePrefix={headlinePrefix}
                index={index}
                article={article}
                {...article}
                onClick={onClick}
                color={timeLine && 'red'}
                forceTarget={forceTarget}
              />
            </ItemList>
          )
        } else return false
      })}
    </List>
  )
}

export default ListWidgetNakedLineArticle
export {
  TimeLine as TimeLine0ListWidgetNakedLineArticle,
  Date as Date0ListWidgetNakedLineArticle,
  StyledIcon as StyledIcon0ListWidgetNakedLineArticle,
  ItemList as ItemList0ListWidgetNakedLineArticle,
}
