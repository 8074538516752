import React, { PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ItemArticle from '../../../../topics/Article/components/ItemArticle'
import WidgetFacebook, {
  DesktopWrapper0WidgetFacebook,
  MobileWrapper0WidgetFacebook,
} from '../../../../topics/Social/widgets/WidgetFacebook'

const FbWrapper = styled.div`
  width: ${(props) => props.theme.sizes.col4};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col4};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col4};
  }
  @media (max-width: 450px) {
    margin: 0;
  }
`
const ArticleWrapper = styled.div`
  width: ${(props) => props.theme.sizes.col8};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col8};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col8};
  }
`
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    ${FbWrapper} {
      margin-left: -${(props) => props.theme.tablet.sizes.gapEdge};
      margin-right: -${(props) => props.theme.tablet.sizes.gapEdge};
      width: calc(100% + 2 * (${(props) => props.theme.tablet.sizes.gapEdge}));
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${FbWrapper} {
      margin-left: -${(props) => props.theme.mobile.sizes.gapEdge};
      margin-right: -${(props) => props.theme.mobile.sizes.gapEdge};
      width: calc(100% + 2 * (${(props) => props.theme.mobile.sizes.gapEdge}));
    }
  }
  @media (max-width: 965px) {
    ${FbWrapper} {
      margin-left: -${(props) => props.theme.tablet.sizes.gapEdge};
      margin-right: -${(props) => props.theme.tablet.sizes.gapEdge};
      width: calc(100% + 2 * (${(props) => props.theme.tablet.sizes.gapEdge}));
    }
    ${DesktopWrapper0WidgetFacebook} {
      display: none;
    }
    ${MobileWrapper0WidgetFacebook} {
      display: block;
    }
    ${ArticleWrapper} {
      width: 100%;
    }
  }
  @media (max-width: 450px) {
    ${FbWrapper} {
      margin: 0;
    }
  }
`

class BlockFacebookAndArticle extends PureComponent {
  render() {
    const {
      data: { articles },
      ...props
    } = this.props
    return (
      <Wrapper {...props}>
        <FbWrapper>
          <WidgetFacebook />
        </FbWrapper>
        <ArticleWrapper>
          <ItemArticle view="small_landscape" article={articles[0]} />
          <ItemArticle view="small_landscape" article={articles[1]} />
        </ArticleWrapper>
      </Wrapper>
    )
  }
}

BlockFacebookAndArticle.propTypes = {
  articles: PropTypes.arrayOf(ItemArticle.propTypes.article),
}

export default BlockFacebookAndArticle
export {
  Wrapper as Wrapper0BlockfacebookAndArticle,
  FbWrapper as FbWrapper0BlockFacebookAndArticle,
  ArticleWrapper as ArticleWrapper0BlockFacebookAndArticle,
  WidgetFacebook as WidgetFacebook0BlockFacebookAndArticle,
}
