import React from 'react'
import styled from 'styled-components'
import { Wrapper0BlockMostReadArticle, WrapperArticleRect0BlockMostReadArticle } from './index'
import SkeletonWidgetMostRead from '../../../../topics/Article/widgets_line/WidgetMostRead/skeleton'
import SkeletonSmallPortraitItemArticle from '../../../../topics/Article/components/ItemArticle/views_default/SmallPortraitItemArticle/skeleton'
import skeletonFade from '../../../../modules/mixins/skeleton-fade'
import PropTypes from 'prop-types'

const StyledSkeletonSmallPortraitItemArticle = styled(SkeletonSmallPortraitItemArticle)`
  order: 2;
  align-items: center;
`

const SkeletonContainerAdRectangle = styled.div`
  background-color: ${(props) => props.theme.color.skeletonScreen};
  ${skeletonFade};
  width: 300px;
  height: 250px;
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
    display: none;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
    display: none;
  }
`

const StyledWrapperArticleRect0BlockMostReadArticle = styled(WrapperArticleRect0BlockMostReadArticle)`
  @media (min-width: ${(props) => props.theme.breakPoint + 'px'}) {
    margin-right: ${(props) => props.theme.sizes.gapCol};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
    width: 100%;
    margin-right: 0;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
    width: 100%;
    margin-right: 0;
  }
`

const StyledSkeletonWidgetMostRead = styled(SkeletonWidgetMostRead)`
  flex-basis: ${(props) => props.theme.sizes.col8.slice(0, -1) + ' + ' + props.theme.sizes.gapEdge + ')'};
  flex-wrap: nowrap;
  padding-top: ${(props) => props.theme.sizes.gapVertical};
  padding-left: ${(props) => props.theme.sizes.gapVertical};
  padding-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: ${(props) =>
      props.theme.tablet.sizes.col8.slice(0, -1) + ' + ' + props.theme.tablet.sizes.gapEdge + ')'};
    padding-top: ${(props) => props.theme.tablet.sizes.gapVertical};
    padding-left: ${(props) => props.theme.tablet.sizes.gapVertical};
    padding-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
    width: calc(100% - ${(props) => props.theme.tablet.sizes.gapVertical});
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: ${(props) =>
      props.theme.mobile.sizes.col8.slice(0, -1) + ' + ' + props.theme.mobile.sizes.gapEdge + ')'};
    padding-top: ${(props) => props.theme.mobile.sizes.gapVertical};
    padding-left: ${(props) => props.theme.mobile.sizes.gapVertical};
    padding-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
    width: calc(100% - ${(props) => props.theme.mobile.sizes.gapVertical});
  }
  @media (max-width: 450px) {
    width: calc(100% - (${(props) => props.theme.mobile.sizes.gapVertical} + 2px));
  }
`

const StyledWrapper0BlockMostReadArticle = styled(Wrapper0BlockMostReadArticle)`
  flex-wrap: nowrap;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-direction: column;
  }
  @media (max-width: 450px) {
    margin-right: 0;
    margin-left: 0;
  }
`

const SkeletonBlockMostReadArticle = ({ ...props }) => (
  <StyledWrapper0BlockMostReadArticle {...props}>
    <StyledWrapperArticleRect0BlockMostReadArticle>
      <SkeletonContainerAdRectangle />
      <StyledSkeletonSmallPortraitItemArticle className="hey" />
    </StyledWrapperArticleRect0BlockMostReadArticle>
    <StyledSkeletonWidgetMostRead />
  </StyledWrapper0BlockMostReadArticle>
)

SkeletonBlockMostReadArticle.propTypes = {
  margin: PropTypes.string,
}

SkeletonBlockMostReadArticle.defaultProps = {
  margin: '0px',
}

export default SkeletonBlockMostReadArticle
