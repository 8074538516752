import home from '../config/layouts/home'
import department from '../config/layouts/department'
import departmentSplus from '../config/layouts/department-splus'
import district from '../config/layouts/district'
import mvpSleedingDaysDE from './MvpEvent/mvpSleedingDays.de'
import mvpSleedingDaysIT from './MvpEvent/mvpSleedingDays.it'
import tag from './Tag/layout'

const layouts = {
  home,
  department,
  departmentSplus,
  district,
  mvpSleedingDaysDE,
  mvpSleedingDaysIT,
  tag,
}

export default layouts
