import React from 'react'

function DefaultAvatar(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 76.688 76.688">
      <rect
        width="75.688"
        height="75.688"
        transform="translate(0.5 0.5)"
        fill="#fff"
        stroke="#e5e5e5"
        stroke-width="1"
      />
      <path
        d="M3.7,55.922H46.209c0-11.923-5.184-22.81-12.96-26.957A13.655,13.655,0,0,0,24.955,4.6,13.725,13.725,0,0,0,11.476,18.079,14.59,14.59,0,0,0,16.66,28.965C8.884,33.631,3.7,44,3.7,55.922Z"
        transform="translate(13.389 17.155)"
        fill="#707070"
        stroke="#707070"
        stroke-width="1"
      />
    </svg>
  )
}

export default DefaultAvatar
