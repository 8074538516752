import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'
import ItemArticle from '../../../../topics/Article/components/ItemArticle'
import ErrorBoundary from '../../../../topics/Error/widgets/ErrorBoundary'
import translate from '../../../../modules/translate'

const StyledItemArticle = styled(ItemArticle)`
  width: ${(props) => props.theme.sizes.col4};
  margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};

  @media (min-width: ${(props) => props.theme.breakPoint + 'px'}) {
    &:before {
      right: ${(props) => props.theme.sizes.gapColHalf} !important;
    }
    padding-right: ${(props) => props.theme.sizes.gapColHalf};
    border-right: 1px solid #e5e5e5;
    border-bottom: none;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col4};
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
    border-bottom: 1px solid #e5e5e5;
    border-right: none;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col4};
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
    border-bottom: 1px solid #e5e5e5;
    border-right: none;
  }
`

const StyledLastArticles = styled(ItemArticle)`
  width: ${(props) => props.theme.sizes.col4};
  border-bottom: none;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col4};
    margin-bottom: 0;
    ${(props) =>
      !props.pr &&
      css`
        padding-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
      `}
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col4};
    ${(props) =>
      !props.pr &&
      css`
        padding-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
      `}
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  ${(props) =>
    props.pr &&
    css`
      border-bottom: none;
      background-color: ${(props) => props.theme.color.pr};
      padding-top: ${(props) => props.theme.sizes.gapVerticalHalf};
      @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
        padding-top: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
      }
      @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
        padding-top: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
      }
      @media (min-width: 450px) {
        margin-left: -${(props) => props.theme.mobile.sizes.gapEdge};
        margin-right: -${(props) => props.theme.mobile.sizes.gapEdge};
        padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
        padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
      }
    `}
  @media(max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    ${StyledItemArticle} {
      width: ${(props) => props.theme.tablet.sizes.col12};
    }
    ${StyledLastArticles} {
      width: ${(props) => props.theme.tablet.sizes.col12};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${StyledItemArticle} {
      width: ${(props) => props.theme.mobile.sizes.col12};
    }
    ${StyledLastArticles} {
      width: ${(props) => props.theme.mobile.sizes.col12};
    }
  }
`

class BlockThreeArticle extends PureComponent {
  render() {
    const { data, pr, noLazyLoading, ...props } = this.props
    const errorOptions = {
      component: undefined,
      link: '/',
      linkText: translate('error_try_homepage'),
      logError: 'BlockThreeArticle index',
    }
    let articles = data && data.articles ? data.articles : data
    if (!pr && articles) {
      articles.forEach((article) => {
        article.articleLayout = false
      })
    }
    return (
      <ErrorBoundary error={!(data && articles)} {...errorOptions}>
        <Wrapper pr={data && pr} {...props}>
          {articles && (
            <Fragment>
              {articles[0] && (
                <StyledItemArticle
                  view="small_portrait"
                  article={articles[0]}
                  nextToAnother={true}
                  noLazyLoading={noLazyLoading}
                />
              )}
              {articles[1] && (
                <StyledItemArticle
                  view="small_portrait"
                  article={articles[1]}
                  nextToAnother={true}
                  noLazyLoading={noLazyLoading}
                />
              )}
              {articles[2] && (
                <StyledLastArticles
                  view="small_portrait"
                  pr={pr}
                  article={articles[2]}
                  nextToAnother={true}
                  noLazyLoading={noLazyLoading}
                />
              )}
            </Fragment>
          )}
        </Wrapper>
      </ErrorBoundary>
    )
  }
}

BlockThreeArticle.propTypes = {
  articles: PropTypes.arrayOf(ItemArticle.propTypes.article),
}

export default withTheme(BlockThreeArticle)
export { Wrapper as Wrapper0BlockThreeArticle }
