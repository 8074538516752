import mvpSleedingDays from './mvpSleedingDays'

const translations = {
  header: {
    lng: 'IT',
    label: {
      title: '4 marzo 2023',
      subtitle: 'Stazione a valle',
    },
    title:
      '<span>STOL & SportNews</span><br />Giornata dello slittino<br />per le famiglie<br /><span>Corno del Renon</span>',
    subject: 'STOL & SportNews - Giornata dello slittino per le famiglie - Corno del Renon',
    description: [
      'Le famiglie risparmiano 139€*',
      '+++ Biglietti giornalieri gratuiti',
      '+++ Parcheggio gratuito',
      '+++ Noleggio gratuito di slittini',
    ],
    button: 'Partecipa!',
    bottomDescription01: 'Numero di partecipanti limitato!',
    bottomDescription02: 'Ancora ',
    bottomDescription03: ' posti disponibili.',
  },
  listbox: {
    lng: 'IT',
    title: 'Trascorri una giornata meravigliosa con la famiglia',
    content: {
      title: 'Il portafoglio resta a casa!',
      description: `Goditi una giornata di divertimento in slittino sul Corno del
          Renon con tutta la famiglia. Ti aspetta un'esperienza indimenticabile con i tuoi cari in
          un ambiente incantevole.`,
      list: {
        first: {
          title: 'Biglietto giornaliero gratuito',
          description: 'per tutta la famiglia',
        },
        second: {
          title: 'Parcheggio gratuito',
          description: 'direttamente alla stazione a valle',
        },
        third: {
          title: 'Noleggio gratuito di slittini',
          description: '2 slittini per famiglia presso lo Skicenter Corno del Renon (fino ad esaurimento scorte)',
        },
        fourth: {
          title: 'Consigli professionali',
          description: 'con Martin Psenner e la nuova slitta Bachmann con il sistema frenante premiato all´ISPO',
        },
      },
    },
  },
  offer: {
    offerID: 'sleedingOfferIT',
    title: 'Pronti a partire!',
    subTitle:
      'Non sei ancora abbonato? Iscriviti ora e assicurati una giornata invernale da sogno con la tua famiglia.',
    content: {
      bottomList: ['Già ABBONATO? Ti abbiamo inviato via email il link per la registrazione.'],
    },
  },
  paragraph: {
    title: "Divertimento puro sull'Altopiano del Renon!",
    subTitle: "Un'indimenticabile giornata in famiglia con un panorama unico",
    content: [
      'Ciò che il paesaggio da sogno del Corno del Renon promette viene anche mantenuto. Sui 2,5 km di percorso si sperimenta la pura varietà: dalla stazione intermedia fino a valle si alternano dolci pendii e avventurosi tratti nel bosco.',
      "E' il momento di salire sulla cabinovia, godersi la vista panoramica e la discesa dalla pista da slittino. Un divertimento invernale in cui le famiglie e gli amici si scatenano per ore e ore. Il programma comprende anche un corso di formazione per una slittata sicura con l'esperto di slittino Martin Psenner e Michael Graf di \"Bachmann Rodel” e recentemente premiato con l’ISPO-Award a Monaco.",
      'Ringraziamo anche i nostri partner della cabinovia del Corno del Renon e dello Snowspace Skicenter Corno di Renon per il loro grande sostegno a questa giornata!',
      '*Due adulti, due bambini, due slittini noleggiati',
    ],
  },
  footer: {
    lng: 'IT',
    formTitle: 'Domande, suggerimenti e critiche',
  },
}

const mvpSleedingDaysIT = mvpSleedingDays({ translations })
export default mvpSleedingDaysIT
