import styled, { withTheme } from 'styled-components'
import NakedDefaultItemArticle, {
  Description0NakedDefaultItemArticle,
  Image0NakedDefaultItemArticle,
} from '../NakedDefaultItemArticle'
import React, { useEffect, useState } from 'react'
import { useScreenSize } from '../../../../../../modules/media-query'

const Wrapper = styled(NakedDefaultItemArticle)`
  align-items: center;
  flex-basis: auto;

  ${Image0NakedDefaultItemArticle} {
    flex-basis: 100%;
  }

  ${Description0NakedDefaultItemArticle} {
    flex: 1;
    flex-basis: 100%;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-wrap: nowrap;
    ${Image0NakedDefaultItemArticle} {
      min-width: ${(props) => props.theme.tablet.sizes.col6};
      flex-basis: ${(props) => props.theme.tablet.sizes.col6};
      max-width: none;
    }
    ${Description0NakedDefaultItemArticle} {
      padding-left: ${(props) => props.theme.tablet.sizes.gapCol};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-wrap: nowrap;
    ${Image0NakedDefaultItemArticle} {
      min-width: ${(props) => props.theme.mobile.sizes.col6};
      flex-basis: ${(props) => props.theme.mobile.sizes.col6};
      max-width: none;
    }
    ${Description0NakedDefaultItemArticle} {
      padding-left: 14px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @media (max-width: 450px) {
    ${Description0NakedDefaultItemArticle} {
      padding-right: 0.5em;
    }
  }
`

const SmallPortraitItemArticle = ({ ...props }) => {
  const [truncateTitle, setTruncateTitle] = useState(2)
  const [truncateText, setTruncateText] = useState(4)
  const mode = useScreenSize()

  useEffect(() => {
    switch (mode) {
      case 'mobileS':
      case 'mobile':
        setTruncateTitle(4)
        break
      case 'tablet':
        setTruncateTitle(2)
        setTruncateText(3)
        break
      case 'desktop':
      default:
        setTruncateTitle(2)
        setTruncateText(4)
    }
  }, [mode])
  return (
    <Wrapper
      truncateTitle={{ numberOfLines: truncateTitle }}
      truncateText={{ numberOfLines: truncateText }}
      moreLink={false}
      {...props}
    />
  )
}

SmallPortraitItemArticle.propTypes = {
  ...NakedDefaultItemArticle.propTypes,
}

export default withTheme(SmallPortraitItemArticle)
