import LargeHotelImage from '../../layouts/Blocks/views_article/BlockThreeHotelsCommercial/images/header_03_desktop.jpg'
import SmallHotelImage from '../../layouts/Blocks/views_article/BlockThreeHotelsCommercial/images/header_03_mobil.jpg'

const jobsSLCSlotIDs = {
  desktop:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/35357643-649a-4620-9fcf-8e62a001467e?p=r'
      : 'https://delivery.connect.suedtirol.live/json/fbf8f8a3-8364-43a3-a54b-eef3badd94cb?p=r',
  tablet:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/07cf37dd-47ba-43b0-a726-6080988dcd4b?p=r'
      : 'https://delivery.connect.suedtirol.live/json/322fd3cd-ec08-481f-8167-6c6edc3f05e0?p=r',
  mobile:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/07cf37dd-47ba-43b0-a726-6080988dcd4b?p=r'
      : 'https://delivery.connect.suedtirol.live/json/9fb67308-e690-4059-a641-a4e680f74f04?p=r',
  tabletApp:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/07cf37dd-47ba-43b0-a726-6080988dcd4b?p=r'
      : 'https://delivery.connect.suedtirol.live/json/153aa858-3d38-4e4e-be0b-eab85aa385d2?p=r',
  mobileApp:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/07cf37dd-47ba-43b0-a726-6080988dcd4b?p=r'
      : 'https://delivery.connect.suedtirol.live/json/a8eaafda-78ec-49ea-91fb-0ff1e1f58421?p=r',
}

const homeLayout = {
  general: {
    adIds: ['H1', 'H2'],
  },
  feed: [
    {
      name: 'leader_headline',
      noGapEdge: true,
      dataIds: ['headlines'],
      adIds: ['H3', 'H4'],
      articleIds: [0, 1, 2, 3, 4, 5],
      margin: {
        mobile: {
          bottom: 'half',
        },
      },
    },
    {
      name: 'special_box',
      dataIds: ['specialbox1'],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
        mobile: {
          top: '0',
        },
      },
    },
    {
      name: 'portrait_article',
      articleIds: [6, 7, 8],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'two_landscape_article',
      adIds: ['H5', 'H6'],
      articleIds: [9, 10],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'jobs',
      noGapEdge: true,
      numberItems: {
        desktop: 6,
        tablet: 3,
        mobile: 3,
      },
      slotIds: jobsSLCSlotIDs,
      margin: {
        all: {
          top: 'half',
          bottom: '0',
        },
      },
    },
    {
      name: 'mostread_article',
      dataIds: ['mostread'],
      adIds: ['H6'],
      articleIds: [11],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H7'],
      margin: {
        all: {
          top: '0',
          bottom: 'full',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'special_box',
      dataIds: ['specialbox2'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'pr',
      dataIds: ['pr'],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [12],
      adIds: ['H6'],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H8'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
      mobileBlocked: true,
    },
    {
      name: 'video',
      dataIds: ['videos'],
    },
    {
      name: 'portrait_article',
      articleIds: [13, 14, 15],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'two_landscape_article',
      adIds: ['H9', 'H10'],
      articleIds: [16, 17],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'special_box_hotels_commercial_disable',
      dataStatic: {
        imageLarge: {
          url: LargeHotelImage,
          width: 2000,
          height: 251,
        },
        imageSmall: {
          url: SmallHotelImage,
          width: 600,
          height: 169,
        },
        'background-color': 'transparent',
        url: undefined,
      },
    },
    {
      name: 'special_box',
      dataIds: ['specialbox3'],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'portrait_article',
      articleIds: [18, 19, 20],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'big_article',
      adIds: ['H10'],
      articleIds: [21, 22, 23],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H11'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ontour',
      dataIds: ['ontour'],
      noGapEdge: true,
      margin: {
        desktop: {
          top: 'full',
          left: '0',
          right: '0',
          bottom: '0',
        },
        tablet: {
          bottom: 'calc(half - 10px)',
        },
        mobile: {
          bottom: 'calc(half - 10px)',
        },
      },
    },
    {
      name: 'events',
      marginTop: false,
      dataIds: ['events'],
      adIds: ['H12', 'H13', 'H14'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'portrait_article',
      articleIds: [24, 25, 26],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [27],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'weather',
      dataIds: ['weather'],
      adIds: ['H15'],
      noGapEdge: true,
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'big_article',
      adIds: ['H16'],
      articleIds: [28, 29, 30],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'instagram',
      dataIds: ['instagram'],
      noGapEdge: true,
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
        mobile: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'big_article',
      adIds: ['H17', 'H16'],
      articleIds: [31, 32, 33],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'facebook_article',
      articleIds: [34, 35],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
        mobile: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [36],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H18'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'portrait_article',
      articleIds: [37, 38, 39],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [40],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H21'],
      margin: {
        all: {
          top: '0',
          bottom: 'half',
          left: '0',
          right: '0',
        },
      },
    },

    {
      name: 'special_box',
      dataIds: ['specialbox4'],
      margin: {
        all: {
          top: 'half',
          bottom: 'half',
          left: '0',
          right: '0',
        },
      },
    },

    {
      name: 'portrait_article',
      articleIds: [41, 42, 43],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [44],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H22'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'portrait_article',
      articleIds: [45, 46, 47],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [48],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H23'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'portrait_article',
      articleIds: [49, 50, 51],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [52],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H24'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },

    {
      name: 'special_box',
      dataIds: ['specialbox5'],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },

    {
      name: 'portrait_article',
      articleIds: [53, 54, 55],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [56],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H25'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'portrait_article',
      articleIds: [57, 58, 59],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [60],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H26'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'portrait_article',
      articleIds: [61, 62, 63],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [64],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H27'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },

    {
      name: 'special_box',
      dataIds: ['specialbox6'],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },

    {
      name: 'portrait_article',
      articleIds: [65, 66, 67],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [68],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H28'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'portrait_article',
      articleIds: [69, 70, 71],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [72],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'ad',
      adIds: ['H29'],
      margin: {
        all: {
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'portrait_article',
      articleIds: [73, 74, 75],
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'landscape_article',
      articleIds: [76],
      margin: {
        all: {
          top: 'half',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'rackpaper',
      dataIds: ['rackpaper'],
      noGapEdge: true,
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
    {
      name: 'map',
      noGapEdge: true,
      margin: {
        all: {
          top: 'full',
          bottom: '0',
          left: '0',
          right: '0',
        },
      },
    },
  ],
  appVersions: {
    ios: '1.0',
    android: '1.0',
    desktop: '7.1',
  },
}

export default homeLayout

export { jobsSLCSlotIDs }
