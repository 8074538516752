const getModifiedResponse = ({ route, data, modules }) => {
  const routeOptions = route.getOptions()
  const routeQueryOptions = routeOptions && routeOptions.query
  if (data && routeQueryOptions && typeof routeQueryOptions.modifyResponse === 'string') {
    const screenModule = modules[routeQueryOptions.modifyResponse]
    if (screenModule && typeof screenModule === 'function') {
      return screenModule({ response: data })
    }
  }
  return data
}

export default getModifiedResponse
