import React from 'react'

const Plus = (props) => (
  <svg
    version="1.1"
    id="PlusIcon"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1132.6 999.5"
    {...props}
  >
    <path
      fill="#D41E25"
      d="M1132.6,706.3v133.3c0,88.3-71.7,160-160,160H159.7C71.5,999.3,0,927.9,0,839.4V160C0,71.7,71.7,0,160,0h279.9
L1132.6,706.3z"
    />
    <path
      id="Pfad_911"
      fill="#FFFFFF"
      d="M308.2,690.5c-2.6,26.5,0.3,53.3,8.6,78.7c9.8,20.9,29.5,30.7,66.6,30.7
c35.1,2.1,65.4-24.6,67.5-59.8c0.2-2.6,0.2-5.2,0-7.8c0-138.9-322.1-108.2-322.1-317.2c0-141.4,140.2-184.5,258.2-184.5
c124.2,0,237.3,60.2,227.4,201.6H435.2c0-45.5-5.1-72.5-18.5-84.8c-13-11-29.8-16.3-46.7-14.8c-40.6,0-62.7,25.6-62.7,70.1
c0,103.3,322.1,98.3,322.1,308.6c0,114.3-93.4,199.2-244.7,199.2c-159.8,0-265.5-40.6-255.7-223.8L308.2,690.5z"
    />
    <path
      fill="#03A9F4"
      d="M439.9,0l692.7,706.3V0H439.9z M1094.8,240.6H962.2v132.6h-70.4V240.6H759.4v-70.4h132.4V37.8h70.4v132.4h132.6
V240.6z"
    />
    <polygon
      fill="#FFFFFF"
      points="1094.8,170.2 1094.8,240.6 962.2,240.6 962.2,373.2 891.8,373.2 891.8,240.6 759.4,240.6 759.4,170.2
891.8,170.2 891.8,37.8 962.2,37.8 962.2,170.2 "
    />
  </svg>
)

export default Plus
