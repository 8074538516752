import React, { forwardRef, memo, useContext, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css, ThemeContext } from 'styled-components'
import ReactGA from 'react-ga'
import IconLeft from '../IconLeft'
import IconRight from '../IconRight'
import Image from '../../../../../../components/Image'

const Wrapper = styled.div`
  position: relative;
`

const InstagramImageWrapper = styled.div`
  display: flex;
  background: ${(props) => props.theme.color.multimedia.backgroundSecondary};
`

const InstagramImageContainer = styled(Image)`
  width: calc(100% / 3);
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
  }
  float: right;
  z-index: 0;
`

const ActiveMobileImage = styled(InstagramImageContainer)`
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: flex;
  }
`

const HiddenMobileImage = styled(InstagramImageContainer)`
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: none;
  }
`

const StyledArrows = css`
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  display: none;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: block;
  }
`

const StyledIconLeft = styled(IconLeft)`
  position: absolute;
  display: none;
  left: 15px;
  ${StyledArrows}
`

const StyledIconRight = styled(IconRight)`
  position: absolute;
  right: 15px;
  ${StyledArrows}
`

const ImagesInstagram = forwardRef(({ images, navDirection, ...props }, ref) => {
  const [active, setActive] = useState(0)

  const prevImage = () => {
    if (active !== 0) setActive(active - 1)
  }

  const nextImage = () => {
    if (active !== 2) setActive(active + 1)
  }

  useImperativeHandle(ref, () => ({
    nextImage() {
      nextImage()
    },
    prevImage() {
      prevImage()
    },
  }))

  const themeContext = useContext(ThemeContext)

  const urlRegex = /^(http(?:s)?:\/\/s3-images(?:-re)?\.stol\.it)\/instagram\/(\w+\.\w+)$/gm

  return (
    <Wrapper {...props}>
      {active !== 0 && <StyledIconLeft onClick={() => prevImage()} />}
      {active !== 2 && <StyledIconRight onClick={() => nextImage()} />}
      <InstagramImageWrapper>
        {images.map((image, index) => {
          const imageURL = image.replace(urlRegex, `$1/_images/fit/420x420/instagram/$2`)
          const MobileImage = index === active ? ActiveMobileImage : HiddenMobileImage
          return (
            <MobileImage
              aspectRatio={1}
              bgColor={themeContext.color.multimedia.backgroundSecondary}
              src={imageURL}
              key={'instagramImageContainer' + index}
              onClick={() =>
                ReactGA.event({
                  category: 'Image Of The Day',
                  action: 'click',
                  label: `Instagram image nr. ${index + 1}`,
                  value: image,
                })
              }
            />
          )
        })}
      </InstagramImageWrapper>
    </Wrapper>
  )
})

ImagesInstagram.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  ),
}

export default ImagesInstagram
