import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'
import translate from '../../../../../../../../modules/translate/index'

const Wrapper = styled.div`
  border-left-width: 5px;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 0px;
  border-left-color: ${props => props.theme.color.stol};
  border-style: solid;
  font-size: 1em;
  margin-top: 20px;
  margin-bottom: 10px;
`

const Title = styled.h2`
  font-size: ${props => props.theme.font.title1};
  @media (max-width: ${props => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${props => props.theme.font.title1};
  }
  @media (max-width: ${props => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${props => props.theme.mobile.font.title1};
  }
  font-family: ${props => props.theme.font.primary};
  font-style: italic;
  font-weight: 400;
  color: ${props => props.theme.color.textPrimary};
  padding-bottom: 15px;
  margin: 0;
`
const ItemListWrapper = styled.div`
  display: table-row;
  align-items: center;
`

const ColumnValue = styled.p`
  color: ${props => props.theme.color.textSecondary};
  font-family: ${props => props.theme.font.primary};
  margin: 0;
  display: table-cell;
  border-top: 5px solid transparent;
`

const ItemListGroup = styled.div`
  display: table-row-group;
`

const ItemListHeader = styled.div`
  display: table-caption;
  padding-left: 30px;
`

const ColumnKey = styled.span`
  display: table-cell;
  padding-right: 15px;
  padding-left: 30px;
  max-width: 175px;
  ${props =>
    props.isNamed
      ? css`
          font-family: ${props => props.theme.font.primary};
          font-weight: ${props => props.theme.fontWeight.bold};
          white-space: ${props => (props.isNamed.length > 22 ? 'normal' : 'nowrap')};
        `
      : css`
          font-size: 10px;
          vertical-align: top;
          padding-top: 10px;
        `}
`
const Table = styled.div`
  display: table;
`
const ListBoxDetailArticle = ({ title, list, fontSize, ...props }) => (
  <Wrapper {...props}>
    <Table>
      <ItemListHeader>
        <Title className="factBoxTitle">{title}</Title>
      </ItemListHeader>
      <ItemListGroup>
        {list.map(listItem => {
          return (
            <ItemListWrapper key={listItem.key}>
              <ColumnKey isNamed={listItem.key}>{listItem.key ? listItem.key : translate('listbox_icon')}</ColumnKey>
              <ColumnValue>{listItem.value}</ColumnValue>
            </ItemListWrapper>
          )
        })}
      </ItemListGroup>
    </Table>
  </Wrapper>
)

ListBoxDetailArticle.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
}

export default withTheme(ListBoxDetailArticle)
