import React from 'react'
import styled from 'styled-components'
import Link from '../../../../components/Link'
import FBDesktopImage from './images/FB_desktop.svg'
import FBMobileImage from './images/FB_mobile.svg'

const Wrapper = styled.div``

const DesktopWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: none;
  }
`
const Image = styled.img``

const MobileWrapper = styled.div`
  display: none;
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    display: block;
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
`

//Note that some stylings are applied directly in the svg file.

const WidgetFacebook = ({ link, ...props }) => {
  return (
    <Wrapper {...props}>
      <DesktopWrapper>
        <Link to={link}>
          <Image src={FBDesktopImage} />
        </Link>
      </DesktopWrapper>
      <MobileWrapper>
        <Link to={link}>
          <Image src={FBMobileImage} />
        </Link>
      </MobileWrapper>
    </Wrapper>
  )
}

WidgetFacebook.defaultProps = {
  link: 'https://www.facebook.com/stol.it/',
}

export default WidgetFacebook
export {
  DesktopWrapper as DesktopWrapper0WidgetFacebook,
  MobileWrapper as MobileWrapper0WidgetFacebook,
  Wrapper as Wrapper0WidgetFacebook,
}
