import searchSettings from '../search-settings'

const customApiPathSearch = ({ match }) => {
  const screenProps = searchSettings({ match })
  if (!screenProps) {
    return false
  }
  return `search/${screenProps.sortBy}/${screenProps.searchTerm}`
}

export default customApiPathSearch
