import React from 'react'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary/index'
import MessageError from '../../../Error/screens/MessageError/index'
import translate from '../../../../modules/translate/index'
import SkeletonDetailArticle from '../../components/DetailArticle/skeleton'
import styled from 'styled-components'
import CircularProgress from '../../../../components/CircularProgress'
import isStolApp from '../../../../modules/is-stolapp'
import checkForValueInUserAgent from '../../../../modules/check-for-value-in-useragent'
import isServerSide from '../../../../modules/is-server-side'
import DetailArticle from '../../components/DetailArticle'

const StyledCircularProgress = styled(CircularProgress)`
  text-align: center;
  padding-top: 15%;
  min-height: 100vh;
`

const ScreenArticle = ({ loading, error, response, location, ready, ...props }) => {
  const errorOptions = {
    component: translate('article'),
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'Article screen display',
  }
  const data = response && response.data
  const meta = response && response.meta

  if (data && data.districts && data.districts.length > 0 && isStolApp() && checkForValueInUserAgent('eventtracking')) {
    if (!isServerSide()) {
      let districtsURIArr = []
      for (let district in data.districts) {
        districtsURIArr.push(encodeURI(data.districts[district].name))
      }
      window.location.href = 'stolapp://tracking/events?bezirke=' + districtsURIArr.join(encodeURI(','))
    }
  }
  let routerState
  if (loading && !isServerSide()) {
    routerState = (location && location.state) || (window && window.athesia_react && window.athesia_react.state)
  }

  return (
    <ErrorBoundary error={error} {...errorOptions} {...props}>
      {error && <MessageError error={error} logInfo={translate('dev_errorlog_no_data')} {...errorOptions} />}
      {loading && !isStolApp() && <SkeletonDetailArticle routerState={routerState} />}
      {loading && isStolApp && <StyledCircularProgress />}
      {ready && data && <DetailArticle data={data} adTargeting={meta && meta.adsTargeting} location={location} />}
    </ErrorBoundary>
  )
}

export default ScreenArticle
