import React from 'react'
import domToReact from 'html-react-parser/lib/dom-to-react'
import { Helmet } from 'react-helmet-async'
import isServerSide from '../../../is-server-side'

const ExternalLiveticker = (attribs, embedBoxChildren) => {
  return (
    <div>
      {!isServerSide() && !document.querySelector(`script[src*="${attribs.src}"]`) && (
        <Helmet>
          <script src={attribs.src} />
        </Helmet>
      )}
      {domToReact(embedBoxChildren)}
    </div>
  )
}

export default ExternalLiveticker
