import React from 'react'
import styled from 'styled-components'
import MapWeather from '../../components/MapWeather'
import PreviewSymbols from '../../components/PreviewSymbols'
import Title from '../../../../components/Title'
import Link from '../../../../components/Link'
import PropTypes from 'prop-types'
import translate from '../../../../modules/translate'
import ContainerFull from '../../../../components/ContainerFull'
import ContainerAd from '../../../../components/ContainerAd'
import routes from '../../../routes'

const Today = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: ${(props) => props.theme.sizes.col4};
  max-height: 290px;
  @media (max-width: 980px) {
    max-height: 350px;
  }
  @media (max-width: 790px) {
    max-height: 330px;
  }
  @media (max-width: 740px) {
    max-height: 481px;
  }
  @media (max-width: 650px) {
    max-height: 440px;
  }
  @media (max-width: 450px) {
    max-height: 335px;
  }
  @media (max-width: 360px) {
    padding: 0px 10px;
  }

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.tablet.sizes.col4};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.mobile.sizes.col4};
  }
`
const StyledMapWeather = styled(MapWeather)`
  margin: 0;
`
const StyledTitleToday = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.titleBigger};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.titleBigger};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.titleBigger};
  }

  color: ${(props) => props.theme.color.textSecondary};
  line-height: 50px;
  margin: 0 0 0.5em;
`
const Preview = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 290px;
  @media (max-width: 980px) {
    max-height: 350px;
  }
  @media (max-width: 790px) {
    max-height: 330px;
  }
  flex-basis: ${(props) => props.theme.sizes.col8};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.tablet.sizes.col8};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.mobile.sizes.col8};
  }
  @media (max-width: 360px) {
    padding: 0px 10px;
  }

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    background-color: ${(props) => props.theme.color.textTertiary};
    top: 0;
    bottom: 0;
    width: 1px;
    height: auto;
    right: auto;
    left: -20px;
  }
`
const StyledTitlePreview = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: ${(props) => props.theme.color.textSecondary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 50px;
  margin: 0;
`
const StyledPreviewSymbols = styled(PreviewSymbols)``
const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  color: ${(props) => props.theme.color.stol};
  align-self: flex-end;
  margin-right: 2em;
  margin-top: 1em;
`

const StyledContainerAd = styled(ContainerAd)`
  flex-basis: 100%;
`
const Wrapper = styled(ContainerFull)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: ${(props) => props.theme.sizes.gapVerticalHalf};
  padding-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  position: relative;

  ${Today}, ${Preview} {
    text-decoration: none;
  }

  &:before {
    background-color: ${(props) => props.theme.color.background};
    border: ${(props) => props.theme.color.backgroundBorder} 1px solid;
    border-right-width: 0;
    border-left-width: 0;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  @media (max-width: 979px) {
    ${Today}, ${Preview} {
      flex-basis: ${(props) => props.theme.sizes.col6};
    }

    ${StyledPreviewSymbols} {
      & > div {
        flex-basis: 50%;
      }

      &:nth-child(2n) {
        padding-right: 0;
      }

      &:nth-child(2n + 1) {
        padding-left: 0;
      }
    }
  }

  @media (max-width: 739px) {
    padding-left: ${(props) => props.theme.sizes.gapEdge};
    padding-right: ${(props) => props.theme.sizes.gapEdge};
    ${Today}, ${Preview} {
      flex-basis: 100%;
    }

    ${Today} {
      margin-bottom: 20px;

      ${StyledLink} {
        margin-top: 0;
      }
    }

    ${Preview} {
      &:before {
        content: ' ';
        display: block;
        position: absolute;
        background-color: ${(props) => props.theme.color.textTertiary};
        left: 0;
        right: 0;
        height: 1px;
        width: auto;
        top: -10px;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: ${(props) => props.theme.mobile.sizes.gapVerticalHalf + ' ' + props.theme.mobile.sizes.gapEdge};
  }

  @media (max-width: 360px) {
    padding: 10px 0px;
  }
`
const matchToday = (data) =>
  data.map((dataItem) => ({
    location: dataItem.location,
    name: dataItem.name,
    icon: {
      name: dataItem.icon.name,
      description: dataItem.description,
    },
    minTemp: dataItem.minTemp,
    maxTemp: dataItem.maxTemp,
  }))

const matchDays = (data) =>
  data.map((dataItem) => ({
    date: dataItem.date,
    icon: {
      name: dataItem.icon.name,
      description: dataItem.description,
    },
    minTemp: dataItem.minTemp,
    maxTemp: dataItem.maxTemp,
  }))
const WidgetWeather = ({ data, adIds, ...props }) => {
  const today = matchToday(data.currentPreview)
  const days = matchDays(data.preview)
  const currentPreviewTitle = data.currentPreviewTitle
  if (props && props.title) {
    delete props.title
  }
  return (
    <Wrapper as="section" {...props}>
      <Today as="a" href={routes.getOptions('weather').path}>
        {currentPreviewTitle && <StyledTitleToday tag="h2">{currentPreviewTitle}</StyledTitleToday>}
        <StyledMapWeather data={today} />
        <StyledLink to={routes.getOptions('weather').path}>{translate('more')}</StyledLink>
      </Today>
      <Preview>
        <StyledTitlePreview tag="div">{translate('weather_preview')}</StyledTitlePreview>
        <StyledPreviewSymbols days={days} />
        <StyledLink to={routes.getOptions('weather').path}>{translate('more')}</StyledLink>
      </Preview>
      <StyledContainerAd adIds={adIds} noGapEdge />
    </Wrapper>
  )
}

WidgetWeather.propTypes = {
  title: PropTypes.string,
  today: MapWeather.propTypes.data,
  previewText: PropTypes.string,
}

WidgetWeather.defaultProps = {
  title: translate('weather_today'),
  data: MapWeather.defaultProps.data,
  previewText: translate('weather_preview_header'),
}

export default WidgetWeather
export {
  Wrapper as Wrapper0WidgetWeather,
  Today as Today0WidgetWeather,
  Preview as Preview0WidgetWeather,
  StyledTitleToday as StyledTitleToday0WidgetWeather,
  StyledTitlePreview as StyledTitlePreview0WidgetWeather,
  StyledLink as StyledLink0WdidgetWeather,
}
