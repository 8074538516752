import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NakedItemOnTour from './views/NakedItemOnTour'
import WidgetItemOnTour from './views/WidgetItemOnTour'
import WidgetItemOnTourWithButtons from './views/WidgetItemOnTourWithButtons'
import WidgetItemOnTourWithEventTipps from './views/WidgetItemOnTourWithEventTipps'

const components = {
  naked: NakedItemOnTour,
  widgetOnTour: WidgetItemOnTour,
  widgetOnTourWithButtons: WidgetItemOnTourWithButtons,
  widgetOnTourWithEventTipps: WidgetItemOnTourWithEventTipps
}

class ItemOnTour extends Component {
  render() {
    const { title, url, start, view, headline, image, ...props } = this.props
    const TagName = components[view]
    return <TagName headline={headline} image={image} title={title} url={url} start={start} {...props} />
  }
}

ItemOnTour.propTypes = {
  view: PropTypes.oneOf(Object.keys(components)).isRequired,
}

ItemOnTour.defaultProps = {
  view: 'widgetOnTour',
}

export default ItemOnTour
