import React from 'react'
import styled from 'styled-components'
import TopLine from '../../../../../../components/TopLine'
import translate from '../../../../../../modules/translate'
import TruncateText from '../../../../../../components/TruncateText'
import Link from '../../../../../../components/Link'

const StyledTitle = styled(Link)`
    color: ${(props) => props.theme.color.stol};
    font-size: ${(props) => props.theme.fontSize.base};
    @media(max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}){
      font-size: ${(props) => props.theme.tablet.fontSize.base};
    }
    @media(max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}){
      font-size: ${(props) => props.theme.mobile.fontSize.base};
    }
    line-height: ${(props) => props.theme.lineHeight.title};
    font-family: ${(props) => props.theme.font.primary};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin-top: 0;
    word-wrap: break-word;
    @media(max-width: 900px){
      max-width: 125px;
    }
  }
`

const LocationTitle = ({ headline, ...props }) => (
  <StyledTitle to={headline.route} {...props}>
    <TruncateText text={translate('location_prefix') + headline.name} truncateOptions={{ numberOfLines: 1 }} />
  </StyledTitle>
)

LocationTitle.propTypes = {
  headline: TopLine.propTypes.headline,
}

export default LocationTitle
