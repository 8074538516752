import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ItemArticle from '../../../../topics/Article/components/ItemArticle'
import BlockTwoLandscapeArticle from '../BlockTwoLandscapeArticle'
import AdUnit from '../../../../components/AdUnit'
import ContainerAd from '../../../../components/ContainerAd'

const StyledBlockTwoLandscapeArticle = styled(BlockTwoLandscapeArticle)`
  flex: 1;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const StyledContainerAd = styled(ContainerAd)`
  padding-left: 0;
  padding-right: 0;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: calc(100% + 2 * ${(props) => props.theme.tablet.sizes.gapCol});
    margin-left: -${(props) => props.theme.tablet.sizes.gapCol};
    margin-right: -${(props) => props.theme.tablet.sizes.gapCol};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  > div > div {
    margin: ${(props) => props.theme.sizes.gapVerticalHalf} 10px ${(props) => props.theme.sizes.gapVertical};
  }
`

class BlockBigArticle extends PureComponent {
  render() {
    const {
      data: { articles },
      adIds,
      noLazyLoading,
      ...props
    } = this.props
    return (
      <Wrapper {...props}>
        <ItemArticle view="big" article={articles[0]} noLazyLoading={noLazyLoading} />
        {adIds && articles.length === 1 && <StyledContainerAd adIds={adIds} />}
        {articles.length > 1 && (
          <StyledBlockTwoLandscapeArticle adIds={adIds} data={{ articles: [articles[1], articles[2]] }} />
        )}
      </Wrapper>
    )
  }
}

BlockBigArticle.propTypes = {
  articles: PropTypes.arrayOf(ItemArticle.propTypes.article),
  adIds: PropTypes.arrayOf(AdUnit.propTypes.adId),
}

export default BlockBigArticle
