import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import logos from './keys/logoKeys'
import ContainerFull from '../../../../components/ContainerFull/index'
import Text from '../../../../components/Text/index'
import Icon from '../../../../components/Icon/index'
import { htmlParser } from '../../../../modules/parse'
import { useSwipeable } from 'react-swipeable'

const StyledContainerArticle = styled(ContainerFull)`
  background-color: ${(props) => props.theme.color.background};
  border: 1px solid ${(props) => props.theme.color.backgroundBorder};
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: 0 40px;
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: 760px) {
    padding: 0;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: 0;
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`
const Controls = styled.div`
  font-size: 24px;
  margin-bottom: -0.1em;
  margin-top: -0.1em;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: block;
    font-size: 1em;
    width: 50%;
    order: 2;
  }
`

const StyledLogo = styled.img`
  height: 16.5px;
  margin: 0;
  padding: 0;
  display: inherit;

  svg {
    max-width: 100px;
    height: 16.5px;
    margin: 0;
    padding: 0;
  }

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: 20px;

    svg {
      max-width: 180px;
      height: 20px;
    }
  }
`

const LogoWrapper = styled.div`
  margin: 0;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    order: 1;
    width: 50%;
    justify-content: left;
  }
`

const StyledIcon = styled(Icon)`
  user-select: none;
  display: block;
  cursor: pointer;
  transform: rotate(-90deg);
  float: right;

  ${(props) =>
    props.disabled &&
    css`
      color: ${(props) => props.theme.color.textTertiary};
    `};
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: 0 0.5em;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 0;
  flex-direction: row;
  min-height: 22px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: auto;
    flex-wrap: wrap;
    padding: 9px 10px 5px 10px;
  }
`

const Label = styled.span`
  color: ${(props) => props.theme.color.textSecondary};
`

const HeadlineText = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 1em;
  overflow: hidden;
  flex: 1;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: 0;
    margin-top: 0.7em;
    order: 3;
    overflow: unset;
    text-align: left;
    flex: unset;
    width: 100%;
    height: 39px;
  }
  @media (max-width: 600px) {
    margin-top: 0.5em;
  }
`

const StyledText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 15px;

  a {
    font-size: 15px;
  }

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    -webkit-line-clamp: 2;
    line-height: 18px;
  }
`

const HeadlinePaper = ({ data, ...props }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const logo = logos[data[currentSlide].paper]

  const prevSlide = () => {
    if (currentSlide === 0) {
      return
    }
    setCurrentSlide(currentSlide - 1)
  }

  const nextSlide = () => {
    if (currentSlide === data.length - 1) {
      return
    }
    setCurrentSlide(currentSlide + 1)
  }

  const handlerSwipes = useSwipeable({
    onSwipedLeft: ({ dir }) => nextSlide(),
    onSwipedRight: ({ dir }) => prevSlide(),
  })

  return (
    <StyledContainerArticle id="headlinepaper" {...props} {...handlerSwipes}>
      <Wrapper>
        <LogoWrapper>
          <StyledLogo src={logo} />
        </LogoWrapper>
        <HeadlineText>
          <StyledText>
            <Label>{htmlParser(data[currentSlide].headlineAnnouncement)}</Label>
            <span> </span>
            {htmlParser(data[currentSlide].headline)}
          </StyledText>
        </HeadlineText>
        {data.length > 1 && (
          <Controls>
            <StyledIcon icon="arrowDown" onClick={nextSlide} disabled={currentSlide === data.length - 1} />
            <StyledIcon icon="arrowUp" onClick={prevSlide} disabled={currentSlide === 0} />
          </Controls>
        )}
      </Wrapper>
    </StyledContainerArticle>
  )
}

HeadlinePaper.propTypes = {
  data: PropTypes.array,
}

export default HeadlinePaper

export {
  Wrapper as WrapperHeadlinePaper,
  Controls as ControlsHeadlinePaper,
  LogoWrapper as LogoWrapperHeadlinePaper,
  HeadlineText as HeadlineTextHeadlinePaper,
  StyledIcon as StyledIconHeadlinePaper,
}
