import MaterialCircularProgress from '@mui/material/CircularProgress'
import styled, { css } from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  & .MuiCircularProgress-root {
    color: ${(props) => props.theme.default.color.stol};
  }

  ${(props) =>
    props.align === 'center' &&
    css`
      width: 100%;
      display: flex;
      flex: 2;
      justify-content: center;
      ${(props) =>
        !props.size &&
        css`
          padding-top: ${(props) => props.theme.sizes.gapVertical};
          padding-bottom: ${(props) => props.theme.sizes.gapVertical};
          @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
            padding-top: ${(props) => props.theme.tablet.sizes.gapVertical};
            padding-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
          }
          @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
            padding-top: ${(props) => props.theme.mobile.sizes.gapVertical};
            padding-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
          }
        `}
    `}
`

const CircularProgress = ({ align, size, ...props }) => (
  <Wrapper align={align} size={size} {...props}>
    <MaterialCircularProgress size={size} />
  </Wrapper>
)

CircularProgress.propTypes = {
  align: PropTypes.string,
  size: PropTypes.number,
}

export default CircularProgress
