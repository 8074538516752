function filterDistrics(object) {
  if (object.hasOwnProperty('districts')) return object.districts
  for (let i = 0; i < Object.keys(object).length; i++) {
    if (typeof object[Object.keys(object)[i]] === 'object') {
      let o = filterDistrics(object[Object.keys(object)[i]])
      if (o != null) return o
    }
  }
  return null
}

const menuDistricts = (menuData) => {
  return filterDistrics(menuData.responseData)
}

export default menuDistricts
