import React from 'react'
import styled from 'styled-components' //{ keyframes }
import PropTypes from 'prop-types'
import image from './images'

//ANIMATIONS
/*
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
*/

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

const Before = styled.div`
  padding-top: 100%;
`

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  display: block;
  width: 100%;
`
/*
    ${props => props.name === 'a' && css`
  animation: ${rotate} 6s linear infinite;
  `}
*/

const EmptyIcon = styled(Icon)``

const IconWeather = ({ name, description, square, ...props }) => (
  <Wrapper {...props}>
    {(children => {
      if (square) {
        return [<Before key="before" />, <InnerWrapper key="inner">{children}</InnerWrapper>]
      }
      return children
    })(name in image ? <Icon src={image[name]} name={name} title={description} /> : <EmptyIcon as="div" key="empty" />)}
  </Wrapper>
)

IconWeather.propTypes = {
  ...Icon.propTypes,
  square: PropTypes.bool,
}

IconWeather.defaultProps = {
  name: null,
  description: null,
  square: false,
}

export default IconWeather
