import React, { useState } from 'react'
import styled from 'styled-components'
import Bookmarkred from '../../images/bookmarkred.svg'
import Bookmarkgrey from '../../images/bookmarkgrey.svg'
import fetchData, { FETCH_METHODS } from '../../../../modules/fetch-data'
import userSession from '../../../../topics/Profile/modules/user-session'
import isServerSide from '../../../../modules/is-server-side'

const Wrapper = styled.div``

const Symbol = styled.img`
  cursor: pointer;
`
const BookmarkStateChanger = ({ data, isBookmarked, loading, ...props }) => {
  const [stateData, setStateData] = useState({ state: isBookmarked, src: isBookmarked ? Bookmarkred : Bookmarkgrey })

  function onUpdate(stateNow) {
    if (stateNow) {
      if (!isServerSide()) {
        fetchData({
          location: '/user/bookmarks/delete',
          version: 'v1',
          access: userSession.getAccessToken(),
          method: FETCH_METHODS.POST,
          data: { article: window.location.pathname },
        })
      }

      setStateData({ state: false, src: Bookmarkgrey })
    } else {
      if (!isServerSide()) {
        fetchData({
          location: '/user/bookmarks/add',
          version: 'v1',
          access: userSession.getAccessToken(),
          method: FETCH_METHODS.POST,
          data: { article: window.location.pathname },
        })
      }
      setStateData({ state: true, src: Bookmarkred })
    }
  }
  return (
    <Wrapper {...props}>
      {data && !loading && (
        <Symbol
          id={'bookmarkSymbol'}
          onClick={() => {
            onUpdate(stateData.state)
          }}
          src={stateData.src}
          {...props}
        />
      )}
    </Wrapper>
  )
}
BookmarkStateChanger.propTypes = {}
BookmarkStateChanger.defaultProps = {}
export default BookmarkStateChanger
