import React, { memo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Icon from '../../../../components/Icon'
import PropTypes from 'prop-types'
import ContainerFull from '../../../../components/ContainerFull'
import Link from '../../../../components/Link'
import translate from '../../../../modules/translate'
import hasItems from '../../../../modules/has-items'
import errorLog from '../../../../modules/error-log'
import ImagesInstagram from './components/ImagesInstagram'
import { useSwipeable } from 'react-swipeable'

const Wrapper = styled(ContainerFull)`
  width: 100%;
  z-index: 0;
  position: relative;
  ${(props) =>
    props.margin &&
    css`
      margin: ${(props) => props.margin};
    `}
`

const StyledLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const LabelWrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  background: ${(props) => props.theme.color.multimedia.backgroundSecondary};
  &:nth-child(1n) {
    padding-left: 45px;
  }
  &:nth-child(2n) {
    padding-right: 45px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
const IconAndDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.color.textInverted};
  font-size: 2em;
  opacity: 0.7;
  margin-right: 5px;
`
const TagLink = styled(Link)`
  color: ${(props) => props.theme.color.textInverted};
  font-family: ${(props) => props.theme.font.primary};
  font-size: 1.125em;
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-right: 0;
    font-size: 1em;
  }
  opacity: 0.7;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 2;
`

const error = (logInfo) => {
  errorLog({ error: 'Home screen - Instagram Widget', info: logInfo })
}

const WidgetInstagram = ({ data, margin, ...props }) => {
  const imagesInstagram = useRef()
  const handlerSwipes = useSwipeable({
    onSwipedLeft: ({ dir }) => imagesInstagram.current.nextImage(),
    onSwipedRight: ({ dir }) => imagesInstagram.current.prevImage(),
  })

  if (!data || !hasItems(data)) {
    error(translate('dev_errorlog_no_data'))
    return false
  }
  const { title, tag, urlTag, url, images } = data

  if (!hasItems(images) || images.length < 3) {
    error(translate('dev_errorlog_no_data'))
    return false
  }

  return (
    <Wrapper margin={margin} as="section" cinemaMode="multimedia" noGapEdge {...props} {...handlerSwipes}>
      <StyledLink to={url} />
      <ImagesInstagram images={images} ref={imagesInstagram} />
      <LabelWrapper>
        <IconAndDescription>
          <StyledIcon icon="instagram" />
          <TagLink>{title}</TagLink>
        </IconAndDescription>
        <TagLink to={urlTag}>{tag}</TagLink>
      </LabelWrapper>
    </Wrapper>
  )
}

WidgetInstagram.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
  tag: PropTypes.string,
  url: PropTypes.string,
}

export default WidgetInstagram
