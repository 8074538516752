import LayoutBuilder from '../LayoutBuilder'
import ScreenLoader from '../ScreenLoader'
import React from 'react'

const ScreenLayout = ({ layout, blocks, ...props }) => {
  const RouteLayoutBuilder = ({ response, ...props }) => <LayoutBuilder data={response && response.data} {...props} />
  return <ScreenLoader Screen={RouteLayoutBuilder} layout={layout} blocks={blocks} {...props} />
}

export default ScreenLayout
