import React from 'react'
import styled from 'styled-components'
import Title from '../../../../../../components/Title'
import TopLine, { LinkHeadLine0TopLine } from '../../../../../../components/TopLine'
import Date from '../../../../../../components/Date'
import Splus from '../../../ItemArticle/components/DescriptionItemArticle/components/TitleItemArticle/images/s-plus.svg'
import BookmarkSymbol from '../../../../../../components/BookmarkSymbol'
import isStolApp from '../../../../../../modules/is-stolapp'
import DescriptionDetailArticle from '../DescriptionDetailArticle'

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  order: 1;
  flex-wrap: wrap;
`
const StyledDate = styled(Date)`
  flex-basis: auto;
  font-size: ${(props) => props.theme.default.fontSize.small};
  color: ${(props) => (props.inverted ? props.theme.color.textInverted : props.theme.color.textSecondary)};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
    width: 100%;
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
    order: 0;
  }
`
const StyledTopLine = styled(TopLine)`
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
    order: 1;
  }
  &:hover {
    opacity: 0.7;
  }
`
const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.default.sizes.gapVertical};
  ${LinkHeadLine0TopLine} {
    font-size: ${(props) => props.theme.default.fontSize.text};
    color: ${(props) => (props.inverted ? props.theme.color.textInverted : props.theme.color.stol)};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
    ${LinkHeadLine0TopLine} {
      font-size: ${(props) => props.theme.tablet.fontSize.text};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
    ${LinkHeadLine0TopLine} {
      font-size: ${(props) => props.theme.mobile.fontSize.text};
    }
  }
`
const StyledTitle = styled(Title)`
  color: ${(props) => props.inverted && props.theme.color.textInverted};
  order: 2;
  margin: ${(props) => props.theme.default.sizes.gapVertical} 0;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin: ${(props) => props.theme.tablet.sizes.gapVertical} 0;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin: ${(props) => props.theme.mobile.sizes.gapVertical} 0;
  }
`

const BlockDiv = styled.div`
  display: block;
`
const StyledSplus = styled.img`
  height: 1em;
  margin-bottom: -4px;
  margin-right: 5px;
`

const StyledDateContainer = styled(BlockDiv)`
  display: flex;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
    display: flex;
    justify: space-between;
  }
`

const StyledBookmarkSymbol = styled(BookmarkSymbol)`
  margin-left: 10px;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    float: right;
  }
`

const HeaderDetailArticle = ({
  title,
  headline,
  date,
  description,
  inverted,
  fontSize,
  plus,
  plusTeaser,
  plusDescription,
  bookmark,
  isCommunityNews,
  ...props
}) => {
  return (
    <Wrapper inverted={inverted} {...props}>
      <Header>
        <StyledTopLine className="headline" headline={headline} inverted={inverted} />
        <StyledDateContainer>
          <StyledDate inverted={inverted} format="dddd, D. MMMM YYYY">
            {date}
          </StyledDate>
          {!isStolApp() && bookmark && bookmark.show && <StyledBookmarkSymbol></StyledBookmarkSymbol>}
        </StyledDateContainer>
      </Header>
      <StyledTitle className="title" inverted={inverted}>
        <BlockDiv>
          {plus != undefined && plus && <StyledSplus src={Splus} />}
          {title}
        </BlockDiv>
      </StyledTitle>
      {!isCommunityNews && <DescriptionDetailArticle text={description} inverted={inverted} fontSize={fontSize} />}
    </Wrapper>
  )
}

export default HeaderDetailArticle
export {
  Wrapper as Wrapper0HeaderDetailArticle,
  StyledDate as StyledDate0HeaderDetailArticle,
  StyledTopLine as StyledTopLine0HeaderDetailArticle,
  Header as Header0DetailArticle,
  StyledTitle as StyledTitle0DetailArticle,
  DescriptionDetailArticle as StyledText0DetailArticle,
  StyledDate as StyledDate0DetailArticle,
}
