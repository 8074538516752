import isStolApp from '../../../../modules/is-stolapp'
import isServerSide from '../../../../modules/is-server-side'
import routes from '../../../../topics/routes'

const SCREEN_PIANO = {
  login: 'login',
  register: 'register',
  restore: 'restore',
  newPassword: 'new_password',
}

const showModalId = ({ screen = SCREEN_PIANO.login, elementSelector, appRedirect } = {}) => {
  if (isServerSide()) {
    return true
  }
  if (screen === SCREEN_PIANO.login && isStolApp()) {
    let redirectRoute = routes.getOptions('login').path
    if (appRedirect) redirectRoute += appRedirect
    window.location.href = redirectRoute
    return true
  }
  window.tp = window.tp || []
  if (window.tp.pianoId && window.tp.pianoId.getUser() && window.tp.pianoId.getUser().email_confirmation_required) {
    window.tp.pianoId.showEmailConfirmationRequired()
    return true
  }
  window.tp.push([
    'init',
    function () {
      const showOptions = {
        screen: screen,
      }
      if (elementSelector) {
        showOptions.displayMode = 'inline'
        showOptions.containerSelector = elementSelector
      }
      window.tp.pianoId.show(showOptions)
    },
  ])
  return true
}

export default showModalId

export { SCREEN_PIANO }
