import hasItems from '../../../../modules/has-items'
import WidgetTopTopic from '../../../Article/widgets/WidgetTopTopic'
import React from 'react'

const PreComponentHome = ({ ready, response: { data } }) => {
  if (ready && data && hasItems(data.toptopic)) {
    return <WidgetTopTopic data={data.toptopic} />
  }
  return false
}

export default PreComponentHome
