import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '../../../../../../components/Text'
import Link from '../../../../../../components/Link'
import translate from '../../../../../../modules/translate'
import {useUserContext} from "../../../../../../components/ContextUser";

const Wrapper = styled.div`

`

const Title = styled(Text)`
  color: ${(props) => (props.hasBackground ? 'white' : props.theme.color.stol)};
  width: 25%;
  margin-bottom: 5px;
  white-space: nowrap;
  font-size: ${(props) => props.theme.default.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
    width: 100%;
  }
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledLink = styled(Link)`
  position: relative;
  margin-right: 10px;
  align-self: flex-start;
  border: solid 1px #ccc;
  padding: 0px 10px;
  color: #888;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: ${(props) => (props.hasBackground ? 'white' : 'transparent')};
  font-size: ${(props) => props.theme.default.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }

  &:hover {
    color: #000;
    opacity: 0.7;
  }
`

const CloudTag = ({ plus, tags,hasBackground = false, ...props }) => {
  const {
    access: { isPlus },
  } = useUserContext()

  const hideContent = plus ? !isPlus && plus : false

  if(!hideContent){return(
  <Wrapper {...props}>
    <Title hasBackground={hasBackground}>{translate('tagcloud')}</Title>
    <Tags>
      {tags.map((tag) => (
        <StyledLink hasBackground={hasBackground} to={`/tag/${tag}`}>
          {tag}
        </StyledLink>
      ))}
    </Tags>
  </Wrapper>
)}else{
    return false
  }
}

CloudTag.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
}

export default CloudTag
