import React from 'react'
import styled from 'styled-components'
import { translateFontSizeToTheme } from '../../../../../../modules/font'
import Text from '../../../../../../components/Text'

const Wrapper = styled(Text)`
  color: ${(props) => (props.inverted ? props.theme.color.textInverted : 'rgba(0, 0, 0, .7)')};
  order: 3;
  line-height: ${(props) => props.theme.default.lineHeight.text};
  font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.default.fontSize.text)};
  font-weight: normal;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    line-height: ${(props) => props.theme.tablet.lineHeight.text};
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.tablet.fontSize.text)};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    line-height: ${(props) => props.theme.mobile.lineHeight.text};
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.mobile.fontSize.text)};
  }
`

const DescriptionDetailArticle = ({ text, inverted, fontSize, ...props }) => (
  <Wrapper as="h2" className="description" inverted={inverted} fontSize={fontSize} {...props}>
    {text}
  </Wrapper>
)

export default DescriptionDetailArticle
