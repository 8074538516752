const jobsSLCSlotIDs = {
  desktop:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/13005116-12fe-4baf-aa23-404b68bcd87f?p=r' // ids from article
      : 'https://delivery.connect.suedtirol.live/json/c21357a0-548b-461c-91a4-1c55e30d0852?p=r',
  tablet:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/13005116-12fe-4baf-aa23-404b68bcd87f?p=r' // ids from article
      : 'https://delivery.connect.suedtirol.live/json/62411551-8085-424f-8cf6-192164380c67?p=r',
  mobile:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/13005116-12fe-4baf-aa23-404b68bcd87f?p=r' // ids from article
      : 'https://delivery.connect.suedtirol.live/json/18a500c3-2160-462e-ab74-24e6b76fd3cc?p=r',
  tabletApp:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/13005116-12fe-4baf-aa23-404b68bcd87f?p=r' // ids from article
      : 'https://delivery.connect.suedtirol.live/json/2acafca3-3e11-45d1-918b-1645b6c75fba?p=r',
  mobileApp:
    process.env.RAZZLE_ENVIRONMENT === 'staging'
      ? 'https://connect-delivery.staging.suedtirol.live/json/13005116-12fe-4baf-aa23-404b68bcd87f?p=r' // ids from article
      : 'https://delivery.connect.suedtirol.live/json/5e7610cd-65fa-438e-ac87-800131fec652?p=r',
}

export { jobsSLCSlotIDs }
