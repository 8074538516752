import React from 'react'
import styled from 'styled-components'
import ContainerArticle from '../../../../../../components/ContainerArticle'

const StyledSection = styled.section`
  padding-top: 1em;
  padding-bottom: 1em;
`

const WrapperWidgetNakeLineArticle = styled(({ children, containerArticle, ...props }) => (
  <StyledSection {...props}>
    {(containerArticle && <ContainerArticle>{children}</ContainerArticle>) || children}
  </StyledSection>
))`
  background-color: ${(props) => props.theme.color.background};
  border: ${(props) => props.theme.color.backgroundBorder} 1px solid;
  position: relative;
`

export default WrapperWidgetNakeLineArticle
