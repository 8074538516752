import React from 'react'
import styled, { css, withTheme } from 'styled-components'
import translate from '../../../../../../modules/translate'
import { translateFontSizeToTheme } from '../../../../../../modules/font'
import VideoArticle from '../../../../../../components/VideoArticle'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${(props) => props.theme.color.multimedia.backgroundTertiary};
  ${(props) =>
    props.inHeader &&
    css`
      margin-bottom: 40px;
      margin-top: 20px;
    `}
  ${(props) =>
    props.inHeader &&
    css`
      @media (min-width: ${(props) => props.theme.tablet.breakPoint + 1 + 'px'}) {
        transform: translateX(-40px);
        width: calc(100% + 80px);
      }
    `}
`

const Description = styled.p`
  margin: 0;
  padding: 10px;
  min-height: 35px;
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.color.textInverted};
  font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.default.fontSize.small)};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.tablet.fontSize.small)};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.mobile.fontSize.small)};
  }
`

const VideoDetailArticle = ({
  video,
  copyright,
  type,
  partner,
  local,
  inHeader,
  image,
  showDesc,
  showAds,
  adTargeting,
  fontSize,
  plus,
  ...props
}) => {
  let descriptionVideo = image ? image.description : video.description
  descriptionVideo = descriptionVideo && `${descriptionVideo} - `

  return (
    <Wrapper inHeader={inHeader} noGapEdge {...props}>
      {video && <VideoArticle video={video} image={image} adsTargeting={adTargeting} showAds={showAds} plus={plus} />}
      {descriptionVideo && (
        <Description fontSize={fontSize}>
          {descriptionVideo}
          {copyright && translate('video_copyright', { copyright: copyright })}
        </Description>
      )}
    </Wrapper>
  )
}

VideoDetailArticle.defaultProps = {
  showAds: true,
}

export default withTheme(VideoDetailArticle)
