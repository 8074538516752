import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import NakedDefaultItemArticle, {
  Description0NakedDefaultItemArticle,
  Image0NakedDefaultItemArticle,
  Wrapper0NakedDefaultItemArticle,
} from '../../views_default/NakedDefaultItemArticle'
import { useMediaQuery, useScreenSize } from '../../../../../../modules/media-query'

const Wrapper = styled(NakedDefaultItemArticle)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.color.multimedia.backgroundTertiary};
  ${Wrapper0NakedDefaultItemArticle} {
    flex-wrap: nowrap;
  }

  ${Image0NakedDefaultItemArticle} {
    flex-basis: ${(props) => props.theme.sizes.col6};
  }

  ${Description0NakedDefaultItemArticle} {
    flex: 1;
    padding-left: ${(props) => props.theme.sizes.gapCol};
    margin-top: 0;
    align-self: center;
    padding-right: calc(${(props) => props.theme.sizes.gapCol} / 2);
  }
  @media (min-width: ${(props) => props.theme.breakPoint + 'px'}) {
    ${Image0NakedDefaultItemArticle} {
      max-width: 300px;
    }
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    ${Description0NakedDefaultItemArticle} {
      padding-left: 25px;
      padding-right: 15px;
    }
    ${Image0NakedDefaultItemArticle} {
      flex-basis: ${(props) => props.theme.tablet.sizes.col6};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${Description0NakedDefaultItemArticle} {
      padding-left: 15px;
      padding-right: ${(props) => props.theme.mobile.sizes.gapColHalf};
    }
    ${Image0NakedDefaultItemArticle} {
      flex-basis: ${(props) => props.theme.mobile.sizes.col6};
    }
  }
`

const TopTopicItemArticle = ({ ...props }) => {
  const [truncateTitle, setTruncateTitle] = useState(2)
  const [truncateText, setTruncateText] = useState(3)
  const mode = useScreenSize()
  const descriptionTwoLines = useMediaQuery('(max-width:700px)')

  useEffect(() => {
    switch (mode) {
      case 'mobileS':
        setTruncateTitle(3)
        break
      case 'mobile':
        setTruncateTitle(4)
        break
      case 'tablet':
        setTruncateTitle(2)
        setTruncateText(descriptionTwoLines ? 2 : 3)
        break
      case 'desktop':
      default:
        setTruncateTitle(2)
        setTruncateText(3)
    }
  }, [mode, descriptionTwoLines])
  return (
    <Wrapper
      borderBottom="none"
      inverted
      truncateTitle={{ numberOfLines: truncateTitle }}
      truncateText={{ numberOfLines: truncateText }}
      moreLink={false}
      {...props}
    />
  )
}

TopTopicItemArticle.propTypes = {
  ...NakedDefaultItemArticle.propTypes,
}

export default withTheme(TopTopicItemArticle)
