const getImageDetails = (data) => {
  if (!data) {
    return false
  }

  if (typeof data === 'string') {
    return {
      url: data,
    }
  }

  if (typeof data === 'object') {
    const details = {}
    if (data.height && data.width) {
      details.ratio = data.height / data.width
    }
    return {
      ...details,
      ...data,
    }
  }

  return false
}

export default getImageDetails
