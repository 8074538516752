import React from 'react'
import PropTypes from 'prop-types'
import GalleryDetailArticle from './components/GalleryDetailArticle/index'
import QuoteDetailArticle from './components/QuoteDetailArticle/index'
import ListBoxDetailArticle from './components/ListBoxDetailArticle/index'
import VideoDetailArticle from '../VideoDetailArticle/index'
import ImageDetailArticle from '../ImageDetailArticle/index'
import HeadlinePaperDetailArticle from './components/HeadlinePaperDetailArticle/index'
import OnTourDetailArticle from './components/OnTourDetailArticle'
import MessageError from '../../../../../Error/screens/MessageError'
import translate from '../../../../../../modules/translate'
import { withRouter } from 'react-router'

const EmbedsContentArticle = ({ embedType, embedId, location, data, ...props }) => {
  const errorOptions = {
    component: translate('embed'),
    link: location.pathname,
    linkText: translate('error_try_later'),
    logError: 'EmbedsDetailArticle index',
  }


  switch (embedType) {
    case 'image':
      return <ImageDetailArticle id={embedId} allowOtherFormat {...props} {...data} />
    case 'video':

      return <VideoDetailArticle id={embedId} {...props} {...data} />
    case 'factbox':
      return <ListBoxDetailArticle id={embedId} {...props} {...data} />
    case 'quote':
      return <QuoteDetailArticle id={embedId} {...props} {...data} />
    case 'gallery':
      return <GalleryDetailArticle id={embedId} {...props} {...data} />
    case 'headline':
      return <HeadlinePaperDetailArticle id={embedId} {...props} {...data} />
    case 'ontour':
      return <OnTourDetailArticle id={embedId} {...props} {...data} />
    default:
      return (
        <MessageError
          logInfo={translate('dev_errorlog_article_embed', { type: embedType, id: embedId })}
          {...errorOptions}
        />
      )
  }
}

EmbedsContentArticle.propTypes = {
  embedType: PropTypes.oneOf(['image', 'video', 'factbox', 'quote', 'gallery', 'headline', 'ontour']),
  embedId: PropTypes.string,
}

export default withRouter(EmbedsContentArticle)
