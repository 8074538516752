import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'
import { skeletonFade } from '../../../../modules/mixins'

const PROPORTION = 0.75
const noJustify = [10, 30, 5, 30, 40, 30, 10, 30, 60, 10, 60, 30, 40, 60]

const FontSizeToHeight = (fontSize) => {
  const [, size, unit] = fontSize.match(/^([0-9|.]+)(.*)$/)
  const newSize = size * PROPORTION
  return newSize + unit
}

const LineHeightToMargin = (lineHeight, fontSize) => {
  const [, size, unit] = fontSize.match(/^([0-9|.]+)(.*)$/)
  const newSize = size * lineHeight - size * PROPORTION
  return Math.round(newSize * 10) / 10 / 2 + unit
}

const Wrapper = styled.div``

const Line = styled(({ lineWidth, lineHeight, fontSize, align, ...props }) => <hr {...props} />)`
  border: 0;
  margin: 0;
  border-style: solid none none;
  border-width: ${(props) => FontSizeToHeight(props.fontSize)};
  border-color: ${(props) => props.theme.color.skeletonScreen};
  width: ${(props) => props.lineWidth};
  //padding-bottom: ${(props) => LineHeightToMargin(props.lineHeight, props.fontSize)};
  margin-top: ${(props) => LineHeightToMargin(props.lineHeight, props.fontSize)};
  ${// eslint-disable-next-line
  (props) =>
    (props.align === 'right' &&
      css`
        margin-left: auto;
      `) ||
    // eslint-disable-next-line
    (props.align === 'center' &&
      css`
        margin-left: auto;
        margin-right: auto;
      `)} ${skeletonFade};
`

class TextSkeleton extends Component {
  render() {
    let { fontSize, lines, lastWidth, lineHeight, align, justify, extremity, theme, ...props } = this.props
    return (
      <Wrapper {...props}>
        {[...Array(lines)].map((x, i) => {
          let width = '100%'
          if (!justify) {
            const pxOffset = noJustify[i % noJustify.length] * extremity
            width = `calc(100% - ${pxOffset}px)`
          }
          if (lastWidth !== '' && i + 1 === lines) {
            width = lastWidth
          }
          if (fontSize in theme.fontSize) {
            fontSize = theme.fontSize.base
          }
          return <Line key={i} lineWidth={width} fontSize={fontSize} lineHeight={lineHeight} align={align} />
        })}
      </Wrapper>
    )
  }
}

TextSkeleton.propTypes = {
  fontSize: PropTypes.string,
  lineHeight: PropTypes.number,
  lines: PropTypes.number,
  lastWidth: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.bool,
  extremity: PropTypes.number,
}

TextSkeleton.defaultProps = {
  fontSize: 'base',
  lineHeight: 1.4,
  lines: 1,
  lastWidth: '',
  align: 'left',
  justify: true,
  extremity: 1,
}

export default withTheme(TextSkeleton)
export { Line as Line0TextSkeleton }
