import options from '../../../../config/options'

const apiSortBy = {
  relevanz: 'relevance',
  datum: 'date',
}

const searchSettings = ({ match }) => {
  const requestSortBy =
    match && match.params && match.params.sortBy ? match.params.sortBy : options.Search.defaultSortBy
  const sortBy = apiSortBy[requestSortBy]
  let searchTerm = match && match.params && match.params.searchTerm
  if (!searchTerm) {
    return false
  }

  searchTerm = searchTerm ? encodeURI(searchTerm) : searchTerm
  return {
    sortBy,
    searchTerm,
  }
}

export default searchSettings
