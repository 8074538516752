import options from '../options'

const departmentSplus = {
  general: {
    adIds: ['S1', 'S2'],
  },
  loadMore: true,
  feed: [
    {
      name: 'tabs',
      dataStatic: {
        locations: options.Plus.tabLinks.map(({ value }) => {
          return {
            pathName: value,
            items: options.Plus.tabLinks,
          }
        }),
      },
      noGapEdge: true,
    },
    {
      name: 'title',
      dataProps: ['title'],
      noGapEdge: true,
    },
    {
      name: 'category_articles_panel',
      dataProps: ['articles', 'locale'],
      noGapEdge: true,
      feed: [
        {
          name: 'big_one_article',
          articleIds: [0],
          noLazyLoading: true,
        },
        {
          name: 'three_landscape_article',
          articleIds: [1, 2, 3],
          adIds: ['S5', 'S6'],
          noLazyLoading: true,
        },
        {
          name: 'three_landscape_article',
          articleIds: [4, 5, 6],
          adIds: ['S6'],
        },
        {
          name: 'articles',
          articlesFrom: 7,
        },
      ],
    },
  ],
}

export default departmentSplus
