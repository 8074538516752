import styled from 'styled-components'
import Link from '../../../Link'

const LinkBreadcrumb = styled(Link)`
  color: ${(props) => props.theme.color.textSecondary};
  transition: color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.color.textPrimary};
    text-decoration: underline;
  }
`

LinkBreadcrumb.propTypes = Link.propTypes

export default LinkBreadcrumb
