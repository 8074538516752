import React from 'react'
import styled from 'styled-components'
import StolOnTour from '../../components/StolOnTour'
import ContainerFull from '../../../../../../components/ContainerFull'

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.multimedia.backgroundSecondary};
  width: 100%;
  min-height: 95px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`

const StyledStolOnTour = styled(StolOnTour)`
  width: 230px;
  height: 60px;
`

const NakedOnTourHeader = ({ children, ...props }) => (
  <ContainerFull as="section" className="headerOnTour" cinemaMode="multimedia">
    <Wrapper {...props}>
      <StyledStolOnTour className="stol_logo_ontour" />
      {children}
    </Wrapper>
  </ContainerFull>
)

export default NakedOnTourHeader
export { ContainerFull as HeaderWrapper0NakedOnTourHeader }
