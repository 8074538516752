import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Title from '../../../../components/Title'

const Wrapper = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.titleBigger};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.titleBigger};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.titleBigger};
  }
  color: ${(props) => props.theme.color.textSecondary};
  margin: 0.5em 0 0.5em;
`

const TitleBlock = ({ title, ...props }) => {
  if (!title) return false
  return <Wrapper {...props}>{title}</Wrapper>
}

TitleBlock.propTypes = {
  title: PropTypes.string,
}

export default TitleBlock
