//this defines the route which is called when you click on a video in the app.

import hasItems from '../has-items'
import { addSpecialTargets } from '../ads-targets'
import encodeTargets from '../encode-targets'

const optionsJwPlayerApp = (jwId, showAds, adsTargeting) => {
  addSpecialTargets(adsTargeting)
  const decodedParamsString = encodeTargets(adsTargeting)

  return `video-jw://${showAds ? 'withAds' : 'noAds'}/${jwId}${
    hasItems(adsTargeting) && `?cust_params=${decodedParamsString}`
  }`
}

export default optionsJwPlayerApp
