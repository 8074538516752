import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import translate from '../../modules/translate'
import ItemArticle from '../../topics/Article/components/ItemArticle'
import { withRouter } from 'react-router'
import WidgetContext from '../../bundles/components/WidgetContext'
import loadable from '@loadable/component'

const Tabs = loadable(() => import(/* webpackChunkName: "Tabs", webpackPrefetch: true */ '../Tabs'))
const TabsItem = loadable(() => import(/* webpackChunkName: "TabsItem", webpackPrefetch: true */ '../TabsItem'))

const StyledTabs = styled(Tabs)`
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`
const StyledDisableDiv = styled.div`
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`
const StyledTabsItems = styled(TabsItem)`
  flex-basis: calc(100% / 3);
`

const CategoryArticlesPanel = ({
  articles,
  indicatorColor,
  color,
  disable,
  children = () => {},
  match,
  loading,
  ...props
}) => {
  const [value, setValue] = useState(0)
  const categoryArticles = articles.filter((article) => {
    if (match.params.local === 'lokal' || value === 1) {
      return article.local === true
    }
    if (match.params.local === 'weltweit' || value === 2) {
      return article.local === false
    }
    return true
  })
  const path = match.url
  const pathAll = match.params.local ? path.replace('/' + match.params.local, '') : match.url
  const pathLocal = match.params.local ? path.replace(match.params.local, 'lokal') : path + '/lokal'
  const pathGlobal = match.params.local ? path.replace(match.params.local, 'weltweit') : path + '/weltweit'

  return (
    <Fragment>
      {!disable && (
        <WidgetContext>
          {(isWidget) => {
            const handleChange = (event, value) => {
              setValue(value)
            }
            const optionsTabItems = (path) => {
              const options = {
                _color: color,
                isWidget: isWidget,
              }
              if (!isWidget) options.value = path
              return options
            }
            return (
              !loading && (
                <StyledTabs
                  indicatorColor={indicatorColor}
                  onChange={handleChange}
                  value={isWidget ? value : match.url}
                  {...props}
                >
                  <StyledTabsItems
                    label={translate('category_all')}
                    _color={color}
                    indicatorColor={indicatorColor}
                    disabled={loading}
                    {...optionsTabItems(pathAll)}
                  />
                  <StyledTabsItems
                    label={translate('category_local')}
                    _color={color}
                    indicatorColor={indicatorColor}
                    disabled={loading}
                    {...optionsTabItems(pathLocal)}
                  />
                  <StyledTabsItems
                    label={translate('category_global')}
                    _color={color}
                    indicatorColor={indicatorColor}
                    disabled={loading}
                    {...optionsTabItems(pathGlobal)}
                  />
                </StyledTabs>
              )
            )
          }}
        </WidgetContext>
      )}
      {disable && <StyledDisableDiv />}
      {children(categoryArticles)}
    </Fragment>
  )
}

CategoryArticlesPanel.propTypes = {
  articles: PropTypes.arrayOf(ItemArticle.propTypes.article),
  disable: PropTypes.bool,
  indicatorColor: PropTypes.string,
  color: PropTypes.string,
}

CategoryArticlesPanel.defaultProps = {
  articles: [],
}

export default withRouter(CategoryArticlesPanel)
