import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'

const Wrapper = styled.div``
const SchemaCreator = ({ data, ...props }) => {
  let content
  if (data && data.content && data.content.length) {
    content = data.content.replaceAll('"', "'")
    content = content.replaceAll('<script>', '<_script>')
    content = content.replaceAll('</script>', '</_script>')
  }

  let image_url = data.image ? data.image.url.replaceAll('{type}', 'fit') : ''
  image_url = image_url.replaceAll('{dimensions}', '1000x563')

  return (
    <Helmet {...props}>
      <script type="application/ld+json">
        {`
  {
    "@context":"http://schema.org",
    "@type": "NewsArticle",
    "headline": "` +
          (data && data.title && data.title.replaceAll('"', "'")) +
          `",
    "description": "` +
          (data && data.description && data.description.replaceAll('"', "'")) +
          `",
  "articleSection":"` +
          (data.breadcrumb.department ? data.breadcrumb.department.name : '') +
          `",
     "mainEntityOfPage":{
     "@type":"WebPage",
     "@id":"https://www.stol.it` +
          data.url +
          `"
     },

    "image":
    {
      "@type": "ImageObject",
      "url": "` +
          image_url +
          `",
        "height":"563",
        "width":"1000"

    },
           
    "dateModified": "` +
          data.date +
          `",
       "datePublished": "` +
          new Date(data.ts * 1000).toISOString() +
          `",

    "keywords": "` +
          data.tags.toString().replaceAll('"', "'") +
          `",
    "articleBody": "` +
          (content ? content : data && data.description && data.description.replaceAll('"', "'")) +
          `",
    "isFamilyFriendly": "` +
          true +
          `",
    "isAccessibleForFree":"` +
          !data.plus +
          `", 
    "author": { "@type": "Person", "name": "` +
          data.editor +
          `" } ,
    "publisher": {
    "@type": "Organization",
      "name": "STOL",
      "logo":{
        "@type":"ImageObject",
        "url":"https://s3-italy.brennercom.it/stol-images/img/speciale/piano/logo-stol.png",
        "height":"100",
        "width":"190"
      
      }
    }
  }
`}
      </script>
    </Helmet>
  )
}
SchemaCreator.propTypes = {}
SchemaCreator.defaultProps = {}
export default SchemaCreator
