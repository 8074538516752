import React from 'react'
import styled from 'styled-components'
import ContainerFull from '../../../../components/ContainerFull/index'
import TextSkeleton, { Line0TextSkeleton } from '../../../../components/Text/views/TextSkeleton/index'
import {
  WrapperHeadlinePaper,
  ControlsHeadlinePaper,
  HeadlineTextHeadlinePaper,
  LogoWrapperHeadlinePaper,
  StyledIconHeadlinePaper,
} from './index'

const StyledContainerArticle = styled(ContainerFull)`
  background-color: ${(props) => props.theme.color.background};
  border: 1px solid ${(props) => props.theme.color.backgroundBorder};
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const StyledTextSecondLineSkeleton = styled(TextSkeleton)`
  margin-left: 16px;
  margin-right: 100px;
  height: 20px;
  width: 100%;
  ${Line0TextSkeleton} {
    width: 100%;
    border-width: 20px;
    margin-top: 0;
  }
  @media (max-width: 800px) {
    margin: 10px 0 0;
    height: 44px;
    ${Line0TextSkeleton} {
      margin-top: 12px;
    }
  }
`

const Wrapper = styled(WrapperHeadlinePaper)``
const StyledLogoSkeleton = styled(TextSkeleton)`
  height: 16.5px;
  width: 123px;
  ${Line0TextSkeleton} {
    width: 123px;
    border-width: 16.5px;
    margin-top: 0;
  }
  @media (max-width: 800px) {
    height: 16.5px;
    width: 172px;
    ${Line0TextSkeleton} {
      width: 172px;
      border-width: 16.5px;
    }
  }
`

const SkeletonHeadlinePaper = () => (
  <StyledContainerArticle as="section">
    <Wrapper>
      <LogoWrapperHeadlinePaper>
        <StyledLogoSkeleton lastWidth="80%" />
      </LogoWrapperHeadlinePaper>
      <HeadlineTextHeadlinePaper>
        <StyledTextSecondLineSkeleton lastWidth="80%" />
      </HeadlineTextHeadlinePaper>
      <ControlsHeadlinePaper>
        <StyledIconHeadlinePaper icon="arrowDown" />
        <StyledIconHeadlinePaper disabled={true} icon="arrowUp" />
      </ControlsHeadlinePaper>
    </Wrapper>
  </StyledContainerArticle>
)

export default SkeletonHeadlinePaper
