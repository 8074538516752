import React from 'react'
import NakedItemArticle, {
  Description0NakedItemArticle,
  Image0NakedItemArticle,
  Title0NakedItemArticle,
  TitleLink0NakedItemArticle,
  TopLine0NakedItemArticle,
  Wrapper0NakedItemArticle,
} from '../../views/NakedItemArticle'
import styled, { css, withTheme } from 'styled-components'
import getArticleLayout from '../../modules/get-article-layout'

const Wrapper = styled.div`
  margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  padding-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  border-right: ${(props) => props.borderRight};
  border-left: ${(props) => props.borderLeft};
  border-top: ${(props) => props.borderTop};
  border-bottom: ${(props) => props.borderBottom};
  ${Image0NakedItemArticle} {
    flex-basis: ${(props) => props.theme.sizes.col8};
  }
  ${Description0NakedItemArticle} {
    flex-basis: ${(props) => props.theme.sizes.col4};
    padding-left: 0;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
    padding-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
    ${Image0NakedItemArticle} {
      flex-basis: ${(props) => props.theme.tablet.sizes.col8};
    }
    ${Description0NakedItemArticle} {
      flex-basis: ${(props) => props.theme.tablet.sizes.col4};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
    padding-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
    ${Image0NakedItemArticle} {
      flex-basis: ${(props) => props.theme.mobile.sizes.col8};
    }
    ${Description0NakedItemArticle} {
      flex-basis: ${(props) => props.theme.mobile.sizes.col4};
    }
  }
  ${(props) =>
    props.fixed &&
    css`
      outline: 2px solid ${(props) => props.theme.color.stol};
    `}
`
const NakedDefaultItemArticle = ({
  borderBottom,
  borderRight,
  borderLeft,
  borderTop,
  truncateTitle,
  truncateText,
  fixed,
  articleLayout,
  ...props
}) => {
  const LayoutNakedItemArticle = getArticleLayout(NakedItemArticle, { articleLayout, props })
  return (
    <Wrapper
      as={LayoutNakedItemArticle}
      fixed={fixed}
      borderBottom={borderBottom}
      borderRight={borderRight}
      borderLeft={borderLeft}
      borderTop={borderTop}
      truncateTitle={truncateTitle}
      truncateText={truncateText}
      articleLayout={articleLayout}
      {...props}
    />
  )
}

NakedDefaultItemArticle.propTypes = {
  ...NakedItemArticle.propTypes,
}

NakedDefaultItemArticle.defaultProps = {
  borderBottom: '1px solid rgba(0,0,0,0.2)',
  borderRight: 'none',
  borderLeft: 'none',
  borderTop: 'none',
}

export default withTheme(NakedDefaultItemArticle)

export {
  Image0NakedItemArticle as Image0NakedDefaultItemArticle,
  Title0NakedItemArticle as Title0NakedDefaultItemArticle,
  TitleLink0NakedItemArticle as TitleLink0NakedDefaultItemArticle,
  Description0NakedItemArticle as Description0NakedDefaultItemArticle,
  TopLine0NakedItemArticle as TopLine0NakedDefaultItemArticle,
  Wrapper0NakedItemArticle as Wrapper0NakedDefaultItemArticle,
}
