import React from 'react'
import styled from 'styled-components'
import WidgetEvents from '../../../../topics/Article/widgets_line/WidgetEvents'
import ItemOnTour from '../../../../topics/OnTour/components/ItemOnTour'

const StyledWidgetEvents = styled(WidgetEvents)`
  flex-basis: calc(100% * 2 / 3);
  margin-right: ${(props) => props.theme.sizes.gapCol};
  margin-left: -${(props) => props.theme.sizes.gapEdge};
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: 100%;
    margin-left: -${(props) => props.theme.tablet.sizes.gapEdge};
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: -${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: 100%;
    margin-left: -${(props) => props.theme.mobile.sizes.gapEdge};
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: -${(props) => props.theme.mobile.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    margin-left: 0;
    margin-right: 0;
  }
`

const ItemOnTourWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: ${(props) => props.theme.sizes.col4};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.tablet.sizes.col4};
    flex-direction: row;
    padding-top: 40px;
    margin-bottom: -40px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.mobile.sizes.col4};
    flex-direction: column;
    padding-top: 20px;
    margin-bottom: -20px;
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    padding-top: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-direction: column;
  }
`

const BlockEventsAndOnTourItems = ({ data: { events, onTourItems }, ...props }) => (
  <Wrapper {...props}>
    <StyledWidgetEvents articles={events} />
    <ItemOnTourWrapper>
      {onTourItems.map((item, index) => {
        if (index < 2) {
          return (
            <ItemOnTour
              key={'itemOnTour ' + index}
              image={item.image}
              headline={item.breadcrumb.headline}
              title={item.title}
              url={item.url}
              view="widgetOnTourWithEventTipps"
              start={item.date}
            />
          )
        }
        return null
      })}
    </ItemOnTourWrapper>
  </Wrapper>
)

export default BlockEventsAndOnTourItems
export {
  Wrapper as Wrapper0BlockEventsAndOnTourItems,
  ItemOnTourWrapper as ItemOnTourWrapper0BlockEventsAndOnTourItems,
}
