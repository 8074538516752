import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ItemArticle from '../../../../topics/Article/components/ItemArticle'
import ContainerAd from '../../../../components/ContainerAd'
import AdUnit from '../../../../components/AdUnit'

const Wrapper = styled.div``

const StyledContainerAd = styled(ContainerAd)`
  > div > div {
    margin-top: ${(props) => props.theme.sizes.gapVerticalHalf};
    margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    > div > div {
      margin-top: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
      margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    > div > div {
      margin-top: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
      margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
    }
  }
`

class BlockBigOneArticle extends PureComponent {
  render() {
    const {
      data: { articles },
      adIds,
      inverted,
      noLazyLoading,
      ...props
    } = this.props
    return (
      <Wrapper>
        <ItemArticle view="big" inverted={inverted} article={articles[0]} noLazyLoading={noLazyLoading} {...props} />
        {adIds && <StyledContainerAd adIds={adIds} />}
      </Wrapper>
    )
  }
}

BlockBigOneArticle.propTypes = {
  articles: PropTypes.arrayOf(ItemArticle.propTypes),
  adIds: PropTypes.arrayOf(AdUnit.propTypes.adId),
}

export default BlockBigOneArticle
