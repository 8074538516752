import NakedDefaultItemArticle, {
  Description0NakedDefaultItemArticle,
  Image0NakedDefaultItemArticle,
} from '../NakedDefaultItemArticle'
import styled, { withTheme } from 'styled-components'
import { Description0NakedItemArticle } from '../../views/NakedItemArticle'
import React, { useEffect, useState } from 'react'
import { useMediaQuery, useScreenSize } from '../../../../../../modules/media-query'

const Wrapper = styled(NakedDefaultItemArticle)`
  ${Description0NakedDefaultItemArticle} {
    padding-left: ${(props) => props.theme.sizes.gapVertical};
  }
  @media (max-width: 739px) {
    ${Description0NakedDefaultItemArticle}, ${Image0NakedDefaultItemArticle} {
      flex-basis: 100%;
    }
    ${Description0NakedDefaultItemArticle} {
      padding-left: unset;
      padding-right: unset;
    }
  }
  @media (max-width: 450px) {
    ${Description0NakedItemArticle} {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`

const BigItemArticle = ({ ...props }) => {
  const [truncateText, setTruncateText] = useState(8)
  const mode = useScreenSize()
  const descriptionNextToImage = useMediaQuery('(min-width:740px)')

  useEffect(() => {
    switch (mode) {
      case 'tablet':
        setTruncateText(descriptionNextToImage ? 6 : 3)
        break
      case 'desktop':
      default:
        setTruncateText(8)
    }
  }, [mode, descriptionNextToImage])
  return (
    <Wrapper
      truncateTitle={{ numberOfLines: 2 }}
      truncateText={{ numberOfLines: truncateText }}
      moreLink={false}
      {...props}
    />
  )
}

BigItemArticle.propTypes = {
  ...NakedDefaultItemArticle.propTypes,
}

export default withTheme(BigItemArticle)
