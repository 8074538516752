import React, { Fragment } from 'react'
import styled from 'styled-components'
import skeletonFade from '../../../../../../modules/mixins/skeleton-fade'
import TextSkeleton, { Line0TextSkeleton } from '../../../../../../components/Text/views/TextSkeleton'
import { useMediaQuery, useScreenSize } from '../../../../../../modules/media-query'

const StyledTextSkeleton = styled(TextSkeleton)`
  ${Line0TextSkeleton} {
    border-width: 17px;
    margin-top: 8px;
  }
`

const StyledTitleSkeleton = styled(TextSkeleton)`
  ${Line0TextSkeleton} {
    border-width: 21px;
    margin-top: 6px;
  }
  margin-bottom: 11px;
  margin-top: 34px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${Line0TextSkeleton} {
      border-width: 17px;
      margin-top: 3.5px;
    }
    margin-bottom: 0;
    margin-top: 31px;
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    ${Line0TextSkeleton} {
      border-width: 16px;
    }
  }
`

const TextWrapper = styled.div`
  flex-basis: calc((100% - 200px) / 6 * 2 + 40px);
  margin-left: 30px;
  @media (max-width: 739px) {
    margin: unset;
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

const ImageWrapper = styled.div`
  background-color: ${(props) => props.theme.color.skeletonScreen};
  flex-basis: calc((100% - 200px) / 6 * 4 + 120px);
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  ${skeletonFade}
`

const ImageSkeleton = styled.div`
  padding-top: 56.25%;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 739px) {
    flex-direction: column;
  }
`

const SkeletonBigItemArticle = () => {
  const descriptionNextToImage = useMediaQuery('(min-width:740px)')
  const screen = useScreenSize()
  let text, title
  switch (screen) {
    case 'mobileS':
    case 'mobile':
      text = 0
      title = 2
      break
    case 'tablet':
      text = descriptionNextToImage ? 6 : 3
      title = descriptionNextToImage ? 2 : 1
      break
    case 'desktop':
    default:
      text = 8
      title = 2
  }
  return (
    <Wrapper>
      <Fragment>
        <ImageWrapper>
          <ImageSkeleton />
        </ImageWrapper>
        <TextWrapper>
          <StyledTitleSkeleton lines={title} lastWidth="80%" />
          <StyledTextSkeleton lines={text} lastWidth="50%" />
        </TextWrapper>
      </Fragment>
    </Wrapper>
  )
}

export default SkeletonBigItemArticle
export { Wrapper as Wrapper0SkeletonSmallLandscapeItemArticle }
