import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NakedItemOnTour from '../NakedItemOnTour'

const StyledNakedItemOnTour = styled(NakedItemOnTour)`
  flex-basis: calc((100% / 3) - 20.1px);
  background-color: #ffff;
  height: 250px;
  @media (max-width: 1010px) {
    height: 240px;
  }
  @media (max-width: 981px) {
    height: 230px;
  }
  @media (max-width: 921px) {
    height: 220px;
  }
  @media (max-width: 861px) {
    height: 210px;
  }
  @media (max-width: 790px) {
    height: 210px;
    min-width: 200px;
  }
`

const WidgetItemOnTourWithButtons = ({ headline, title, start, url, image, ...props }) => (
  <StyledNakedItemOnTour headline={headline} title={title} image={image} url={url} start={start} {...props} />
)

WidgetItemOnTourWithButtons.propTypes = {
  headline: NakedItemOnTour.propTypes.headline,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    copyright: PropTypes.string,
    id: PropTypes.number,
    ratio: PropTypes.number,
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  url: PropTypes.string.isRequired,
}

export default WidgetItemOnTourWithButtons
