import React from 'react'
import styled from 'styled-components'
import Logo from '../../../../images/StolLogoNegativ'
import translate from '../../../../modules/translate'
import Title from '../../../../components/Title'
import Delimiter from '../../../../components/Delimiter'
import Link from '../../../../components/Link'
import isStolApp from '../../../../modules/is-stolapp'
import routes from '../../../routes'

const StyledTitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.titleBigger};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.titleBigger};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.titleBigger};
  }
  color: ${(props) => props.theme.color.textInverted};
`
const StyledDelimiter = styled(Delimiter)`
  font-size: ${(props) => props.theme.fontSize.titleBigger};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.titleBigger};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.titleBigger};
  }
  color: ${(props) => props.theme.color.textInverted};
  margin: 0 15px;
`
const StyledLogo = styled(Logo)`
  width: 100px;
  fill: ${(props) => props.theme.color.textInverted};
`
let Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  order: 1;
  color: white;
  margin-bottom: 40px;
  position: relative;
  padding-top: 20px;
  height: 70px;
  align-items: center;
`

const HeaderVideo = ({ ...props }) => (
  <Wrapper {...props} {...(!isStolApp() && { to: routes.generatePathByRouteName('videos'), as: Link })}>
    <StyledLogo />
    <StyledDelimiter />
    <StyledTitle>{translate('videos_title')}</StyledTitle>
  </Wrapper>
)

export default HeaderVideo
export { StyledTitle as StyledTitle0HeaderVideo }
