import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme, css } from 'styled-components'
import Avatar from '@mui/material/Avatar'
import hasItems from '../../../../../../../../modules/has-items/index'
import translate from '../../../../../../../../modules/translate/index'
import { ImageFormat } from '../../../../../../../../modules/parse/index'
import { translateFontSizeToTheme } from '../../../../../../../../modules/font'

const StyledAvatar = styled(Avatar)`
  && {
    width: 92px;
    height: 92px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    margin-left: 45px;
    margin-right: 18px;
  }

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    && {
      margin-bottom: 18px;
      float: left;
    }
  }
`
const WrapperQuote = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.color.textPrimary};
  font-style: italic;
  line-height: 31px;
  font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.default.fontSize.title1)};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.tablet.fontSize.title1)};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.mobile.fontSize.title1)};
  }
`
const Quote = styled.span`
  color: ${(props) => props.theme.color.textSecondary};
`
const WrapperText = styled.div`
  ${(props) =>
    props.image === false &&
    css`
      margin-top: 1em;
    `}
`

const Author = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  font-size: 1em;
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${StyledAvatar} {
      margin-left: 0;
    }

    display: block;
  }
`

const QuoteDetailArticle = ({ image, quote, author, fontSize, ...props }) => {
  const imageLink = image && new ImageFormat(image)
  return (
    <Wrapper {...props}>
      {hasItems(image) && <StyledAvatar src={imageLink.format('detail_158x158')} />}
      <WrapperText image={hasItems(image)}>
        <WrapperQuote fontSize={fontSize}>
          {translate('quote_prefix')}
          <Quote>{' ' + quote + ' '}</Quote>
          {translate('quote_suffix')}
        </WrapperQuote>
        {hasItems(author) && (
          <Author>
            {translate('quote_author_prefix')} {author}
          </Author>
        )}
      </WrapperText>
    </Wrapper>
  )
}

QuoteDetailArticle.propTypes = {
  image: PropTypes.string,
  quote: PropTypes.string.isRequired,
  author: PropTypes.string,
}

export default withTheme(QuoteDetailArticle)
