import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import translate from '../../../../../../modules/translate'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import React, { useEffect, useState } from 'react'
import Button from '../../../../../../components/Button'

const DialogMissingFields = ({ id, open = false }) => {
  const [modalOpen, setModalOpen] = useState(open)
  useEffect(() => {
    if (open !== modalOpen) {
      setModalOpen(open)
    }
  }, [id])

  return (
    <Dialog
      open={modalOpen}
      aria-labelledby="verification-dialog-title"
      aria-describedby="verification-dialog-description"
    >
      <DialogTitle id="verification-dialog-title">{translate('missing_fields_dialog_title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="verification-dialog-description">
          {translate('verification_dialog_description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalOpen(false)} view="grey">
          {translate('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogMissingFields
