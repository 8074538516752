import React from 'react'
import PropTypes from 'prop-types'
import Title from '../../../Title'
import styled, { css } from 'styled-components'
import ContainerFull from '../../../ContainerFull'
import SvgSplus from '../../../Icon/keys/iconKeys/images/Splus'
import NakedTitleTopic, { StyledTitle0NakedTitleTopic } from '../NakedTitleTopic'

const Wrapper = styled(ContainerFull)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 108px;
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  background-color: ${(props) => props.theme.color.background};
  background-image: -o-linear-gradient(
    55deg,
    ${(props) => props.theme.color.backgroundBorder} 30%,
    ${(props) => props.theme.color.background} 70%
  );
  background-image: -moz-linear-gradient(
    55deg,
    ${(props) => props.theme.color.backgroundBorder} 30%,
    ${(props) => props.theme.color.background} 70%
  );
  background-image: -webkit-linear-gradient(
    55deg,
    ${(props) => props.theme.color.backgroundBorder} 30%,
    ${(props) => props.theme.color.background} 70%
  );
  background-image: -ms-linear-gradient(
    55deg,
    ${(props) => props.theme.color.backgroundBorder} 30%,
    ${(props) => props.theme.color.background} 70%
  );
  background-image: linear-gradient(
    55deg,
    ${(props) => props.theme.color.backgroundBorder} 30%,
    ${(props) => props.theme.color.background} 70%
  );
  border: ${(props) => props.theme.color.backgroundBorder} 1px solid;

  margin-bottom: 0;
  @media (max-width: 600px) {
    height: 85px;
    ${StyledTitle0NakedTitleTopic} {
      font-size: 30px;
    }
  }
`
const PlusIconTitle = styled.div`
  display: flex;
`
const PlusIcon = styled(SvgSplus)`
  height: ${(props) => props.theme.fontSize.titleBigger};
  width: ${(props) => props.theme.fontSize.titleBigger};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    height: ${(props) => props.theme.tablet.fontSize.titleBigger};
    width: ${(props) => props.theme.tablet.fontSize.titleBigger};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: 30px;
    width: 30px;
  }
`
const WrapperTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledTitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.titleBigger};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.titleBigger};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 30px;
  }
  ${(props) =>
    props.subelement !== undefined &&
    css`
      margin-bottom: 0;
    `}
`

const PlusTitleTopic = ({ title, subelement, children, ...props }) => (
  <Wrapper {...props}>
    <WrapperTitle>
      <PlusIcon />
      <StyledTitle subelement={subelement}> &nbsp; {title}</StyledTitle>
      {subelement && subelement}
    </WrapperTitle>
    {children}
  </Wrapper>
)

PlusTitleTopic.defaultProps = {}

PlusTitleTopic.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  subelement: PropTypes.node,
}

export default PlusTitleTopic
export { StyledTitle as StyledTitle0PlusTitleTopic, WrapperTitle as WrapperTitle0PlusTitleTopic }
