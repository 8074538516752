import EmbedsContentArticle from '../../../../topics/Article/components/DetailArticle/components/EmbedsDetailArticle'
import React from 'react'

const embedInternal = ({ domNode, embeds }) => {
  const { attribs } = domNode
  if (embeds) {
    for (const embedId in embeds) {
      if (embedId === attribs.id) {

        const embed = embeds[embedId]
        const embedType = embed.type
        const embedData = embed.data
        return <EmbedsContentArticle embedType={embedType} embedId={embedId} data={embedData} {...embed} />
      }
    }
  }
}

export default embedInternal
