import React, { useContext } from 'react'
import styled from 'styled-components'
import d from '../../../../layouts/Blocks/views_article/BlockJobs/dolomitenmarkt.svg'
import k from '../../../../layouts/Blocks/views_article/BlockJobs/karriere_suedtirol.svg'
import s from '../../../../layouts/Blocks/views_article/BlockJobs/suedtirolerjobs.svg'
import j from '../../../../layouts/Blocks/views_article/BlockJobs/joobz.svg'
import ContextMenu from '../../../../components/ContextMenu'

const Wrapper = styled.div`
  display: unset;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: ${(props) => (props.isInsideHeader ? '8px 0' : '1px 7px 20px')};
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: ${(props) => (props.isInsideHeader ? '8px 0 3px' : '5px 40px 15px')};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: ${(props) => (props.isInsideHeader ? '0 0 5px' : '15px 10px 10px')};
  }
`

const StyledBottomWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-direction: column;
  }
`

const PoweredByDesktopTablet = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.color.textSecondary};
  font-size: 15px;
  position: ${(props) => (props.isInsideHeader ? '' : 'relative')};
  top: ${(props) => (props.isInsideHeader ? '' : '10px')};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: 13px;
    top: ${(props) => (props.isInsideHeader ? '' : '9px')};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: none;
  }
`

const PoweredByMobile = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: block;
    width: max-content;

    font-family: ${(props) => props.theme.font.primary};
    color: ${(props) => props.theme.color.textSecondary};
    font-size: 12px;
    margin: ${(props) => (props.isInsideHeader ? '0 0 5px' : '15px 0 5px')};
    margin-left: auto;
    margin-right: auto;
    order: 2;
  }
`

const StyledPartnerList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 7px;
  row-gap: 7px;
  margin: ${(props) => (props.isInsideHeader ? '5px 0' : '5px 30px 5px 0')};
  position: ${(props) => (props.isInsideHeader ? '' : 'relative')};
  top: ${(props) => (props.isInsideHeader ? '' : '10px')};
  order: 0;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    column-gap: 7px;
    margin-right: ${(props) => (props.isInsideHeader ? '0' : '20px')};
    top: ${(props) => (props.isInsideHeader ? '' : '9px')};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    top: ${(props) => (props.isInsideHeader ? '' : '0px')};
    justify-content: center;
    margin-right: 0;
    margin-top: ${(props) => (props.isInsideHeader ? 'inherit' : '10px')};

    order: 3;
  }
`

const StyledPartner = styled.img`
  height: 25px;
  width: auto;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    height: 22px;
  }
  @media (max-width: 760px) {
    height: 21px;
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    height: 20px;
  }
`

const JobPoweredByBlock = ({ partner, children }) => {
  const menuData = useContext(ContextMenu)
  let poweredBy

  if (partner) {
    poweredBy = partner
  } else {
    poweredBy = (menuData && menuData.responseData && menuData.responseData.meta.poweredByLogos) || [
      { svg: s },
      { svg: d },
      { svg: k },
      { svg: j },
    ]
  }

  return (
    <Wrapper isInsideHeader={!children}>
      <PoweredByDesktopTablet isInsideHeader={!children}>powered by</PoweredByDesktopTablet>
      <StyledBottomWrapper>
        <PoweredByMobile isInsideHeader={!children}>powered by</PoweredByMobile>
        <StyledPartnerList isInsideHeader={!children}>
          {poweredBy.map((partner, index) => (
            <StyledPartner src={partner.svg ? partner.svg : partner.img} />
          ))}
        </StyledPartnerList>
        {children}
      </StyledBottomWrapper>
    </Wrapper>
  )
}

export default JobPoweredByBlock

export {
  Wrapper as JobPoweredByBlock0Wrapper,
  StyledPartner as JobPoweredByBlock0StyledPartner,
  StyledPartnerList as JobPoweredByBlock0StyledPartnerList,
  StyledBottomWrapper as JobPoweredByBlock0StyledBottomWrapper,
  PoweredByDesktopTablet as JobPoweredByBlock0PoweredByDesktopTablet,
  PoweredByMobile as JobPoweredByBlock0PoweredByMobile,
}
