import React, { Fragment } from 'react'
import styled from 'styled-components'
import SkeletonWidgetNakedLineArticle, {
  WrapperLineItems0SkeletonWidgetNakedLineArticle,
} from '../WidgetNakedLineArticle/skeleton'
import TextSkeleton, { Line0TextSkeleton } from '../../../../components/Text/views/TextSkeleton'

const StyledTextSkeleton = styled(TextSkeleton)`
  width: 100%;
  position: absolute;
  left: 0;
  top: ${(props) => props.theme.sizes.gapVertical};
  ${Line0TextSkeleton} {
    border-width: 20px;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const StyledSkeletonWidgetNakedLineArticle = styled(SkeletonWidgetNakedLineArticle)`
  ${WrapperLineItems0SkeletonWidgetNakedLineArticle} {
    margin-top: calc(2 * ${(props) => props.theme.sizes.gapVertical});
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    ${WrapperLineItems0SkeletonWidgetNakedLineArticle} {
      margin-top: calc(2 * ${(props) => props.theme.tablet.sizes.gapVertical});
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${WrapperLineItems0SkeletonWidgetNakedLineArticle} {
      margin-top: calc(2 * ${(props) => props.theme.mobile.sizes.gapVertical});
    }
  }
`

const SkeletonWidgetMostRead = ({ ...props }) => {
  return (
    <Fragment>
      <StyledSkeletonWidgetNakedLineArticle title items={5} numbering views {...props}>
        <StyledTextSkeleton lastWidth="50%" />
      </StyledSkeletonWidgetNakedLineArticle>
    </Fragment>
  )
}

export default SkeletonWidgetMostRead
