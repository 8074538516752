import React, { Fragment, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import Button from '../Button'
import CircularProgress from '../CircularProgress'
import Config from '../../config'
import MessageError from '../../topics/Error/screens/MessageError'
import { Get } from 'react-axios'
import translate from '../../modules/translate'
import { withRouter } from 'react-router'
import Text from '../Text'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'
import isServerSide from '../../modules/is-server-side'

const StyledButton = styled(Button)`
  margin: 0 auto;
  margin-bottom: 10px;
  color: ${(props) => props.color};
`

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: ${(props) => props.theme.sizes.gapVerticalHalf};
  margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-top: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
`

const StyledCircularProgress = styled(CircularProgress)`
  margin-bottom: 12px;
`

const StyledMessageError = styled(MessageError)`
  margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
`

const StyledText = styled(Text)`
  margin: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.color.stol};
`

const LoadMore = ({
  articles,
  afterLoadMore,
  color,
  customRoute,
  loadMoreRoute,
  loadMoreArgument,
  version,
  ...props
}) => {
  const [helperArticles, setArticles] = useState(articles)
  const [loadMore, getMoreArticles] = useState(false)
  const [noMoreArticles, setNoMoreArticles] = useState(false)

  if (articles.length <= 0) {
    return false
  }
  const errorOptions = {
    component: translate('load_more_error'),
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: props.location.pathname + ' LoadMore',
  }
  if (noMoreArticles) {
    if (!isServerSide()) {
      window.setTimeout(() => setNoMoreArticles(false), 8000)
    }
  }
  loadMoreRoute = loadMoreRoute ? loadMoreRoute : props.location.pathname
  if (helperArticles.slice(-1)[0].id !== undefined) {
    let customLoadMoreArgument = loadMoreArgument
      ? loadMoreArgument
      : helperArticles.slice(-1)[0].id.toString() + '-' + helperArticles.slice(-1)[0].ts.toString()
    loadMoreRoute = 'loadmore-' + customLoadMoreArgument + loadMoreRoute
  } else {
    let customLoadMoreArgument = loadMoreArgument
      ? loadMoreArgument
      : helperArticles.slice(-1)[0].data.id.toString() + '-' + helperArticles.slice(-1)[0].data.ts.toString()
    loadMoreRoute = 'loadmore-' + customLoadMoreArgument + loadMoreRoute
  }
  return (
    <Wrapper>
      {loadMore && (
        <Fragment>
          <Get url={Config.restUrl(customRoute ? customRoute : loadMoreRoute, version ? version : 'v1')}>
            {(error, response, isLoading) => {
              if (isLoading) {
                return <StyledCircularProgress />
              }
              if (error) {
                return <StyledMessageError logInfo={translate('dev_errorlog_no_data')} {...errorOptions} />
              }
              if (response) {
                if (response.data && response.data.data && response.data.data.articles) {
                  const newArticle = articles.concat(response.data.data.articles)

                  afterLoadMore(uniqBy(newArticle, 'id'))
                  setArticles(uniqBy(newArticle, 'id'))
                }
                if (response.data && response.data.data && response.data.data.searchEntries) {
                  const newArticle = articles.concat(response.data.data.searchEntries)

                  afterLoadMore(newArticle)
                  setArticles(newArticle)
                }
                if (response.data && !response.data.data) {
                  setArticles(helperArticles.concat(response.data))
                }

                getMoreArticles(false)
                if (
                  (response.data &&
                    response.data.data &&
                    response.data.data.articles &&
                    helperArticles.length ===
                      uniqBy(helperArticles.concat(response.data.data.articles), 'id').length) ||
                  (response.data &&
                    response.data.length &&
                    helperArticles.length === uniqBy(helperArticles.concat(response.data), 'id').length)
                ) {
                  setNoMoreArticles(true)
                }
                return <Fragment />
              }
              return null
            }}
          </Get>
        </Fragment>
      )}
      {noMoreArticles && <StyledText>{translate('no_more_articles_found')}</StyledText>}
      {
        <StyledButton
          color={color ? color : props.theme.color.stol}
          onClick={() => {
            getMoreArticles(true)
          }}
        >
          {translate('load_more')}
        </StyledButton>
      }
    </Wrapper>
  )
}

LoadMore.propTypes = {
  articles: PropTypes.object,
  afterLoadMore: PropTypes.func,
  /**
   * Hex color code
   **/
  color: PropTypes.string,
  /**
   * Custom API Route
   **/
  customRoute: PropTypes.string,
  /**
   * Custom load-more Route exp.: /loadmore-' + loadMoreArgument + loadMoreRoute
   */
  loadMoreRoute: PropTypes.string,
  loadMoreArgument: PropTypes.string,
}

LoadMore.defaultProps = {
  afterLoadMore: () => {},
}

export default withTheme(withRouter(LoadMore))
