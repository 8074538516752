import React from 'react'
import styled, { css } from 'styled-components'
import Icon from '../../../../../../components/Icon'
import Link from '../../../../../../components/Link'

const Wrapper = styled.div`
  margin-bottom: 25px;

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: 20px;
  }
`

const AuthorHeader = styled.div`
  font-size: ${(props) => props.theme.fontSize.base};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: bold;
  font-style: italic;
  color: ${(props) => props.theme.color.textPrimary};
  line-height: ${(props) => props.theme.lineHeight.text};
  margin-bottom: 15px;
`

const AuthorContainer = styled.div`
  display: flex;
`

const AuthorNameContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;

  font-family: ${(props) => props.theme.font.primary};
`

const AuthorDescLabel = styled.div`
  color: #7e7e7e;
  font-size: 14px;
  line-height: 17px;
`

const AuthorNameWrapper = styled.div`
  color: #333333;
  font-size: 16px;
  line-height: 19px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  -webkit-box-pack: center;
  // justify-content: center;
  align-content: center;
`

const AuthorNameLabel = styled.div`
  margin-right: 10px;
`

const AuthorImageWrapper = styled.div`
  margin-right: 10px;
`

const AuthorImage = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 25px;
`
const AuthorSocialContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  align-content: center;
`

const StyledIconLink = styled.a`
  padding-right: 0px !important;

  &:after {
    background-image: none !important;
    width: 0px !important;
  }
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  padding-left: 0px;
  padding-right: 10px;
  color: #333333;
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }

  &:after {
    background-image: none !important;
    width: 0px !important;
  }

  ${(props) =>
    props.inverted &&
    css`
      color: #fff;
    `}
`

const StyledMobileIconLink = styled.a`
  padding-right: 10px !important;

  &:after {
    background-image: none !important;
    width: 0px !important;
  }
`
const StyledMarginedIcon = styled(Icon)`
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 50px;
  color: #333333;
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  ${(props) =>
    props.inverted &&
    css`
      color: #fff;
    `}

  &:after {
    background-image: none !important;
    width: 0px !important;
  }
`

const MobileOnly = styled.span`
  margin-top: -5px;
  display: none;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: block;
  }
`

const PaddedMobileOnly = styled.span`
  margin-top: -5px;
  padding-left: 60px;
  display: none;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: block;
  }
`

const DesktopOnly = styled.span`
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: none;
  }
`

const Always = styled.div``

const AuthorInfo = ({ author, isCommunityNews, ...props }) => {
  const hasImage = author.imgUrl != null
  const TagName = hasImage ? DesktopOnly : Always
  const MobileOnlyElement =
    hasImage && (author.facebook != null || author.instagram != null || author.twitter != null || author.email)
      ? PaddedMobileOnly
      : MobileOnly

  return (
    <Wrapper {...props}>
      {author.header && author.header != null && <AuthorHeader>{author.header}</AuthorHeader>}
      {(author.firstName != null || author.lastName != null) && (
        <AuthorContainer>
          {hasImage && (
            <AuthorImageWrapper>
              <AuthorImage src={author.imgUrl} />
            </AuthorImageWrapper>
          )}
          <AuthorNameContainer>
            {!isCommunityNews && <AuthorDescLabel>Von: </AuthorDescLabel>}
            {isCommunityNews && <AuthorDescLabel>Inhaltlich verantwortlich: </AuthorDescLabel>}
            <AuthorNameWrapper>
              <AuthorNameLabel>
                {((author.firstName ? author.firstName : '') + ' ' + (author.lastName ? author.lastName : '')).trim()}
              </AuthorNameLabel>

              <TagName>
                {isCommunityNews && author.link != null && (
                  <StyledIconLink target="_blank" href={author.link}>
                    <StyledIcon icon="externalLink" />
                  </StyledIconLink>
                )}
                {author.facebook != null && !isCommunityNews && (
                  <StyledIconLink target="_blank" href={author.facebook}>
                    <StyledIcon icon="facebook" />
                  </StyledIconLink>
                )}
                {author.instagram != null && !isCommunityNews && (
                  <StyledIconLink target="_blank" href={author.instagram}>
                    <StyledIcon icon="instagram" />
                  </StyledIconLink>
                )}
                {author.twitter != null && !isCommunityNews && (
                  <StyledIconLink target="_blank" href={author.twitter}>
                    <StyledIcon icon="twitter" />
                  </StyledIconLink>
                )}
                {author.email != null && !isCommunityNews && (
                  <StyledIconLink href={'mailto:' + author.email}>
                    <StyledIcon icon="email" />
                  </StyledIconLink>
                )}
              </TagName>
            </AuthorNameWrapper>
          </AuthorNameContainer>
        </AuthorContainer>
      )}

      {(author.firstName != null || author.lastName != null) && hasImage && (
        <MobileOnlyElement>
          {isCommunityNews && author.link != null && (
            <StyledMobileIconLink target="_blank" href={author.link}>
              <StyledIcon icon="externalLink" />
            </StyledMobileIconLink>
          )}
          {author.facebook != null && (
            <StyledMobileIconLink target="_blank" href={author.facebook}>
              <StyledIcon icon="facebook" />
            </StyledMobileIconLink>
          )}
          {author.instagram != null && (
            <StyledMobileIconLink target="_blank" href={author.instagram}>
              <StyledIcon icon="instagram" />
            </StyledMobileIconLink>
          )}
          {author.twitter != null && (
            <StyledMobileIconLink target="_blank" href={author.twitter}>
              <StyledIcon icon="twitter" />
            </StyledMobileIconLink>
          )}
          {author.email != null && (
            <StyledMobileIconLink target="_blank" href={'mailto:' + author.email}>
              <StyledIcon icon="email" />
            </StyledMobileIconLink>
          )}
        </MobileOnlyElement>
      )}
    </Wrapper>
  )
}

export default AuthorInfo
