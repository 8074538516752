import React from 'react'
import styled from 'styled-components'
import translate from '../../../../modules/translate'
import WidgetNakedLineArticle from '../WidgetNakedLineArticle'
import { Text0DescriptionItemArticle } from '../../../../topics/Article/components/ItemArticle/components/DescriptionItemArticle'

const StyledWidgetNakedLineArticle = styled(WidgetNakedLineArticle)`
  ${Text0DescriptionItemArticle} {
    display: none;
  }
`

const WidgetRelated = ({ articles, ...props }) => {
  return (
    <StyledWidgetNakedLineArticle
      articles={articles}
      title={translate('article_related')}
      containerArticle
      {...props}
    />
  )
}

WidgetRelated.propTypes = {
  article: WidgetNakedLineArticle.propTypes.articles,
}

export default WidgetRelated
