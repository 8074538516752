import userSession from '../../../../modules/user-session'
import hasItems from '../../../../../../modules/has-items'

const setSmsVerified = (verified) => {
  const userData = userSession.getData()
  if (hasItems(userData)) {
    userData.smsVerified = verified
    userSession.storeData(userData)
  }
}

export default setSmsVerified
