import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ItemArticle from '../../../../topics/Article/components/ItemArticle'
import styled from 'styled-components'
import BlockTwoLandscapeArticle from '../BlockTwoLandscapeArticle'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
`

class BlockThreeLandscapeArticle extends PureComponent {
  render() {
    const {
      data: { articles },
      adIds,
      noLazyLoading,
      ...props
    } = this.props

    if (!articles) {
      return false
    }
    return (
      <Wrapper {...props}>
        {articles[0] && <ItemArticle view="small_landscape" article={articles[0]} noLazyLoading={noLazyLoading} />}
        <BlockTwoLandscapeArticle
          data={{ articles: [articles[1], articles[2]] }}
          view="two_landscape_article"
          adIds={adIds}
        />
      </Wrapper>
    )
  }
}

BlockThreeLandscapeArticle.propTypes = {
  articles: PropTypes.arrayOf(ItemArticle.propTypes.article),
}

export default BlockThreeLandscapeArticle
