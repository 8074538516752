import React from 'react'
import styled from 'styled-components'
import MapDistrictsSouthTyrol from '../../../MapDistrictsSouthTyrol'
import NakedTitleTopic from '../NakedTitleTopic'

const StyledMapDistrictsSouthTyrol = styled(MapDistrictsSouthTyrol)`
  width: 190px;
  align-self: flex-start;
  margin-top: 7px;
`

const StyledTitleTopic = styled(NakedTitleTopic)`
  justify-content: space-between;
  @media (max-width: 450px) {
    display: block;

    ${StyledMapDistrictsSouthTyrol} {
      width: 132.5px;
      height: 80px;
      border-shadow: 1px 1px 1px black;
      margin-top: -7px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const DistrictTitleTopic = ({ district, ...props }) => (
  <StyledTitleTopic {...props}>
    <StyledMapDistrictsSouthTyrol district={district} />
  </StyledTitleTopic>
)

DistrictTitleTopic.defaultProps = {
  district: 'undefined',
}

DistrictTitleTopic.propTypes = {
  district: MapDistrictsSouthTyrol.propTypes.district,
}

export default DistrictTitleTopic
