import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Link from '../Link'
import miniArrow from './images/mini-arrow.svg'

const selectedCss = css`
  color: ${(props) => props.theme.color.textPrimary};
  transform: translateX(4px);
  padding-left: 2px;
  &:before {
    opacity: 1;
    left: -10px;
  }
`

const NavLink = styled(({ selected, ...props }) => <Link {...props} />)`
  color: ${(props) => props.theme.color.textSecondary};
  margin: 0.4em 0;
  padding: 0 1em;
  cursor: default;
  position: relative;
  line-height: ${(props) => props.theme.lineHeight.title};
  transition: transform 0.2s ease-out;

  &:before {
    content: url(${miniArrow});
    width: 5px;
    line-height: 5px;
    opacity: 0;
    transition: opacity 0.2s ease-in, left 0.2s ease-out;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  ${(props) =>
    props.to &&
    css`
      cursor: pointer;
      ${(props) => props.selected && selectedCss};
      &:hover {
        ${selectedCss};
      }
    `};
`

NavLink.propTypes = {
  selected: PropTypes.bool,
}

NavLink.defaultProps = {
  selected: false,
}

export default NavLink
