import styled from 'styled-components'
import LeaderItemArticle from '../LeaderItemArticle'
import NakedItemArticle, {
  Title0NakedItemArticle,
  TopLine0NakedItemArticle,
  Description0NakedItemArticle,
} from '../../views/NakedItemArticle'

const BigLeaderItemArticle = styled(LeaderItemArticle)`
  ${Title0NakedItemArticle} {
    font-size: 28px;
    line-height: 30px;
    font-weight: 500;
    padding: 1.0625rem 1.1875rem 0.5rem;
  }
  ${Description0NakedItemArticle} {
    ${TopLine0NakedItemArticle} {
      font-size: 1.0625rem;
      line-height: 19px;
      margin-bottom: 0;
      padding: 0.176em 1.1875rem;
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-bottom: 0;
    ${Title0NakedItemArticle} {
      font-size: 1.125em;
      line-height: 1.3125rem;
      padding: 0.1875rem 0.6875rem 0.0625rem;
    }
    ${TopLine0NakedItemArticle} {
      font-size: 0.9375rem;
      padding: 0.0625rem 0.625rem 0;
    }

    ${Description0NakedItemArticle} {
      ${TopLine0NakedItemArticle} {
        padding: 0.25em 0.625rem;
      }
    }
  }
`

BigLeaderItemArticle.propTypes = {
  ...NakedItemArticle.propTypes,
}

BigLeaderItemArticle.defaultProps = {
  truncateTitle: {
    numberOfLines: 3,
  },
}

export default BigLeaderItemArticle
