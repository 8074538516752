import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import Text from '../../../../../../components/Text'
import ContainerArticle from '../../../../../../components/ContainerArticle'
import Color from 'color'
import themes from '../../../../../../config/themes'

const fade = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const StyledText = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.small};
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  padding: 10px;

  a {
    color: ${(props) => props.color};
  }
`
const Wrapper = styled(ContainerArticle)`
  background-color: ${(props) => props.backgroundColor};
  animation: ${fade} 1s linear;
`

export const colorSets = Object.freeze({
  error: themes.default.color.stolDark,
  warning: themes.default.color.warning,
  success: themes.default.color.success,
})

const MessageText = ({ color = colorSets.warning, children, ...props }) => {
  const backgroundColor = Color(color).lighten(0.5).fade(0.8).rgb().toString()
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <StyledText color={color} {...props}>
        {children}
      </StyledText>
    </Wrapper>
  )
}

MessageText.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
}

export default MessageText
