import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.span`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) => props.theme.color.textSecondary};
  margin-left: 0.5em;
  margin-right: 0.5em;
`
const Delimiter = ({ ...props }) => <Wrapper {...props}>»</Wrapper>

export default Delimiter
