import React from 'react'
//import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Title from '../../../../components/Title'
import ContainerFull from '../../../../components/ContainerFull'
import ItemArticle from '../../components/ItemArticle'
import Link from '../../../../components/Link'
import Image from '../../../../components/Image'
import { ImageFormat } from '../../../../modules/parse'

const Wrapper = styled(ContainerFull)`
  position: relative;
  background-image: ${(props) => props.image};
`

const StyledTitle = styled(Title)`
  margin: 0;
  position: absolute;
  margin-top: 1em;
  z-index: 11;
  background-color: rgba(211, 29, 35, 0.8);
  padding: 10px;
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  padding-right: 20px;
  color: ${(props) => props.theme.color.textInverted};
  font-size: 32px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    width: 100%;
    margin-top: 0;
    padding-left: 10px;
    font-size: 28px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-left: 10px;
    font-size: 28px;
    width: calc(100% - 10px);
    position: relative;
    margin-top: 0;
    opacity: 1;
    padding-right: 0;
    font-size: 22px;
  }
  ${(props) =>
    props.image === undefined &&
    css`
      margin-bottom: 1em;
      margin-top: 0;
      position: relative;
    `}
`
const RelativeOverlayDiv = styled.div`
  position: relative;
`
const StyledCopyRightDiv = styled.div`
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  font-size: 10px;
  line-height: 15px;
  padding: 5px 0;
  color: #3b3e40;
  font-family: ${(props) => props.theme.font.primary};

  font-weight: 400;

  -webkit-text-size-adjust: 100%;

  z-index: 10;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: unset;
    width: 100%;
    writing-mode: unset;
    transform: unset;
    line-height: unset;
    text-align: right;
    padding: unset;

    padding-right: 3px;
  }
`
const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  margin-bottom: ${(props) => (props.articles > 0 ? '-5px' : '25px')};
`

const ArticlesWrapper = styled.div`
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  margin-left: ${(props) => props.theme.sizes.gapEdge};
  margin-top: -2em;
  padding-bottom: 1px;
  ${(props) =>
    props.image === undefined &&
    props.title === undefined &&
    css`
      padding-top: ${(props) => props.theme.sizes.gapVertical};
    `}
  @media(max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    ${(props) =>
      props.image === undefined &&
      props.title === undefined &&
      css`
        padding-top: ${(props) => props.theme.tablet.sizes.gapVertical};
      `}
  }

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-top: 1em;
    ${(props) =>
      props.image === undefined &&
      props.title === undefined &&
      css`
        padding-top: ${(props) => props.theme.mobile.sizes.gapVertical};
      `}
  }

  ${(props) =>
    props.image === undefined &&
    css`
      margin-top: 0;
    `}
`

const StyledItemArticle = styled(ItemArticle)`
  z-index: 10;
  padding-bottom: 0;
`

const WidgetTopTopic = ({ data: { title, image, articles, imageCopyright, url }, data, ...props }) => {
  const BigImageItem = new ImageFormat(image)
  let firstArticle = articles && articles.slice(0, 1)[0]
  let pathname = '#'
  if (articles && articles.length > 0 && firstArticle) {
    pathname = firstArticle.url
  }
  if (url) {
    pathname = url
  }
  let to = {
    pathname,
    state: { ...firstArticle },
  }

  return (
    <Wrapper as="section" cinemaMode="multimedia" image={image} noGapEdge articles={articles} {...props}>
      <Link to={to}>
        {title && <StyledTitle image={image}>{title}</StyledTitle>}

        {image && (
          <RelativeOverlayDiv>
            <StyledImage
              id={'styled_image'}
              src={BigImageItem.format('detail_1200x675')}
              height={607.5}
              width={1080}
              srcSet={`${BigImageItem.format('detail_329x185')} 521w, ${BigImageItem.format('detail_521x293')} 759w`}
              sizes="(max-width: 780px) 800px, (max-width: 480px) 500px, 1280px"
              articles={articles && articles.length}
            />
            <StyledCopyRightDiv>{imageCopyright}</StyledCopyRightDiv>
          </RelativeOverlayDiv>
        )}
      </Link>
      <ArticlesWrapper image={image} title={title}>
        {articles &&
          articles.map((article, index) => (
            <StyledItemArticle
              view="top_topic"
              key={'top-topic-article' + index}
              article={article}
              inverted={1}
            ></StyledItemArticle>
          ))}
      </ArticlesWrapper>
    </Wrapper>
  )
}

WidgetTopTopic.propTypes = {}

WidgetTopTopic.defaultProps = {}

export default WidgetTopTopic
