import translate from '../../../../../../modules/translate'
import React from 'react'
import styled from 'styled-components'
import MessageText from '../../../../../UserGeneratedContent/components/FormUGC/components/MessageText'
import Icon from '../../../../../../components/Icon'

const StyledMessageText = styled(MessageText)`
  justify-content: center;
`

const StyledOpenDialog = styled.div``

const MessageIcon = styled(Icon)`
  margin-right: 0.3em;
  font-size: ${(props) => props.theme.fontSize.title2};
`
const MessageTextMissingFields = () => (
  <StyledMessageText>
    <MessageIcon icon="account" />
    <StyledOpenDialog>{translate('missing_fields_dialog_title')}</StyledOpenDialog>
  </StyledMessageText>
)

export default MessageTextMissingFields
