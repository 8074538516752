import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Districts from './components/DistrictsMap'
import options from '../../config/options'

const StyledMapDistrictsSouthTyrol = styled(({ district, fill, ...props }) => <Districts {...props} />)`
  ${(props) => props.districts} {
    fill: ${(props) => props.theme.color.stol};
  }
  ${(props) => props.district} {
    transition: fill 0.1s ease-in-out;
    fill: ${(props) =>
      props.fill === 'red'
        ? css`
            ${(props) => props.theme.color.stol};
          `
        : props.fill === 'grey'
        ? css`
            ${(props) => props.theme.color.textTertiary};
          `
        : css`
            ${(props) => props.theme.color.textTertiary};
          `};
  }
`

const MapDistrictsSouthTyrol = ({ district, districts, fill, ...props }) => {
  const districtCLass = district !== options.Layout.districtsNav.nationwideIdentifier ? `.${district}` : '.bezirk'

  const districtsCLasses =
    districts &&
    districts
      .map((district) => {
        return `.${district}`
      })
      .join()

  return <StyledMapDistrictsSouthTyrol district={districtCLass} districts={districtsCLasses} fill={fill} {...props} />
}

MapDistrictsSouthTyrol.propTypes = {
  district: PropTypes.oneOf([
    'bozen_leifers',
    'bozen_land',
    'eisacktal',
    'ladinien',
    'meran_burggrafenamt',
    'pustertal',
    'ueberetsch_unterland',
    'vinschgau',
    'wipptal',
    'landesweit',
    'undefined',
  ]),
  districts: PropTypes.array,
  fill: PropTypes.oneOf(['grey', 'red']),
}

MapDistrictsSouthTyrol.defaultProps = {
  districts: [],
  fill: 'red',
}

export default MapDistrictsSouthTyrol
