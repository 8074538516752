import React from 'react'

function SvgStolLogoNegativ(props) {
  return (
    <svg viewBox="0 0 116.1 48.8" {...props}>
      <path d="M82.5 34.3h4v4.4h-4zm8.1-12.5h4.7v17h-4.7zm0-7.3h4.7v3.7h-4.7zm16.1 10.2v-2.9h-2.5V17l-4.7 2.1v2.7h-1.9v2.9h1.9v10.5c0 1 .5 3.8 4.6 3.8a15.7 15.7 0 002.5-.2v-2.9a2 2 0 01-.8.1c-1.5 0-1.5-.8-1.5-2.1v-9.1h2.4zm-58.9-4.3c-2.6 0-2.8 2.3-2.8 6.8 0 6.7.6 8.1 2.8 8.1s2.8-1.4 2.8-8.1c0-4.5-.2-6.8-2.8-6.8z" />
      <path
        className="stol-logo-negativ_svg__a"
        d="M0 0v48.8h116.1V0zm15.5 39.2c-5.3 0-8.9-1.3-8.5-7.4h6a6.9 6.9 0 00.3 2.6c.3.7 1 1 2.2 1a2.1 2.1 0 002.2-2.2C17.7 28.6 7 29.6 7 22.7c0-4.7 4.7-6.1 8.6-6.1 4.1 0 7.9 2 7.6 6.7h-6c0-1.5-.2-2.4-.6-2.8A2 2 0 0015 20a2 2 0 00-2.1 2.3c0 3.5 10.7 3.3 10.7 10.3.1 3.8-3 6.6-8.1 6.6zm21.1-18.5h-3.2v11.7c0 1.6 0 2.7 1.9 2.7a3.8 3.8 0 001.1-.1v3.8a23.3 23.3 0 01-3.2.2c-5.3 0-6-3.5-6-4.8V20.7h-2.5v-3.8h2.5v-3.5l6.1-2.7v6.2h3.2v3.8zm11.2 18.5c-7.9 0-8.9-4.8-8.9-11.6 0-6 .9-11.1 8.9-11.1s8.9 5.1 8.9 11.1c0 6.8-1.1 11.6-8.9 11.6zm19.8-.4h-6.1V7.6h6.1zm46 7.5H77.2V2.4h36.4z"
      />
    </svg>
  )
}

export default SvgStolLogoNegativ
