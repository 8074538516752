import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import loadable from '@loadable/component'
import StolLogo from '../../images/StolLogo'
import { Helmet } from 'react-helmet-async'

const StyledStolLogo = styled(StolLogo)`
  width: 100px;
`
const ImageProgressive = loadable(
  () => import(/* webpackChunkName: "ImageProgressive", webpackPrefetch: true */ '../ImageProgressive'),
  {
    ssr: false,
  },
)

const ImageWrapper = styled.div`
  background-color: ${(props) => props.bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const ImageDummy = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${({ aspectRatio }) => {
    if (!aspectRatio) {
      return ''
    }
    const calcAspectRatio = aspectRatio * 100
    return css`
      padding-top: ${calcAspectRatio}%;
    `
  }}
`
const ImageDummyTest = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: relative;
`

const StyledImage = styled(
  React.forwardRef((props, ref) => {
    return (
      <ImageProgressive
        fallback={
          <ImageWrapper {...props}>
            <ImageDummy
              width={props.width ? `${props.width}px` : '100%'}
              height={props.height ? `${props.height}px` : '100%'}
              aspectRatio={props.aspectRatio}
            ></ImageDummy>
          </ImageWrapper>
        }
        alt=""
        ref={ref}
        {...props}
      />
    )
  }),
)`
  display: block;
  position: relative;
  ${(props) =>
    props.height && !props.width
      ? css`
          width: ${(props) => (props.current_width !== 'auto' ? props.current_width + 'px' : 'auto')};
        `
      : css`
          width: 100%;
        `}

  ${(props) =>
    props.height &&
    props.width &&
    css`
      width: ${(props) => props.width + 'px'};
      height: ${(props) => props.height + 'px'};
    `}

  ${(props) =>
    (props.height && !props.width) || props.natural_height > props.natural_width
      ? css`
          img {
            width: auto !important;
            height: 100% !important;
            left: 50% !important;
            top: 50% !important;
            transform: translate(-50%, -50%) scale(1);
          }
        `
      : css`
          img {
            top: 50% !important;
            transform: translateY(-50%) !important;
          }
        `}
`

class Image extends Component {
  state = {
    dimensions: {},
  }

  render() {
    const { src, blur, aspectRatio, getDimensions, height, width, isTopTopic, noLazyLoading, ...props } = this.props

    const { naturalHeight, naturalWidth, currentWidth } = this.state.dimensions
    const onImgLoad = ({ target: img }) => {
      const dimensions = { naturalHeight: img.naturalHeight, naturalWidth: img.naturalWidth, currentWidth: img.width }
      dimensions.currentWidth = (dimensions.naturalWidth * height) / dimensions.naturalHeight
      this.setState({
        dimensions: dimensions,
      })
      if (getDimensions) {
        getDimensions(dimensions)
      }
    }
    if (src) {
      return (
        <Fragment>
          {!noLazyLoading && (
            <StyledImage
              id={'TESTOJER2'}
              src={src}
              placeholderSrc={blur}
              onLoad={onImgLoad}
              width={width}
              height={height}
              natural_width={naturalWidth}
              natural_height={naturalHeight}
              current_width={currentWidth ? currentWidth : 'auto'}
              bgColor="#D1D1D1"
              aspectRatio={aspectRatio}
              loadOnScreen
              noLazyLoading={noLazyLoading}
              {...props}
            />
          )}

          {noLazyLoading && (
            <ImageWrapper id={'TESTOJER'}>
              <ImageDummy width={width ? `${width}px` : '100%'} height={height ? `${height}px` : '100%'}>
                <ImageDummyTest
                  width={width ? `${width}px` : '100%'}
                  height={height ? `${height}px` : '100%'}
                  src={src}
                  {...props}
                />
                <Helmet>
                  <link rel="preload" href={src} as="image" />
                </Helmet>
              </ImageDummy>
            </ImageWrapper>
          )}
        </Fragment>
      )
    } else {
      return (
        <ImageWrapper>
          <StyledStolLogo />
        </ImageWrapper>
      )
    }
  }
}

Image.propTypes = {
  src: PropTypes.string,
  blur: PropTypes.string,
  aspectRatio: PropTypes.number,
  getDimensions: PropTypes.func,
}

export default Image
