import React from 'react'
import styled from 'styled-components'
import OnTourLogo from './stol_ontour_300x70.svg'
import Link from '../../../../../../components/Link'
import isStolApp from '../../../../../../modules/is-stolapp'
import routes from '../../../../../routes'

let Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.color.multimedia.backgroundSecondary};
`
const StyledOnTourLogo = styled.img``

//TODO namedRoute function didn't work here
const StolOnTour = ({ ...props }) => (
  <Wrapper {...props} {...(!isStolApp() && { as: Link, to: routes.getOptions('ontour').path })}>
    <StyledOnTourLogo src={OnTourLogo} />
  </Wrapper>
)

export default StolOnTour
export { Wrapper as Wrapper0StolOnTour }
