import styled from 'styled-components'
import Button from '../../../../../../components/Button'

const DialogButton = styled(Button)`
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
    margin: 0 0 8px 0 !important;
  }
`

export default DialogButton
