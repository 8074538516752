import React from 'react'
import domToReact from 'html-react-parser/lib/dom-to-react'
import { Helmet } from 'react-helmet-async'
import isServerSide from '../../../is-server-side'

const ExternalTwitter = (attribs, embedBoxChildren) => (
  <div>
    {!isServerSide() && !document.querySelector(`script[src*="${attribs.src}"]`) && (
      <Helmet>
        <script src={attribs.src} {...attribs}></script>
      </Helmet>
    )}
    {domToReact(embedBoxChildren)}
  </div>
)
export default ExternalTwitter
