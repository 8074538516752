import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import MessageText, { colorSets } from '../../../../../UserGeneratedContent/components/FormUGC/components/MessageText'
import React, { useState } from 'react'
import useTimeout from '../../../../../../modules/use-timeout'

const DialogMessage = ({ children, color, fadeOut = false }) => {
  const showMessage = useTimeout({ active: fadeOut })

  if (!showMessage) {
    return false
  }
  return (
    <DialogContent sx={{ m: 0, p: 0 }}>
      <DialogContentText>
        <MessageText color={color}>
          <center>{children}</center>
        </MessageText>
      </DialogContentText>
    </DialogContent>
  )
}

export default DialogMessage

export { colorSets }
