import React from 'react'
import styled from 'styled-components'
import SkeletonBigItemArticle from '../../../../topics/Article/components/ItemArticle/views_default/BigItemArticle/skeleton'
import SkeletonSmallLandScapeArticle from '../../../../topics/Article/components/ItemArticle/views_default/SmallLandscapeItemArticle/skeleton'

const Wrapper = styled.div`
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: 5px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: 0;
  }
  @media (max-width: 450px) {
    margin-right: 0;
    margin-left: 0;
  }
`

const ContainerArticle = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.textTertiary};
  margin-bottom: ${(props) => (props.lastArticle ? 0 : props.theme.sizes.gapVerticalHalf)};
  padding-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => (props.lastArticle ? 0 : props.theme.tablet.sizes.gapVerticalHalf)};
    padding-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => (props.lastArticle ? 0 : props.theme.mobile.sizes.gapVerticalHalf)};
    padding-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
`

const SkeletonBlockBigArticle = ({ ...props }) => (
  <Wrapper {...props}>
    <ContainerArticle>
      <SkeletonBigItemArticle />
    </ContainerArticle>
    <ContainerArticle>
      <SkeletonSmallLandScapeArticle />
    </ContainerArticle>
    <ContainerArticle lastArticle={true}>
      <SkeletonSmallLandScapeArticle />
    </ContainerArticle>
  </Wrapper>
)

SkeletonBlockBigArticle.propTypes = {}

SkeletonBlockBigArticle.defaultProps = {
  margin: '0',
}

export default SkeletonBlockBigArticle
