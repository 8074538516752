const addJokerCardCallback = () => {
  window.clientCallBackFunction = function (jokerCardElement) {
    let maps = document.getElementsByClassName('district_nav')

    if (maps.length !== 1) {
      maps = document.querySelectorAll('main .district_nav')

      if (maps.length !== 1) return
    }

    const map = maps[0]

    map.style = 'box-shadow: none; border: 0'

    jokerCardElement.append(map)
  }
}

export default addJokerCardCallback
