import ReactGA from 'react-ga'

const screenModifyResponseDistrict = ({
  response: {
    data: { identifier, articles },
  },
  response,
}) => {
  const trackCategory = 'district'

  ReactGA.event({ category: trackCategory, action: 'view', label: identifier })

  const trackedArticles = articles.map((article) => {
    article.track = {
      category: 'district',
      label: identifier,
    }

    return article
  })
  response.data.articles = trackedArticles
  return response
}

export default screenModifyResponseDistrict
