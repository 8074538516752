import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import ItemArticle from '../../../../topics/Article/components/ItemArticle'
import AdUnit from '../../../../components/AdUnit'
import ContainerAd from '../../../../components/ContainerAd'
import { ContainerQuery } from 'react-container-query'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
`

const StyledContainerAd = styled(ContainerAd)`
  @media (min-width: ${(props) => props.theme.breakPoint + 'px'}) {
    padding-top: 0;
    > div > div {
      margin-left: ${(props) => props.theme.sizes.gapCol};
    }
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: 100%;
    justify-content: space-evenly;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: 100%;
    justify-content: space-evenly;
  }
`

const ArticleWrapper = styled.div`
  margin-bottom: calc(-${(props) => props.theme.sizes.gapVertical} / 2);
  flex: 1;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: calc(-${(props) => props.theme.tablet.sizes.gapVertical} / 2);
    flex-basis: 100%;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: calc(-${(props) => props.theme.mobile.sizes.gapVertical} / 2);
    flex-basis: 100%;
  }
`
const containerQuery = {
  mobile: {
    maxWidth: 680,
  },
}

class BlockTwoLandscapeArticle extends PureComponent {
  render() {
    const {
      data: { articles },
      adIds,
      noLazyLoading,
      ...props
    } = this.props
    const responsiveAdIds = (themeMode) => {
      if (this.props.theme.mode === 'tablet' && !themeMode.mobile && adIds.length === 2) return adIds
      if (this.props.theme.mode !== 'tablet' || themeMode.mobile) return [adIds[0]]
      return false
    }

    return (
      <ContainerQuery query={containerQuery}>
        {(themeMode) => (
          <Wrapper {...props}>
            <ArticleWrapper themeMode={themeMode} adIds={adIds}>
              {articles[0] && (
                <ItemArticle view="small_landscape" article={articles[0]} noLazyLoading={noLazyLoading} />
              )}
              {articles[1] && (
                <ItemArticle view="small_landscape" article={articles[1]} noLazyLoading={noLazyLoading} />
              )}
            </ArticleWrapper>
            {responsiveAdIds(themeMode) && articles[1] && (
              <StyledContainerAd themeMode={themeMode} adIds={responsiveAdIds(themeMode)} noGapEdge />
            )}
          </Wrapper>
        )}
      </ContainerQuery>
    )
  }
}

BlockTwoLandscapeArticle.propTypes = {
  articles: PropTypes.arrayOf(ItemArticle.propTypes.article),
  adIds: PropTypes.arrayOf(AdUnit.propTypes.adId),
}

export default withTheme(BlockTwoLandscapeArticle)
