import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import WidgetMostRead from '../../../../topics/Article/widgets_line/WidgetMostRead'
import ItemArticle from '../../../../topics/Article/components/ItemArticle'
import ContainerAd from '../../../../components/ContainerAd'
import AdUnit from '../../../../components/AdUnit'
import hasItems from '../../../../modules/has-items'
import ErrorBoundary from '../../../../topics/Error/widgets/ErrorBoundary'
import translate from '../../../../modules/translate'

const WrapperArticleRect = styled.div`
  flex-basis: ${(props) => props.theme.sizes.col4};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.tablet.sizes.col4};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.mobile.sizes.col4};
  }
  display: flex;
  flex-direction: column;
`
const WrapperWidgetMostRead = styled.div`
  flex-basis: ${(props) => props.theme.sizes.col8};
  margin-left: ${(props) => props.theme.sizes.gapCol};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin: 0 -${(props) => props.theme.sizes.gapEdge};
    flex-basis: calc(100% + (2 * ${(props) => props.theme.sizes.gapEdge}));
  }

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin: 0;
    flex-basis: 100%;
  }
`
const StyledItemArticle = styled(ItemArticle)`
  order: 2;
  align-items: center;
`
const StyledContainerAdRectangle = styled(AdUnit)`
  > div > div {
    padding-bottom: ${(props) => props.theme.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    display: none;
  }
`
const StyledContainerAdFullsize = styled(ContainerAd)`
  flex-basis: 100%;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: 0;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    ${WrapperArticleRect} {
      flex-basis: 100%;
    }
    ${StyledItemArticle} {
      order: 1;
      width: 100%;
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${WrapperArticleRect} {
      flex-basis: 100%;
    }
    ${StyledItemArticle} {
      order: 1;
      width: 100%;
    }
  }
`

const BlockMostReadArticle = ({ data: { articles, mostread }, adIds, theme, ...props }) => {
  return (
    <Wrapper {...props}>
      <WrapperArticleRect>
        <StyledItemArticle view="small_portrait" borderBottom="none" article={articles[0]} />
        {adIds && adIds[0] && theme.mode === 'desktop' && <StyledContainerAdRectangle adId={adIds[0]} />}
      </WrapperArticleRect>
      <ErrorBoundary error={!hasItems(mostread) && 'no mostread articles'} component={translate('mostread')}>
        <WrapperWidgetMostRead articles={mostread}>
          <WidgetMostRead articles={mostread} />
        </WrapperWidgetMostRead>
      </ErrorBoundary>
      {adIds && adIds[1] && <StyledContainerAdFullsize adIds={[adIds[1]]} noGapEdge />}
    </Wrapper>
  )
}

BlockMostReadArticle.propTypes = {
  articles: PropTypes.arrayOf(ItemArticle.propTypes.article),
  adIds: PropTypes.arrayOf(AdUnit.propTypes.adId),
}

export default withTheme(memo(BlockMostReadArticle))
export { Wrapper as Wrapper0BlockMostReadArticle, WrapperArticleRect as WrapperArticleRect0BlockMostReadArticle }
