import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from '../../../../components/Link'
import { Img } from 'react-progressive-loader'
import getImageDetails from './modules/get-image-details'

const Wrapper = styled(Link)`
  display: block;
`
const InnerWrapper = styled.div`
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  width: 100%;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const BannerNaked = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`

const StyledLargeImage = styled(Img)`
  height: fit-content;
  display: block;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: none;
  }
`

const StyledSmallImage = styled(Img)`
  height: fit-content;
  display: none;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: block;
  }
`

const BannerBlock = ({ imageSmall, imageLarge, imageHeight, url, ...props }) => {
  if (!imageSmall && !imageLarge) {
    return false
  }
  const largeImage = getImageDetails(imageLarge)
  const smallImage = getImageDetails(imageSmall)

  // To Define
  const customRatioLarge = imageHeight === 'small' ? 100 / 2000 : 0.33
  const customRatioSmall = imageHeight === 'small' ? 100 / 600 : 0.655

  return (
    <Wrapper to={url} {...props}>
      <InnerWrapper>
        <StyledLargeImage
          src={largeImage.url}
          bgColor={props.theme.color.skeletonScreen}
          aspectRatio={largeImage.ratio || customRatioLarge}
          loadOnScreen
        />
        <StyledSmallImage
          src={smallImage.url}
          bgColor={props.theme.color.skeletonScreen}
          aspectRatio={smallImage.ratio || customRatioSmall}
          loadOnScreen
        />
      </InnerWrapper>
    </Wrapper>
  )
}

BannerBlock.propTypes = {
  imageSmall: PropTypes.string,
  imageLarge: PropTypes.string,
  url: PropTypes.string,
}

export default BannerBlock
