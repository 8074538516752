import React from 'react'
import PropTypes from 'prop-types'
import NakedTitleTopic, { StyledTitle0NakedTitleTopic, WrapperTitle0NakedTitleTopic } from './_views/NakedTitleTopic'
import DistrictTitleTopic from './_views/DistrictTitleTopic'
import ScreenTitleTopic from './_views/ScreenTitleTopic'
import PlusTitleTopic from './_views/PlusTitleTopic'
import JobsTitleTopic from './_views/JobsTitleTopic'

const titleTopicComponents = {
  naked: NakedTitleTopic,
  district: DistrictTitleTopic,
  screen: ScreenTitleTopic,
  plus: PlusTitleTopic,
  jobs: JobsTitleTopic,
}

const TitleTopic = ({ view, ...props }) => {
  let TitleTopicComponent = titleTopicComponents[view]

  if (props.title && props.title.includes('s+')) {
    props.title = props.title.split('+')[1]
    TitleTopicComponent = titleTopicComponents['plus']
    return <TitleTopicComponent {...props} />
  }
  if (view === 'jobs' || view.includes('jobwidget')) {
    return <JobsTitleTopic {...props} />
  }
  return <TitleTopicComponent {...props} />
}

TitleTopic.defaultProps = {
  view: 'naked',
}

TitleTopic.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  subelement: PropTypes.node,
}

export default TitleTopic
export {
  StyledTitle0NakedTitleTopic as StyledTitle0TitleTopic,
  WrapperTitle0NakedTitleTopic as WrapperTitle0TitleTopic,
}
