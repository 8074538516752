import React from 'react'
import styled from 'styled-components'
import partnerLogo from './images'
import PropTypes from 'prop-types'

const Wrapper = styled.img``

const PartnerImageArticle = ({ partner, ...props }) => {
  return <Wrapper src={partnerLogo[partner]} {...props} />
}

PartnerImageArticle.propTypes = {
  partner: PropTypes.string,
}

export default PartnerImageArticle
