import Icon from '../../../../../../components/Icon'
import translate from '../../../../../../modules/translate'
import React from 'react'
import styled, { css } from 'styled-components'
import Button from '../../../../../../components/Button'
import Text from '../../../../../../components/Text'

const StyledButton = styled(Button)`
  margin: 0 auto;
`

const WrapperInnerNoEvents = styled.div`
  width: 100%;
  text-align: center;
  margin-top: calc(${(props) => props.theme.sizes.gapVertical} * 2);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: calc(${(props) => props.theme.tablet.sizes.gapVertical} * 2);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: calc(${(props) => props.theme.mobile.sizes.gapVertical} * 2);
  }
`

const StyledText = styled(Text)`
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 700;
  font-size: 28px;
  margin: 0.5em 0;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.2;
  margin-top: calc(${(props) => props.theme.sizes.gapVertical} * 1.5);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: calc(${(props) => props.theme.tablet.sizes.gapVertical} * 1.5);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: calc(${(props) => props.theme.mobile.sizes.gapVertical} * 1.5);
  }
  ${(props) =>
    props.href === undefined &&
    css`
      @media (max-width: 450px) {
        padding-left: 0.3em;
        padding-right: 0.3em;
      }
    `}
`
const StyledRedTest = styled(StyledText)`
  color: ${(props) => props.theme.color.stol};
`

const NoEventsWidgetEvents = (props) => (
  <WrapperInnerNoEvents>
    <StyledButton to="mailto:support@firstavenue.it">
      <Icon icon="email" /> {translate('events_booking_button')}
    </StyledButton>
    <StyledText>
      {translate('events_will_be_found')}{' '}
      <StyledRedTest as="a" href="/events">
        {translate('here')}
      </StyledRedTest>
    </StyledText>
  </WrapperInnerNoEvents>
)

export default NoEventsWidgetEvents
