import isStolApp from '../../modules/is-stolapp'
import React from 'react'
import styled from 'styled-components'
import { ImageFormat } from '../../modules/parse'
import ImageArticle from '../ImageArticle'
import scrollToView from '../../modules/scroll-to-view'
import DisabledPlusVideo from './components/DisabledPlusVideo'
import PropTypes from 'prop-types'
import CircularProgress from '../CircularProgress'
import skeletonFade from '../../modules/mixins/skeleton-fade'
import AppVideoImage from './components/AppVideoImage'
import VideoPlayer from './components/VideoPlayer'
import { useUserContext } from '../ContextUser'

const WrapperVideo = styled.div`
  position: relative;
  width: 100%;
  border: none;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-left: 0;
  }

  .embed-box {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  iframe,
  .jwplayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .jw-state-idle .jw-controls {
    background: none;
  }
`

const onPlusImageClick = () => {
  scrollToView('plusDialog')
}

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const VideoSkeleton = styled.div`
  background-color: ${(props) => props.theme.color.skeletonScreen};
  ${skeletonFade}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const VideoArticle = ({
  video,
  image,
  showAds,
  adsTargeting,
  plus,
  autoplay,
  loading,
  defaultOptions,
  jwAutoStartHandler,
                        isMuted,
  ...props
}) => {
  const {
    access: { isPlus },
  } = useUserContext()

  if (loading || (!video && !image && !adsTargeting)) {
    return <WrapperVideo>{loading && (isStolApp() ? <StyledCircularProgress /> : <VideoSkeleton />)}</WrapperVideo>
  }

  const hidePlus = plus && !isPlus
  let imageUrl = video.jwId && `https://content.jwplatform.com/thumbs/${video.jwId}-720.jpg`

  if (video.imageUrl) imageUrl = video.imageUrl.replace('{dimensionWidth}', 720)
  if (image) imageUrl = new ImageFormat(image.url).format('detail_759x427')

  if (hidePlus) {
    return (
      <ImageArticle
        image={{ url: imageUrl }}
        OverlayComponent={DisabledPlusVideo}
        onClick={onPlusImageClick}
        {...props}
      />
    )
  }

  if (isStolApp() && video) {
    return <AppVideoImage video={video} imageUrl={imageUrl} adsTargeting={adsTargeting} showAds={showAds} />
  }

  if (!hidePlus)
    return (
      <WrapperVideo {...props}>
        <VideoPlayer
          video={video}
          imageUrl={imageUrl}
          adsTargeting={adsTargeting}
          showAds={showAds}
          defaultOptions={defaultOptions}
          jwAutoStartHandler={jwAutoStartHandler}
          autoplay={autoplay}
          isMuted={isMuted}
        />
      </WrapperVideo>
    )
}

VideoArticle.propTypes = {
  video: PropTypes.object,
  image: PropTypes.string,
  showAds: PropTypes.bool,
  adsTargeting: PropTypes.object,
  plus: PropTypes.bool,
  autoplay: PropTypes.bool,
}

VideoArticle.defaultProps = {
  autoplay: false,
}

export default VideoArticle

export { WrapperVideo as WrapperVideo0VideoArticle }
