import TitleTopic from '../../../../components/TitleTopic'
import React from 'react'
import styled from 'styled-components'

const StyledTitleTopic = styled(TitleTopic)`
  margin-bottom: 0;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: 0;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: 0;
  }
`

const BlockTitleTopic = ({ data: { title } = {}, ...props }) => <StyledTitleTopic view="screen" title={title} />

export default BlockTitleTopic
