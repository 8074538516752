import React, { Component } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import PropTypes from 'prop-types'

class ResizeDetector extends Component {
  constructor() {
    super()
    this.ro = new ResizeObserver(this.resizeObserver)
  }

  resizeObserver = (entries) => {
    entries.forEach((entry) => {
      this.onResizeCallback(entry.target)
    })
  }

  componentDidMount() {
    const resizableElement = this.el
    this.ro.observe(resizableElement)
    this.onResizeCallback(resizableElement)
  }

  componentWillUnmount() {
    this.ro.disconnect()
  }

  onResizeCallback = (element) => {
    this.props.onResize(element.getBoundingClientRect(), element)
  }

  render() {
    const props = { ...this.props }
    delete props.onResize
    return (
      <div ref={(el) => (this.el = el)} {...props}>
        {this.props.children}
      </div>
    )
  }
}

ResizeDetector.propTypes = {
  onResize: PropTypes.func.isRequired,
}

export default ResizeDetector
