const options = require('../../config/options')

const createUrl = (urlPattern) => {
  if (!urlPattern) return false
  let url = urlPattern.replace('[ressourcePath]', options.Bundles.ressourcePath)
  url = url.replace('[defaultHost]', options.Bundles.defaultHost)
  return url
}

export default createUrl
