import React, { Component } from 'react'
import axios from 'axios'
import errorLog from '../../../../modules/error-log'
import Config from '../../../../config'
import isServerSide from '../../../../modules/is-server-side'
import NotFoundMessage from '../../components/NotFoundMessage'
import loadable from '@loadable/component'
const LayoutDefault = loadable(() =>
  import(/* webpackChunkName: "LayoutDefault" , webpackPrefetch: true */ '../../../../layouts/Default'),
)

class NotFoundError extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 404,
    }
  }
  componentDidMount() {
    let isoLocation =
      this.props.history.location && this.props.history.location.state && this.props.history.location.state.location
        ? this.props.history.location && this.props.history.location.state && this.props.history.location.state.location
        : window && window.location
    if (isoLocation) {
      if (!isServerSide()) {
        let url =
          Config.restUrl('').split('/api/rest/') && Config.restUrl('').split('/api/rest/')[0] + isoLocation.pathname
        axios(url)
          .then((response) => {
            errorLog({ error: 'Got response for undefined screen', info: response, type: 'not-found', level: 'info' })
          })
          .catch((error) => {
            if (error && error.response) {
              this.setState({ status: error.response.status })
            }
          })
      }
    }
  }
  render() {
    const { ...props } = this.props
    const meta = {
      title: 'Ups! 404 - Seite nicht gefunden | stol.it',
      adsTargeting: {
        STOL7_seite: '404',
      },
    }
    return (
      <LayoutDefault meta={meta}>
        <NotFoundMessage status={this.state.status} {...props} />
      </LayoutDefault>
    )
  }
}

NotFoundError.defaultProps = {
  logError: 'NotFoundError',
}

export default NotFoundError
