import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import WidgetOnTourItems from '../..'

const StyledWidgetOnTourItems = styled(WidgetOnTourItems)``

const Wrapper = styled.div`
  margin-bottom: 5px;
  background-color: #ffff;
  @media (max-width: 790px) {
    position: relative;
    overflow: hidden;
    height: 210px;
    ${StyledWidgetOnTourItems} {
      justify-content: left;
      flex-wrap: nowrap;
      overflow-y: hidden;
    }
  }
`

const WidgetOnTourWithButtonsItems = ({ onTourItems, ...props }) => {
  return (
    <Wrapper {...props}>
      <StyledWidgetOnTourItems
        onTourItems={onTourItems}
        numberItems={props.numberItems ? props.numberItems : 3}
        view="widgetOnTourWithButtons"
      />
    </Wrapper>
  )
}

WidgetOnTourWithButtonsItems.propTypes = {
  onTourItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        copyright: PropTypes.string,
        id: PropTypes.number,
        ratio: PropTypes.number,
        url: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
      headline: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
}

export default WidgetOnTourWithButtonsItems
