import React from 'react'
import styled, { css } from 'styled-components'
import TextArticle from '../../../../../../../../components/TextArticle'
import PropTypes from 'prop-types'

const Wrapper = styled(TextArticle)`
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  margin-top: 10px;
  line-height: ${(props) => props.theme.lineHeight.text};
  text-shadow: 0 0 0px ${(props) => props.theme.color.textTertiary};
  margin-bottom: 0;
  ${(props) =>
    props.inverted === 1 &&
    css`
      color: ${(props) => props.theme.color.textInverted};
      background-color: ${(props) => props.theme.color.multimedia[props.inverted_background_color]};
    `};
`
const TextItemArticle = ({ inverted, children, truncateOptions, inverted_background_color, ...props }) => (
  <Wrapper
    inverted={inverted}
    truncateOptions={truncateOptions}
    inverted_background_color={inverted_background_color}
    tag="div"
    {...props}
  >
    {children}
  </Wrapper>
)

TextItemArticle.defaultProps = {
  inverted_background_color: 'backgroundTertiary',
}

TextItemArticle.propTypes = {
  children: PropTypes.node,
  inverted: PropTypes.number,
}

export default TextItemArticle
export { Wrapper as Wrapper0TextItemArticle }
