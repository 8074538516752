import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import 'moment/locale/de'
import Text from '../Text'
import moment from 'moment/moment'

const Wrapper = styled(Text)``

const Date = ({ children, format, ...props }) => {
  let date = moment(children).locale('de').format(format)

  if (!moment(children, moment.ISO_8601).isValid()) {
    date = ''
  }

  return <Wrapper {...props}>{date}</Wrapper>
}

Date.propTypes = {
  children: PropTypes.string,
  format: PropTypes.string,
}

Date.defaultProps = {
  format: 'LLL',
}

export default Date
