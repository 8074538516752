import React from 'react'
import styled, { withTheme } from 'styled-components'
import translate from '../../../../modules/translate'
import WidgetNakedLineArticle from '../WidgetNakedLineArticle'
import MailIconWidgetEvents from './components/MailIconWidgetEvents'
import NoEventsWidgetEvents from './components/NoEventsWidgetEvents'

const StyledWidgetNakedLineArticle = styled(WidgetNakedLineArticle)`
  padding-left: ${(props) => props.theme.sizes.gapColHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapColHalf};
  }
`

const reMap = (articles) => {
  return articles.map((article) => {
    const place = article.place

    return {
      breadcrumb: {
        headline: {
          name: place,
          route: '#',
        },
      },
      ...article,
      isEvent: true,
    }
  })
}

const WidgetEvents = ({ articles, ...props }) => {
  const hasEvents = articles && articles.length > 0

  return (
    <StyledWidgetNakedLineArticle
      articles={hasEvents && reMap(articles)}
      timeLine
      headlinePrefix={translate('location_prefix')}
      title={translate('ontour_event_tips_title')}
      forceTarget={'_blank'}
      {...props}
    >
      <MailIconWidgetEvents />
      {!hasEvents && <NoEventsWidgetEvents />}
    </StyledWidgetNakedLineArticle>
  )
}

WidgetEvents.propTypes = {
  article: WidgetNakedLineArticle.propTypes.articles,
}

export default withTheme(WidgetEvents)
