import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ItemArticle from '../../../../topics/Article/components/ItemArticle'
import WidgetVideo from '../../../../topics/Video/widgets/WidgetVideo'
import AdUnit from '../../../../components/AdUnit'
import hasItems from '../../../../modules/has-items'
import ErrorBoundary from '../../../../topics/Error/widgets/ErrorBoundary'
import translate from '../../../../modules/translate'

const Wrapper = styled.div``

const StyledWidgetVideo = styled(WidgetVideo)`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-left: -${(props) => props.theme.sizes.gapEdge};
  margin-right: -${(props) => props.theme.sizes.gapEdge};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
    margin-left: -${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: -${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
    margin-left: -${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: -${(props) => props.theme.mobile.sizes.gapEdge};
  }
  @media (max-width: 450px) {
    margin-right: 0;
    margin-left: 0;
  }
`

class BlockVideoArticle extends PureComponent {
  render() {
    const { data, ...props } = this.props
    return (
      <Wrapper {...props}>
        {hasItems(data) && (
          <ErrorBoundary component={translate('video_error')}>
            <StyledWidgetVideo videos={data} />
          </ErrorBoundary>
        )}
      </Wrapper>
    )
  }
}

BlockVideoArticle.propTypes = {
  articles: PropTypes.arrayOf(ItemArticle.propTypes.article),
  adIds: PropTypes.arrayOf(AdUnit.propTypes.adId),
}

export default BlockVideoArticle
