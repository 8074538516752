import React from 'react'
import domToReact from 'html-react-parser/lib/dom-to-react'
import OnLoadedDomElement from '../../../on-loaded-dom-element'
import isServerSide from '../../../is-server-side'

const addInstagramScript = ({ src }) => {
  if (!isServerSide()) {
    if (!document.getElementById('instagram-embed-script')) {
      let fbScript = document.createElement('script')
      fbScript.setAttribute('src', src)
      fbScript.setAttribute('type', 'text/javascript')
      fbScript.setAttribute('id', 'instagram-embed-script')
      fbScript.setAttribute('onload', 'if(window.instgrm){window.instgrm.Embeds.process()}')
      document.head.appendChild(fbScript)
    } else if (window.instgrm) {
      window.instgrm.Embeds.process()
    }
  }
}

const ExternalInstagram = (attribs, embedBoxChildren) => (
  <>
    {domToReact(embedBoxChildren)}
    <OnLoadedDomElement callback={addInstagramScript} src={attribs.src} />
  </>
)

export default ExternalInstagram
