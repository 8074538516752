import React from 'react'
import styled from 'styled-components'
import NakedOnTourHeader from '../NakedOnTourHeader'
import PoweredBy, { Wrapper0PoweredBy } from '../../components/PoweredBy'
import { Wrapper0StolOnTour } from '../../components/StolOnTour'

const StyledNakedOnTourHeader = styled.div`
  align-items: start;
  height: 200px;
  position: relative;
  ${Wrapper0PoweredBy} {
    margin-top: 10px;
    align-items: center;
  }
  ${Wrapper0StolOnTour} {
    margin: 30px 0 0;
  }
  @media (max-width: 790px) {
    justify-content: center;
    height: auto;
    ${Wrapper0PoweredBy} {
      flex-direction: column;
      width: 100%;
      margin-bottom: 20px;
      padding: 0;
    }
    ${Wrapper0StolOnTour} {
      margin-top: 20px;
    }
  }
`

const HeaderOnTour = ({ ...props }) => (
  <StyledNakedOnTourHeader as={NakedOnTourHeader} {...props}>
    <PoweredBy />
  </StyledNakedOnTourHeader>
)

export default HeaderOnTour
export { StyledNakedOnTourHeader, Wrapper0PoweredBy, Wrapper0StolOnTour }
