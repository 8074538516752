import React from 'react'

const Play = (props) => (
  <svg width="240" height="240" viewBox="0 0 240 240" {...props}>
    <g>
      <title>background</title>
      <rect fill="none" id="canvas_background" height="602" width="802" y="-1" x="-1" />
    </g>
    <g>
      <title>Layer 1</title>
      <path
        fill="#ffffff"
        id="svg_1"
        d="m62.8,199.5c-1,0.8 -2.4,0.6 -3.3,-0.4c-0.4,-0.5 -0.6,-1.1 -0.5,-1.8l0,-154.7c-0.2,-1.3 0.7,-2.4 1.9,-2.6c0.7,-0.1 1.3,0.1 1.9,0.4l154.7,77.7c2.1,1.1 2.1,2.8 0,3.8l-154.7,77.6z"
      />
    </g>
  </svg>
)

export default Play
