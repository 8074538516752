import userSession from '../../../../modules/user-session'
import { Redirect } from 'react-router'
import { getStoredItem, clearStoredItem } from '../../../../../../modules/stored-item'
import React from 'react'
import isServerSide from '../../../../../../modules/is-server-side'

const LogoutPiano = () => {
  userSession.clear()

  if (!isServerSide()) {
    window.tp.push([
      'init',
      function () {
        window.tp.pianoId.logout()
      },
    ])
  }

  const redirectUrl = getStoredItem('login_redirect')
  clearStoredItem('login_redirect')
  return <Redirect to={redirectUrl} />
}

export default LogoutPiano
