import React from 'react'
import { Helmet } from 'react-helmet-async'
import Options from '../../config/options'

const generateMeta = (meta) => {
  let elements = []

  const Config = Options.Meta

  const addElement = (property, content) => {
    elements.push(<meta key={'meta_' + property} name={property} property={property} content={content} />)
  }

  addElement('og:site_name', 'stol.it')
  addElement('application-name', 'stol.it')
  addElement('apple-mobile-web-app-title', 'stol.it')
  addElement('al:iphone:app_store_id', Config.app_store_id)
  addElement('al:iphone:app_name', 'stol.it')
  addElement('al:android:package', Config.play_store_id)
  addElement('al:android:app_name', 'stol.it')
  addElement('google-play-app', 'app-id=' + Config.play_store_id)
  addElement('al:web:should_fallback', 'true')

  if (meta.adsTargeting && meta.adsTargeting.STOL7_id && meta.url) {
    let url = 'stolmobile://article/open?id=' + meta.adsTargeting.STOL7_id + '&url=' + meta.url

    addElement('al:android:url', url)
    addElement('al:iphone:url', url)
    addElement('apple-itunes-app', 'app-id=' + Config.app_store_id + ', app-argument=' + url)
  } else {
    addElement('apple-itunes-app', 'app-id=' + Config.app_store_id)
  }

  if (
    meta.adsTargeting &&
    ((meta.adsTargeting.STOL7_seite && meta.adsTargeting.STOL7_seite === 'Artikel') ||
      (meta.adsTargeting.STOL7_artikel && meta.adsTargeting.STOL7_artikel === 'Video'))
  ) {
    addElement('og:type', 'article')
  }

  Object.keys(meta).forEach((metaKey) => {
    switch (metaKey) {
      case 'adsTargeting':
        return
      case 'title':
        elements.push(<title key={'title_' + metaKey}>{meta[metaKey]}</title>)
        addElement('og:title', meta[metaKey])
        return
      case 'image':
        addElement('og:image', meta[metaKey].url)
        addElement('og:image:height', meta[metaKey].height)
        addElement('og:image:width', meta[metaKey].width)
        return
      case 'headline':
        addElement('og:headline', meta[metaKey])
        return
      case 'description':
        addElement('og:description', meta[metaKey])
        break
      case 'url':
        addElement('og:url', meta[metaKey])
        break
      default:
    }

    addElement(metaKey, meta[metaKey])
  })

  //canonical url tag
  elements.push(<link rel="canonical" href={meta['url']} />)

  return elements
}

const Meta = ({ meta, ...props }) => <Helmet>{meta && generateMeta(meta)}</Helmet>

export default Meta
