import optionsJwPlayerApp from '../../../../modules/options-jwplayer-app'
import ImageArticle from '../../../ImageArticle'
import PlayButton from '../PlayButton'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTransparentLink = styled.a`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
`

const AppVideoImage = ({ video, imageUrl, showAds, adsTargeting, ...props }) => (
  <>
    <StyledTransparentLink
      href={
        video.type === 'youtube' || video.type === 'embed'
          ? video.url
          : video.type === 'jw' && optionsJwPlayerApp(video.jwId, showAds, adsTargeting)
      }
    />
    <ImageArticle image={{ url: imageUrl }} OverlayComponent={PlayButton} {...props} />
  </>
)

AppVideoImage.propTypes = {
  video: PropTypes.shape({
    type: PropTypes.oneOf(['youtube', 'embed']),
    jwId: PropTypes.string,
    url: PropTypes.string,
  }),
  imageUrl: PropTypes.string,
  showAds: PropTypes.bool,
  adsTargeting: PropTypes.object,
}

export default AppVideoImage
