import SkeletonSmallLandscapeItemArticle from '../../../../topics/Article/components/ItemArticle/views_default/SmallLandscapeItemArticle/skeleton'
import React from 'react'
import styled from 'styled-components'

const WrapperSkeletonSmallLandscapeItemArticle = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const SkeletonBlockThreeLandscapeArticle = ({ numberItems = 3 }) => {
  const SmallLandscapeItemArticles = []
  for (let i = 0; i < numberItems; i++) {
    SmallLandscapeItemArticles.push(
      <WrapperSkeletonSmallLandscapeItemArticle
        key={i + ' WrapperSkeletonSmallLandscapeItemArticle0SkeletonListDepartment'}
      >
        <SkeletonSmallLandscapeItemArticle />
      </WrapperSkeletonSmallLandscapeItemArticle>,
    )
  }
  return SmallLandscapeItemArticles
}

export default SkeletonBlockThreeLandscapeArticle
