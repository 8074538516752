import React from 'react'
import translate from '../../../../modules/translate/index'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import MessageError from '../../../Error/screens/MessageError'
import PropTypes from 'prop-types'
import PagePaper from '../../components/PagePaper'
import CircularProgress from '../../../../components/CircularProgress'

const errorOptions = {
  component: translate('paper'),
  link: '/',
  linkText: translate('error_try_homepage'),
  logError: 'ScreenPaper screen display',
}

const ScreenPaper = ({ response: { data }, error, loading, ready, ...props }) => (
  <ErrorBoundary {...errorOptions} {...props}>
    {error && <MessageError logInfo={error.message} {...errorOptions} />}
    {loading && <CircularProgress align="center" />}
    {data && <PagePaper data={data} />}
  </ErrorBoundary>
)

ScreenPaper.propTypes = {
  response: PropTypes.shape({
    meta: PropTypes.object,
    data: PagePaper.propTypes.data,
  }),
  loading: PropTypes.bool,
  error: PropTypes.object,
  ready: PropTypes.bool,
  match: PropTypes.object,
}

ScreenPaper.defaultProps = {
  response: {
    data: undefined,
  },
}

export default ScreenPaper
