// changes the first letter of a given string to uppercase.
// TODO implement toLowerCase()

const firstLetterToUpperCase = (params) => {
  if (typeof params === 'string') {
    return params.charAt(0).toUpperCase() + params.slice(1)
  }
  return null
}

export default firstLetterToUpperCase
