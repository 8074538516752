import translate from '../../../../../../modules/translate'
import React from 'react'
import DialogMessage, { colorSets } from '../DialogMessage'
import { useMutation } from '@tanstack/react-query'
import apiConnector from '../../../../../../modules/api-connector'
import userSession from '../../../../modules/user-session'
import useTimeout from '../../../../../../modules/use-timeout'
import { useUserContext } from '../../../../../../components/ContextUser'
import DialogActions from '../DialogActions'
import DialogButton from '../DialogButton'

const DialogContentSendSMS = ({ onClose = () => {}, onSuccess = () => {} }) => {
  const { profile } = useUserContext()

  const mutation = useMutation({
    mutationFn: (data) => {
      return apiConnector({
        location: 'sendSms',
        method: 'post',
        data: data,
        access: userSession.getAccessToken(),
      })
    },
  })
  const showMessage = useTimeout({ active: mutation.isSuccess, timeout: 3000 })

  if (!showMessage) {
    onSuccess()
    return false
  }

  const blockDialog = mutation.isSuccess || mutation.isLoading

  return (
    <>
      {mutation.isError && (
        <DialogMessage color={colorSets.error}>
          {mutation.error?.response?.data?.error || mutation.error?.response?.data?.errors}
        </DialogMessage>
      )}
      {mutation.isSuccess && (
        <DialogMessage color={colorSets.success}>{translate('verification_send_success')}</DialogMessage>
      )}
      <DialogActions>
        <DialogButton
          onClick={() => {
            mutation.mutate({ phone: profile && profile.user_phone })
          }}
          disabled={blockDialog}
          loading={mutation.isLoading}
        >
          {translate('verification_send_button')}
        </DialogButton>
        <DialogButton onClick={onClose} disabled={blockDialog} view="grey">
          {translate('close')}
        </DialogButton>
      </DialogActions>
    </>
  )
}

export default DialogContentSendSMS
