import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import DescriptionItemArticle, {
  Title0DescriptionItemArticle,
  TitleLink0DescriptionItemArticle,
  TopLine0DescriptionItemArticle,
} from '../../components/DescriptionItemArticle'
import Link from '../../../../../../components/Link'
import ImageItemArticle from '../../../../../../components/ImageArticle'
import ReactGA from 'react-ga'

const StyledImageItemArticle = styled(ImageItemArticle)``

const StyledDescriptionItemArticle = styled(DescriptionItemArticle)`
  position: relative;
`

const Wrapper = styled.article`
  position: relative;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: stretch;
  align-content: flex-start;
  ${(props) =>
    props.fixed &&
    css`
      outline: 2px solid ${(props) => props.theme.color.stol};
    `}
`

const MobileLinkOverlay = styled(Link)`
  background-color: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  @media (max-width: 450px) {
    display: block;
  }
`

const NakedItemArticle = ({
  url,
  image,
  video,
  plus,
  hasVideo,
  department,
  headline,
  headlineInImage,
  title,
  titleInImage,
  description,
  type,
  local,
  partner,
  partnerUrl,
  children,
  inverted,
  truncateTitle,
  truncateText,
  inverted_background_color,
  fixed,
  moreLink,
  date,
  track,
  adsTargeting,
  descriptionParent,
  to,
  noLazyLoading,
  ...props
}) => {
  let trackOnClick
  if (track)
    trackOnClick = () => {
      ReactGA.event({
        category: track.category,
        action: 'article click',
        label: track.label,
        value: `${title} | ${url}`,
      })
    }
  const DescriptionParent = descriptionParent
  return (
    <Wrapper fixed={fixed} {...props}>
      <MobileLinkOverlay to={to} />
      <StyledImageItemArticle
        date={date}
        url={url}
        onClick={trackOnClick}
        image={image}
        video={video}
        headline={headline}
        department={department}
        title={title}
        type={type}
        local={local}
        partner={partner}
        communityLogo={partnerUrl}
        hasVideo={hasVideo}
        description={description}
        adsTargeting={adsTargeting}
        to={to}
        parentClass={props.className && props.className}
        noLazyLoading={noLazyLoading}
      />
      <DescriptionParent>
        <StyledDescriptionItemArticle
          date={date}
          url={url}
          onClick={trackOnClick}
          partner={partner}
          department={department}
          headline={headline}
          title={title}
          inverted={inverted}
          video={video}
          plus={plus}
          image={image}
          description={description}
          truncateTitle={truncateTitle}
          truncateText={truncateText}
          inverted_background_color={inverted_background_color}
          moreLink={moreLink}
          adsTargeting={adsTargeting}
          to={to}
          nextToAnother={props.nextToAnother}
        />
      </DescriptionParent>
      {children}
    </Wrapper>
  )
}

NakedItemArticle.defaultProps = {
  ...ImageItemArticle.defaultProps,
  descriptionParent: Fragment,
}

NakedItemArticle.propTypes = {
  ...ImageItemArticle.propTypes,
  ...DescriptionItemArticle.propTypes,
  partner: PropTypes.string,
  children: PropTypes.node,
  headlineInImage: PropTypes.bool,
  descriptionParent: PropTypes.func,
}

export default NakedItemArticle

export {
  StyledImageItemArticle as Image0NakedItemArticle,
  StyledDescriptionItemArticle as Description0NakedItemArticle,
  Title0DescriptionItemArticle as Title0NakedItemArticle,
  TitleLink0DescriptionItemArticle as TitleLink0NakedItemArticle,
  TopLine0DescriptionItemArticle as TopLine0NakedItemArticle,
  Wrapper as Wrapper0NakedItemArticle,
}
