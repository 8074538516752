import isServerSide from '../../../../modules/is-server-side'

const checkUrlForRedirect = (url) => {
  let isExternal = undefined
  let internalUrl = undefined
  const hasProtocol = url.indexOf('://') > 0 || url.indexOf('//') === 0
  if (hasProtocol && !isServerSide()) {
    const urlLocation = document.createElement('a')
    urlLocation.href = url
    if (urlLocation.host !== location.host) {
      isExternal = true
    } else {
      internalUrl = urlLocation.pathname
    }
  }
  return {
    isExternal,
    internalUrl,
  }
}

export default checkUrlForRedirect
