import React, { useContext } from 'react'
import loadable from '@loadable/component'
const AdsProvider = loadable(() =>
  import(/* webpackChunkName: "AdsProvider", webpackPrefetch: true */ './components/AdsProvider'),
)

const ContextAds = React.createContext({ adsDispatch: () => {}, targets: {}, showAds: true, videoTargetUri: '' })

const useAdsContext = () => useContext(ContextAds)

export default ContextAds

export { AdsProvider, useAdsContext }
