import React, { Fragment, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Link from '../../../../../../../../components/Link'
import translate from '../../../../../../../../modules/translate'
import Button from '../../../../../../../../components/Button'
import PropTypes from 'prop-types'
import errorLog from '../../../../../../../../modules/error-log'
import DefaultAvatar from '../../../../../../../../images/DefaultAvatar'
import ReactGA from 'react-ga'
import { useAsync } from 'react-async'
import { actionPOST } from '../../../../../../../../modules/fetch-data'
import userSession from '../../../../../../../Profile/modules/user-session'
import isStolApp from '../../../../../../../../modules/is-stolapp'
import { useCookies } from 'react-cookie'
import showModalId from '../../../../../../../../vendor/Piano/modules/show-modal-id'
import ErrorBoundary from '../../../../../../../Error/widgets/ErrorBoundary'
import { useUserContext } from '../../../../../../../../components/ContextUser'
import consoleLogger from '../../../../../../../../modules/console-logger'
import pushEventToDataLayer from '../../../../../../../../modules/push-event-to-data-layer'
import routes from '../../../../../../../routes'
import { CircularProgress } from '@mui/material'

const TextAreaWrapper = styled.div`
  margin: 10px;
  flex: 3;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 100%;
`
const StyledInfo = styled.div`
  display: block;
  flex-basis: 100%;
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  font-family: ${(props) => props.theme.font.primary};
`

const StyledImageDiv = styled.div`
  height: 80px;
  min-width: 80px;
  max-width: 80px;
  max-height: 80px;
  background-color: ${(props) => props.theme.color.backgroundSecondary};
  margin: 10px;
`
const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
`

const StyledSendContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75em;
  margin-top: 0.5em;
`

const showButton = css`
  visibility: visible;
  opacity: 1;
  transition: background-color 0.2s ease-in, box-shadow 0.1s ease-in-out, border-color 0.2s ease-in-out,
    color 0.15s ease-in-out, opacity 1s, visibility 1s ease-in-out;
`

const StyledSendButton = styled(Button)`
  ${showButton}
`

const StyledTextArea = styled.textarea`
  ${(props) =>
    props.hasText
      ? css`
          min-height: 100px;
        `
      : css`
          min-height: 58px;
        `}
  max-width: 100%;
  box-sizing: border-box;
  resize: none;
  margin-bottom: 10px;
  flex-basis: 100%;
  border: 1px solid ${(props) => props.theme.color.backgroundBorder};
  padding: 10px;
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  font-family: ${(props) => props.theme.font.primary};
  transition: all 0.5s ease;
  display: flex;

  &:focus ~ ${StyledSendButton} {
    ${showButton}
  }

  &:focus {
    outline: none !important;
    box-shadow: 4px 4px 2px ${(props) => props.theme.color.backgroundBorder};
    min-height: 100px;
  }
`
const InfoSuccess = styled.div`
  font-weight: bold;
`
const InfoWarning = styled.div`
  font-weight: bold;
  margin-top: 5px;
`
const InfoError = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.color.stol};
`

const StyledDefaultAvatar = styled(DefaultAvatar)``

const Wrapper = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.color.backgroundSecondary};
  justify-content: center;
  align-items: flex-start;
  padding: 10px 10px;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${StyledSendContainer} {
      margin: 0 auto;
      margin-top: 1.5em;
      flex-direction: column;
    }

    ${StyledSendButton} {
      padding-top: 1em;
      padding-bottom: 1em;
    }

    ${StyledSendContainer}, ${StyledSendButton} {
      width: 100%;
      box-sizing: border-box;
    }
  }
`

/**
 * Amount of time a user has to wait until it can send a new comment (milliseconds)
 */
const COMMENT_SEND_TIMEOUT_MS = 10000

const AddComments = ({ onClick, data, isReady, onSuccess, autoFocus, hasAccess, customCommentValue, ...props }) => {
  const [consoleLog] = consoleLogger('AddComments')
  const [textAreaValue, setTextAreaValue] = useState(customCommentValue)
  const [commentTimeoutActive, setCommentTimeoutActive] = useState(false)
  const [commentTimeoutProgress, setCommentTimeoutProgress] = useState(50)
  const [, , removeCookie] = useCookies(['stol_jwt_token'])
  const { profile } = useUserContext()
  const access = userSession.getAccessToken()

  consoleLog('profile', profile, 'UseUserData')

  if (profile && !profile.verified) {
    hasAccess = false
  }

  let commentTimeoutInterval = null

  useEffect(() => {
    return () => {
      clearTimeout(commentTimeoutInterval)
      commentTimeoutInterval = null
    }
  }, [])

  const onSendComment = (data) => {
    onSuccess(data)
    setCommentTimeoutActive(true)
    setTextAreaValue('')

    setCommentTimeoutProgress(100)

    commentTimeoutInterval = setInterval(() => {
      setCommentTimeoutProgress((prev) => {
        if (prev <= 0) {
          clearTimeout(commentTimeoutInterval)
          setCommentTimeoutActive(false)

          return 100
        }

        return prev - 1
      })
    }, COMMENT_SEND_TIMEOUT_MS / 100)
  }

  const { isPending, isFulfilled, error, run } = useAsync({
    deferFn: actionPOST,
    access,
    location: 'comment',
    onResolve: onSendComment,
  })

  if (error) {
    errorLog({ error: 'POST Comment', info: `Could not send comment with this data: ${data}` })
  }

  const onChangeTextArea = (e) => {
    if (e.target.value.length <= 999) {
      setTextAreaValue(e.target.value)
    }
  }
  const onSend = () => {
    if (!commentTimeoutActive && data && data.comment !== '') {
      {
        run({
          data,
          onError: (response) => {
            const httpStatus = response && response.error && response.error.http_status && response.error.http_status
            if (isStolApp() && httpStatus === 401) {
              removeCookie('stol_jwt_token')
              userSession.clear()
              showModalId({ appRedirect: '/comment_sending_error' })
            }
          },
        })
      }
      ReactGA.event({ category: 'Comment', action: 'send comment' })
      pushEventToDataLayer('AddComment')
    }
  }
  return (
    <ErrorBoundary info={translate('dev_errorlog_addcomment')}>
      <Wrapper {...props}>
        <StyledImageDiv>
          <StyledDefaultAvatar />
        </StyledImageDiv>
        <TextAreaWrapper>
          <StyledTextArea
            disabled={isPending || !hasAccess || commentTimeoutActive}
            onChange={onChangeTextArea}
            hasText={data && data.comment}
            value={textAreaValue}
            autoFocus={autoFocus}
          />
          <StyledInfo>
            {!profile && (
              <Fragment>
                <StyledLink onClick={showModalId}>{translate('comments_add_signin1') + ' '}</StyledLink>
                {translate('comments_add_signin2')}
              </Fragment>
            )}
            {profile && `${translate('logged_in_as')} ${profile.firstName} ${profile.lastName}`}
            {profile && !profile.verified && (
              <InfoWarning>
                {translate('verification_message', { customMessage: translate('comments_verification') })}
                <StyledLink to={routes.generatePathByRouteName('account')}> {translate('user_account')}</StyledLink>
              </InfoWarning>
            )}
            {isFulfilled && commentTimeoutActive && <InfoSuccess>{translate('comments_info_success')}</InfoSuccess>}
            {isPending && <InfoSuccess>{translate('comments_info_loading')}</InfoSuccess>}
            {error && <InfoError>{translate('comments_info_error')}</InfoError>}
          </StyledInfo>
          <StyledSendContainer>
            {commentTimeoutActive && (
              <CircularProgress variant="determinate" value={commentTimeoutProgress} size={15} />
            )}

            <StyledSendButton onClick={onSend} disabled={!hasAccess || commentTimeoutActive} loading={isPending}>
              {translate('comments_add_send')}
            </StyledSendButton>
          </StyledSendContainer>
        </TextAreaWrapper>
      </Wrapper>
    </ErrorBoundary>
  )
}

AddComments.propTypes = {
  data: PropTypes.shape({
    comment: PropTypes.string,
    date: PropTypes.string,
    articleId: PropTypes.number,
    userId: PropTypes.number,
  }),
  isReady: PropTypes.bool,
  onChange: PropTypes.func,
  onSuccess: PropTypes.func,
  onClick: PropTypes.func,
}

AddComments.defaultProps = {
  isReady: false,
  customCommentValue: '',
  autoFocus: false,
}

export default AddComments
