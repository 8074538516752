import React, { Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import WidgetOnTourWithButtons from '../../../../topics/OnTour/widgets/WidgetOnTourWithButtons'

const StyledWidgetOnTourWithButtons = styled(WidgetOnTourWithButtons)`
  background-color: white;
  @media (max-width: 790px) {
    overflow-x: hidden;
  }
`

const OnTour = ({ data, ...props }) => (
  <Fragment>
    <StyledWidgetOnTourWithButtons onTourItems={data} {...props} />
  </Fragment>
)

OnTour.defaultProps = {
  view: 'widgetOnTourWithButtons',
}

export default withTheme(OnTour)
